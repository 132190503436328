import TenantApi from '@/api/TenantApi';
import store from '../store/index';
import router from '../router/index';

export default {
    async getAllCollectionPages(params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const tenants: any[] = [];
        const pageResponses = await TenantApi.getAllCollectionPages(params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                tenants.push({
                    uuid: userObj.id,
                    name: userObj.attributes.name,
                    urlName: userObj.attributes.urlName,
                    uniqueAksId: userObj.attributes.uniqueAksId,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                    deletedAt: userObj.meta.deletedAt,
                });
            });
        });
        return tenants;
    },
    async getSingle(tenantUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await TenantApi.getSingle(tenantUUID);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            urlName: response.data.data.attributes.urlName,
            uniqueAksId: response.data.data.attributes.uniqueAksId,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            deletedAt: response.data.data.meta.deletedAt,
        };
    },
    async createTenant(name: string, uniqueAksId?: boolean): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await TenantApi.createTenant(name, uniqueAksId);

        if(response.data.data.type && response.data.data.type == 'tenants') {

            return {
                uuid: response.data.data.id,
                name: response.data.data.attributes.name,
                urlName: response.data.data.attributes.urlName,
                uniqueAksId: response.data.data.attributes.uniqueAksId,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                deletedAt: response.data.data.meta.deletedAt,
            };
        } else {
            throw TypeError("Response not type tenants");
        }
    },
    async updateTenant(tenantUUID: string, name = "", uniqueAksId?: boolean): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await TenantApi.updateTenant(tenantUUID, name, uniqueAksId);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            urlName: response.data.data.attributes.urlName,
            uniqueAksId: response.data.data.attributes.uniqueAksId,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            deletedAt: response.data.data.meta.deletedAt,
        };
    },
    async deleteTenant(tenantUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await TenantApi.deleteTenant(tenantUUID);
    },
    async restoreTenant(tenantUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await TenantApi.restoreTenant(tenantUUID);
    },
    async getAssociatedUsers(tenantUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const users: any[] = [];
        const response = await TenantApi.getAssociatedUsers(tenantUUID);

        response.data.data.forEach((userObj: any) => {
            users.push({
                uuid: userObj.id,
                email: userObj.attributes.email,
                role: userObj.attributes.role,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                lastActiveAt: userObj.meta.lastActiveAt,
            })
        });

        return users;
    },
    async removeUser(tenantUUID: string, userUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await TenantApi.removeUser(tenantUUID, userUUID);
    },
    async addUser(tenantUUID: string, userUUID: string, role: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await TenantApi.addUser(tenantUUID, userUUID, role);
    },
}
  