
    import Vue from 'vue'

    import ExtendedMultiChart from '@/components/visualization/ExtendedMultiChart.vue';
    import DeviceInfoDialog from '@/components/entities/device/DeviceInfoDialog.vue';
    import ReadingService from '@/services/ReadingService';
    import DeviceService from '@/services/DeviceService';
    import { autoComplete } from '@/utils/Util';
    import TimeRangeFilter from '../filters/TimeRangeFilter.vue';
    import AggregationIntervalFilter from '../filters/AggregationIntervalFilter.vue';
    import SelectionFilter from '../filters/SelectionFilter.vue';
    import { ReadingAggregationFrequencies, ReadingAggregationFunctions } from '../entities/reading/ReadingCollections';


    export default Vue.extend({
        name: 'MultiChartCard',

        components: {
            ExtendedMultiChart,
            DeviceInfoDialog,
            TimeRangeFilter,
            AggregationIntervalFilter,
            SelectionFilter
        },
        props: ['item'],
        data: (_this: any): any => ({
            devices: [] as any[],
            loadingDevices: false,
            requiredSelect: [
                (v: any) => (!!v && Object.keys(v).length > 0) || _this.requiredError
            ],
            loading: false,
            downloadLoading: false,
            timeFunctions: ReadingAggregationFunctions,
            timeFrequencies: ReadingAggregationFrequencies,
            selection: _this.item.config.selection ?? [],
            selectionData: [] as any[],
            selectedAggregation: _this.item.config.aggregation ?? { aggregationInterval: 1,  aggregationFrequency: 'daily' } as any,
        }),
        async created() {
            if(this.index && parseInt(this.index))
                await this.delay(parseInt(this.index)*100);

            if('selectedChartType' in this.item.config) {
                this.selection = this.item.config.selection.map((value: any) => {                      
                    value.selectedChartType = this.item.config.selectedChartType;
                    value.selectedChartAxis = 'y';
                    return value;
                })
                this.configUpdate();
            }

            this.getDevices();
            if(this.item.config) {
                this.getData();
            }
        },
        watch: {
            selectedAggregation(): any {
                this.getData();
                this.configUpdate();
            },
            currentTenant() {
                this.selection = [];
                this.getDevices();
            },
        },
        methods: {
            delay(ms: number) {
                return new Promise(res => setTimeout(res, ms));
            },
            onRefresh() {
                this.getData();
            },
            onDelete() {
                this.$emit('delete', this.item);
            },
            configUpdate() {
                this.$emit('update:item', {...this.item, config: { selection: this.selection, aggregation: this.selectedAggregation }});
            },
            onDeleteDevice(index: number) {
                this.selection.splice(index, 1);
                this.selectionData.splice(index, 1);
            },
            onAddDevice() {
                this.selection.push({ device: undefined, function: { name: "Last", value: "last" }, selectedChartType: 'bar', selectedChartAxis: 'y', edit: true });
            },
            onSaveDevice(index: number) {
                this.selection[index].edit = false; 
                this.getDeviceData(index);
                this.configUpdate();
            },
            onColorReset(item: any) {
                Vue.set(item, 'selectedColor', undefined);
            },
            autoComplete,
            getDevices() {
                this.devices = [];
                this.loadingDevices = true;

                DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
                    this.loadingDevices = false;

                    this.devices = devices;
                }).catch((err: any) => {
                    console.log(err, err.response);  
                    this.loadingDevices = false;    
                });
            },
            async getDeviceData(index: number) {
                this.loading = true;
                let aggregationFrom = this.selectedAggregation.from;
                let aggregationUntil = this.selectedAggregation.until;

                if(!aggregationFrom || !aggregationUntil) {
                    this.loading = false;
                    return;
                }

                let aggregationInterval = this.selectedAggregation.aggregationInterval;
                let aggregationFrequency = this.selectedAggregation.aggregationFrequency;
                let aggregationFunction = this.selection[index].function.value;
       
                try {
                    const data = await ReadingService.getAllAggregationPages(this.currentTenant, this.selection[index].device.uuid, aggregationInterval, aggregationFrequency, aggregationFunction, { from: aggregationFrom, until: aggregationUntil });

                    if(index < this.selectionData.length) {
                        this.selectionData.splice(index, 1, { device: this.selection[index].device, data: data });
                    } else {
                        this.selectionData.push({ device: this.selection[index].device, data: data });
                    }
                } catch(error) {
                    console.log(error);
                }
                this.loading = false;
            },
            async getData() {
                for (let i = 0; i < this.selection.length; i++) {
                    await this.getDeviceData(i);
                }
            },
        },
        computed: {
            chartLabels(): any[] {
                return this.selectionData.map((value: any) => (value.device.description.length > 70 ? value.device.description.substring(0, 70)+'...' : value.device.description) + ', ' + value.device.unit);
            },
            chartAxes(): any[] {
                return this.selection.map((value: any) => value.selectedChartAxis);
            },
            chartTypes(): any[] {
                return this.selection.map((value: any) => value.selectedChartType);
            },
            chartColors(): any[] {
                return this.selection.map((value: any) => value.selectedColor?.hexa);
            },
            chartData(): any[] {
                return this.selectionData.map((value: any) => value.data);
            },
            inEdit(): any {
                return this.selection.reduce((acc: boolean, value: any) => acc || value.edit, false);
            },
            requiredError(): any {
                return this.$t('required');
            },
            currentTenant(): any {
                return this.$root.$store.state.session.selectedTenant.uuid;
            },
            timeUnits(): any[] {
                return [
                {name: this.$t('timeUnit.minutes'), frequency: 'MINUTELY' },
                {name: this.$t('timeUnit.hours'), frequency: 'HOURLY'}, 
                {name: this.$t('timeUnit.days'), frequency: 'DAILY'},
                {name: this.$t('timeUnit.weeks'), frequency: 'WEEKLY'},
                {name: this.$t('timeUnit.months'), frequency: 'MONTHLY'},
                {name: this.$t('timeUnit.years'), frequency: 'YEARLY'}
            ]
            },
            timeRanges(): any[] {
                return [
                { value: "1h", label: "1 " + this.$t('timeUnit.hours'), interval: "1", frequency: "MINUTELY" },
                { value: "6h", label: "6 " + this.$t('timeUnit.hours'), interval: "15", frequency: "MINUTELY" },
                { value: "12h", label: "12 " + this.$t('timeUnit.hours'), interval: "15", frequency: "MINUTELY" },
                { value: "1d", label: "1 " + this.$t('timeUnit.day'), interval: "1", frequency: "HOURLY" },
                { value: "1w", label: "1 " + this.$t('timeUnit.week'), interval: "1", frequency: "DAILY" },
                { value: "1m", label: "1 " + this.$t('timeUnit.month'), interval: "1", frequency: "DAILY" },
                { value: "6m", label: "6 " + this.$t('timeUnit.months'), interval: "1", frequency: "WEEKLY" },
                { value: "1y", label: "1 " + this.$t('timeUnit.year'), interval: "1", frequency: "MONTHLY" },
                { value: "3y", label: "3 " + this.$t('timeUnit.years'), interval: "1", frequency: "MONTHLY" },
            ];
            }
        },
    })
