import store from '../store/index'
import router from '../router/index'
import ImageApi from '@/api/ImageApi';

export default {
    async getImage(readingUUID: string): Promise<string> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await ImageApi.getImage(readingUUID);

        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();  
            fileReader.readAsDataURL(response.data);
            fileReader.onloadend = () => {
                resolve(fileReader.result as string)
            };
            fileReader.onerror = reject;
        });
    },   
    async getUploadURL(readingUUID: string, imageFile: any): Promise<any> {
        const response = await ImageApi.getUploadURL(readingUUID, imageFile);

        if(response && response.data && response.data.data.type && response.data.data.type == 'uploadURL') {
            return response.data.data.attributes.url;
        } else {
            throw TypeError("Response not type uploadURL");
        }
    },   
    async uploadImage(url: string, imageFile: any): Promise<any> {    
        return ImageApi.uploadImage(url, imageFile);
    },
    async deleteImage(readingUUID: string): Promise<void> {
        await ImageApi.deleteImage(readingUUID);
    }
}
  