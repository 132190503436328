
  import Vue from 'vue'
  import PropertiesTable from '@/components/entities/property/PropertiesTable.vue'
  import PropertyCreateDialog from '@/components/entities/property/PropertyCreateDialog.vue'
  import EntityFiltersCard from '@/components/filters/EntityFiltersCard.vue'

  import InfoCard from '@/components/misc/InfoCard.vue'

  import PropertyService from '@/services/PropertyService';
  import { extractErrorMessage } from '@/utils/Util'

  export default Vue.extend({
    name: 'PropertiesView',

    components: {
      PropertiesTable,
      PropertyCreateDialog,
      InfoCard,
      EntityFiltersCard
    },
    data: () => ({
      properties: [] as any,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      filters: {} as any,
      filtersConfig: [
        { attribute: 'name', always: true },
        { attribute: 'aksId', always: false },
        { attribute: 'identifier', always: false },
        { attribute: 'street', always: false },
        { attribute: 'postalCode', always: false },
        { attribute: 'city', always: true },
        { attribute: 'country', always: false },
        { attribute: 'tags', always: false },
        { attribute: 'createdAt', always: false }
      ]
    }),
    async created() {
      this.updateProperties();
    },
    watch: {
      currentTenant() {
        this.filters = {};
        this.updateProperties();
      }
    },
    methods: {
      onTableReload() {
        this.updateProperties();
      },
      onPropertyFiltersApply() {
        this.updateProperties();
      },
      onPropertyItemUdate(item: any) {
        this.properties.splice(this.properties.findIndex((prop: any) => prop.uuid === item.uuid), 1);
        this.properties.push(item);
      },
      onPropertyItemDelete(item: any) {
        this.properties.splice(this.properties.findIndex((prop: any) => prop.uuid === item.uuid), 1);
      },
      onPropertyCreation(property: any) {
        this.properties.push(property);
      },
      updateProperties() {
        this.properties = [];
        this.dataLoaded = false;
        
        let params = {};

        Object.assign(params, this.filters);

        PropertyService.getAllCollectionPages(this.currentTenant, params).then((properties: any[]) => {
          this.dataLoaded = true;

          this.properties = properties;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('property.loadingError') + ' ' + extractErrorMessage(err);
          this.dataError = true;      
        });
      }
    },
    computed: {
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'properties' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['properties'].includes('POST');
        } else {
          return false;
        }
      },
      lastCreatedAt(): any {
        let occurrences: any = {};
        let sum = 0;

        let pastDate = new Date();
        pastDate.setDate(pastDate.getDate()-7); 

        this.properties.forEach((userObj: any) => {
          let _date = new Date(userObj.createdAt);
          if(_date >= pastDate) {
            let item = _date.getDate() + '-' + _date.getMonth();
            occurrences[item] = (occurrences[item] || 0) + 1;
            sum = sum + 1;
          }
        }, {});

        return sum;
      }
    }
  })
