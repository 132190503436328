import DeviceApi from '@/api/DeviceApi';
import store from '../store/index'
import router from '../router/index'

const timeUnits = [
    {name: 'minutes', value: 60 },
    {name: 'hours', value: 3600 }, 
    {name: 'days', value: 86400 },
    {name: 'weeks', value: 604800 },
    {name: 'months', value: 2629800 },
    {name: 'years', value: 31557600 }
];

export default {
    async getCount(tenant: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        params['itemsPerPage'] = 1;

        const response = await DeviceApi.getCollection(tenant, params);

        return response?.data?.meta?.page?.count;
    },
    async getAllCollectionPages(tenant: string, params: any = {}): Promise<any[]> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const devices: any[] = [];
        const pageResponses = await DeviceApi.getAllCollectionPages(tenant, params);
  
        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
            devices.push({
                uuid: userObj.id,
                aksId: (userObj.attributes.aksId || ''),
                localAksId: (userObj.attributes.localAksId || ''),
                description: userObj.attributes.description,
                unit: userObj.attributes.unit,
                type: userObj.attributes.type,
                alerting: userObj.attributes.alerting,
                isRelative: userObj.attributes.isRelative,
                frequency: userObj.attributes.frequency,
                interval: userObj.attributes.interval,
                aggregation: userObj.attributes.aggregation,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                deletedAt: userObj.meta?.deletedAt,
                })
            });
        });

        return devices;
    },
    async getSingle(tenant: string, deviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceApi.getSingle(tenant, deviceUUID);
  
        return {
                uuid: response.data.data.id,
                aksId: (response.data.data.attributes.aksId || ''),
                localAksId: (response.data.data.attributes.localAksId || ''),
                description: response.data.data.attributes.description,
                unit: response.data.data.attributes.unit,
                type: response.data.data.attributes.type,
                alerting: response.data.data.attributes.alerting,
                isRelative: response.data.data.attributes.isRelative,
                frequency: response.data.data.attributes.frequency,
                interval: response.data.data.attributes.interval,
                aggregation: response.data.data.attributes.aggregation,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                deletedAt: response.data.data.meta?.deletedAt,
            };
    },
    async updateDevice(tenantUUID: string, deviceUUID: string, aksId = "", 
     localAksId = "", description = "", unit = "", type="",
     frequency = "", interval=-1, aggregation="", 
     propertyUUID = "", connectorUUID = "", alerting?: boolean, isRelative?: boolean): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const response = await DeviceApi.updateDevice(tenantUUID, 
         deviceUUID, aksId, localAksId, description, unit, type, frequency, interval, aggregation, 
         propertyUUID, connectorUUID, alerting, isRelative);

        if(response.data.data.type && response.data.data.type == 'devices') {
            return {
                uuid: response.data.data.id,
                aksId: (response.data.data.attributes.aksId || ''),
                localAksId: (response.data.data.attributes.localAksId || ''),
                description: response.data.data.attributes.description,
                unit: response.data.data.attributes.unit,
                type: response.data.data.attributes.type,
                alerting: response.data.data.attributes.alerting,
                isRelative: response.data.data.attributes.isRelative,
                frequency: response.data.data.attributes.frequency,
                interval: response.data.data.attributes.interval,
                aggregation: response.data.data.attributes.aggregation,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                deletedAt: response.data.data.meta?.deletedAt,
            }
        } else {
            throw TypeError("Response not type devices");
        }
    },
    async getAssociatedReadingsCount(tenantUUID: string, deviceUUID: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        params['itemsPerPage'] = 1;

        const response = await DeviceApi.getAssociatedReadings(tenantUUID, deviceUUID, params);

        return response?.data?.meta?.page?.count;
    },
    async getAssociatedSetPointsCount(tenantUUID: string, deviceUUID: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        params['itemsPerPage'] = 1;
        params['state'] = 'all';

        const response = await DeviceApi.getAssociatedSetPoints(tenantUUID, deviceUUID, params);

        return response?.data?.meta?.page?.count;
    },
    async deleteDevice(tenantUUID: string, deviceUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await DeviceApi.deleteDevice(tenantUUID, deviceUUID);
    },
    async restoreDevice(tenantUUID: string, deviceUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await DeviceApi.restoreDevice(tenantUUID, deviceUUID);
    },
    async getAssociatedProperty(tenantUUID: string, deviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const response = await DeviceApi.getAssociatedProperty(tenantUUID, deviceUUID);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            aksId: (response.data.data.attributes.aksId || ''),
            identifier: response.data.data.attributes.identifier,
            street: response.data.data.attributes.street,
            postalCode: response.data.data.attributes.postalCode,
            city: response.data.data.attributes.city,
            country: response.data.data.attributes.country,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async getAssociatedConnector(tenantUUID: string, deviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceApi.getAssociatedConnector(tenantUUID, deviceUUID);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async getAssociatedTags(tenantUUID: string, deviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const tags: any[] = [];
        const response = await DeviceApi.getAssociatedTags(tenantUUID, deviceUUID);

        response.data.data.forEach((userObj: any) => {
            tags.push({
                uuid: userObj.id,
                name: userObj.attributes.name,
                description: userObj.attributes.description,
                type: userObj.attributes.type,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                deviceId: deviceUUID
            });
        });
        return tags;
    },
    async addTags(tenantUUID: string, deviceUUID: string, tags: any[]): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        return await DeviceApi.addTags(tenantUUID, deviceUUID, tags);
    },
    async removeTags(tenantUUID: string, deviceUUID: string, tags: any[]): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        await DeviceApi.removeTags(tenantUUID, deviceUUID, tags);
    },
    async createDevice(tenantUUID: string, propertyUUID: string, connectorUUID: string, 
     aksId: string, description: string, unit: string, type: string, 
     frequency: string, interval: number, aggregation: string, localAksId = "", alerting = true, isRelative = false): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
    
        const response = await DeviceApi.createDevice(tenantUUID, propertyUUID, connectorUUID, aksId, description, unit, type, frequency, interval, aggregation, localAksId, alerting, isRelative);

        if(response.data.data.type && response.data.data.type == 'devices') {
            return {
                uuid: response.data.data.id,
                aksId: (response.data.data.attributes.aksId || ''),
                localAksId: (response.data.data.attributes.localAksId || ''),
                description: response.data.data.attributes.description,
                unit: response.data.data.attributes.unit,
                type: response.data.data.attributes.type,
                alerting: response.data.data.attributes.alerting,
                isRelative: response.data.data.attributes.isRelative,
                frequency: response.data.data.attributes.frequency,
                interval: response.data.data.attributes.interval,
                aggregation: response.data.data.attributes.aggregation,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            }
        } else {
            throw TypeError("Response not type devices");
        }
    },    
    async getAssociatedAlerts(tenantUUID: string, deviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const alerts: any[] = [];
        const response = await DeviceApi.getAssociatedAlerts(tenantUUID, deviceUUID);

        response.data.data.forEach((userObj: any) => {
            let timeUnit;
            let frequency;
            for(const unit of [...timeUnits].reverse()) {
                if(userObj.attributes.maxAge % unit.value === 0) {
                    timeUnit = unit.name;
                    frequency =  userObj.attributes.maxAge / unit.value;
                    break;
                }
            }
            alerts.push({
                uuid: userObj.id,
                recipients: userObj.attributes.recipients,
                maxAge: userObj.attributes.maxAge,
                paused: userObj.attributes.paused,
                active: userObj.attributes.active,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                timeUnit: timeUnit,
                frequency: frequency
            });
        });
        return alerts;
    },
    async addAlert(tenantUUID: string, deviceUUID: string, recipients: string[], maxAge: number): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceApi.addAlert(tenantUUID, deviceUUID, recipients, maxAge);

        if(response.data.data.type && response.data.data.type == 'alerts') {
            let timeUnit;
            let frequency;
            for(const unit of [...timeUnits].reverse()) {
                if(response.data.data.attributes.maxAge % unit.value === 0) {
                    timeUnit = unit.name;
                    frequency =  response.data.data.attributes.maxAge / unit.value;
                    break;
                }
            }
            return {
                uuid: response.data.data.id,
                recipients: response.data.data.attributes.recipients,
                maxAge: response.data.data.attributes.maxAge,
                paused: response.data.data.attributes.paused,
                active: response.data.data.attributes.active,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                timeUnit: timeUnit,
                frequency: frequency
            }
        } else {
            throw TypeError("Response not type alerts");
        }
    },
    async updateAlert(tenantUUID: string, deviceUUID: string, alertUUID: string, recipients?: string[], maxAge?: number): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceApi.updateAlert(tenantUUID, deviceUUID, alertUUID, recipients, maxAge);

        if(response.data.data.type && response.data.data.type == 'alerts') {
            let timeUnit;
            let frequency;
            for(const unit of [...timeUnits].reverse()) {
                if(response.data.data.attributes.maxAge % unit.value === 0) {
                    timeUnit = unit.name;
                    frequency =  response.data.data.attributes.maxAge / unit.value;
                    break;
                }
            }
            return {
                uuid: response.data.data.id,
                recipients: response.data.data.attributes.recipients,
                maxAge: response.data.data.attributes.maxAge,
                paused: response.data.data.attributes.paused,
                active: response.data.data.attributes.active,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                timeUnit: timeUnit,
                frequency: frequency
            }
        } else {
            throw TypeError("Response not type alerts");
        }
    },
    async removeAlert(tenantUUID: string, deviceUUID: string, alertUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await DeviceApi.removeAlert(tenantUUID, deviceUUID, alertUUID);
    },
    async getAssociatedIoTDevices(tenantUUID: string, deviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const devices: any[] = [];
        const response = await DeviceApi.getAssociatedIoTDevices(tenantUUID, deviceUUID);

        response.data.data.forEach((userObj: any) => {
            devices.push({
                uuid: userObj.id,
                serialnumber: userObj.attributes.serialnumber,
                type: userObj.attributes.type,
                description: userObj.attributes.description,
                connectivity: userObj.attributes.connectivity,
                mqttPassword: userObj.attributes.mqttPassword,
                enabled: userObj.attributes.enabled,
                state: userObj.attributes.state,
                mode: userObj.attributes.mode,
                hardware: userObj.attributes.hardware,
                config: userObj.attributes.config,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
            });
        });
        return devices;
    },
}
  