import DeviceShareApi from '@/api/DeviceShareApi';
import store from '../store/index'
import router from '../router/index'

export default {
    async getAllCollectionPages(params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const deviceShares: any[] = [];
        const pageResponses = await DeviceShareApi.getAllCollectionPages(params);

        pageResponses.forEach((response: any) => {
                response.data.data.forEach((userObj: any) => {
                    deviceShares.push({
                        uuid: userObj.id,
                        state: userObj.attributes.state,
                        from: userObj.attributes.from,
                        until: userObj.attributes.until,
                        cascadeUpdates: userObj.attributes.cascadeUpdates,
                        cascadeDeletes: userObj.attributes.cascadeDeletes,
                        targetDeviceId: userObj.attributes.targetDeviceId,
                        sourceDeviceId: userObj.attributes.sourceDeviceId,
                        targetTenantId: userObj.attributes.targetTenantId,
                        sourceTenantId: userObj.attributes.sourceTenantId,
                        createdAt: userObj.meta.createdAt,
                        updatedAt: userObj.meta.updatedAt,
                });
            });
        });

        return deviceShares;
    },
    async getSingle(shareUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceShareApi.getSingle(shareUUID);
  
        return {
            uuid: response.data.data.id,
            state: response.data.data.attributes.state,
            from: response.data.data.attributes.from,
            until: response.data.data.attributes.until,
            cascadeUpdates: response.data.data.attributes.cascadeUpdates,
            cascadeDeletes: response.data.data.attributes.cascadeDeletes,
            targetDeviceId: response.data.data.attributes.targetDeviceId,
            sourceDeviceId: response.data.data.attributes.sourceDeviceId,
            targetTenantId: response.data.data.attributes.targetTenantId,
            sourceTenantId: response.data.data.attributes.sourceTenantId,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async createDeviceShare(sourceTenantId: string, sourceDeviceId: string, targetTenantId: string, cascadeUpdates=false, cascadeDeletes=false, from?: Date, until?: Date): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceShareApi.createDeviceShare(sourceTenantId, sourceDeviceId, targetTenantId, cascadeUpdates, cascadeDeletes, from, until);

        if(response.data.data.type && response.data.data.type == 'deviceshares') {

            return {
                uuid: response.data.data.id,
                state: response.data.data.attributes.state,
                from: response.data.data.attributes.from,
                until: response.data.data.attributes.until,
                cascadeUpdates: response.data.data.attributes.cascadeUpdates,
                cascadeDeletes: response.data.data.attributes.cascadeDeletes,
                targetDeviceId: response.data.data.attributes.targetDeviceId,
                sourceDeviceId: response.data.data.attributes.sourceDeviceId,
                targetTenantId: response.data.data.attributes.targetTenantId,
                sourceTenantId: response.data.data.attributes.sourceTenantId,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            };
        } else {
            throw TypeError("Response not type deviceshares");
        }
    },
    async updateDeviceShare(shareUUID: string, state?: string, sourceDeviceId?: string, targetDeviceId?: string, cascadeUpdates?: boolean, cascadeDeletes?: boolean, from?: Date, until?: Date) : Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const response = await DeviceShareApi.updateDeviceShare(shareUUID, state, sourceDeviceId, targetDeviceId, cascadeUpdates, cascadeDeletes, from, until);

        if(response.data.data.type && response.data.data.type == 'deviceshares') {

            return {
                uuid: response.data.data.id,
                state: response.data.data.attributes.state,
                from: response.data.data.attributes.from,
                until: response.data.data.attributes.until,
                cascadeUpdates: response.data.data.attributes.cascadeUpdates,
                cascadeDeletes: response.data.data.attributes.cascadeDeletes,
                targetDeviceId: response.data.data.attributes.targetDeviceId,
                sourceDeviceId: response.data.data.attributes.sourceDeviceId,
                targetTenantId: response.data.data.attributes.targetTenantId,
                sourceTenantId: response.data.data.attributes.sourceTenantId,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            };
        } else {
            throw TypeError("Response not type deviceshares");
        }
    },
    async deleteDeviceType(shareUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await DeviceShareApi.deleteDeviceShare(shareUUID);
    },
    async syncDeviceShare(shareUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await DeviceShareApi.syncDeviceShare(shareUUID);
    },
    async getSourceTenant(shareUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceShareApi.getSourceTenant(shareUUID);
  
        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            urlName: response.data.data.attributes.urlName,
            uniqueAksId: response.data.data.attributes.uniqueAksId,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            deletedAt: response.data.data.meta.deletedAt,
        };
    },
    async getTargetTenant(shareUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceShareApi.getTargetTenant(shareUUID);
  
        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            urlName: response.data.data.attributes.urlName,
            uniqueAksId: response.data.data.attributes.uniqueAksId,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            deletedAt: response.data.data.meta.deletedAt,
        };
    },
    async getSourceDevice(shareUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceShareApi.getSourceDevice(shareUUID);
  
        return {
            uuid: response.data.data.id,
            aksId: (response.data.data.attributes.aksId || ''),
            localAksId: (response.data.data.attributes.localAksId || ''),
            description: response.data.data.attributes.description,
            unit: response.data.data.attributes.unit,
            type: response.data.data.attributes.type,
            frequency: response.data.data.attributes.frequency,
            interval: response.data.data.attributes.interval,
            aggregation: response.data.data.attributes.aggregation,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            deletedAt: response.data.data.meta?.deletedAt,
        };
    },
    async getTargetDevice(shareUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceShareApi.getTargetDevice(shareUUID);
  
        return {
            uuid: response.data.data.id,
            aksId: (response.data.data.attributes.aksId || ''),
            localAksId: (response.data.data.attributes.localAksId || ''),
            description: response.data.data.attributes.description,
            unit: response.data.data.attributes.unit,
            type: response.data.data.attributes.type,
            frequency: response.data.data.attributes.frequency,
            interval: response.data.data.attributes.interval,
            aggregation: response.data.data.attributes.aggregation,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            deletedAt: response.data.data.meta?.deletedAt,
        };
    },
}
  