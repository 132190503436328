
  import Vue from 'vue'
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import TenantService from '@/services/TenantService';
import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'DeviceTypeInfoDialog',

    components: {
        EntityMetaDialog,
        // Weird import necessary due to circular dependency
        TenantInfoDialog: () => import('@/components/entities/tenant/TenantInfoDialog.vue') as any
    },
    props: ['devicetype'],
    data: () => ({
        localdialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        associatedTenant: {} as any,
        loadingTenant: false,
    }),
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedTenant ={};
        this.alertVisible = false;
        this.loading = false;
        this.loadingTenant = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async loadAssociatedTenant() {
        this.loadingTenant = true;

        try {
            const tenant = await TenantService.getSingle(this.devicetype.tenantId)
            this.loadingTenant = false;

            this.associatedTenant = tenant;
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('deviceType.errorAssociatedTenant') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingTenant = false;
            this.associatedTenant = {};
        }
      },
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                if(this.devicetype.tenantId) {
                    this.loadAssociatedTenant();
                }
            } else {
                this.clear();
            }
        }
    },
    computed: {
        timeUnitLabel(): any {
            if(this.devicetype.dataLimitFrequency === 'HOURLY') {
                return this.$t('timeUnit.hours');
            } else if(this.devicetype.dataLimitFrequency === 'DAILY') {
                return this.$t('timeUnit.days');
            } else if(this.devicetype.dataLimitFrequency === 'WEEKLY') {
                return this.$t('timeUnit.weeks');
            } else if(this.devicetype.dataLimitFrequency === 'MONTHLY') {
                return this.$t('timeUnit.months');
            } else if(this.devicetype.dataLimitFrequency === 'YEARLY') {
                return this.$t('timeUnit.years');
            } else if(this.devicetype.dataLimitFrequency === 'ALWAYS') {
                return this.$t('timeUnit.always');
            }
            return null;
        },
        retentionTime(): any {
            if(this.devicetype && this.devicetype.retention) {
                return this.devicetype.retention / 86400000;
            }
            return null;
        },
        hasTenant(): boolean {
            return Object.keys(this.associatedTenant).length > 0;
        },
    }
  })
