import store from '../store/index'
import router from '../router/index'
import DashboardApi from '@/api/DashboardApi';

export default {
    async getAllDashboardCollectionPages(tenantUUID: string, userUUID: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const tasks: any[] = [];
        const pageResponses = await DashboardApi.getAllDashboardCollectionPages(tenantUUID, userUUID, params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((obj: any) => {
                tasks.push({
                    uuid: obj.id,
                    name: obj.attributes.name,
                    config: obj.attributes.config,
                    createdAt: obj.meta.createdAt,
                    updatedAt: obj.meta.updatedAt,
                });
            });
        });

        return tasks;
    },
    async createDashboard(tenantUUID: string, userUUID: string, name: string, config: any): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DashboardApi.createDashboard(tenantUUID, userUUID, name, config);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.rrule,
            config: response.data.data.attributes.note,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async updateDashboard(tenantUUID: string, userUUID: string, dashboardUUID: string, name="", config=undefined as any): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DashboardApi.updateDashboard(tenantUUID, userUUID, dashboardUUID, name, config);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.rrule,
            config: response.data.data.attributes.note,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async deleteDashboard(tenantUUID: string, userUUID: string, dashboardUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await DashboardApi.deleteDashboard(tenantUUID, userUUID, dashboardUUID);
    }
}
  