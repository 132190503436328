
  import Vue from 'vue'
  import SetpointService from '@/services/SetpointService';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'SetpointInfoDialog',

    components: {
        EntityMetaDialog,
        // Weird import necessary due to circular dependency
        DeviceInfoDialog: () => import('@/components/entities/device/DeviceInfoDialog.vue') as any
    },
    props: ['setpoint'],
    data: () => ({
        localdialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingDevice: false,
        associatedDevice: {} as any
    }),
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedDevice = {};
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      getAssociatedDevice() {
        this.loadingDevice = true;

        SetpointService.getAssociatedDevice(this.$root.$store.state.session.selectedTenant.uuid, this.setpoint.uuid).then((device) => {
            this.loadingDevice = false;

            this.associatedDevice = device;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('setpoint.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingDevice = false;
        });
      },
      getColor(state: string) {
        if (state === 'deprecated') return 'rgba(244, 64, 52, 0.6)'
        else if (state === 'sent') return 'rgba(255, 152, 0, 0.6)'
        else return 'rgba(76, 175, 80, 0.6)'
      },
    },
    watch: {
        localdialog(value: boolean) {
            if(value) {
                this.getAssociatedDevice();
            }
        }
    },
    computed: {
    }
  })
