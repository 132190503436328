
  import Vue from 'vue'
  import Visualization from '@/components/Visualization.vue'
  import GroupCreateDialog from '@/components/dashboard/GroupCreateDialog.vue'

  import * as ls from "local-storage";

  export default Vue.extend({
    name: 'VisualizationView',

    components: {
      Visualization,
      GroupCreateDialog
    },
    data: () => ({
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      visualizationConfig: {},
      defaultConfig: { selection: [], aggregation: { aggregationInterval: 1,  aggregationFrequency: 'daily', from: null, until: null } }
    }),
    created() {
      this.visualizationConfig = ls.get(this.storageKey) ?? this.defaultConfig;
    },
    methods: {
      onUpdate(config: any) {
        ls.set(this.storageKey, config);
      }
    },
    watch: {
      currentTenant() {
        this.visualizationConfig = ls.get(this.storageKey) ?? this.defaultConfig;
      }
    },
    computed: {
      storageKey(): any {
        return this.currentTenant + '/' + this.currentUser.uuid + '/visualization';
      },
      currentUser(): any {
        return this.$root.$store.state.session.currentUser;
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
    }
  })
