
  import Vue from 'vue'
  import EventsCalendar from '@/components/services/event/EventsCalendar.vue'

  import MeteringService from '@/services/MeteringService';
  import DeviceService from '@/services/DeviceService';
import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'EventsView',

    components: {
      EventsCalendar,
    },
    data: () => ({
      events: [] as any,
      devices: [] as any,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      params: {
        pageSize: 1000,
        until: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
        from: null,
        userId: null,
      } as any
    }),
    async created() {
      this.resetParams();
      await this.updateDevices();
      this.updateEvents();
    },
    watch: {
      async currentTenant() {
        this.resetParams();
        await this.updateDevices();
        this.updateEvents();
      },
    },
    methods: {
      async onTableReload() {
        await this.updateDevices();
        this.updateEvents();
      },
      resetParams() {
        this.params = {
          pageSize: 1000,
          until: new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
          from: null,
          userId: null,
        };
      },
      userUpdated(user: any) {
        if(user) {
          this.params.userId = user.uuid;
        } else {
          this.params.userId = null;
        }
        this.updateEvents();
      },
      onEventDelete(item: any) {
        this.events.splice(this.events.findIndex((prop: any) => prop.uuid === item.uuid), 1);
      },
      onEventBatchDelete(untilItem: any) {
        this.events = this.events.filter((value: any) => !(value.deviceId === untilItem.deviceId && value.dueDate <= untilItem.dueDate));
      },
      rangeUpdated(start: string, end: string) {
        if(new Date(end) > new Date(this.params.until)) {
          this.params.from = this.params.until;
          this.params.until = new Date(new Date(end).setFullYear(new Date(end).getFullYear() + 1)).toISOString();
          this.updateEvents();
        }
      },
      updateEvents() {
        if(!this.params.from) {
          this.events = [];
        }
        this.dataLoaded = false;
        
        if (!this.$root.$store.getters.loggedIn(Date.now())) {
            this.$router.push('/login');
        } else {
          MeteringService.getAllEventCollectionPages(this.currentTenant, this.params).then((events: any[]) => {
            this.dataLoaded = true;

            events.forEach((eventObj: any) => {
              const dev = this.devices.find((item: any) => item.uuid === eventObj.deviceId);

              if(!dev) {
                console.log('Event device not found.', eventObj.uuid, eventObj.taskId);
                return;
              }

              const dueDate = new Date(eventObj.dueDate);
              const dateString = dueDate.getFullYear() + '-' + (dueDate.getMonth()+1) + '-' + dueDate.getDate();
              
              this.events.push({
                  ...eventObj, 
                  name: dev.description,
                  start: dateString,
                  end: dateString,
                  timed: false,
                  device: dev,
                })
              });
          }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('event.loadingError') + ' ' + extractErrorMessage(err);
            this.dataError = true;      
          });
          
        }
      },
      async updateDevices() {
        // UpdateDevices needs to be async so we can await before getting the events
        this.devices = [];
        this.dataLoaded = false;

        if (!this.$root.$store.getters.loggedIn(Date.now())) {
            this.$router.push('/login');
        } else {
          let params = {};

          try  {
            this.devices = await DeviceService.getAllCollectionPages(this.currentTenant, params)
            this.dataLoaded = true;
          } catch(err: any) {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
            this.dataError = true;    
            this.dataLoaded = true;
          }
        }
      }
    },
    computed: {
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      }
    }
  })
