
  import Vue from 'vue'
  import TenantService from '@/services/TenantService';
  import UserService from '@/services/UserService';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import UserCreateDialog from '@/components/entities/user/UserCreateDialog.vue'

  export default Vue.extend({
    name: 'TenantEditDialog',
    props: ['tenant'],
    components: {
        EntityMetaDialog,
        UserCreateDialog
    },
    data: (vm: any): any => ({
        localdialog: false,
        localTenant: {} as any,
        showAddUserField: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        associatedUsers: [] as any[],
        users: [] as any[],
    }),
    async created() {
      this.localTenant = Object.assign({}, this.tenant);
    },
    methods: {
      onUserCreation(user: any) {
        this.users.push(user);
      },
      onUserRemoveClick(item: any) {
        this.loading = true;

        TenantService.removeUser(this.tenant.uuid, item.uuid).then(() => {
            this.alertType = "success";
            this.alertText = this.$t('tenant.successRemoveUser') + item.email;
            this.alertVisible = true;
            this.loading = false;
            this.associatedUsers.splice(this.associatedUsers.findIndex((user: any) => user.uuid === item.uuid), 1);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('tenant.errorRemoveUser') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedUsers = [];
        this.users = [];
        this.alertVisible = false;
        this.loading = false;
        this.showAddUserField = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      save() {
        this.loading = true;
        let name = "";
        let uniqueAksId;
        
        if(this.localTenant.name.length > 0 && this.localTenant.name !== this.tenant.name) {
            name = this.localTenant.name;
        }

        if(this.localTenant.uniqueAksId !== undefined && this.localTenant.uniqueAksId !== this.tenant.uniqueAksId) {
            uniqueAksId = this.localTenant.uniqueAksId;
        }

        TenantService.updateTenant(this.tenant.uuid, name, uniqueAksId).then((tenant) => {
            this.alertType = "success";
            this.alertText = this.$t('tenant.successUpdate') as string;
            this.alertVisible = true;

            this.localTenant = tenant;

            this.$emit("update", tenant);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('tenant.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getAssociatedUsers() {
        this.loading = true;

        TenantService.getAssociatedUsers(this.tenant.uuid).then((users: any[]) => {
            this.loading = false;
            this.associatedUsers = users;
            if(users.length === 0) {
                this.alertType = "warning";
                this.alertText = this.$t('tenant.emptyAssociatedUser') as string;
                this.alertVisible = true;
            }
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('tenant.errorAssociatedUser') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      autoComplete,
    },
    watch: {
        tenant() {
            this.localTenant = Object.assign({}, this.tenant);
        },
        localdialog(value: boolean) {
            if(value) {
                this.getAssociatedUsers();
            } else {
                this.clear();
            }
        }
    },
    computed: {
        editForm(): any {
            return this.$refs.editForm;
        },
        permissionRoles() {
            return UserService.getRoles();
        },
        requiredError(): any {
            return this.$t('required');
        }
    }
  })
