import axiosLimiter from '@/api/RateLimiter';

const farFutureDateISOString = new Date(9999, 1, 1).toISOString();

export default {
    getCollection(): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/prices');
    },
    async getAllCollectionPages(params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/prices?';

            //  Filters
            if('type' in params && params.type && params.type.length > 0) {
                requestURL = requestURL + '&type=' + encodeURIComponent(params.type);
            }
            if('subtype' in params && params.subtype && params.subtype.length > 0) {
                requestURL = requestURL + '&subtype=' + encodeURIComponent(params.subtype);
            }
            if('tenant' in params && params.tenant && Object.keys(params.tenant).length > 0) {
                requestURL = requestURL + '&tenantId=' + encodeURIComponent(params.tenant.uuid);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }            
            if('deleted' in params && params.deleted) {
                requestURL = requestURL + '&deletedUntil=' + farFutureDateISOString;
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(priceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/prices/' + priceUUID);
    },
    createPrice(type: string, subtype: string, basePrice: number, perUsePrice=-1, discountRange=undefined, tenantUUID=""): Promise<any> {
        const body = {
            "data": {
                "type": "prices",
                "attributes": {
                    "type": type,
                    "basePrice": basePrice
                }
            }
        } as any;

        if(perUsePrice > 0) {
            body.data.attributes.perUsePrice = perUsePrice;
        }
        if(discountRange) {
            body.data.attributes.discountRange = discountRange;
        }
        if(subtype.length > 0) {
            body.data.attributes.subtype = subtype;
        }
        if(tenantUUID.length > 0) {
            body.data.attributes.tenantId = tenantUUID;
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/prices', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    deletePrice(priceUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/prices/' + priceUUID);
    },
}
  