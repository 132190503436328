import { render, staticRenderFns } from "./ReportEditDialog.vue?vue&type=template&id=56460c64&scoped=true&"
import script from "./ReportEditDialog.vue?vue&type=script&lang=ts&"
export * from "./ReportEditDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56460c64",
  null
  
)

export default component.exports