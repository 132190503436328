import axiosLimiter from '@/api/RateLimiter';

const farFutureDateISOString = new Date(9999, 1, 1).toISOString();

export default {
    async getCollectionPage(tenant: string, params: any): Promise<any> {

        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/readings?';

        //  Filters
        if('from' in params && params.from && params.from.length > 0) {
            requestURL = requestURL + '&from=' + encodeURIComponent(params.from);
        }
        if('until' in params && params.until && params.until.length > 0) {
            requestURL = requestURL + '&until=' + encodeURIComponent(params.until);
        }
        if('timestamp' in params && params.timestamp && params.timestamp.length > 0) {
            requestURL = requestURL + '&timestamp=' + encodeURIComponent(params.timestamp);
        }
        if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
            requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
        }
        if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
            requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
        }
        if('device' in params && params.device && Object.keys(params.device).length > 0) {
            requestURL = requestURL + '&deviceId=' + encodeURIComponent(params.device.uuid);
        }
        if('connector' in params && params.connector && Object.keys(params.connector).length > 0) {
            requestURL = requestURL + '&connectorId=' + encodeURIComponent(params.connector.uuid);
        }
        if('property' in params && params.property && Object.keys(params.property).length > 0) {
            requestURL = requestURL + '&propertyId=' + encodeURIComponent(params.property.uuid);
        }
        if('value' in params && params.value) {
            requestURL = requestURL + '&value=' + parseFloat(params.value);
        }
        if('flag' in params && params.flag) {
            requestURL = requestURL + '&flag=' + encodeURIComponent(params.flag);
        }
        if('deleted' in params && params.deleted) {
            requestURL = requestURL + '&deletedUntil=' + farFutureDateISOString;
        }
        
        // Paging
        if('itemsPerPage' in params && params.itemsPerPage) {
            requestURL = requestURL + '&page[size]=' + encodeURIComponent(params.itemsPerPage);
        }
        if('nextPageLink' in params && params.nextPageLink && params.nextPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.nextPageLink;
        }
        if('prevPageLink' in params && params.prevPageLink && params.prevPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.prevPageLink;
        }

        return axiosLimiter.get(requestURL);
    },
    async getAllCollectionPages(tenant: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/readings?';

            //  Filters
            if('from' in params && params.from && params.from.length > 0) {
                requestURL = requestURL + '&from=' + encodeURIComponent(params.from);
            }
            if('until' in params && params.until && params.until.length > 0) {
                requestURL = requestURL + '&until=' + encodeURIComponent(params.until);
            }
            if('timestamp' in params && params.timestamp && params.timestamp.length > 0) {
                requestURL = requestURL + '&timestamp=' + encodeURIComponent(params.timestamp);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }
            if('device' in params && params.device && Object.keys(params.device).length > 0) {
                requestURL = requestURL + '&deviceId=' + encodeURIComponent(params.device.uuid);
            }
            if('connector' in params && params.connector && Object.keys(params.connector).length > 0) {
                requestURL = requestURL + '&connectorId=' + encodeURIComponent(params.connector.uuid);
            }
            if('property' in params && params.property && Object.keys(params.property).length > 0) {
                requestURL = requestURL + '&propertyId=' + encodeURIComponent(params.property.uuid);
            }
            if('value' in params && params.value) {
                requestURL = requestURL + '&value=' + parseFloat(params.value);
            }
            if('flag' in params && params.flag) {
                requestURL = requestURL + '&flag=' + encodeURIComponent(params.flag);
            }
            if('deleted' in params && params.deleted) {
                requestURL = requestURL + '&deletedUntil=' + farFutureDateISOString;
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    async getAggregationPage(tenantId: string, deviceId: string, interval: string, frequency: string, aggFunction: string, params: any = {}): Promise<any> {

        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantId + '/readings/aggregates?';
        requestURL += '&deviceId=' + encodeURIComponent(deviceId);
        requestURL += '&frequency=' + encodeURIComponent(frequency);
        requestURL += '&interval=' + encodeURIComponent(interval);
        requestURL += '&function=' + encodeURIComponent(aggFunction);

        //  Filters
        if('from' in params && params.from && params.from.length > 0) {
            requestURL = requestURL + '&from=' + encodeURIComponent(params.from);
        }
        if('until' in params && params.until && params.until.length > 0) {
            requestURL = requestURL + '&until=' + encodeURIComponent(params.until);
        }

        // Paging
        if('itemsPerPage' in params && params.itemsPerPage) {
            requestURL = requestURL + '&page[size]=' + encodeURIComponent(params.itemsPerPage);
        }
        if('nextPageLink' in params && params.nextPageLink && params.nextPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.nextPageLink;
        }
        if('prevPageLink' in params && params.prevPageLink && params.prevPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.prevPageLink;
        }

        return axiosLimiter.get(requestURL);
    },
    async getAllAggregationPages(tenantId: string, deviceId: string, interval: string, frequency: string, aggFunction: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantId + '/readings/aggregates?';
            requestURL += '&deviceId=' + encodeURIComponent(deviceId);
            requestURL += '&frequency=' + encodeURIComponent(frequency);
            requestURL += '&interval=' + encodeURIComponent(interval);
            requestURL += '&function=' + encodeURIComponent(aggFunction);
            // console.log(requestURL);

            //  Filters
            if('from' in params && params.from && params.from.length > 0) {
                requestURL = requestURL + '&from=' + encodeURIComponent(params.from);
            }
            if('until' in params && params.until && params.until.length > 0) {
                requestURL = requestURL + '&until=' + encodeURIComponent(params.until);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    async getAllGroupAggregationPages(tenantId: string, filters: any[], interval: number, frequency: string, aggFunction: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantId + '/readings/aggregates?';
            // console.log(requestURL);

            const body = {
                "data": {
                    "type": "group-aggregates",
                    "attributes": {
                        "frequency": frequency.toUpperCase(),
                        "interval": interval,
                        "function": aggFunction.toUpperCase(),
                        "filters": filters
                    }
                }
            } as any;

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.post(requestURL, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(tenant: string, readingUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/readings/' + readingUUID);
    },
    createReading(tenantUUID: string, value: number, timestamp: string, deviceUUID: string, flag?: string): Promise<any> {
    
        const body = {
            data: {
                type: "readings",
                attributes: {
                    value: value,
                    timestamp: timestamp
                },
                relationships: {
                    device: {
                        data: {
                            type: "devices",
                            id: deviceUUID
                        }
                    }
                }
            }
        } as any;

        if(flag && flag.length > 0) {
            body.data.attributes.flag = flag;
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/readings/', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateReading(tenantUUID: string, readingUUID: string, value = "", deviceUUID = "", flag=""): Promise<any> {
        
        const body = {
            data: {
                type: "readings",
                id: readingUUID,
                attributes: {
                }
            }
        } as any;

        if(value.length > 0) {
            body.data.attributes.value = parseFloat(value);
        }
        if(flag == null || flag.length > 0) {
            body.data.attributes.flag = flag;
        }
        // Update timestamp makes problems
        // if(timestamp.length > 0) {
        //     body.data.attributes.timestamp = timestamp;
        // }

        if(deviceUUID.length > 0) {
            body.data.relationships = {
                device: {
                    data: {
                        type: "devices",
                        id: deviceUUID
                    }
                }
            };
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/readings/' + readingUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    deleteReading(tenantUUID: string, readingUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/readings/' + readingUUID);
    },
    restoreReading(tenantUUID: string, readingUUID: string): Promise<any> {

        const body = {
            data: {
                type: "readings",
                id: readingUUID
            }
        } as any;

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/readings/' + readingUUID,  JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    getAssociatedDevice(tenantUUID: string, readingUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/readings/' + readingUUID + '/device');
    },
}
  