
  import Vue from 'vue'

  export default Vue.extend({
    name: 'FlashConfirmDialog',

    props: [],
    data: () => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
    }),
    methods: {
      close() {
        this.localDialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      confirm() {
        this.$emit('confirm');
        this.dismiss();
      }
    }
  })
