import IoTDeviceApi from '@/api/IoTDeviceApi';
import store from '../store/index';
import router from '../router/index';

const timeUnits = [
    {name: 'minutes', value: 60 },
    {name: 'hours', value: 3600 }, 
    {name: 'days', value: 86400 },
    {name: 'weeks', value: 604800 },
    {name: 'months', value: 2629800 },
    {name: 'years', value: 31557600 }
];

export default {
    async getAllCollectionPages(params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const devices: any[] = [];
        const pageResponses = await IoTDeviceApi.getAllCollectionPages(params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                devices.push({
                    uuid: userObj.id,
                    serialnumber: userObj.attributes.serialnumber,
                    type: userObj.attributes.type,
                    description: userObj.attributes.description,
                    connectivity: userObj.attributes.connectivity,
                    mqttPassword: userObj.attributes.mqttPassword,
                    enabled: userObj.attributes.enabled,
                    state: userObj.attributes.state,
                    mode: userObj.attributes.mode,
                    hardware: userObj.attributes.hardware,
                    config: userObj.attributes.config,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                });
            });
        });

        return devices;
    },
    async getSingle(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceApi.getSingle(iotdeviceUUID);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            serialnumber: response.data.data.attributes.serialnumber,
            type: response.data.data.attributes.type,
            description: response.data.data.attributes.description,
            connectivity: response.data.data.attributes.connectivity,
            mqttPassword: response.data.data.attributes.mqttPassword,
            enabled: response.data.data.attributes.enabled,
            state: response.data.data.attributes.state,
            mode: response.data.data.attributes.mode,
            hardware: response.data.attributes.hardware,
            config: response.data.attributes.config,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async createIoTDevice(serialnumber: string, type: string, description: string, connectivity: string, config: any, hardware: any, mqttPassword?: string, tenantUUID?: string, propertyUUID?: string, connectorUUID?: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceApi.createIoTDevice(serialnumber, type, description, connectivity, config, hardware, mqttPassword, tenantUUID, propertyUUID, connectorUUID);

        if(response.data.data.type && response.data.data.type == 'iotdevices') {

            return {
                uuid: response.data.data.id,
                name: response.data.data.attributes.name,
                serialnumber: response.data.data.attributes.serialnumber,
                type: response.data.data.attributes.type,
                description: response.data.data.attributes.description,
                connectivity: response.data.data.attributes.connectivity,
                mqttPassword: response.data.data.attributes.mqttPassword,
                enabled: response.data.data.attributes.enabled,
                state: response.data.data.attributes.state,
                mode: response.data.data.attributes.mode,
                hardware: response.data.data.attributes.hardware,
                config: response.data.data.attributes.config,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            };
        } else {
            throw TypeError("Response not type iotdevices");
        }
    },
    async updateIoTDevice(iotdeviceUUID: string, type?: string, description?: string, connectivity?: string, state?: string, mqttPassword?: string, config?: any, hardware?: any, tenantUUID?: string, propertyUUID?: string, connectorUUID?: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceApi.updateIoTDevice(iotdeviceUUID, type, description, connectivity, state, mqttPassword, config, hardware, tenantUUID, propertyUUID, connectorUUID);
        
        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            serialnumber: response.data.data.attributes.serialnumber,
            type: response.data.data.attributes.type,
            description: response.data.data.attributes.description,
            connectivity: response.data.data.attributes.connectivity,
            mqttPassword: response.data.data.attributes.mqttPassword,
            enabled: response.data.data.attributes.enabled,
            state: response.data.data.attributes.state,
            mode: response.data.data.attributes.mode,
            hardware: response.data.data.attributes.hardware,
            config: response.data.data.attributes.config,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async deleteIoTDevice(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        await IoTDeviceApi.deleteIoTDevice(iotdeviceUUID);
    },
    async getAssociatedMonitoringDevice(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceApi.getAssociatedMonitoringDevice(iotdeviceUUID);

        return {
            tenantId: response.data.data.links.self.split('/')[2],
            uuid: response.data.data.id,
            aksId: (response.data.data.attributes.aksId || ''),
            localAksId: (response.data.data.attributes.localAksId || ''),
            description: response.data.data.attributes.description,
            unit: response.data.data.attributes.unit,
            type: response.data.data.attributes.type,
            frequency: response.data.data.attributes.frequency,
            interval: response.data.data.attributes.interval,
            aggregation: response.data.data.attributes.aggregation,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            deletedAt: response.data.data.meta?.deletedAt,
        };
    },
    async getAssociatedTenant(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceApi.getAssociatedTenant(iotdeviceUUID);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            urlName: response.data.data.attributes.urlName,
            uniqueAksId: response.data.data.attributes.uniqueAksId,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            deletedAt: response.data.data.meta.deletedAt,
        };
    },
    async getAssociatedProperty(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceApi.getAssociatedProperty(iotdeviceUUID);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            aksId: (response.data.data.attributes.aksId || ''),
            identifier: response.data.data.attributes.identifier,
            street: response.data.data.attributes.street,
            postalCode: response.data.data.attributes.postalCode,
            city: response.data.data.attributes.city,
            country: response.data.data.attributes.country,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async getAssociatedConnector(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceApi.getAssociatedConnector(iotdeviceUUID);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            lastActiveAt: response.data.data.meta.lastActiveAt,
        };
    },
    async activateIoTDevice(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        return IoTDeviceApi.activateIoTDevice(iotdeviceUUID);
    },
    async deactivateIoTDevice(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        return IoTDeviceApi.deactivateIoTDevice(iotdeviceUUID);
    },
    async activateIoTDeviceMonitoring(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        return IoTDeviceApi.activateIoTDeviceMonitoring(iotdeviceUUID);
    },
    async deactivateIoTDeviceMonitoring(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        return IoTDeviceApi.deactivateIoTDeviceMonitoring(iotdeviceUUID);
    },
    async getDeviceStatus(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceApi.getDeviceStatus(iotdeviceUUID);

        return {
            mode: response.data.data.attributes.mode,
            version: response.data.data.attributes.version,
            network: response.data.data.attributes.network,
            logging: response.data.data.attributes.logging,
            serialMethod: response.data.data.attributes.serialMethod,
            resetReason: response.data.data.attributes.resetReason,
            lastRestartAt: response.data.data.meta.lastRestartAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async triggerDeviceRestart(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        return IoTDeviceApi.triggerDeviceRestart(iotdeviceUUID);
    },
    async triggerDeviceCollect(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        return IoTDeviceApi.triggerDeviceCollect(iotdeviceUUID);
    },
    async triggerDevicePing(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        return IoTDeviceApi.triggerDevicePing(iotdeviceUUID);
    },
    async triggerOTAUpdate(iotdeviceUUID: string, version: string, component: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        return IoTDeviceApi.triggerOTAUpdate(iotdeviceUUID, version, component);
    },
    async setLogging(iotdeviceUUID: string, level?: string, backend?: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        return IoTDeviceApi.setLogging(iotdeviceUUID, level, backend);
    },
    async setCustomCMD(iotdeviceUUID: string, cmd: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceApi.setCustomCMD(iotdeviceUUID, cmd);

        return {
            cmd: response.data.data.attributes.cmd,
            response: response.data.data.attributes.response,
        };
    },
    async getAllMonitoringCollectionPages(params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const readings: any[] = [];
        const pageResponses = await IoTDeviceApi.getAllMonitoringCollectionPages(params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                readings.push({
                    uuid: userObj.id,
                    serialnumber: userObj.attributes.serialnumber,
                    type: userObj.attributes.type,
                    description: userObj.attributes.description,
                    connectivity: userObj.attributes.connectivity,
                    mqttPassword: userObj.attributes.mqttPassword,
                    enabled: userObj.attributes.enabled,
                    state: userObj.attributes.state,
                    mode: userObj.attributes.mode,
                    hardware: userObj.attributes.hardware,
                    config: userObj.attributes.config,
                    availability: userObj.attributes.availability,
                    alerts: userObj.attributes.alerts, 
                    tenantId: userObj.attributes.tenantId, 
                    propertyId: userObj.attributes.propertyId, 
                    connectorId: userObj.attributes.connectorId, 
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                });
            });
        });

        return readings;
    },
    async getAllMonitoringReadingPages(iotdeviceUUID: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const readings: any[] = [];
        const pageResponses = await IoTDeviceApi.getAllMonitoringReadingPages(iotdeviceUUID, params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                readings.push({
                    uuid: userObj.id,
                    timestamp: userObj.attributes.timestamp,
                    value: userObj.attributes.value,
                    flag: userObj.attributes.flag,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                    deletedAt: userObj.meta.deletedAt,
                });
            });
        });
        
        return readings;
    },
    async getLastMonitoringReading(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const params = {
            itemsPerPage: 1,
        }

        const pageResponse = await IoTDeviceApi.getMonitoringReadingPage(iotdeviceUUID, params);

        if(pageResponse.data.data.length > 0) {
            const readObj = pageResponse.data.data[0];

            return {
                uuid: readObj.id,
                timestamp: readObj.attributes.timestamp,
                value: readObj.attributes.value,
                flag: readObj.attributes.flag,
                createdAt: readObj.meta.createdAt,
                updatedAt: readObj.meta.updatedAt,
                deletedAt: readObj.meta.deletedAt,
            };
        } else {
            return undefined;
        }
    },
    async getAssociatedAlerts(iotdeviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const alerts: any[] = [];
        const response = await IoTDeviceApi.getAssociatedAlerts(iotdeviceUUID);

        response.data.data.forEach((userObj: any) => {
            let timeUnit;
            let frequency;
            for(const unit of [...timeUnits].reverse()) {
                if(userObj.attributes.maxAge % unit.value === 0) {
                    timeUnit = unit.name;
                    frequency =  userObj.attributes.maxAge / unit.value;
                    break;
                }
            }
            alerts.push({
                uuid: userObj.id,
                recipients: userObj.attributes.recipients,
                maxAge: userObj.attributes.maxAge,
                paused: userObj.attributes.paused,
                active: userObj.attributes.active,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                timeUnit: timeUnit,
                frequency: frequency
            });
        });
        return alerts;
    },
    async addAlert(iotdeviceUUID: string, recipients: string[], maxAge: number): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceApi.addAlert(iotdeviceUUID, recipients, maxAge);

        if(response.data.data.type && response.data.data.type == 'alerts') {
            let timeUnit;
            let frequency;
            for(const unit of [...timeUnits].reverse()) {
                if(response.data.data.attributes.maxAge % unit.value === 0) {
                    timeUnit = unit.name;
                    frequency =  response.data.data.attributes.maxAge / unit.value;
                    break;
                }
            }
            return {
                uuid: response.data.data.id,
                recipients: response.data.data.attributes.recipients,
                maxAge: response.data.data.attributes.maxAge,
                paused: response.data.data.attributes.paused,
                active: response.data.data.attributes.active,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                timeUnit: timeUnit,
                frequency: frequency
            }
        } else {
            throw TypeError("Response not type alerts");
        }
    },
    async updateAlert(iotdeviceUUID: string, alertUUID: string, recipients?: string[], maxAge?: number, paused?: boolean): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceApi.updateAlert(iotdeviceUUID, alertUUID, recipients, maxAge, paused);

        if(response.data.data.type && response.data.data.type == 'alerts') {
            let timeUnit;
            let frequency;
            for(const unit of [...timeUnits].reverse()) {
                if(response.data.data.attributes.maxAge % unit.value === 0) {
                    timeUnit = unit.name;
                    frequency =  response.data.data.attributes.maxAge / unit.value;
                    break;
                }
            }
            return {
                uuid: response.data.data.id,
                recipients: response.data.data.attributes.recipients,
                maxAge: response.data.data.attributes.maxAge,
                paused: response.data.data.attributes.paused,
                active: response.data.data.attributes.active,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                timeUnit: timeUnit,
                frequency: frequency
            }
        } else {
            throw TypeError("Response not type alerts");
        }
    },
    async removeAlert(iotdeviceUUID: string, alertUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await IoTDeviceApi.removeAlert(iotdeviceUUID, alertUUID);
    },
}
  