import { render, staticRenderFns } from "./UserRequestPasswordDialog.vue?vue&type=template&id=bb8f672e&scoped=true&"
import script from "./UserRequestPasswordDialog.vue?vue&type=script&lang=ts&"
export * from "./UserRequestPasswordDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb8f672e",
  null
  
)

export default component.exports