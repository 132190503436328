
  import Vue from 'vue'
  import PropertyService from '@/services/PropertyService';
  import DeviceService from '@/services/DeviceService';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
import TagService from '@/services/TagService';
import TagCreateDialog from '../tag/TagCreateDialog.vue';
import AlertsListDialog from '../alert/AlertsListDialog.vue';

  export default Vue.extend({
    name: 'PropertyEditDialog',
    props: ['property'],
    components: {
        EntityMetaDialog,
        TagCreateDialog,
        AlertsListDialog,
        DeviceInfoDialog: () => import('@/components/entities/device/DeviceInfoDialog.vue') as any
    },
    data: (vm: any): any => ({
        localdialog: false,
        localProperty: {} as any,
        showAddDeviceField: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingDevices: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        associatedDevices: [] as any[],
        associatedTags: [] as any[],
        devices: [] as any[],
        selectedDevice: null,
        associatedDeviceFilter: "",
        updatedAssociatedTags: [] as any[],
        tags: [] as any[],
        associatedAlerts: [] as any[],
    }),
    async created() {
        this.localProperty = Object.assign({}, this.property);
    },
    methods: {
      onDeviceRemoveClick(item: any) {
        // TODO currently its not possible to remove a device from a property without assigning it to another
      },
      onAddDeviceClick() {
          if(this.selectedDevice) {
            this.loading = true;

            DeviceService.updateDevice(this.currentTenant, this.selectedDevice.uuid, "", "", "", "", this.property.uuid).then((device: any) => {
                this.alertType = "success";
                this.alertText = this.$t('property.successAddDevice') + this.selectedDevice.name;
                this.alertVisible = true;
                this.loading = false;
            }).catch((err) => {
                console.log(err);
                console.log(err.response);
                this.alertType = "error";
                this.alertText = this.$t('property.errorAddDevice') + ' ' + extractErrorMessage(err);
                this.alertVisible = true; 
                this.loading = false;
            });
          }
      },
      onRemoveTagClick(tag: any) {
        this.updatedAssociatedTags.splice(this.updatedAssociatedTags.findIndex((t: any) => t.uuid === tag.uuid), 1);
      },
      onShowAddDevicesClick() {
        this.showAddDeviceField = !this.showAddDeviceField
        if(this.devices.length === 0) {
          this.loadingDevices = true;

          DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
            this.loadingDevices = false;

            this.devices = devices;
          }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;    
            this.loadingDevices = false;  
          });
        }
      },
      openAssociatedDevices() {
          this.$router.push({ name: 'Devices', params: { property: this.property } });
      },
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedDevices = [];
        this.associatedTags = [];
        this.tags = [];
        this.updatedAssociatedTags = [];
        this.devices = [];
        this.selectedDevice = null;
        this.alertVisible = false;
        this.loading = false;
        this.associatedDeviceFilter = "";
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      async save() {
        this.alertVisible = false;
        this.loading = true;

        let name = undefined;
        let aksId = undefined;
        let identifier = undefined;
        let street = undefined;
        let postalCode = undefined;
        let city = undefined;
        let country = undefined;

        if(this.localProperty.name !== this.property.name) {
            name = this.localProperty.name;
        }
        if(this.localProperty.aksId !== this.property.aksId) {
            aksId = this.localProperty.aksId;
        }
        if(this.localProperty.identifier !== this.property.identifier) {
            identifier = this.localProperty.identifier;
        }
        if(this.localProperty.street !== this.property.street) {
            street = this.localProperty.street;
        }
        if(this.localProperty.postalCode !== this.property.postalCode) {
            postalCode = this.localProperty.postalCode;
        }
        if(this.localProperty.city !== this.property.city) {
            city = this.localProperty.city;
        }
        if(this.localProperty.country !== this.property.country) {
            country = this.localProperty.country;
        }

        let addedTags = this.updatedAssociatedTags.filter((value: any) => this.associatedTags.findIndex((tag: any) => tag.uuid === value.uuid) < 0); // new tags
        let removedTags = this.associatedTags.filter((value: any) => this.updatedAssociatedTags.findIndex((tag: any) => tag.uuid === value.uuid) < 0);  // removed tags

        try {
            // update the bascloud backend
            if(addedTags.length > 0)
                await PropertyService.addTags(this.currentTenant, this.property.uuid, addedTags);
            if(removedTags.length > 0)
                await PropertyService.removeTags(this.currentTenant, this.property.uuid, removedTags);

            const property = await PropertyService.updateProperty(this.currentTenant, this.property.uuid, name, aksId, identifier, street, postalCode, city, country);

            this.alertType = "success";
            this.alertText = this.$t('property.successUpdate') as string;
            this.alertVisible = true;
            
            this.localProperty = property;
            this.$emit("update", property);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('property.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        }
      },
      getAssociatedDevices() {
        this.associatedDevices = [];
        this.loading = true;

        PropertyService.getAssociatedDevices(this.currentTenant, this.property.uuid).then((devices: any[]) => {
            this.loading = false;
            this.associatedDevices = devices;
            if(devices.length === 0) {
                this.alertType = "warning";
                this.alertText = this.$t('property.emptyAssociatedDevice');
                this.alertVisible = true;
            }
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('property.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getAssociatedAlerts() {
        this.loading = true;

        PropertyService.getAssociatedAlerts(this.$root.$store.state.session.selectedTenant.uuid, this.property.uuid).then((alerts: any[]) => {
            this.associatedAlerts = alerts;
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('alert.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getAssociatedTags() {
        this.loading = true;

        PropertyService.getAssociatedTags(this.$root.$store.state.session.selectedTenant.uuid, this.property.uuid).then((tags: any[]) => {
            if(tags.length > 0) {
                this.associatedTags = tags;
                this.updatedAssociatedTags = [...tags];
            }
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('property.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getTags() {
        this.loading = true;

        TagService.getAllCollectionPages(this.$root.$store.state.session.selectedTenant.uuid).then((tags: any[]) => {
            this.tags = tags;
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('property.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      async onAlertDelete(item: any) {
        this.associatedAlerts.splice(this.associatedAlerts.findIndex((value: any) => value.uuid === item.uuid), 1);
      },
      async onAlertCreate(item: any) {
        this.associatedAlerts.push(item);
      },
      async onAlertUpdate(item: any) {
        this.associatedAlerts.splice(this.associatedAlerts.findIndex((value: any) => value.uuid === item.uuid), 1);
        this.associatedAlerts.push(item);
      },
      autoComplete,
    },
    watch: {
        property() {
            this.localProperty = Object.assign({}, this.property);
        },
        async localdialog(value: boolean) {
            if(value) {
                this.getAssociatedDevices();
                this.getAssociatedTags();
                this.getTags();
                this.getAssociatedAlerts();
            } else {
                this.clear();
            }
        }
    },
    computed: {
        filteredAssociatedDevices(): any[] {
            return this.associatedDevices.filter((value: any) => this.autoComplete(value, this.associatedDeviceFilter));
        },
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        }
    }
  })
