
  import Vue from 'vue'
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import IoTDeviceMappingService from '@/services/IoTDeviceMappingService';
  import DeviceService from '@/services/DeviceService';
  import IoTDeviceService from '@/services/IoTDeviceService';
  import IoTDevicePortService from '@/services/IoTDevicePortService';


export default Vue.extend({
  name: 'IotDeviceMappingCreateDialog',

  props: ['iotdevice', 'iotdeviceport'],
  components: {
      EntityMetaDialog,
  },
  data: (vm: any): any => ({
      localdialog: false,
      valid: true,
      passwordToggle: false,
      alertText: "",
      alertVisible: false,
      alertType: "error",
      loading: false,
      loadingTenant: false,
      deviceAggregation: false,
      requiredRule: [
          (v: string) => !!v || vm.requiredError
      ],
      requiredSelect: [
        (v: any) => !!v && Object.keys(v).length > 0 || vm.requiredError
      ],
      identifiersRules: [
        (v: string[]) => v.every((value => !value.includes('/'))) || vm.invalidIdentifierError
      ],
      iotdevicemapping: {
        identifiers: [],
        targetDeviceId: "",
        config: {
        }
      } as any,
      loadingDevices: false,
      devices: [],
      selectedAggregationDevices: [],
      mappingIdentifiers: [],
      associatedTenant: null as any,
      associatedConnector: null as any,
      aggregationModes: [
            { text: 'Grouped', value: 'grouped'},
            { text: 'Longitudinal', value: 'longitudinal'},
        ],
      aggregationFrequencies: [
          "MINUTELY",
          "HOURLY",
          "DAILY",
          "WEEKLY",
          "MONTHLY",
          "YEARLY",
      ],
      aggregationFunctions: [
        'LAST',
        'FIRST',
        'MIN',
        'MAX',
        'AVG',
        'MEDIAN',
        'SUM',
        // deltas variants first calculate the diff between values, then apply the secondary function
        'DELTA_LAST',
        'DELTA_FIRST',
        'DELTA_MIN',
        'DELTA_MAX',
        'DELTA_AVG',
        'DELTA_MEDIAN',
        'DELTA_SUM',
        // last variants take the last value for each sourceId in a bucket, then apply the secondary function
        'LAST_LAST',
        'LAST_FIRST',
        'LAST_MIN',
        'LAST_MAX',
        'LAST_AVG',
        'LAST_MEDIAN',
        'LAST_SUM',
        ],
  }),
  async created() {
    if(this.iotdeviceport && this.iotdeviceport.type === 'ir') {
      this.iotdevicemapping.identifiers = ['1-0:1.8.0*255']
    } else if(this.iotdeviceport && this.iotdeviceport.type === 'api') {

      if(this.iotdeviceport.config.integrationSlug === 'sauter-svc-get-values') {
        this.iotdevicemapping.config.aggregationType = "MAX";
      }
    }
  },
  methods: {
    close() {
      this.localdialog = false;
    },
    clear() {
      this.iotdevicemapping = {
        identifiers: [],
        targetDeviceId: "",
        config: {
        }
      };
      this.deviceAggregation = false;
      this.selectedAggregationDevices = [];
      this.mappingIdentifiers = [];
      this.associatedTenant = null;
      this.associatedConnector = null;
      this.iotdevices = [];
      this.alertVisible = false;
      this.loading = false;
      this.devices = [];
    },
    dismiss() {
      this.clear();
      this.close();
    },
    validate() {
      if(this.createForm.validate()) {
        this.create();
      }
    },
    onAggregationModeChange() {
      console.log('aggregation changed');
    },
    async create() {
      this.alertVisible = false;
      this.loading = true;

      let identifiers = [] as string[];

      if(this.deviceAggregation) {
        await this.generateDeviceAggregateIdentifiers();

        this.iotdevicemapping.config.type = 'device_aggregation';
        identifiers = this.mappingIdentifiers;
      } else {
        identifiers = this.iotdevicemapping.identifiers;
      }
      console.log(this.iotdevicemapping.config)

      IoTDeviceMappingService.create(this.iotdevice.uuid, this.iotdeviceport.uuid, identifiers, this.iotdevicemapping.targetDeviceId, this.iotdevicemapping.config).then((iotdevicemapping: any) => {
          this.alertType = "success";
          this.alertText = this.$t('iotdevicemapping.successCreate') as string;
          this.alertVisible = true;

          this.$emit("success", iotdevicemapping);
          setTimeout(() => { 
              this.dismiss(); 
          }, 1500);
      }).catch((err) => {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('iotdevicemapping.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
      });
    },
    async getAssociatedTenant() {
      this.loadingDevice = true;

      try {
        const tenant = await IoTDeviceService.getAssociatedTenant(this.iotdevice.uuid);
        this.loadingDevice = false;

        this.associatedTenant = tenant;
      } catch(err: any) {
        console.log(err);
        console.log(err.response);
        this.alertType = "error";
        this.alertText = this.$t('user.tenantError')+ ' ' + extractErrorMessage(err);
        this.alertVisible = true; 
        this.loadingDevice = false;
        this.associatedTenant = {};
      }
    },
    async getAssociatedConnector() {
      try {
          this.associatedConnector = await IoTDeviceService.getAssociatedConnector(this.iotdevice.uuid);
      } catch(err: any) {
          console.log(err);
          console.log(err.response);
      }
    },
    getDevices() {
      this.devices = [];
      if(!this.associatedTenant)
        return;

      if(!this.associatedConnector)
        return;

      this.loadingDevices = true;

      DeviceService.getAllCollectionPages(this.associatedTenant?.uuid, { connector: this.associatedConnector }).then((devices: any[]) => {
        this.loadingDevices = false;

        this.devices = devices;
      }).catch((err: any) => {
        console.log(err, err.response);  
        this.alertType = "error";
        this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
        this.alertVisible = true;    
        this.loadingDevices = false;  
      });
    },
    async generateDeviceAggregateIdentifiers() {
        this.iotdevicemapping.config.devices = []
        this.mappingIdentifiers = [];
        this.loading = true;
        
        try {
          for(const dev of this.selectedAggregationDevices) {
            console.log(dev.uuid)
            this.iotdevicemapping.config.devices.push(dev.uuid);
            
            const iotdevices = await DeviceService.getAssociatedIoTDevices(this.associatedTenant.uuid, dev.uuid);
            console.log(iotdevices);
            if(iotdevices.length === 0) {
              this.alertType = "error";
              this.alertText = "No associated IoT Device found for " + dev.uuid;
              this.alertVisible = true; 
              this.loading = false;
              return;
            }

            for(const iotd of iotdevices) {
              // bascloud/iotdevice/220008/data/fc0b1ce7-5af2-4102-a161-6a8f7b5aa9b3/2c31057c-a7b8-450b-ace6-6daed944f58b
              const topicString = 'bascloud/iotdevice/' + iotd.serialnumber + '/data/' + this.associatedConnector.uuid + '/' + dev.uuid;
              console.log(topicString)
              this.mappingIdentifiers.push(topicString)
            }
          }
          this.loading = false;
        } catch(err: any) {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('iotdevicemapping.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
          return;
        }
    },
    autoComplete,
  },
  watch: {
      deviceAggregation() {
        if(this.deviceAggregation) {
          this.iotdevicemapping.identifiers = [];
        } else {
          this.selectedAggregationDevices = [];
          delete this.iotdevicemapping.config.devices;
        }
      },
      associatedTenant() {
          this.getDevices();
      },
      iotdevice() {
          this.localIotdevice = JSON.parse(JSON.stringify(this.iotdevice));
      },
      async localdialog(value: boolean) {
          if(value) {
              await this.getAssociatedTenant();
              await this.getAssociatedConnector();
              await this.getDevices();

              if(this.iotdeviceport && this.iotdeviceport.type === 'ir') {
                this.iotdevicemapping.identifiers = ['1-0:1.8.0*255']
              } else if(this.iotdeviceport && this.iotdeviceport.type === 'api') {

                if(this.iotdeviceport.config.integrationSlug === 'sauter-svc-get-values') {
                  this.iotdevicemapping.config.aggregationType = "MAX";
                }
              }
          } else {
              this.clear(); 
          }
      }
  },
  computed: {
    mappingIdentifiersJSON() {
      return JSON.stringify(this.mappingIdentifiers, null, 2);
    },
    createForm(): any {
        return this.$refs.createForm;
    },
    requiredError(): any {
        return this.$t('required');
    },
    invalidIdentifierError(): any {
        return this.$t('iotdevicemapping.invalidIdentifiers');
    },
    currentTenant(): any {
      return this.$root.$store.state.session.selectedTenant.uuid;
    },
  }
})
