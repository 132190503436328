
  import Vue from 'vue'

  import DeviceTypeInfoDialog from '@/components/entities/devicetype/DeviceTypeInfoDialog.vue';
  import DeviceTypeEditDialog from '@/components/entities/devicetype/DeviceTypeEditDialog.vue';
  import DeviceTypeDeleteDialog from '@/components/entities/devicetype/DeviceTypeDeleteDialog.vue';
  import DeviceTypeRestoreDialog from './DeviceTypeRestoreDialog.vue';

  import { objectsToCSV, filterItem } from '@/utils/Util';

  export default Vue.extend({
    name: 'DeviceTypesTable',

    components: {
      DeviceTypeInfoDialog,
      DeviceTypeEditDialog,
      DeviceTypeDeleteDialog,
      DeviceTypeRestoreDialog
    },
    props: ['devicetypes', 'loading'],
    data: (vm: any): any => ({
      tableLoadingText: vm.loadingText,
      search: "",
      showUUIDs: false,
      numberOfEntities: 0,
      selectedHeaders: [
          { text: vm.$t('attribute.type'), value: 'type', index: 1 },
          { text: vm.$t('attribute.description'), value: 'description', index: 2 },
          { text: vm.$t('attribute.updatedAt'), value: 'updatedAt', index: 3 },
          { text: vm.$t('attribute.createdAt'), value: 'createdAt', index: 4 },
      ],
    }),
    async created() {
      if(this.devicetypes.length === 0) {
        this.tableLoadingText = this.$t('deviceType.noData') as string;
      }

      const columns = this.$root.$store.getters.selectedColumns('deviceType');
      if(columns) {
        this.selectedHeaders = columns;
      }
    },
    watch: {
      selectedHeaders() {
        this.$store.commit("setSelectedColumns", { type: 'deviceType', columns: this.selectedHeaders });
      } 
    },
    methods: {
      filterItem,
      onExport() {
          let filteredItems = this.devicetypes;
          if(this.$refs.dataTable.$children[0].filteredItems) {
            filteredItems = this.$refs.dataTable.$children[0].filteredItems;
          }

          // Data structure
          const data = filteredItems.map((prop: any, index: number) => Object({
            uuid: prop.uuid,
            type: prop.type,
            description: prop.description,
            dataLimit: prop.dataLimit,
            dataLimitFrequency: prop.dataLimitFrequency,
            retention: prop.retention,
            tenantId: prop.tenantId,
            createdAt: prop.createdAt,
            updatedAt: prop.updatedAt,
          }));

          const filename = 'bascloud_devicetypes.csv';
          const csvContent = objectsToCSV(data);
          var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

          if ((navigator as any).msSaveBlob) { // IE 10+
              (navigator as any).msSaveBlob(blob, filename);
          } else {
              var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", filename);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }
          }
      },
      onReload() {
        this.$emit("reload");
      },
      onItemRestore(item: any) {
        this.$emit("restore", item);
      },
      onItemUpdate(item: any) {
        this.$emit("update", item);
      },
      onItemDelete(item: any) {
        this.$emit("delete", item);
      },
      onPaginationAndFilter(pagination: any) {
        this.numberOfEntities = pagination.itemsLength;
      },
      onTableRowClick(row: any) {
        const button: any = this.$refs["devicetypeInfoButton_" + row.uuid];
        // $el needed for native elements
        button.$el.click();
      },
      getTenantByUUID(uuid: string) {
        return this.$root.$store.state.session.tenants.find((element: any) => element.uuid === uuid);
      }
    },
    computed: {
      headers(): any {
        return [
          { text: this.$t('attribute.uuid'), value: 'uuid', index: 0 },
          { text: this.$t('attribute.type'), value: 'type', index: 1 },
          { text: this.$t('attribute.description'), value: 'description', index: 2 },
          { text: this.$t('attribute.updatedAt'), value: 'updatedAt', index: 8 },
          { text: this.$t('attribute.createdAt'), value: 'createdAt', index: 9 },
        ];
      },
      loadingText(): any {
        return this.$t('deviceType.loadingData');
      },
      computedTableHeader(): any {

        return [...this.selectedHeaders,
          { text: this.$t('attribute.actions'), value: 'actions', sortable: false, filterable: false, index: 100 },
        ].sort((a: any, b: any) => a.index-b.index);
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'devicetypes' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['devicetypes'].includes('POST');
        } else {
          return false;
        }
      },
      canUpdate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'devicetypes' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['devicetypes'].includes('PATCH');
        } else {
          return false;
        }
      },
      canDelete(): any {
        if (this.$root.$store.state.session.permissions.resources && 'devicetypes' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['devicetypes'].includes('DELETE');
        } else {
          return false;
        }
      },
    },
  })
