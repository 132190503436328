import OTAApi from "@/api/OTAApi";

export default {
    async getAllVersions(): Promise<any> {

        const response = await OTAApi.getAllVersions();
        
        const versions: any[] = [];

        response.data.forEach((versionObj: any) => {
            if(versionObj.name === 'backup')
                return;

            versions.push({
                name: versionObj.name,
                updatedAt: versionObj.mtime,
            });
        });

        return versions;
    },
    async getVersion(version: string): Promise<any> {
        
        const response = await OTAApi.getVersion(version);

        let versionFiles = {
            name: version,
            files: [] as any[]
        };

        response.data.forEach((fileObj: any) => {
            versionFiles.files.push({
                name: fileObj.name,
                url: process.env.VUE_APP_BASCLOUD_OTA_SERVER_URL + '/' + version + '/' + fileObj.name,
                size: fileObj.size,
                updatedAt: fileObj.mtime,
            });
        });
        return versionFiles;
    },
}
  