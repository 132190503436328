
  import Vue from 'vue'

  export default Vue.extend({
    name: 'DateTimePicker',

    props: ['value', 'label', 'rules', 'dense', 'clearable'],
    data() {
      return {
        dateMenu: false,
        datePicker: true,
        date: undefined as string | undefined,
        time: undefined as string | undefined,
        errorMessages: [] as string[],
        error: false,
      }
    },
    created() {
      this.date = (this.value ? this.localeDateString(new Date(this.value)) : undefined);
      this.time = (this.value ? this.localeTimeString(new Date(this.value)) : undefined);
      // console.log(this.value);
      // console.log(this.date, this.time);
      // So that the value is emitted atleast once
      this.onDateTimeSave();
    },
    methods: {
      onInputClear() {
        this.dateMenu = false;
        this.datePicker = true;

        this.date = undefined;
        this.time = undefined;
        this.$emit("change", "");
        this.$emit('input', "");
      },
      onDateTimeSave() {
        this.dateMenu = false;
        this.datePicker = true;

        this.$emit("change", this.utcDateTimeString);
        this.$emit('input', this.utcDateTimeString);
        // console.log(this.utcDateTimeString);
      },   
      localeDateString(date: Date): string {
        if(date) {
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const day = ('0' + date.getDate()).slice(-2);
          const year = date.getFullYear();
          return year + "-" + month + '-' + day;
        } else {
          return "";
        }
      },  
      localeTimeString(date: Date): string {
        if(date) {
          const hour = ('0' + date.getHours()).slice(-2);
          const min = ('0' + date.getMinutes()).slice(-2);
          return hour + ":" + min;
        } else {
          return "";
        }
      },
    },
    computed: {
        localeDateTimeString(): string {
          if(this.dateTime) {
            return this.dateTime.toLocaleString();
          } else {
            return "";
          }
        },
        utcDateTimeString(): string {
          if(this.dateTime) {
            return this.dateTime.toISOString();
          } else {
            return "";
          }
        },
        dateTime(): Date | undefined {
          if(this.date && this.time) {
            const dateTime = this.date + "T" + this.time + ":00";
            return new Date(dateTime);
          } else {
            return undefined;
          }
        }        
    },
    watch: {
      value(value: Date) {
        this.date = (value ? this.localeDateString(new Date(value)) : undefined);
        this.time = (value ? this.localeTimeString(new Date(value)) : undefined);
      },
      dateTime(value: Date) {
        if(!this.rules) return;
        this.rules.forEach((rule: any) => {
          this.error = false;
          this.errorMessages = [];

          const res = rule(value);
          if(res !== true) {
            this.error = true;
            this.errorMessages.push(res);
          }
        });
      }
    }
  })
