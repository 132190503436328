
  import Vue from 'vue'
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import { extractErrorMessage } from '@/utils/Util';
  import DeviceService from '@/services/DeviceService';
  import IoTDeviceService from '@/services/IoTDeviceService';
  import DeviceInfoDialog from '@/components/entities/device/DeviceInfoDialog.vue';

  export default Vue.extend({
    name: 'IotDeviceMappingInfoDialog',

    components: {
        EntityMetaDialog,
        DeviceInfoDialog
    },
    props: ['iotdevice', 'iotdeviceport', 'iotdevicemapping'],
    data: () => ({
        localdialog: false,
        valid: true,
        passwordToggle: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingDevice: false,
        associatedTenant: {} as any,
        associatedTargetDevice: {} as any,
        associatedConnector: {} as any,
        devices: [] as any[],
        loadingDevices: false
    }),
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedTenant = {};
        this.associatedTargetDevice = {};
        this.alertVisible = false;
        this.loading = false;
        this.associatedConnector = {} as any;
        this.devices = [];
        this.loadingDevices = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async getAssociatedTargetDevice() {
        this.loadingDevice = true;

        try {
          const device = await DeviceService.getSingle(this.associatedTenant.uuid, this.iotdevicemapping.targetDeviceId);
          this.loadingDevice = false;

          this.associatedTargetDevice = device;
        } catch(err: any) {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('user.tenantError')+ ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loadingDevice = false;
          this.associatedTargetDevice = {};
        }
      },
      async getAssociatedTenant() {
        this.loading = true;

        try {
          const tenant = await IoTDeviceService.getAssociatedTenant(this.iotdevice.uuid);
          this.loading = false;

          this.associatedTenant = tenant;
        } catch(err: any) {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('user.tenantError')+ ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
          this.associatedTenant = {};
        }
      },
      async getAssociatedConnector() {
        try {
            this.associatedConnector = await IoTDeviceService.getAssociatedConnector(this.iotdevice.uuid);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
        }
      },
      getDevices() {
        this.devices = [];
        if(!this.associatedTenant)
            return;

        if(!this.associatedConnector)
            return;

        this.loadingDevices = true;

        DeviceService.getAllCollectionPages(this.associatedTenant?.uuid, { connector: this.associatedConnector }).then((devices: any[]) => {
            this.loadingDevices = false;

            this.devices = devices;
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;    
            this.loadingDevices = false;  
        });
      },
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                await this.getAssociatedTenant();
                await this.getAssociatedTargetDevice();
                await this.getAssociatedConnector();
                this.getDevices();
            } else {
                this.clear();
            }
        }
    },
    computed: {
        identifiersJSON(): string {
            return JSON.stringify(this.iotdevicemapping.identifiers, null, 2);
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        },
    }
  })
