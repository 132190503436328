
  import Vue from 'vue'
  import DeviceShareInfoDialog from '@/components/entities/deviceshare/DeviceShareInfoDialog.vue';
  import DeviceShareEditDialog from '@/components/entities/deviceshare/DeviceShareEditDialog.vue';
  import DeviceShareDeleteDialog from '@/components/entities/deviceshare/DeviceShareDeleteDialog.vue';
  import DeviceShareSyncDialog from '@/components/entities/deviceshare/DeviceShareSyncDialog.vue';

  import { objectsToCSV, filterItem } from '@/utils/Util';
import DeviceShareService from '@/services/DeviceShareService';

  export default Vue.extend({
    name: 'DeviceSharesTable',

    components: {
      DeviceShareInfoDialog,
      DeviceShareEditDialog,
      DeviceShareDeleteDialog,
      DeviceShareSyncDialog
    },
    props: ['deviceshares', 'loading'],
    data: (vm: any): any => ({
      tableLoadingText: vm.loadingText,
      search: "",
      showUUIDs: false,
      numberOfEntities: 0,
      selectedHeaders: [
          { text: vm.$t('target') + ' ' + vm.$t('entity.tenant'), value: 'targetTenantId', index: 2 },
          { text: vm.$t('attribute.state'), value: 'state', index: 5 },
          { text: "Cascade Updates", value: 'cascadeUpdates', index: 8 },
          { text: "Cascade Deletes", value: 'cascadeDeletes', index: 9 },
          { text: vm.$t('attribute.updatedAt'), value: 'updatedAt', index: 10 },
          { text: vm.$t('attribute.createdAt'), value: 'createdAt', index: 11 },
      ],
      currentItems: [],
      loadingTargetDevices: false,
      loadingSourceDevices: false,
      loadingTargetTenants: false,
      loadingSourceTenants: false
    }),
    created() {
      if(this.deviceshares.length === 0) {
        this.tableLoadingText = this.$t('apikey.noData');
      }

      const columns = this.$root.$store.getters.selectedColumns('deviceshare');
      if(columns) {
        this.selectedHeaders = columns;
      }
    },
    watch: {
      selectedHeaders() {
        this.$store.commit("setSelectedColumns", { type: 'deviceshare', columns: this.selectedHeaders });
      }
    },
    methods: {
      filterItem,
      onExport() {
          let filteredItems = this.deviceshares;
          if(this.$refs.dataTable.$children[0].filteredItems) {
            filteredItems = this.$refs.dataTable.$children[0].filteredItems;
          }

          // Data structure
          const data = filteredItems.map((conn: any, index: number) => Object({
            uuid: conn.uuid,
            userId: conn.user.uuid,
            userEmail: conn.user.email,
            name: conn.name,
            expiration: conn.expiration,
            createdAt: conn.createdAt,
            updatedAt: conn.updatedAt,
          }));

          const filename = 'bascloud_deviceshares.csv';
          const csvContent = objectsToCSV(data);
          var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

          if ((navigator as any).msSaveBlob) { // IE 10+
              (navigator as any).msSaveBlob(blob, filename);
          } else {
              var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", filename);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }
          }
      },
      onCurrentItemsChange(items: any[]) {
        this.currentItems = items;
      },
      onReload() {
        this.$emit("reload");
      },
      onItemUpdate(item: any) {
        this.$emit("update", item);
      },
      onItemDelete(item: any) {
        this.$emit("delete", item);
      },
      onPaginationAndFilter(pagination: any) {
        this.numberOfEntities = pagination.itemsLength;
      },
      getSourceTenantLabel(share: any) {
        if(!('sourceTenant' in share) && !this.loadingSourceTenants) {
          this.loadCurrentSourceTenants();
        }

        return share?.sourceTenant?.name || share.sourceTenantId;
      },
      getTargetTenantLabel(share: any) {
        if(share.targetTenantId && !('targetTenant' in share) && !this.loadingTargetTenants) {
          this.loadCurrentTargetTenants();
        }

        return share?.targetTenant?.name || share.targetTenantId;
      },
      getSourceDeviceLabel(share: any) {
        if(!('sourceDevice' in share) && !this.loadingSourceDevices) {
          this.loadCurrentSourceDevices();
        }

        return share?.sourceDevice?.description || share.sourceDeviceId;
      },
      getTargetDeviceLabel(share: any) {
        if((this.isTarget(share) || this.isRoot) && !('targetDevice' in share) && !this.loadingTargetDevices) {
          this.loadCurrentTargetDevices();
        }

        return share?.targetDevice?.description || share.targetDeviceId;
      },
      async loadCurrentSourceTenants() {
        if(!this.loadingSourceTenants && this.currentItems.length > 0) {
          this.loadingSourceTenants = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (value: any) => {
            if(!value.sourceTenant)
              promises.push(this.loadSourceTenant(value));
          });
          await Promise.all(promises);
          this.loadingSourceTenants = false;
        }
      }, 
      async loadSourceTenant(share: any) {
        try {
            const tenant = await DeviceShareService.getSourceTenant(share.uuid);
            Vue.set(share, 'sourceTenant', tenant); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(share, 'sourceTenant', {});
        }
      },
      async loadCurrentTargetTenants() {
        if(!this.loadingTargetTenants && this.currentItems.length > 0) {
          this.loadingTargetTenants = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (value: any) => {
            if(!value.targetTenant)
              promises.push(this.loadTargetTenant(value));
          });
          await Promise.all(promises);
          this.loadingTargetTenants = false;
        }
      }, 
      async loadTargetTenant(share: any) {
        try {
            const tenant = await DeviceShareService.getTargetTenant(share.uuid);
            Vue.set(share, 'targetTenant', tenant);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(share, 'targetTenant', {});
        }
      },
      async loadCurrentSourceDevices() {
        if(!this.loadingSourceDevices && this.currentItems.length > 0) {
          this.loadingSourceDevices = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (value: any) => {
            if(!value.sourceDevice)
              promises.push(this.loadSourceDevice(value));
          });
          await Promise.all(promises);
          this.loadingSourceDevices = false;
        }
      }, 
      async loadSourceDevice(share: any) {
        try {
            const device = await DeviceShareService.getSourceDevice(share.uuid);
            Vue.set(share, 'sourceDevice', device); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(share, 'sourceDevice', {});
        }
      },
      async loadCurrentTargetDevices() {
        if(!this.loadingTargetDevices && this.currentItems.length > 0) {
          this.loadingTargetDevices = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (value: any) => {
            if(!value.targetDevice)
              promises.push(this.loadTargetDevice(value));
          });
          await Promise.all(promises);
          this.loadingTargetDevices = false;
        }
      }, 
      async loadTargetDevice(share: any) {
        try {
            const device = await DeviceShareService.getTargetDevice(share.uuid);
            Vue.set(share, 'targetDevice', device); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(share, 'targetDevice', {});
        }
      },
      onTableRowClick(row: any) {
        const button: any = this.$refs["deviceshareInfoButton_" + row.uuid];
        // $el needed for native elements
        button.$el.click();
      },
      userField(item: any) {
        return item.user?.email || '';
      },
      getDays(date: Date) {
        return Math.floor(( date.valueOf() - new Date().valueOf() ) / 86400000); 
      },
      getStateColor(state: string) {
        if (state === 'open') return 'warning'
        if (state === 'approved') return 'success'
        else return undefined
      },
      getColor(date: Date) {
        let diff =  this.getDays(date);
        if (diff <= 0) return 'red'
        else if (diff <= 7) return 'orange'
        else return 'green'
      },
      isSource(share: any): boolean {
          return share.sourceTenantId === this.$root.$store.state.session.selectedTenant.uuid;
      },
      isTarget(share: any): boolean {
          return share.targetTenantId === this.$root.$store.state.session.selectedTenant.uuid;
      },
    },
    computed: {
      headers() {
        return [
          { text: this.$t('attribute.uuid'), value: 'uuid', index: 0 },
          { text: this.$t('target') + ' ' + this.$t('entity.tenant'), value: 'targetTenantId', index: 2 },
          { text: this.$t('source') + ' ' + this.$t('entity.device'), value: 'sourceDeviceId', index: 3 },
          { text: this.$t('target') + ' ' + this.$t('entity.device'), value: 'targetDeviceId', index: 4 },
          { text: this.$t('attribute.state'), value: 'state', index: 5 },
          { text: this.$t('attribute.from'), value: 'from', index: 6 },
          { text: this.$t('attribute.until'), value: 'until', index: 7 },
          { text: "Cascade Updates", value: 'cascadeUpdates', index: 8 },
          { text: "Cascade Deletes", value: 'cascadeDeletes', index: 9 },
          { text: this.$t('attribute.updatedAt'), value: 'updatedAt', index: 10 },
          { text: this.$t('attribute.createdAt'), value: 'createdAt', index: 11 },
        ];
      },
      loadingText(): any {
        return this.$t('loadingData');
      },
      computedTableHeader(): any {
        return [...this.selectedHeaders,
          { text: this.$t('attribute.actions'), value: 'actions', sortable: false, filterable: false, index: 100 },
        ].sort((a: any, b: any) => a.index-b.index);
      },
      canUpdate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'deviceshares' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['deviceshares'].includes('PATCH');
        } else {
          return false;
        }
      },
      canDelete(): any {
        if (this.$root.$store.state.session.permissions.resources && 'deviceshares' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['deviceshares'].includes('DELETE');
        } else {
          return false;
        }
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'deviceshares' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['deviceshares'].includes('POST');
        } else {
          return false;
        }
      }, 
      isRoot(): any {
        return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
          && this.$root.$store.state.session.permissions.role === 'superadmin';
      },
    },
  })
