import IoTDeviceMappingApi from '@/api/IoTDeviceMappingApi';
import store from '../store/index';
import router from '../router/index';

export default {
    async getAllCollectionPages(iotdeviceUUID: string, iotdevicePortUUID: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const devices: any[] = [];
        const pageResponses = await IoTDeviceMappingApi.getAllCollectionPages(iotdeviceUUID, iotdevicePortUUID, params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                devices.push({
                    uuid: userObj.id,
                    identifiers: userObj.attributes.identifiers,
                    targetDeviceId: userObj.attributes.targetDeviceId,
                    config: userObj.attributes.config,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                });
            });
        });

        return devices;
    },
    async getSingle(iotdeviceUUID: string, iotdevicePortUUID: string, iotdeviceMappingUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceMappingApi.getSingle(iotdeviceUUID, iotdevicePortUUID, iotdeviceMappingUUID);

        return {
            uuid: response.data.data.id,
            identifiers: response.data.data.attributes.identifiers,
            targetDeviceId: response.data.data.attributes.targetDeviceId,
            config: response.data.data.attributes.config,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async create(iotdeviceUUID: string, iotdevicePortUUID: string, identifiers: string[], targetDeviceId: string, config: any): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceMappingApi.create(iotdeviceUUID, iotdevicePortUUID, identifiers, targetDeviceId, config);

        if(response.data.data.type && response.data.data.type == 'iotdevicemappings') {

            return {
                uuid: response.data.data.id,
                identifiers: response.data.data.attributes.identifiers,
                targetDeviceId: response.data.data.attributes.targetDeviceId,
                config: response.data.data.attributes.config,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            };
        } else {
            throw TypeError("Response not type iotdevicemappings");
        }
    },
    async update(iotdeviceUUID: string, iotdevicePortUUID: string, iotdeviceMappingUUID: string, identifiers?: string[], targetDeviceId?: string, config?: any): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDeviceMappingApi.update(iotdeviceUUID, iotdevicePortUUID, iotdeviceMappingUUID, identifiers, targetDeviceId, config);
        
        return {
            uuid: response.data.data.id,
            identifiers: response.data.data.attributes.identifiers,
            targetDeviceId: response.data.data.attributes.targetDeviceId,
            config: response.data.data.attributes.config,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async delete(iotdeviceUUID: string, iotdevicePortUUID: string, iotdeviceMappingUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        await IoTDeviceMappingApi.delete(iotdeviceUUID, iotdevicePortUUID, iotdeviceMappingUUID);
    }
}
  