import ConnectorApi from '@/api/ConnectorApi';
import store from '../store/index'
import router from '../router/index'

const timeUnits = [
    {name: 'minutes', value: 60 },
    {name: 'hours', value: 3600 }, 
    {name: 'days', value: 86400 },
    {name: 'weeks', value: 604800 },
    {name: 'months', value: 2629800 },
    {name: 'years', value: 31557600 }
];

export default { 
    async getAllCollectionPages(tenant: string, params: any = {}): Promise<any[]> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const connectors: any[] = [];
        const pageResponses = await ConnectorApi.getAllCollectionPages(tenant, params);
  
        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                connectors.push({
                    uuid: userObj.id,
                    name: userObj.attributes.name,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                    lastActiveAt: userObj.meta.lastActiveAt,
                })
            });
        });

        return connectors;
    },
    async getSingle(tenant: string, connectorUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await ConnectorApi.getSingle(tenant, connectorUUID);
  
        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            lastActiveAt: response.data.data.meta.lastActiveAt,
        };
    },
    async createConnector(tenantUUID: string, name: string): Promise<any> { 
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await ConnectorApi.createConnector(tenantUUID, name);

        if(response.data.data.type && response.data.data.type == 'connectors') {
            return {
                uuid: response.data.data.id,
                name: response.data.data.attributes.name,
                token: response.data.data.attributes.token,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                lastActiveAt: response.data.data.meta.lastActiveAt,
            }
        } else {
            throw TypeError("Response not type connectors");
        }
    },
    async deleteConnector(tenantUUID: string, connectorUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await ConnectorApi.deleteConnector(tenantUUID, connectorUUID);
    },
    async updateConnector(tenantUUID: string, connectorUUID: string, name?: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await ConnectorApi.updateConnector(tenantUUID, connectorUUID, name);

        if(response.data.data.type && response.data.data.type == 'connectors') {
            return {
                uuid: response.data.data.id,
                name: response.data.data.attributes.name,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                lastActiveAt: response.data.data.meta.lastActiveAt,
            }
        } else {
            throw TypeError("Response not type connectors");
        }
    },
    async getAssociatedDevices(tenantUUID: string, connectorUUID: string): Promise<any[]> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const associatedDevices: any[] = [];
        const response = await ConnectorApi.getAssociatedDevices(tenantUUID, connectorUUID);

        response.data.data.forEach((userObj: any) => {
            associatedDevices.push({
                uuid: userObj.id,
                aksId: (userObj.attributes.aksId || ''),
                localAksId: (userObj.attributes.localAksId || ''),
                description: userObj.attributes.description,
                unit: userObj.attributes.unit,
                type: userObj.attributes.type,
                alerting: userObj.attributes.alerting,
                isRelative: userObj.attributes.isRelative,
                frequency: userObj.attributes.frequency,
                interval: userObj.attributes.interval,
                aggregation: userObj.attributes.aggregation,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                deletedAt: userObj.meta.deletedAt,
            })
        });

        return associatedDevices;
    },
    async getAssociatedTags(tenantUUID: string, connectorUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const tags: any[] = [];
        const response = await ConnectorApi.getAssociatedTags(tenantUUID, connectorUUID);

        response.data.data.forEach((userObj: any) => {
            tags.push({
                uuid: userObj.id,
                name: userObj.attributes.name,
                description: userObj.attributes.description,
                type: userObj.attributes.type,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                connectorId: connectorUUID
            });
        });
        return tags;
    },
    async addTags(tenantUUID: string, connectorUUID: string, tags: any[]): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        return await ConnectorApi.addTags(tenantUUID, connectorUUID, tags);
    },
    async removeTags(tenantUUID: string, connectorUUID: string, tags: any[]): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        await ConnectorApi.removeTags(tenantUUID, connectorUUID, tags);
    },
    async generateToken(tenantUUID: string, connectorUUID: string): Promise<any> {  
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const response = await ConnectorApi.generateToken(tenantUUID, connectorUUID);

        return { token: response.data.data.attributes.token, expiration: response.data.data.attributes.expires };
    },
    async getAssociatedAlerts(tenantUUID: string, connectorUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const alerts: any[] = [];
        const response = await ConnectorApi.getAssociatedAlerts(tenantUUID, connectorUUID);

        response.data.data.forEach((userObj: any) => {
            let timeUnit;
            let frequency;
            for(const unit of [...timeUnits].reverse()) {
                if(userObj.attributes.maxAge % unit.value === 0) {
                    timeUnit = unit.name;
                    frequency =  userObj.attributes.maxAge / unit.value;
                    break;
                }
            }
            alerts.push({
                uuid: userObj.id,
                recipients: userObj.attributes.recipients,
                maxAge: userObj.attributes.maxAge,
                paused: userObj.attributes.paused,
                active: userObj.attributes.active,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                timeUnit: timeUnit,
                frequency: frequency
            });
        });
        return alerts;
    },
    async addAlert(tenantUUID: string, connectorUUID: string, recipients: string[], maxAge: number): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await ConnectorApi.addAlert(tenantUUID, connectorUUID, recipients, maxAge);

        if(response.data.data.type && response.data.data.type == 'alerts') {
            let timeUnit;
            let frequency;
            for(const unit of [...timeUnits].reverse()) {
                if(response.data.data.attributes.maxAge % unit.value === 0) {
                    timeUnit = unit.name;
                    frequency =  response.data.data.attributes.maxAge / unit.value;
                    break;
                }
            }
            return {
                uuid: response.data.data.id,
                recipients: response.data.data.attributes.recipients,
                maxAge: response.data.data.attributes.maxAge,
                paused: response.data.data.attributes.paused,
                active: response.data.data.attributes.active,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                timeUnit: timeUnit,
                frequency: frequency
            }
        } else {
            throw TypeError("Response not type alerts");
        }
    },
    async updateAlert(tenantUUID: string, connectorUUID: string, alertUUID: string, recipients?: string[], maxAge?: number): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await ConnectorApi.updateAlert(tenantUUID, connectorUUID, alertUUID, recipients, maxAge);

        if(response.data.data.type && response.data.data.type == 'alerts') {
            let timeUnit;
            let frequency;
            for(const unit of [...timeUnits].reverse()) {
                if(response.data.data.attributes.maxAge % unit.value === 0) {
                    timeUnit = unit.name;
                    frequency =  response.data.data.attributes.maxAge / unit.value;
                    break;
                }
            }
            return {
                uuid: response.data.data.id,
                recipients: response.data.data.attributes.recipients,
                maxAge: response.data.data.attributes.maxAge,
                paused: response.data.data.attributes.paused,
                active: response.data.data.attributes.active,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                timeUnit: timeUnit,
                frequency: frequency
            }
        } else {
            throw TypeError("Response not type alerts");
        }
    },
    async removeAlert(tenantUUID: string, connectorUUID: string, alertUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await ConnectorApi.removeAlert(tenantUUID, connectorUUID, alertUUID);
    },
}
  