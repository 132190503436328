
  import Vue from 'vue'
  import UserService from '@/services/UserService';
  import MeteringService from '@/services/MeteringService';
import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'UserDeleteDialog',
    props: ['user'],
    data: () => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        userTenant: {} as any,
    }),
    methods: {
      close() {
        this.localDialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async loadAssociatedTenant() {
        this.loading = true;

        try {
          const tenant = await UserService.getAssociatedTenant(this.user.uuid)
          this.loading = false;

          this.userTenant = tenant;
        } catch(err: any) {
          if(err.response && err.response.status === 404) {
              this.alertType = "warning";
              this.alertText = err.response.data.errors[0].source['pointer'];
              this.alertVisible = true;
              this.loading = false;
          } else {
              console.log(err);
              console.log(err.response);
              this.alertType = "error";
              this.alertText = this.$t('user.tenantError') + ' ' + extractErrorMessage(err);
              this.alertVisible = true; 
              this.loading = false;
          }
          this.userTenant = {};
        }
      },
      async confirmDelete() {
        this.loading = true;

        try {
            await UserService.deleteUser(this.user.uuid);
            if(Object.keys(this.userTenant).length > 0) {
                await MeteringService.deleteUser(this.userTenant.uuid, this.user.uuid);
            }
            this.alertType = "success";
            this.alertText = this.$t('user.successDelete') as string;
            this.alertVisible = true;
            this.loading = false;

            setTimeout(() => { 
                this.$emit("delete", this.user);
                this.dismiss(); 
            }, 2000);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('user.errorDelete') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        }
      }
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                await this.loadAssociatedTenant();
            } else {
                this.clear();
            }
        }
    },
  })
