
  import Vue from 'vue'
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import IoTDeviceService from '@/services/IoTDeviceService';
  import DeviceService from '@/services/DeviceService';
  import IoTDeviceMappingService from '@/services/IoTDeviceMappingService';
import IoTDevicePortService from '@/services/IoTDevicePortService';


  export default Vue.extend({
    name: 'IotDeviceMappingEditDialog',

    props: ['iotdevice', 'iotdeviceport', 'iotdevicemapping'],
    components: {
        EntityMetaDialog,
    },
    data: (vm: any): any => ({
        localdialog: false,
        localIotdevicemapping: {} as any,
        valid: true,
        passwordToggle: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingTenant: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
          (v: any) => !!v && Object.keys(v).length > 0 || vm.requiredError
        ],
        aggregationModes: [
            { text: 'Grouped', value: 'grouped'},
            { text: 'Longitudinal', value: 'longitudinal'},
        ],
        loadingDevices: false,
        deviceAggregation: false,
        identifiersChanged: false,
        devices: [],
        selectedAggregationDevices: [],
        associatedTenant: null as any,
        associatedConnector: null as any,
        aggregationFrequencies: [
            "MINUTELY",
            "HOURLY",
            "DAILY",
            "WEEKLY",
            "MONTHLY",
            "YEARLY",
        ],
        aggregationFunctions: [
        'LAST',
        'FIRST',
        'MIN',
        'MAX',
        'AVG',
        'MEDIAN',
        'SUM',
        // deltas variants first calculate the diff between values, then apply the secondary function
        'DELTA_LAST',
        'DELTA_FIRST',
        'DELTA_MIN',
        'DELTA_MAX',
        'DELTA_AVG',
        'DELTA_MEDIAN',
        'DELTA_SUM',
        // last variants take the last value for each sourceId in a bucket, then apply the secondary function
        'LAST_LAST',
        'LAST_FIRST',
        'LAST_MIN',
        'LAST_MAX',
        'LAST_AVG',
        'LAST_MEDIAN',
        'LAST_SUM',
        ],
    }),
    async created() {
        this.localIotdevicemapping = JSON.parse(JSON.stringify(this.iotdevicemapping));
    },
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedTenant = null;
        this.associatedConnector = null;
        this.devices = [];
        this.selectedAggregationDevices = [];
        this.identifiersChanged = false;
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      onAggregationModeChange(value: string) {
        if(!value) {
          delete this.localIotdevicemapping.config.aggregationMode;
          delete this.localIotdevicemapping.config.aggregationInterval;
          delete this.localIotdevicemapping.config.aggregationFrequency;
          delete this.localIotdevicemapping.config.aggregationFunction;
        }
      },
      async save() {
        this.alertVisible = false;
        this.loading = true;

        console.log(this.localIotdevicemapping);

        if(this.deviceAggregation) {
          await this.generateDeviceAggregateIdentifiers();
        }

        let identifiers;
        let targetDeviceId;
        let config;

        if(this.localIotdevicemapping.targetDeviceId !== this.iotdevicemapping.targetDeviceId) {
          targetDeviceId = this.localIotdevicemapping.targetDeviceId;
        }
        if(JSON.stringify(this.localIotdevicemapping.identifiers.sort()) != JSON.stringify(this.iotdevicemapping.identifiers.sort())) {
          identifiers = this.localIotdevicemapping.identifiers;
        }
        if(JSON.stringify(this.localIotdevicemapping.config) != JSON.stringify(this.iotdevicemapping.config)) {
          config = this.localIotdevicemapping.config;
        }
        console.log(config);

        IoTDeviceMappingService.update(this.iotdevice.uuid, this.iotdeviceport.uuid, this.iotdevicemapping.uuid, identifiers, targetDeviceId, config).then((iotdevicemapping: any) => {

            this.alertType = "success";
            this.alertText = this.$t('iotdevicemapping.successUpdate') as string;
            this.alertVisible = true;
            console.log(iotdevicemapping);
            this.localIotdevicemapping = JSON.parse(JSON.stringify(iotdevicemapping));;

            this.$emit("update", iotdevicemapping);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('iotdevicemapping.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      async getAssociatedTenant() {
        this.loadingDevice = true;

        try {
          const tenant = await IoTDeviceService.getAssociatedTenant(this.iotdevice.uuid);
          this.loadingDevice = false;

          this.associatedTenant = tenant;
        } catch(err: any) {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('user.tenantError')+ ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loadingDevice = false;
          this.associatedTenant = {};
        }
      },
      async generateDeviceAggregateIdentifiers() {
        this.loading = true;
        let devices = [];
        let identifiers = [];

        try {

            for(const dev of this.selectedAggregationDevices) {
                console.log(dev.uuid)
                devices.push(dev.uuid);
                
                const iotdevices = await DeviceService.getAssociatedIoTDevices(this.associatedTenant.uuid, dev.uuid);
                console.log(iotdevices);

                for(const iotd of iotdevices) {
                  // bascloud/iotdevice/220008/data/fc0b1ce7-5af2-4102-a161-6a8f7b5aa9b3/2c31057c-a7b8-450b-ace6-6daed944f58b
                  const topicString = 'bascloud/iotdevice/' + iotd.serialnumber + '/data/' + this.associatedConnector.uuid + '/' + dev.uuid;
                  console.log(topicString)
                  identifiers.push(topicString)
                }
            }
            this.loading = false;
            this.localIotdevicemapping.config.devices = devices;
            this.identifiersChanged = (JSON.stringify(this.iotdevicemapping.identifiers.sort()) != JSON.stringify(identifiers.sort()))
            this.localIotdevicemapping.identifiers = identifiers;
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('iotdevicemapping.errorCreate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
            return;
        }
      },
      async getAssociatedConnector() {
        try {
            this.associatedConnector = await IoTDeviceService.getAssociatedConnector(this.iotdevice.uuid);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
        }
      },
      getDevices() {
        this.loadingDevices = true;
        this.devices = [];

        DeviceService.getAllCollectionPages(this.associatedTenant.uuid, { connector: this.associatedConnector }).then((devices: any[]) => {
          this.loadingDevices = false;

          this.devices = devices;
          if(this.localIotdevicemapping.config?.devices) {
            this.selectedAggregationDevices = this.devices.filter((value: any) => this.localIotdevicemapping.config.devices.includes(value.uuid));
          }
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;    
            this.loadingDevices = false;  
        });
      },
      autoComplete,
    },
    watch: {
        deviceAggregation() {
          if(this.deviceAggregation) {
              // this.localIotdevicemapping.identifiers = [];
          } else {
            this.selectedAggregationDevices = [];
            delete this.localIotdevicemapping.config.devices;
          }
        },
        iotdevicemapping() {
            this.localIotdevicemapping = JSON.parse(JSON.stringify(this.iotdevicemapping));
        },
        async localdialog(value: boolean) {
            if(value) {
              this.deviceAggregation = this.iotdevicemapping.config?.type === 'device_aggregation';
              await this.getAssociatedTenant();
              await this.getAssociatedConnector();
              this.getDevices();
            } else {
                this.clear(); 
            }
        }
    },
    computed: {
        mappingIdentifiersJSON() {
            return JSON.stringify(this.localIotdevicemapping.identifiers, null, 2);
        },
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        },
        activeLabel() {
            if(this.iotdevice.enabled)
                return this.$t('enabled');
            return this.$t('disabled');
        },
        monitoringLabel() {
            if(this.associatedMonitoringAlerts.length > 0)
                return this.$t('on');
            return this.$t('off');
        },
    }
  })
