
  import Vue from 'vue'

  import { objectsToCSV, filterItem } from '@/utils/Util';
  import IoTDeviceService from '@/services/IoTDeviceService';
  import ReadingService from '@/services/ReadingService';

  import IotDeviceDeleteDialog from './IotDeviceDeleteDialog.vue';
  import IotDeviceDisableDialog from './IotDeviceDisableDialog.vue'
  import IotDeviceInfoDialog from './IotDeviceInfoDialog.vue';
  import IotDeviceEditDialog from './IotDeviceEditDialog.vue';
  import IotDeviceCommandDialog from './IotDeviceCommandDialog.vue'

  import DeviceService from '@/services/DeviceService';
  
  export default Vue.extend({
    name: 'IotDevicesTable',

    components: {
      IotDeviceDeleteDialog,
      IotDeviceDisableDialog,
      IotDeviceInfoDialog,
      IotDeviceEditDialog,
      IotDeviceCommandDialog
    },
    props: ['iotdevices', 'loading'],
    data: (vm: any): any => ({
      loadingTenants: false,
      loadingPingReadings: false,
      loadingMonitoring: false,
      loadingProperties: false,
      loadingConnectors: false,
      currentItems: [],
      tableLoadingText: vm.loadingText,
      search: "",
      showUUIDs: false,
      numberOfEntities: 0,
      selectedHeaders: [
          { text: vm.$t('attribute.serialnumber'), value: 'serialnumber', index: 1 },
          { text: vm.$t('attribute.type'), value: 'type', index: 2 },
          { text: vm.$t('enabled'), value: 'enabled', index: 3 },
          { text: vm.$t('attribute.connectivity'), value: 'connectivity', index: 4 },
          { text: vm.$t('attribute.createdAt'), value: 'createdAt', index: 9 },
      ],
      refreshInterval: null,
    }),
    created() {
      if(this.iotdevices.length === 0) {
        this.tableLoadingText = this.$t('iotdevice.noData');
      }

      const columns = this.$root.$store.getters.selectedColumns('iotdevices');
      if(columns) {
        this.selectedHeaders = columns;
      }

      this.refreshInterval = setInterval(this.loadLastPings, 60*1000);
    },
    destroyed() {
      clearInterval(this.refreshInterval);
    },
    watch: {
      selectedHeaders() {
        this.$store.commit("setSelectedColumns", { type: 'iotdevices', columns: this.selectedHeaders });
      },
    },
    methods: {
      async onMonitoringDisable(item: any) {
        Vue.set(item, 'monitoringLoading', true);
        try {
          await IoTDeviceService.deactivateIoTDeviceMonitoring(item.uuid);
          Vue.set(item, 'monitoring', false);
        } finally {
          Vue.set(item, 'monitoringLoading', false);
        }
      },
      async onMonitoringEnable(item: any) {
        Vue.set(item, 'monitoringLoading', true);
        try {
          await IoTDeviceService.activateIoTDeviceMonitoring(item.uuid);
          Vue.set(item, 'monitoring', true);
        } finally {
          Vue.set(item, 'monitoringLoading', false);
        }
      },
      async onItemDisable(item: any) {
        Vue.set(item, 'activationLoading', true);
        try {
          Vue.set(item, 'enabled', false);
        } finally {
          Vue.set(item, 'activationLoading', false);
        }
      },
      async onItemEnable(item: any) {
        Vue.set(item, 'activationLoading', true);
        try {
          await IoTDeviceService.activateIoTDevice(item.uuid);
          Vue.set(item, 'enabled', true);
        } finally {
          Vue.set(item, 'activationLoading', false);
        }
      },
      onCurrentItemsChange(items: any[]) {
        this.currentItems = items;
      },
      getTenantLabel(item: any) {
        if(!('tenant' in item) && !this.loadingTenants) {
          this.loadTenants();
        }

        return item?.tenant?.name || '';
      },
      async loadTenants() {
        if(!this.loadingTenants && this.currentItems.length > 0) {
          this.loadingTenants = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (value: any) => {
            if(!value.tenant)
              promises.push(this.loadTenant(value));
          });
          await Promise.all(promises);
          this.loadingTenants = false;
        }
      }, 
      async loadTenant(iotdevice: any) {
        try {
            const tenant = await IoTDeviceService.getAssociatedTenant(iotdevice.uuid);
            Vue.set(iotdevice, 'tenant', tenant); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(iotdevice, 'tenant', {});
        }
      },
      getPropertyLabel(item: any) {
        if(!('property' in item) && !this.loadingProperties) {
          this.loadProperties();
        }

        return item?.property?.name || '';
      },
      async loadProperties() {
        if(!this.loadingProperties && this.currentItems.length > 0) {
          this.loadingProperties = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (value: any) => {
            if(!value.property)
              promises.push(this.loadProperty(value));
          });
          await Promise.all(promises);
          this.loadingProperties = false;
        }
      }, 
      async loadProperty(iotdevice: any) {
        try {
            const property = await IoTDeviceService.getAssociatedProperty(iotdevice.uuid);
            Vue.set(iotdevice, 'property', property); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(iotdevice, 'property', {});
        }
      },
      getConnectorLabel(item: any) {
        if(!('connector' in item) && !this.loadingConnectors) {
          this.loadConnectors();
        }

        return item?.connector?.name || '';
      },
      async loadConnectors() {
        if(!this.loadingConnectors && this.currentItems.length > 0) {
          this.loadingConnectors = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (value: any) => {
            if(!value.connector)
              promises.push(this.loadConnector(value));
          });
          await Promise.all(promises);
          this.loadingConnectors = false;
        }
      }, 
      async loadConnector(iotdevice: any) {
        try {
            const connector = await IoTDeviceService.getAssociatedConnector(iotdevice.uuid);
            Vue.set(iotdevice, 'connector', connector); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(iotdevice, 'connector', {});
        }
      },
      getlastPingLabel(item: any) {
        if(!('lastPing' in item) && !this.loadingPingReadings) {
          this.loadLastPings();
        }

        if(item?.lastPing?.timestamp) {
          return new Date(item?.lastPing?.timestamp).toLocaleString();
        } else {
          return ''
        }
      },
      async loadLastPings() {
        if(!this.loadingPingReadings && this.currentItems.length > 0) {
          this.loadingPingReadings = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (value: any) => {
            if(!value.lastPing)
              promises.push(this.loadPingReading(value));
          });
          await Promise.all(promises);
          this.loadingPingReadings = false;
        }
      }, 
      async loadPingReading(iotdevice: any) {
        try {
          const reading = await IoTDeviceService.getLastMonitoringReading(iotdevice.uuid);
          Vue.set(iotdevice, 'lastPing', reading); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(iotdevice, 'lastPing', {});
        }
      },
      getMonitoringLabel(item: any) {
        if(!('monitoring' in item) && !this.loadingMonitoring) {
          this.loadMonitoringAlerts();
        }

        if(item?.monitoring !== undefined) {
          return item.monitoring ? 'on' : 'off';
        } else {
          return ''
        }
      },
      async loadMonitoringAlerts() {
        if(!this.loadingMonitoring && this.currentItems.length > 0) {
          this.loadingMonitoring = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (value: any) => {
            if(!value.monitoring)
              promises.push(this.loadMonitoringAlert(value));
          });
          await Promise.all(promises);
          this.loadingMonitoring = false;
        }
      }, 
      async loadMonitoringAlert(iotdevice: any) {
        try {
            const alerts = await IoTDeviceService.getAssociatedAlerts(iotdevice.uuid);
            Vue.set(iotdevice, 'monitoring', alerts.filter((value: any) => !value.paused).length > 0); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(iotdevice, 'monitoring', undefined);
        }
      },
      filterItem,
      onExport() {
          let filteredItems = this.iotdevices;
          if(this.$refs.dataTable.$children[0].filteredItems) {
            filteredItems = this.$refs.dataTable.$children[0].filteredItems;
          }

          // Data structure
          const data = filteredItems.map((dev: any, index: number) => Object({
            uuid: dev.uuid,
            serialnumber: dev.serialnumber,
            type: dev.type,
            description: dev.description,
            connectivity: dev.connectivity,
            enabled: dev.active,
            state: dev.state,
            mode: dev.mode,
            createdAt: dev.createdAt,
            updatedAt: dev.updatedAt,
          }));

          const filename = 'bascloud_iotdevices.csv';
          const csvContent = objectsToCSV(data);
          var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

          if ((navigator as any).msSaveBlob) { // IE 10+
              (navigator as any).msSaveBlob(blob, filename);
          } else {
              var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", filename);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }
          }
      },
      onReload() {
        this.$emit("reload");
      },
      onItemUpdate(item: any) {
        this.$emit("update", item);
      },
      onItemDelete(item: any) {
        this.$emit("delete", item);
      },
      onPaginationAndFilter(pagination: any) {
        this.numberOfEntities = pagination.itemsLength;
      },
      onTableRowClick(row: any) {
        const button: any = this.$refs["iotdeviceInfoButton_" + row.uuid];
        // $el needed for native elements
        button.$el.click();
      },
    },
    computed: {
      headers() {
        return [
          { text: this.$t('attribute.uuid'), value: 'uuid', index: 0 },
          { text: this.$t('attribute.serialnumber'), value: 'serialnumber', index: 1 },
          { text: this.$t('attribute.type'), value: 'type', index: 2 },
          { text: this.$t('enabled'), value: 'enabled', index: 3 },
          { text: this.$t('attribute.connectivity'), value: 'connectivity', index: 4 },
          { text: this.$t('attribute.description'), value: 'description', index: 5 },
          { text: this.$t('attribute.state'), value: 'state', index: 6 },
          { text: this.$t('attribute.mode'), value: 'mode', index: 7 },
          { text: this.$t('attribute.tenant'), value: 'tenant', index: 9 },
          { text: this.$t('attribute.property'), value: 'property', index: 10 },
          { text: this.$t('attribute.connector'), value: 'connector', index: 11 },
          { text: this.$t('attribute.monitoring'), value: 'monitoring', index: 998 },
          { text: this.$t('attribute.lastPing'), value: 'lastPing', index: 999 },
          { text: this.$t('attribute.updatedAt'), value: 'updatedAt', index: 1000 },
          { text: this.$t('attribute.createdAt'), value: 'createdAt', index: 1001 },
        ];
      },
      loadingText(): any {
        return this.$t('loadingData');
      },
      computedTableHeader(): any {
        return [...this.selectedHeaders,
          { text: this.$t('attribute.actions'), value: 'actions', sortable: false, filterable: false, index: 10000 },
        ].sort((a: any, b: any) => a.index-b.index);
      },
      canUpdate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'iotdevices' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['iotdevices'].includes('PATCH');
        } else {
          return false;
        }
      },
      canDelete(): any {
        if (this.$root.$store.state.session.permissions.resources && 'iotdevices' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['iotdevices'].includes('DELETE');
        } else {
          return false;
        }
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'iotdevices' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['iotdevices'].includes('POST');
        } else {
          return false;
        }
      }, 
    },
  })
