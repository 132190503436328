
  import Vue from 'vue';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import TagService from '@/services/TagService';
  import { TagTypes } from './TagTypes';

  export default Vue.extend({
    name: 'TagEditDialog',
    props: ['tag'],
    components: {
        EntityMetaDialog,
    },
    data: (vm: any): any => ({
        localdialog: false,
        localTag: {} as any,
        showAddDeviceField: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingDevices: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        tagTypes: TagTypes,
    }),
    async created() {
        this.localTag = Object.assign({}, this.tag);
    },
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      async save() {
        this.alertVisible = false;
        this.loading = true;

        let name = "";
        let description = "";
        let type = "";

        if(this.localTag.name !== this.tag.name) {
            name = this.localTag.name;
        }
        if(this.localTag.description !== this.tag.description) {
            description = this.localTag.description;
        }
        if(this.localTag.type !== this.tag.type) {
            type = this.localTag.type;
        }

        try {
            const tag = await TagService.update(this.currentTenant, this.tag.uuid, name, description, type); 

            this.alertType = "success";
            this.alertText = this.$t('tag.successUpdate') as string;
            this.alertVisible = true;

            this.localTag = tag;
            
            this.$emit("update", tag);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('tag.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        }
      },
      autoComplete,
    },
    watch: {
        tag() {
            this.localTag = Object.assign({}, this.tag);
        },
        async localdialog(value: boolean) {
            if(value) {
                //
            } else {
                this.clear();
            }
        }
    },
    computed: {
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        }
    }
  })
