import axios from 'axios';

const s3Instance = axios.create({ headers: {} });

delete s3Instance.defaults.headers.common["Authorization"];

export default {
    getImage(readingUUID: string): Promise<any> {
        return axios.get(process.env.VUE_APP_BASCLOUD_IMAGE_SERVER_URL + '/readings/' + readingUUID, { responseType: "blob" });
    },   
    getUploadURL(readingUUID: string, imageFile: any): Promise<any> {
        const contentType = imageFile.type;
        
        return axios.get(process.env.VUE_APP_BASCLOUD_IMAGE_SERVER_URL + '/readings/' + readingUUID + '/upload', { data: {}, headers: {"Content-Type": contentType}});
    },   
    async uploadImage(url: string, imageFile: any): Promise<any> {        
        const imageType = imageFile.type;

        const buffer = await imageFile.arrayBuffer();

        return s3Instance.put(url, buffer, { headers: { 'Content-Type': imageType }});
    },
    deleteImage(readingUUID: string): Promise<any> {
        return axios.delete(process.env.VUE_APP_BASCLOUD_IMAGE_SERVER_URL + '/readings/' + readingUUID);
    }
}
  