import store from '../store/index';
import router from '../router/index';
import SetpointApi from '@/api/SetpointApi';

export default {
    async getCollectionPage(tenantUUID: string, params: any): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const pageResponse = await SetpointApi.getCollectionPage(tenantUUID, params);

        return pageResponse;
    },
    async getAllCollectionPages(tenantUUID: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const setpoints: any[] = [];
        const pageResponses = await SetpointApi.getAllCollectionPages(tenantUUID, params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                setpoints.push({
                    uuid: userObj.id,
                    timestamp: userObj.attributes.timestamp,
                    value: userObj.attributes.value,
                    state: userObj.attributes.state,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                });
            });
        });
        return setpoints;
    },
    async getSingle(tenantUUID: string, readingUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const pageResponse = await SetpointApi.getSingle(tenantUUID, readingUUID);

        return {
            uuid: pageResponse.data.data.id,
            timestamp: pageResponse.data.data.attributes.timestamp,
            value: pageResponse.data.data.attributes.value,
            state: pageResponse.data.data.attributes.state,
            createdAt: pageResponse.data.data.meta.createdAt,
            updatedAt: pageResponse.data.data.meta.updatedAt,
        };
    },
    async createSetpoint(tenantUUID: string, value: number, timestamp: string, deviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await SetpointApi.createSetpoint(tenantUUID, value, timestamp, deviceUUID);

        if(response.data.data.type && response.data.data.type == 'setpoints') {

            return {
                uuid: response.data.data.id,
                timestamp: response.data.data.attributes.timestamp,
                value: response.data.data.attributes.value,
                state: response.data.data.attributes.state,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            };
        } else {
            throw TypeError("Response not type setpoints");
        }
    },
    async updateSetpoint(tenantUUID: string, setpointUUID: string, value = "", deviceUUID = ""): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await SetpointApi.updateSetpoint(tenantUUID, setpointUUID, value, deviceUUID);

        return {
            uuid: response.data.data.id,
            timestamp: response.data.data.attributes.timestamp,
            value: response.data.data.attributes.value,
            state: response.data.data.attributes.state,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async deleteSetpoint(tenantUUID: string, setpointUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await SetpointApi.deleteSetpoint(tenantUUID, setpointUUID);
    },
    async getAssociatedDevice(tenantUUID: string, setpointUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await SetpointApi.getAssociatedDevice(tenantUUID, setpointUUID);

        return {
            uuid: response.data.data.id,
            aksId: (response.data.data.attributes.aksId || ''),
            localAksId: (response.data.data.attributes.localAksId || ''),
            description: response.data.data.attributes.description,
            unit: response.data.data.attributes.unit,
            type: response.data.data.attributes.type,
            alerting: response.data.data.attributes.alerting,
            isRelative: response.data.data.attributes.isRelative,
            frequency: response.data.data.attributes.frequency,
            interval: response.data.data.attributes.interval,
            aggregation: response.data.data.attributes.aggregation,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            deletedAt: response.data.data.meta.deletedAt,
        };
    },
}
  