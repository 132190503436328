import axiosLimiter from '@/api/RateLimiter';

const farFutureDateISOString = new Date(9999, 1, 1).toISOString();

export default {
    getCollection(tenant: string, params: any = {}): Promise<any> {
        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/devicecomputations?';

        //  Filters
        if('targetDeviceId' in params && params.targetDeviceId && params.targetDeviceId.length > 0) {
            requestURL = requestURL + '&targetDeviceId=' + encodeURIComponent(params.targetDeviceId);
        }
        if('sourceDeviceId' in params && params.sourceDeviceId && params.sourceDeviceId.length > 0) {
            requestURL = requestURL + '&sourceDeviceId=' + encodeURIComponent(params.sourceDeviceId);
        }
        if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
            requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
        }
        if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
            requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
        }

        // Paging
        if('itemsPerPage' in params && params.itemsPerPage) {
            requestURL = requestURL + '&page[size]=' + encodeURIComponent(params.itemsPerPage);
        }
        if('nextPageLink' in params && params.nextPageLink && params.nextPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.nextPageLink;
        }
        if('prevPageLink' in params && params.prevPageLink && params.prevPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.prevPageLink;
        }

        return axiosLimiter.get(requestURL);
    },
    async getAllCollectionPages(tenant: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/devicecomputations?';

            //  Filters
            if('targetDeviceId' in params && params.targetDeviceId && params.targetDeviceId.length > 0) {
                requestURL = requestURL + '&targetDeviceId=' + encodeURIComponent(params.targetDeviceId);
            }
            if('sourceDeviceId' in params && params.sourceDeviceId && params.sourceDeviceId.length > 0) {
                requestURL = requestURL + '&sourceDeviceId=' + encodeURIComponent(params.sourceDeviceId);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(tenant: string, deviceComputationUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/devicecomputations/' + deviceComputationUUID);
    },
    updateDeviceComputation(tenantUUID: string, deviceComputationUUID: string, targetDeviceId = "", 
     formula = "", scope?: any): Promise<any> {
        
        const body = {
            data: {
                type: "devicecomputations",
                id: deviceComputationUUID,
                attributes: {
                }
            }
        } as any;

        if(targetDeviceId.length > 0) {
            body.data.attributes.targetDeviceId = targetDeviceId;
        }
        if(formula.length > 0) {
            body.data.attributes.formula = formula;
        }
        if(scope && Object.keys(scope).length > 0) {
            body.data.attributes.scope = scope;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devicecomputations/' + deviceComputationUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    getSourceDevices(tenantUUID: string, deviceComputationUUID: string, params: any = {}): Promise<any> {

        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/'  + tenantUUID + '/devicecomputations/' + deviceComputationUUID + '/sourcedevices?';

        // Paging
        if('itemsPerPage' in params && params.itemsPerPage) {
            requestURL = requestURL + '&page[size]=' + encodeURIComponent(params.itemsPerPage);
        }
        if('nextPageLink' in params && params.nextPageLink && params.nextPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.nextPageLink;
        }
        if('prevPageLink' in params && params.prevPageLink && params.prevPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.prevPageLink;
        }

        return axiosLimiter.get(requestURL);
    },
    getTargetDevice(tenantUUID: string, deviceComputationUUID: string): Promise<any> {
        const requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devicecomputations/' + deviceComputationUUID + '/targetdevice';

        return axiosLimiter.get(requestURL);
    },
    deleteDeviceComputation(tenantUUID: string, deviceComputationUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devicecomputations/' + deviceComputationUUID);
    },
    createDeviceComputation(tenantUUID: string, targetDeviceId: string, formula: string, 
        scope: any): Promise<any> {
    
        const body = {
            data: {
                type: "devicecomputations",
                attributes: {
                    formula: formula,
                    targetDeviceId: targetDeviceId,
                    scope: scope
                }
            }
        } as any;

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devicecomputations', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
}
  