
  import Vue from 'vue';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import UserService from '@/services/UserService';
  import AlertService from '@/services/AlertService';

  export default Vue.extend({
    name: 'AlertEditDialog',

    props: ['alert'],
    components: {
        EntityMetaDialog,
    },
    data: (vm: any): any => ({
        localdialog: false,
        showAddDeviceField: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingDevices: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        requiredSelectList: [
          (v: any) => v.length > 0 || vm.requiredError
        ],
        recipients: [] as string[],
        paused: false,
        users: [] as any[],
        selectedTimeUnit: 60,
        selectedTimeFrequency: 0,
        timeUnits: [
            {name: vm.$t('timeUnit.minutes'), value: 60 },
            {name: vm.$t('timeUnit.hours'), value: 3600 }, 
            {name: vm.$t('timeUnit.days'), value: 86400 },
            {name: vm.$t('timeUnit.weeks'), value: 604800 },
            {name: vm.$t('timeUnit.months'), value: 2629800 },
            {name: vm.$t('timeUnit.years'), value: 31557600 }
        ],
    }),
    async created() {
        this.recipients = this.alert.recipients;
        this.paused = this.alert.paused ?? false;
        this.findTimeUnit();
    },
    methods: {
      findTimeUnit() {
        for(const unit of [...this.timeUnits].reverse()) {
            if(this.alert.maxAge % unit.value === 0) {
                this.selectedTimeUnit = unit.value;
                this.selectedTimeFrequency = this.alert.maxAge / unit.value;
                break;
            }
        }
      },
      close() {
        this.localdialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.paused = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      async save() {
        this.alertVisible = false;
        this.loading = true;

        let maxAge;
        if(this.alert.maxAge !== this.selectedTimeFrequency * this.selectedTimeUnit) {
            maxAge = this.selectedTimeFrequency * this.selectedTimeUnit;
        }
        let recipients;
        if(JSON.stringify(this.alert.recipients) !== JSON.stringify(this.recipients)) {
            recipients = this.recipients;
        }
        let paused;
        if(this.alert.paused !== this.paused) {
          paused = this.paused;
        }

        try {
            const _alert = await AlertService.updateAlert(this.alert.uuid, recipients, maxAge, paused); 

            this.alertType = "success";
            this.alertText = this.$t('alert.successUpdate') as string;
            this.alertVisible = true;

            this.$emit("update", _alert);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('alert.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        }
      },
      autoComplete,
      getUsers() {
        this.users = [];
        this.loading = true;
        let params = {};

        UserService.getAllCollectionPages(params).then((users: any[]) => {
          this.loading = false;

          this.users = users;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.loading = false;    
        });
      },
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
              this.recipients = this.alert.recipients;
              this.paused = this.alert.paused ?? false;
              this.findTimeUnit();
              this.getUsers();
            } else {
              this.clear();
            }
        }
    },
    computed: {
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        }
    }
  })
