
  import Vue from 'vue';
  import chroma from "chroma-js"; 
  import UserService from '@/services/UserService';
  import TenantService from '@/services/TenantService';

  import { autoComplete, extractErrorMessage } from '@/utils/Util';

  import EventInfoDialog from '@/components/services/event/EventInfoDialog.vue'

  export default Vue.extend({
    name: 'EventsCalendar',

    components: {
      EventInfoDialog
    },
    props: ['events', 'loading'],
    data: (vm: any): any => ({
      focus: '',
      type: 'month',
      weekdaysOrder: [1, 2, 3, 4, 5, 6, 0],
      selectedEvent: {},
      showSelectedEventInfo: false,
      users: [] as any[],
      userLoading: false,
      selectedUser: {}
    }),
    async created() {
      if(this.isRoot) {
        this.getUsers();
      }
    },
    watch: {
      selectedUser(user: any) {
        this.$emit("update:user", user); 
      },
      async currentTenant() {
        this.getUsers();
      },
    },
    methods: {
      onReload() {
        this.$emit("reload");
      },
      getDays(date: Date) {
        return Math.floor(( date.valueOf() - new Date().valueOf() ) / 86400000); 
      },
      getColor(date: Date) {
        let diff =  this.getDays(date);
        if (diff <= 0) return 'red'
        else if (diff <= 7) return 'accent'
        else return 'primary'
      },
      getEventColor(event: any) {
        if(this.getDays(new Date(event.dueDate)) < 0)
          return 'error'
        return this.deviceColors[event.deviceId]
      },
      onEventDelete(item: any) {
        this.$emit('delete:event', item);
      },      
      onEventBatchDelete(item: any) {
        this.$emit('batchDelete:event', item);
      },
      rangeUpdated(range: any) {
        this.$emit("update:range", range.start.date, range.end.date);
      },
      showEvent(nativeEvent: any) {
        this.selectedEvent = nativeEvent.event;
        this.showSelectedEventInfo = true;
      },
      viewDay (date: any) {
        this.focus = date.date;
        this.type = 'day';
      },
      setToday () {
        this.focus = ''
      },
      typeToLabel(type: string) {
        if(type == 'day') return this.$t('timeUnit.day');
        else if(type === 'week') return this.$t('timeUnit.week');
        else if(type === 'month') return this.$t('timeUnit.month');
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      autoComplete,
      getUsers() {
        this.users = [];
        this.userLoading = true;

        TenantService.getAssociatedUsers(this.currentTenant).then((users: any[]) => {
          this.userLoading = false;
          this.users = users;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('user.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;     
          this.userLoading = false; 
        });
      },
    },
    computed: {
      currentLocale() {
        return this.$root.$store.state.config.language;
      },
      deviceColors() {
        const devices = [...new Set(this.events.map((item: any) => item.deviceId))];
        const colors = chroma.scale(['#58c1e3', '#005686', '#004a39']).colors(devices.length);
        return Object.assign({}, ...devices.map((dev: any, index: number) => ({[dev]: colors[index] })));
      },
      isRoot(): any {
          return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
              && this.$root.$store.state.session.permissions.role === 'superadmin';
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      } 
    },
  })
