
  import Vue from 'vue'
  import PricesTable from '@/components/entities/price/PricesTable.vue'
  import PriceCreateDialog from '@/components/entities/price/PriceCreateDialog.vue'
  import EntityFiltersCard from '@/components/filters/EntityFiltersCard.vue'

  import InfoCard from '@/components/misc/InfoCard.vue'

  import PriceService from '@/services/PriceService';
  import { extractErrorMessage } from '@/utils/Util'

  export default Vue.extend({
    name: 'PricesView',

    components: {
      PricesTable,
      PriceCreateDialog,
      InfoCard,
      EntityFiltersCard
    },
    data: () => ({
      prices: [] as any,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      filters: {} as any,      
      filtersConfig: [
        { attribute: 'tenant', always: true },
        { attribute: 'type', always: false },
        { attribute: 'subtype', always: false },
        { attribute: 'createdAt', always: false }
      ]
    }),
    async created() {
      this.update();
    },
    watch: {
    },
    methods: {
      onTableReload() {
        this.update();
      },
      onFiltersApply() {
        this.update();
      },
      onItemUpdate(item: any) {
        this.prices.splice(this.prices.findIndex((prop: any) => prop.uuid === item.uuid), 1);
        this.prices.push(item);
      },
      onItemDelete(item: any) {
        this.prices.splice(this.prices.findIndex((prop: any) => prop.uuid === item.uuid), 1);
      },
      onItemCreation(item: any) {
        this.prices.push(item);
      },
      update() {
        this.prices = [];
        this.dataLoaded = false;
        
        let params = {};
        Object.assign(params, this.filters);

        PriceService.getAllCollectionPages(params).then((prices: any[]) => {
          this.dataLoaded = true;

          this.prices = prices;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('price.loadingError') + ' ' + extractErrorMessage(err);
          this.dataError = true;      
        });
      }
    },
    computed: {
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'prices' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['prices'].includes('POST');
        } else {
          return false;
        }
      },
      lastCreatedAt(): any {
        let occurrences: any = {};
        let sum = 0;

        let pastDate = new Date();
        pastDate.setDate(pastDate.getDate()-7); 

        this.prices.forEach((userObj: any) => {
          let _date = new Date(userObj.createdAt);
          if(_date >= pastDate) {
            let item = _date.getDate() + '-' + _date.getMonth();
            occurrences[item] = (occurrences[item] || 0) + 1;
            sum = sum + 1;
          }
        }, {});

        return sum;
      }
    }
  })
