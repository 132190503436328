
  import Vue from 'vue'
  import DeviceService from '@/services/DeviceService';
  import MeteringService from '@/services/MeteringService';
import { extractErrorMessage } from '@/utils/Util';
import DeviceComputationService from '@/services/DeviceComputationService';

  export default Vue.extend({
    name: 'DeviceDeleteDialog',
    props: ['device'],
    data: () => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
    }),
    methods: {
      close() {
        this.localDialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async confirmDelete() {
        this.loading = true;
      
        try {
            await DeviceService.deleteDevice(this.$root.$store.state.session.selectedTenant.uuid, this.device.uuid);
            try {
                await MeteringService.deleteDeviceTask(this.$root.$store.state.session.selectedTenant.uuid, this.device.uuid);
            } catch(err: any) {
                //ignore
            }

            this.alertType = "success";
            this.alertText = this.$t('device.successDelete') as string;
            this.alertVisible = true;
            this.loading = false;
            
            setTimeout(() => { 
                this.$emit("delete", this.device);
                this.dismiss(); 
            }, 2000);

        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('device.errorDelete') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        }
      }
    }
  })
