
  import Vue from 'vue'
  import { autoComplete, extractErrorMessage } from '@/utils/Util';

  import DeviceTypeService from '@/services/DeviceTypeService';

  export default Vue.extend({
    name: 'DeviceTypeCreateDialog',

    components: {
    },
    props: [],
    data: (vm: any): any => ({
        dialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        typeRule: [
          (v: string) => v.search(' ') === -1 || vm.whitespaceError,
          (v: string) => !!v || vm.requiredError
        ],
        requiredRule: [
          (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
            (v: any) => !!v || vm.requiredError
        ],
        devicetype: {
          type: "",
          description: "",
          dataLimit: undefined,
          retention: undefined,
        },
        associatedTenant: {} as any,        
        timeUnits: [
            {name: vm.$t('timeUnit.always'), frequency: 'ALWAYS' },
            {name: vm.$t('timeUnit.hour'), frequency: 'HOURLY'}, 
            {name: vm.$t('timeUnit.day'), frequency: 'DAILY'},
            {name: vm.$t('timeUnit.week'), frequency: 'WEEKLY'},
            {name: vm.$t('timeUnit.month'), frequency: 'MONTHLY'},
            {name: vm.$t('timeUnit.year'), frequency: 'YEARLY'}
        ],
    }),
    methods: {
      validate() {
        if(this.createForm.validate()) {
          this.create();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.devicetype = {
          type: "",
          description: "",
          dataLimit: undefined,
          retention: undefined,
        };
        this.associatedTenant = {};
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      create() {
        this.loading = true;

        let type = this.devicetype.type;
        let description = this.devicetype.description;
        let dataLimit = -1;
        let dataLimitFrequency = "";

        let retentionMS = -1;
        let tenantId = "";

        if("dataLimit" in this.devicetype && this.devicetype.dataLimit && parseInt(this.devicetype.dataLimit) > 0) {
            dataLimit = parseInt(this.devicetype.dataLimit);
        }
        if(dataLimit > 0 && this.devicetype.dataLimitFrequency) {
            dataLimitFrequency = this.devicetype.dataLimitFrequency;
        }
        if("retention" in this.devicetype && this.devicetype.retention) {
          retentionMS = parseInt(this.deviceType.retention) * 86400000;
        }
        
        if(this.associatedTenant && Object.keys(this.associatedTenant).length > 0) {
            tenantId = this.associatedTenant.uuid;
        }

        DeviceTypeService.createDeviceType(type, description, retentionMS, dataLimit, tenantId, dataLimitFrequency).then((devicetype: any) => {
          this.alertType = "success";
          this.alertText = this.$t('deviceType.successCreate') as string;
          this.alertVisible = true;

          this.$emit("success", devicetype);
          setTimeout(() => { 
              this.dismiss(); 
          }, 1500);
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('deviceType.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
          this.loading = false;
        });
      },
      autoComplete,
    },
    watch: {
      dialog(value: boolean) {
        if(!value) {
          this.clear();
        }
      }
    },
    computed: {
        createForm(): any {
            return this.$refs.createForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        whitespaceError(): any {
            return this.$t('whitespace');
        },
    }
  })
