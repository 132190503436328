
  import Vue from 'vue'

  import ConnectorInfoDialog from '@/components/entities/connector/ConnectorInfoDialog.vue';
  import ConnectorEditDialog from '@/components/entities/connector/ConnectorEditDialog.vue';
  import ConnectorDeleteDialog from '@/components/entities/connector/ConnectorDeleteDialog.vue';
  import ConnectorGenerateTokenDialog from '@/components/entities/connector/ConnectorGenerateTokenDialog.vue';
  import { objectsToCSV, filterItem } from '@/utils/Util';

  export default Vue.extend({
    name: 'ConnectorsTable',

    components: {
      ConnectorDeleteDialog,
      ConnectorEditDialog,
      ConnectorInfoDialog,
      ConnectorGenerateTokenDialog,
    },
    props: ['connectors', 'loading'],
    data: (vm: any): any => ({
      tableLoadingText: vm.loadingText,
      search: "",
      showUUIDs: false,
      numberOfEntities: 0,
      selectedHeaders: [
          { text: vm.$t('attribute.name'), value: 'name', index: 1 },
          { text: vm.$t('attribute.updatedAt'), value: 'updatedAt', index: 8 },
          { text: vm.$t('attribute.createdAt'), value: 'createdAt', index: 9 },
          { text: vm.$t('attribute.lastActiveAt'), value: 'lastActiveAt', index: 10 },
      ],
    }),
    created() {
      if(this.connectors.length === 0) {
        this.tableLoadingText = this.$t('connector.noData');
      }

      const columns = this.$root.$store.getters.selectedColumns('connector');
      if(columns) {
        this.selectedHeaders = columns;
      }
    },
    watch: {
      selectedHeaders() {
        this.$store.commit("setSelectedColumns", { type: 'connector', columns: this.selectedHeaders });
      } 
    },
    methods: {
      filterItem,
      onExport() {
          let filteredItems = this.connectors;
          if(this.$refs.dataTable.$children[0].filteredItems) {
            filteredItems = this.$refs.dataTable.$children[0].filteredItems;
          }

          // Data structure
          const data = filteredItems.map((conn: any, index: number) => Object({
            uuid: conn.uuid,
            name: conn.name,
            lastActiveAt: conn.lastActiveAt,
            createdAt: conn.createdAt,
            updatedAt: conn.updatedAt,
          }));

          const filename = 'bascloud_connectors.csv';
          const csvContent = objectsToCSV(data);
          var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

          if ((navigator as any).msSaveBlob) { // IE 10+
              (navigator as any).msSaveBlob(blob, filename);
          } else {
              var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", filename);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }
          }
      },
      onReload() {
        this.$emit("reload");
      },
      onConnectorItemUpdate(item: any) {
        this.$emit("update", item);
      },
      onConnectorItemDelete(item: any) {
        this.$emit("delete", item);
      },
      onPaginationAndFilter(pagination: any) {
        this.numberOfEntities = pagination.itemsLength;
      },
      onTableRowClick(row: any) {
        const button: any = this.$refs["connectorInfoButton_" + row.uuid];
        // $el needed for native elements
        button.$el.click();
      }
    },
    computed: {
      headers() {
        return [
          { text: this.$t('attribute.uuid'), value: 'uuid', index: 0 },
          { text: this.$t('attribute.name'), value: 'name', index: 1 },
          { text: this.$t('attribute.updatedAt'), value: 'updatedAt', index: 8 },
          { text: this.$t('attribute.createdAt'), value: 'createdAt', index: 9 },
          { text: this.$t('attribute.lastActiveAt'), value: 'lastActiveAt', index: 10 },
        ];
      },
      loadingText(): any {
        return this.$t('loadingData');
      },
      computedTableHeader(): any {
        return [...this.selectedHeaders,
          { text: this.$t('attribute.actions'), value: 'actions', sortable: false, filterable: false, index: 100 },
        ].sort((a: any, b: any) => a.index-b.index);
      },
      canUpdate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'connectors' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['connectors'].includes('PATCH');
        } else {
          return false;
        }
      },
      canDelete(): any {
        if (this.$root.$store.state.session.permissions.resources && 'connectors' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['connectors'].includes('DELETE');
        } else {
          return false;
        }
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'connectors' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['connectors'].includes('POST');
        } else {
          return false;
        }
      }, 
    },
  })
