
  import Vue from 'vue'
  import AuthService from '@/services/AuthService';
import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'UserChangePasswordDialog',

    components: {
    },
    props: ['value', 'email', 'token'],
    data: (vm: any): any => ({
        localdialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        valid: true,
        setByInit: false,
        requiredRules: [
            (v: string) => !!v || vm.requiredError
        ],
        showPassword: false,
        rules: {
            required: (v: string) => !!v || vm.requiredError,
            min: (v: string) => (v && v.length >= 3) || vm.passwordLengthError
        },
        changePassword: "",
        verifyChangePassword: "",
        localToken: "",
    }),
    created() {
        this.localdialog = this.value;
        if(this.token) {
            this.localToken = this.token;
            this.setByInit = true;
        }
    },
    methods: {
      validate() {
          if(this.passwordForm.validate()) {
              this.requestPasswordChange();
          }
      },
      close() {
        this.localdialog = false;
        if(this.$root.$store.getters.loggedIn(Date.now())) {
            this.$router.replace("/dashboard");
        }
      },
      clear() {
        this.localToken = "";
        this.changePassword = "";
        this.verifyChangePassword = "";
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      requestPasswordChange() {
        this.loading = true;

        AuthService.changePassword(this.email, this.token, this.changePassword).then(() => {
            this.alertType = "success";
            this.alertText = this.$t('user.successPasswordChange') as string;
            this.alertVisible = true;
            this.loading = false;

            setTimeout(() => { 
                this.dismiss();
                if(this.$root.$store.getters.loggedIn(Date.now())) {
                    this.$router.replace("/dashboard");
                } else {
                    this.$router.replace("/login");
                }
            }, 2000);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('user.errorPasswordChange') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
    },
    watch: {
      value() {
        this.localdialog = this.value;
      },
        async localdialog(value: boolean) {
            if(value) {
                if(this.token) {
                    this.localToken = this.token;
                }
            }
            this.$emit('input', value);
        }
    },
    computed: {
        passwordMatch() {
            return () => this.changePassword === this.verifyChangePassword || this.$t('login.passwordMatch');
        },
        passwordForm(): any {
            return this.$refs.passwordForm;
        },
        passwordLengthError(): any {
            return this.$t('login.minPasswordLength');
        },
        requiredError(): any {
            return this.$t('required');
        }
    }
  })
