
  import Vue from 'vue'
  import ApiKeysTable from '@/components/entities/apikey/ApiKeysTable.vue'
  import ApiKeyCreateDialog from '@/components/entities/apikey/ApiKeyCreateDialog.vue'
  import InfoCard from '@/components/misc/InfoCard.vue'
  import ApiKeyService from '@/services/ApiKeyService';

  import { extractErrorMessage } from '@/utils/Util'

  export default Vue.extend({
    name: 'ApiKeysView',

    components: {
      ApiKeysTable,
      InfoCard,
      ApiKeyCreateDialog
    },
    data: () => ({
      apikeys: [] as any,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      filters: {} as any,
    }),
    created() {
      this.updateData();
    },
    watch: {
    },
    methods: {
      onTableReload() {
        this.updateData();
      },
      onFiltersUpdate(filters: any) {
        this.filters = filters;
        this.updateData();
      },
      onItemUpdate(item: any) {
        this.apikeys.splice(this.apikeys.findIndex((conn: any) => conn.uuid === item.uuid), 1);
        this.apikeys.push(item);
      },
      onItemDelete(item: any) {
        this.apikeys.splice(this.apikeys.findIndex((conn: any) => conn.uuid === item.uuid), 1);
      },
      onCreation(item: any) {
        this.apikeys.push(item);
      },
      async updateData() {
        this.apikeys = [];
        this.dataLoaded = false;

        let params = {};

        Object.assign(params, this.filters);

        try {
          let apikeys = await ApiKeyService.getAllCollectionPages(params);

          this.dataLoaded = true;
          this.apikeys = apikeys;
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = extractErrorMessage(err);
          this.dataError = true;   
          this.dataLoaded = true;  
        }
      }
    },
    computed: {
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'apikeys' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['apikeys'].includes('POST');
        } else {
          return false;
        }
      },      
      lastCreatedAt(): any {
        let occurrences: any = {};
        let sum = 0;

        let pastDate = new Date();
        pastDate.setDate(pastDate.getDate()-7); 

        this.apikeys.forEach((userObj: any) => {
          let _date = new Date(userObj.createdAt);
          if(_date >= pastDate) {
            let item = _date.getDate() + '-' + _date.getMonth();
            occurrences[item] = (occurrences[item] || 0) + 1;
            sum = sum + 1;
          }
        }, {});

        return sum;
      }
    }
  })
