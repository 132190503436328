import PriceApi from '@/api/PriceApi';
import store from '../store/index';
import router from '../router/index';

export default {
    async getAllCollectionPages(params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const prices: any[] = [];
        const pageResponses = await PriceApi.getAllCollectionPages(params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                prices.push({
                    uuid: userObj.id,
                    type: userObj.attributes.type,
                    subtype: userObj.attributes.subtype,
                    basePrice: userObj.attributes.basePrice,
                    perUsePrice: userObj.attributes.perUsePrice,
                    discountRange: userObj.attributes.discountRange,
                    tenantId: userObj.attributes.tenantId,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                });
            });
        });

        return prices;
    },
    async getSingle(priceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await PriceApi.getSingle(priceUUID);

        return {
            uuid: response.data.data.id,
            type: response.data.data.attributes.type,
            subtype: response.data.data.attributes.subtype,
            basePrice: response.data.data.attributes.basePrice,
            perUsePrice: response.data.data.attributes.perUsePrice,
            discountRange: response.data.data.attributes.discountRange,
            tenantId: response.data.data.attributes.tenantId,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async createPrice(type: string, subtype: string, basePrice: number, perUsePrice=-1, discountRange=undefined, tenantUUID=""): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await PriceApi.createPrice(type, subtype, basePrice, perUsePrice, discountRange, tenantUUID);

        if(response.data.data.type && response.data.data.type == 'prices') {

            return {
                    uuid: response.data.data.id,
                    type: response.data.data.attributes.type,
                    subtype: response.data.data.attributes.subtype,
                    basePrice: response.data.data.attributes.basePrice,
                    perUsePrice: response.data.data.attributes.perUsePrice,
                    discountRange: response.data.data.attributes.discountRange,
                    tenantId: response.data.data.attributes.tenantId,
                    createdAt: response.data.data.meta.createdAt,
                    updatedAt: response.data.data.meta.updatedAt,
                };
            } else {
                throw TypeError("Response not type prices");
            }
    },
    async deletePrice(priceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        await PriceApi.deletePrice(priceUUID);
    },
}
  