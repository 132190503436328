
  import Vue from 'vue'

  import { objectsToCSV, filterItem, extractErrorMessage } from '@/utils/Util';
import UserService from '@/services/UserService';
import MobileAnalyticsService from '@/services/MobileAnalyticsService';

  export default Vue.extend({
    name: 'MobileAnalyticsTable',

    components: {
    },
    props: ['logs', 'versions', 'loading'],
    data: (vm: any): any => ({
      tableLoadingText: vm.loadingDataText,
      search: "",
      showUUIDs: false,
      numberOfEntities: 0,
      expanded: [],
      selectedHeaders: [
        { text: vm.$t('entity.user'), value: 'user', index: 0 },
        { text: vm.$t('attribute.email'), value: 'payload.email', index: 2 },
        { text: 'App version', value: 'payload.appversion', index: 3 },
        { text: 'Device name', value: 'payload.devicename', index: 4 },
        { text: 'OS', value: 'payload.betriebssystem', index: 5 },
        { text: 'Device version', value: 'payload.deviceversionnr', index: 6 },
        { text: 'Connectivity', value: 'payload.konnektivitaetstatus', index: 7 },
        { text: vm.$t('attribute.createdAt'), value: 'createdAt', index: 9 },
      ],
      loadingUsers: false,
      currentItems: [],
    }),
    async created() {

      if(this.logs.length === 0) {
        this.tableLoadingText = 'No analytics log data available.';
      }

      const columns = this.$root.$store.getters.selectedColumns('logs');
      if(columns) {
        this.selectedHeaders = columns;
      }
    },
    watch: {
      selectedHeaders() {
        this.$store.commit("setSelectedColumns", { type: 'logs', columns: this.selectedHeaders });
      }
    },
    methods: {
      filterItem,
      onReload() {
        this.$emit("reload");
      },
      onPaginationAndFilter(pagination: any) {
        this.numberOfEntities = pagination.itemsLength;
      },
      clickRow(item: any) {
        const indexExpanded = this.expanded.findIndex((val: any) => val.id === item.id);
        if (indexExpanded > -1) {
          this.expanded.splice(indexExpanded, 1)
        } else {
          this.expanded.push(item);
        }
      },
      onExport() {
          let filteredItems = this.devices;
          if(this.$refs.dataTable.$children[0].filteredItems) {
            filteredItems = this.$refs.dataTable.$children[0].filteredItems;
          }

          // Data structure
          const data = filteredItems.map((logObj: any, index: number) => Object({
            type: logObj.type,
            userId: logObj.userId,
            email: logObj.payload.email,
            appversion: logObj.payload.appversion,
            devicename: logObj.payload.devicename,
            os: logObj.payload.betriebssystem,
            connectivity: logObj.payload.konnektivitaetstatus,
            createdAt: logObj.createdAt,
          }));

          const filename = 'bascloud_mobile_analytics_logs.csv';
          const csvContent = objectsToCSV(data);
          var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

          if ((navigator as any).msSaveBlob) { // IE 10+
              (navigator as any).msSaveBlob(blob, filename);
          } else {
              var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", filename);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }
          }
      },
      onCurrentItemsChange(items: any[]) {
        this.currentItems = items;
      },
      getVersionColor(item: any) {
        if(item?.payload?.appversion && item?.payload?.betriebssystem && item?.payload?.betriebssystem.toLowerCase() in this.versions) {
          return this.versions[item?.payload?.betriebssystem.toLowerCase()] === item?.payload?.appversion ? 'success' : 'warning';
        }

        return 'grey lighten-1';
      },
      getUserLabel(item: any) {
        if(!('user' in item) && !this.loadingUsers) {
          this.loadUsers();
        }

        return item?.user?.email || item.userId;
      },
      async loadUsers() {
        if(!this.loadingUsers && this.currentItems.length > 0) {
          this.loadingUsers = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (value: any) => {
            if(!value.targetTenant)
              promises.push(this.loadUser(value));
          });
          await Promise.all(promises);
          this.loadingUsers = false;
        }
      }, 
      async loadUser(item: any) {
        try {
            const user = await UserService.getSingle(item.userId);
            Vue.set(item, 'user', user); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(item, 'user', {});
        }
      },
      expandAttributes(item: any) {
        const{ user, id, ...rest} = item;

        return rest;
      }
    },
    computed: {
      keyedLogs() {
        return this.logs.map((value: any, index: number) => ({...value, id: index}));
      },
      headers() {
        return [
          { text: this.$t('entity.user'), value: 'user', index: 0 },
          { text: this.$t('entity.user')+ ' ' +this.$t('attribute.uuid'), value: 'userId', index: 1 },
          { text: this.$t('attribute.email'), value: 'payload.email', index: 2 },
          { text: 'App version', value: 'payload.appversion', index: 3 },
          { text: 'Device name', value: 'payload.devicename', index: 4 },
          { text: 'OS', value: 'payload.betriebssystem', index: 5 },
          { text: 'Device version', value: 'payload.deviceversionnr', index: 6 },
          { text: 'Connectivity', value: 'payload.konnektivitaetstatus', index: 7 },
          { text: this.$t('attribute.type'), value: 'type', index: 8 },
          { text: this.$t('attribute.createdAt'), value: 'createdAt', index: 9 },
        ];
      },
      loadingDataText(): string {
        return this.$t('loadingData');
      },
      computedTableHeader(): any {
        return this.selectedHeaders.sort((a: any, b: any) => a.index-b.index);
      },
    },
  })
