import IoTDevicePortApi from '@/api/IoTDevicePortApi';
import store from '../store/index';
import router from '../router/index';

export default {
    async getAllCollectionPages(iotdeviceUUID: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const devices: any[] = [];
        const pageResponses = await IoTDevicePortApi.getAllCollectionPages(iotdeviceUUID, params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                devices.push({
                    uuid: userObj.id,
                    type: userObj.attributes.type,
                    portnumber: userObj.attributes.portnumber,
                    config: userObj.attributes.config,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                });
            });
        });

        return devices;
    },
    async getSingle(iotdeviceUUID: string, iotdevicePortUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDevicePortApi.getSingle(iotdeviceUUID, iotdevicePortUUID);

        return {
            uuid: response.data.data.id,
            type: response.data.data.attributes.type,
            portnumber: response.data.data.attributes.portnumber,
            config: response.data.data.attributes.config,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async create(iotdeviceUUID: string, type: string, portnumber: number, config: any): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDevicePortApi.create(iotdeviceUUID, type, portnumber, config);

        if(response.data.data.type && response.data.data.type == 'iotdeviceports') {

            return {
                uuid: response.data.data.id,
                type: response.data.data.attributes.type,
                portnumber: response.data.data.attributes.portnumber,
                config: response.data.data.attributes.config,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            };
        } else {
            throw TypeError("Response not type iotdeviceports");
        }
    },
    async update(iotdeviceUUID: string, iotdevicePortUUID: string, portnumber?: number, config?: any): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await IoTDevicePortApi.update(iotdeviceUUID, iotdevicePortUUID, portnumber, config);
        
        return {
            uuid: response.data.data.id,
            type: response.data.data.attributes.type,
            portnumber: response.data.data.attributes.portnumber,
            config: response.data.data.attributes.config,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async delete(iotdeviceUUID: string, iotdevicePortUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        await IoTDevicePortApi.delete(iotdeviceUUID, iotdevicePortUUID);
    }
}
  