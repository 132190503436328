
  import Vue from 'vue'

  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import TenantService from '@/services/TenantService';
  import PriceService from '@/services/PriceService';
  import DeviceShareService from '@/services/DeviceShareService';
  import DeviceService from '@/services/DeviceService';
  import DateTimePicker from '@/components/misc/DateTimePicker.vue'
  import DeviceCreateDialog from '@/components/entities/device/DeviceCreateDialog.vue'

  export default Vue.extend({
    name: 'DeviceShareEditDialog',

    props: ['deviceshare'],
    components: {
        EntityMetaDialog,
        DateTimePicker,
        DeviceCreateDialog,
        TenantInfoDialog: () => import('@/components/entities/tenant/TenantInfoDialog.vue') as any,
        DeviceInfoDialog: () => import('@/components/entities/device/DeviceInfoDialog.vue') as any
    },
    data: (vm: any): any => ({
        localdialog: false,
        localDeviceshare: {} as any,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingTenant: false,
        loadingDevice: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
            (v: any) => Object.keys(v).length > 0 || vm.requiredError
        ],
        sourceTenant: {} as any,
        targetTenant: {} as any,
        sourceDevice: {} as any,
        updatedSourceDevice: {} as any,
        targetDevice: {} as any,
        updatedTargetDevice: {} as any,
        states: [
            {value: 'open', text: 'Open'},
            {value: 'approved', text: 'Approved'}
        ],
        sourceDevices: [] as any[],
        targetDevices: [] as any[],
        deviceShareApproved: false
    }),
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedTenant = null;
        this.deviceShareApproved = false;
        this.updatedAssociatedTenant = null;
        this.alertVisible = false;
        this.loading = false;
        this.loadingTenant = false;
        this.retentionDays = 180;
        this.loadingDevice = false;
        this.sourceDevices = [];
        this.targetDevices = [];
        this.updatedTargetDevice =  {};
        this.updatedSourceDevice =  {};
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      onDeviceCreation(item: any) {
        this.targetDevices.push(item);
        this.targetDevice = item;
      },
      async save() {
        this.alertVisible = false;
        this.loading = true;

        let state;
        let sourceDeviceId;
        let targetDeviceId;
        let cascadeUpdates;
        let cascadeDeletes;
        let from;
        let until;

        if(this.localDeviceshare.state !== this.deviceshare) {
            state = this.localDeviceshare.state;
        }
        if(this.updatedSourceDevice.uuid !== this.deviceshare.sourceDeviceId) {
            sourceDeviceId = this.updatedSourceDevice.uuid;
        }
        if(this.updatedTargetDevice.uuid !== this.deviceshare.targetDeviceId) {
            targetDeviceId = this.updatedTargetDevice.uuid;
        }

        if(this.localDeviceshare.cascadeUpdates !== this.deviceshare.cascadeUpdates) {
            cascadeUpdates = this.localDeviceshare.cascadeUpdates;
        }
        if(this.localDeviceshare.cascadeDeletes !== this.deviceshare.cascadeDeletes) {
            cascadeDeletes = this.localDeviceshare.cascadeDeletes;
        }

        if(this.localDeviceshare.from && this.localDeviceshare.from !== this.deviceshare.from) {
            from = new Date(this.localDeviceshare.from);
        }
        if(this.localDeviceshare.until && this.localDeviceshare.until !== this.deviceshare.until) {
            until = new Date(this.localDeviceshare.until);
        }

        try {
            const newDeviceShare = await DeviceShareService.updateDeviceShare(this.deviceshare.uuid, state, sourceDeviceId, targetDeviceId, cascadeUpdates, cascadeDeletes, from, until);

            this.alertType = "success";
            this.alertText = this.$t('deviceShare.successUpdate') as string;
            this.alertVisible = true;
            
            this.$emit("update", newDeviceShare);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('deviceShare.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        }
      },
      getSourceDevices() {
        this.loadingDevice = true;

        DeviceService.getAllCollectionPages(this.deviceshare.sourceTenantId).then((devices: any[]) => {
          this.loadingDevice = false;

          this.sourceDevices = devices;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;    
          this.loadingDevice = false;  
        });
      },
      getTargetDevices() {
        this.loadingDevice = true;

        DeviceService.getAllCollectionPages(this.deviceshare.targetTenantId).then((devices: any[]) => {
          this.loadingDevice = false;

          this.targetDevices = devices;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;    
          this.loadingDevice = false;  
        });
      },
      async loadSourceTenant() {
        this.loadingTenant = true;

        try {
            const tenant = await DeviceShareService.getSourceTenant(this.deviceshare.uuid);
            this.loadingTenant = false;

            this.sourceTenant = tenant;
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('price.errorAssociatedTenant') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingTenant = false;
            this.sourceTenant = {};
        }
      },
      async loadTargetTenant() {
        this.loadingTenant = true;

        if(this.deviceshare.state !== 'approved' && !this.isRoot && !this.isTarget) {
            this.alertType = "warning";
            this.alertText = this.$t('deviceShare.notApproved') as string;
            this.alertVisible = true; 
            this.loadingDevice = false;
            this.targetDevice = {};
        } else {
            try {
                const tenant = await DeviceShareService.getTargetTenant(this.deviceshare.uuid);
                this.loadingTenant = false;

                this.targetTenant = tenant;
            } catch(err: any) {
                console.log(err);
                console.log(err.response);
                this.alertType = "error";
                this.alertText = this.$t('price.errorAssociatedTenant') + ' ' + extractErrorMessage(err);
                this.alertVisible = true; 
                this.loadingTenant = false;
                this.targetTenant = {};
            }
        }
      },
      async loadSourceDevice() {
        this.loadingDevice = true;

        try {
            const device = await DeviceShareService.getSourceDevice(this.deviceshare.uuid);
            this.loadingDevice = false;

            this.sourceDevice = device;
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('price.errorAssociatedTenant') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingDevice = false;
            this.sourceDevice = {};
        }
      },
      async loadTargetDevice() {
        this.loadingDevice = true;

        // if(this.deviceshare.state !== 'approved') {
        //     this.alertType = "warning";
        //     this.alertText = this.$t('deviceShare.notApproved') as string;
        //     this.alertVisible = true; 
        //     this.loadingDevice = false;
        //     return;
        // } else 
        if(!this.deviceshare.targetDeviceId) {
            this.alertType = "warning";
            this.alertText = this.$t('deviceShare.emptyTargetDevice') as string;
            this.alertVisible = true; 
            this.loadingDevice = false;
            this.targetDevice = {};
            return;
        }
        
        try {
            const device = await DeviceShareService.getTargetDevice(this.deviceshare.uuid);
            this.loadingDevice = false;

            this.targetDevice = device;
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('price.errorAssociatedTenant') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingDevice = false;
            this.targetDevice = {};
        }
      },
      autoComplete,
    },
    watch: {
        sourceDevice() {
            this.updatedSourceDevice = this.sourceDevice;
        },
        targetDevice() {
            this.updatedTargetDevice = this.targetDevice;
        },
        deviceShareApproved() {
            if(this.deviceShareApproved) {
                this.localDeviceshare.state = 'approved';
            } else {
                this.localDeviceshare.state = 'open';
            }
        },
        async localdialog(value: boolean) {
            if(value) {
                if(this.isRoot || this.isSource) {
                    this.getSourceDevices();
                }
                this.loadSourceTenant();
                this.loadTargetTenant();
                this.loadSourceDevice();

                if(this.isRoot || this.isTarget) {
                    this.getTargetDevices();
                    this.loadTargetDevice();
                }

                this.localDeviceshare = JSON.parse(JSON.stringify(this.deviceshare));
                this.deviceShareApproved = this.deviceshare.state === 'approved';
            } else {
                this.clear();
            }
        }
    },
    computed: {
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },       
        hasSourceTenant(): boolean {
            return Object.keys(this.sourceTenant).length > 0;
        },
        hasTargetTenant(): boolean {
            return Object.keys(this.targetTenant).length > 0;
        },
        hasTargetDevice(): boolean {
            return Object.keys(this.targetDevice).length > 0;
        },
        isSource(): boolean {
            return this.deviceshare.sourceTenantId === this.$root.$store.state.session.selectedTenant.uuid;
        },
        isTarget(): boolean {
            return this.deviceshare.targetTenantId === this.$root.$store.state.session.selectedTenant.uuid;
        },
        isRoot(): any {
            return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
                && this.$root.$store.state.session.permissions.role === 'superadmin';
        },
        canDeviceCreate(): any {
            if (this.$root.$store.state.session.permissions.resources && 'devices' in this.$root.$store.state.session.permissions.resources) {
            return this.$root.$store.state.session.permissions.resources['devices'].includes('POST');
            } else {
            return false;
            }
        },
    }
  })
