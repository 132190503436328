import ReportApi from '@/api/ReportApi';
import store from '../store/index'
import router from '../router/index'

export default {
    async createDeviceReport(recipients: string[], columns: string[], filters: any, options={}, cc=[], bcc=[]): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await ReportApi.createDeviceReport(recipients, columns, filters, options, cc, bcc);
    },    
    async createReadingReport(recipients: string[], columns: string[], filters: any, options={}, cc=[], bcc=[]): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await ReportApi.createReadingReport(recipients, columns, filters, options, cc, bcc);
    },    
    async createBillingReport(recipients: string[], columns: string[], filters: any, options={}, cc=[], bcc=[]): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await ReportApi.createBillingReport(recipients, columns, filters, options, cc, bcc);
    },    
    async createMeteringReport(recipients: string[], columns: string[], filters: any, options={}, cc=[], bcc=[]): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await ReportApi.createMeteringReport(recipients, columns, filters, options, cc, bcc);
    },
    async createIotDeviceReport(recipients: string[], columns: string[], filters: any, options={}, cc=[], bcc=[]): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await ReportApi.createIotDeviceReport(recipients, columns, filters, options, cc, bcc);
    },  
}
  