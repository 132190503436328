
  import Vue from 'vue'
  import TenantService from '@/services/TenantService';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'TenantInfoDialog',

    components: {
        EntityMetaDialog,
        // Weird import necessary due to circular dependency
        UserInfoDialog: () => import('@/components/entities/user/UserInfoDialog.vue') as any
    },
    props: {
        'tenant': {
            type: Object,
            required: true
        }, 
        'external': {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        localdialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        associatedUsers: [] as any[]
    }),
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedUsers = [];
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      loadAssociatedUsers() {
        this.loading = true;
        
        TenantService.getAssociatedUsers(this.tenant.uuid).then((users: any[]) => {
            this.loading = false;
            this.associatedUsers = users;
            if(users.length === 0) {
                this.alertType = "warning";
                this.alertText = this.$t('tenant.emptyAssociatedUser') as string;
                this.alertVisible = true;
                this.loading = false;
            }
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('tenant.errorAssociatedUser') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      }
    },
    watch: {
        localdialog(value: boolean) {
            if(value) {
                if(!this.tenant.deletedAt && !this.external) {
                    this.loadAssociatedUsers();
                }
            }
        }
    },
    computed: {
        isTenantAdmin(): boolean {
            return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
              && this.$root.$store.state.session.permissions.role === 'admin';
        },
        isUser(): any {
            return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
                && this.$root.$store.state.session.permissions.role === 'user';
        },
        isRoot(): any {
            return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
                && this.$root.$store.state.session.permissions.role === 'superadmin';
        },
    }
  })
