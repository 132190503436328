
  import Vue from 'vue'
  import NavigationDrawer from '@/components/NavigationDrawer.vue'

  import UserService from '@/services/UserService';
  import TenantService from '@/services/TenantService';
  import MeteringService from '@/services/MeteringService';
  import { extractErrorMessage } from '@/utils/Util';

  import UserInfoDialog from '@/components/entities/user/UserInfoDialog.vue'
  import UserRequestPasswordDialog from '@/components/entities/user/UserRequestPasswordDialog.vue'
  import SettingsMenu from '@/components/misc/SettingsMenu.vue'
  import UserEditDialog from '@/components/entities/user/UserEditDialog.vue'
  import NotificationsMenu from '@/components/misc/NotificationsMenu.vue'
  import UserApiKeyTokenDialog from '@/components/misc/UserApiKeyTokenDialog.vue' 

  export default Vue.extend({
    name: 'InterfaceFrameView',

    components: {
      NavigationDrawer,
      UserInfoDialog,
      UserRequestPasswordDialog,
      SettingsMenu,
      UserEditDialog,
      NotificationsMenu,
      UserApiKeyTokenDialog
    },
    data: () => ({
      drawer: null,
      title: 'BAScloud',
      dataError: false,
      alertText: "",
      alertType: "error",
    }),
    async created() {
      this.$i18n.locale = this.$root.$store.state.config.language;

      if (!this.$root.$store.getters.loggedIn(Date.now()) || ('token' in this.$route.query && 'email' in this.$route.query) || ('llt' in this.$route.query)) {
          this.$router.push({ path: '/login', query: this.$route.query })
      } else {

        try {
          const user = await UserService.getSelf();

          this.$root.$store.commit("setCurrentUser", user);

          const permissionData = await UserService.getPermissions(this.$root.$store.state.session.currentUser.uuid);
          this.$root.$store.commit("setPermissions", permissionData.permissions);
        
          const tenants = await TenantService.getAllCollectionPages();

          this.$root.$store.commit("setTenants", tenants);
        } catch(err: any) {
          console.log(err.response);
          this.alertType = "error";
          this.alertText = extractErrorMessage(err);
          this.dataError = true; 
          throw err;
        }

        if(this.$root.$store.state.session.selectedTenant?.uuid) {
          // Check services
          try {
            const meteringUser = await MeteringService.getUser(this.$root.$store.state.session.selectedTenant.uuid, this.$root.$store.state.session.currentUser.uuid);
            if(Object.keys(meteringUser).length > 0) {
              this.$root.$store.commit("setServices", { metering: true });
            }
          } catch(err: any) {
            if(err.response && err.response.status !== 404) {
              console.log(err);
              console.log(err.response);
            }
          }
        }

        // If current url path is the root, forward to dashboard
        if(this.$route.path == '/') {
          if(this.$root.$store.state.session.tenants.length === 0) {
            this.$router.push('/tenants');
          } else {
            this.$router.push('/dashboard');
          }
        }
      }
    },
    methods: {
      logout() {
        // State is reset so overwrittes language, fix here
        const selectedLanguage = this.$root.$store.state.config.language;
        this.$store.commit('logout');
        this.$root.$store.commit('setLanguage', selectedLanguage);
        this.$router.push('/login');
      }
    },
    computed: {
      numberOfNotifications(): number {
        return this.$root.$store.state.volatile.notifications.filter((value: any) => value.new).length;
      },
      userIcon(): string {
        if(this.$root.$store.state.session.permissions.role=='superadmin') {
          return 'mdi-account-cowboy-hat';
        } else if (this.$root.$store.state.session.permissions.role=='admin') {
          return 'mdi-account-hard-hat';
        } else {
          return 'mdi-account';
        }
      },
      userEmail(): string {
        return this.$root.$store.state.session.currentUser?.email || '';
      },
      userIntials(): string {
        return this.$root.$store.state.session.currentUser?.email?.slice(0,2) || '';
      },
      userRole(): string {
        return this.$t('entity.'+this.$root.$store.state.session.permissions.role) as string;
      },
      isRoot(): any {
          return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
              && this.$root.$store.state.session.permissions.role === 'superadmin';
      },
      sessionExpirationMinutes(): number {
        const diff = new Date(this.$root.$store.state.auth.tokenExpiration).getTime() - new Date(Date.now()).getTime();
        return Math.floor((diff/1000)/60);
      },
      sessionExpires(): boolean {
        return this.sessionExpirationMinutes < 5;
      },
      canUserUpdate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'users' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['users'].includes('PATCH');
        } else {
          return false;
        }
      },
    }
  })
