import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import InterfaceFrameView from '@/views/InterfaceFrameView.vue'
import DashboardView from '@/views/DashboardView.vue'
import VisualizationView from '@/views/VisualizationView.vue'
import LoginView from '@/views/LoginView.vue'
import TenantsView from '@/views/entities/TenantsView.vue'
import UsersView from '@/views/entities/UsersView.vue'
import PropertiesView from '@/views/entities/PropertiesView.vue'
import ConnectorsView from '@/views/entities/ConnectorsView.vue'
import DevicesView from '@/views/entities/DevicesView.vue'
import ReadingsView from '@/views/entities/ReadingsView.vue'
import SetpointsView from '@/views/entities/SetpointsView.vue'
import EventsView from '@/views/services/EventsView.vue'
import TasksView from '@/views/services/TasksView.vue'
import ReportsView from '@/views/services/ReportsView.vue'
import DeviceTypesView from '@/views/entities/DeviceTypesView.vue'
import PricesView from '@/views/entities/PricesView.vue'
import ApiKeysView from '@/views/entities/ApiKeysView.vue'
import DeviceSharesView from '@/views/entities/DeviceSharesView.vue'
import TagsView from '@/views/entities/TagsView.vue'
import BCCView from '@/views/functions/BCCView.vue'
import IoTDeviceView from '@/views/entities/IoTDeviceView.vue'

import store from '@/store/index'
import IotDeviceMonitoringView from '@/views/functions/IotDeviceMonitoringView.vue'
import MobileAnalyticsView from '@/views/functions/MobileAnalyticsView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/',
    name: 'InterfaceFrame',
    component: InterfaceFrameView,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: DashboardView
      },
      {
        path: 'visualization',
        name: 'Visualization',
        component: VisualizationView
      },
      {
        path: 'tenants',
        name: 'Tenants',
        component: TenantsView
      },
      {
        path: 'users',
        name: 'Users',
        component: UsersView
      },
      {
        path: 'properties',
        name: 'Properties',
        component: PropertiesView
      },
      {
        path: 'connectors',
        name: 'Connectors',
        component: ConnectorsView
      },
      {
        path: 'devices',
        name: 'Devices',
        component: DevicesView
      },
      {
        path: 'readings',
        name: 'Readings',
        component: ReadingsView
      },
      {
        path: 'setpoints',
        name: 'Setpoints',
        component: SetpointsView
      },
      {
        path: 'events',
        name: 'Events',
        component: EventsView
      },
      {
        path: 'tasks',
        name: 'Tasks',
        component: TasksView
      },
      {
        path: 'devicetypes',
        name: 'DeviceTypes',
        component: DeviceTypesView
      },
      {
        path: 'prices',
        name: 'Prices',
        component: PricesView
      },
      {
        path: 'reports',
        name: 'Reports',
        component: ReportsView
      },
      {
        path: 'apikeys',
        name: 'apikeys',
        component: ApiKeysView
      },
      {
        path: 'deviceshares',
        name: 'deviceshares',
        component: DeviceSharesView
      },
      {
        path: 'tags',
        name: 'tags',
        component: TagsView
      },
      {
        path: 'bccserial',
        name: 'BCC Serial',
        component: BCCView
      },
      {
        path: 'iotdevices',
        name: 'iotdevices',
        component: IoTDeviceView
      },
      {
        path: 'iotmonitoring',
        name: 'iotmonitoring',
        component: IotDeviceMonitoringView
      },
      {
        path: 'mobileanalytics',
        name: 'mobileanalytics',
        component: MobileAnalyticsView
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !store.getters.loggedIn(Date.now())) {
    next({ name: 'Login', query: to.query })
  } else {
    next()
  } 
})

export default router
