import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import * as Cookies from "js-cookie";
import Axios from 'axios'

Vue.use(Vuex)

const getDefaultState = (): any => {
  return {
    auth: {
      token: "",
      tokenExpiration: -1,
    },
    session: {
      permissions: {},
      tenants: [],
      selectedTenant: {},
      currentUser: {},
      services: {
        metering: false,
      }
    },
    config: {
      version: process.env.VUE_APP_VERSION,
      user: {},
      language: "en",
      darkMode: false
    },
    volatile: {
      notifications: []
    }
  }
}

export default new Vuex.Store({
  state: getDefaultState(),
  mutations: {
    setPermissions(state, permissions: any) {
      state.session.permissions = permissions;
    },
    setLanguage(state, language: string) {
      state.config.language = language;
    },
    setDarkMode(state, darkMode: boolean) {
      state.config.darkMode = darkMode;
    },
    setToken(state, token: any) {
      state.auth.token = token.token;
      state.auth.tokenExpiration = token.expiration;
      // Set token for all future ajax requests
      Axios.defaults.headers.common['Authorization'] = `Bearer ${state.auth.token}`;
    },
    setTenants(state, tenants: any[]) {
      // sort tenants by name
      tenants.sort((a: any, b: any) => (a?.name > b?.name) ? 1 : ((b?.name > a?.name) ? -1 : 0));

      state.session.tenants = tenants;
      if(Object.keys(state.session.selectedTenant).length === 0 && tenants.length > 0) {
        if((state.session.currentUser.uuid in state.config.user) && state.config.user[state.session.currentUser.uuid]['selectedTenant']) {
          state.session.selectedTenant = state.config.user[state.session.currentUser.uuid]['selectedTenant'];
        } else {
          state.session.selectedTenant = tenants[0];
        }
      }
      if(!(state.session.currentUser.uuid in state.config.user)){
        state.config.user[state.session.currentUser.uuid] = { columns: {} };
      }
      state.config.user[state.session.currentUser.uuid]['selectedTenant'] = state.session.selectedTenant;
    },
    setSelectedColumns(state, data: any) {
      if(!(state.session.currentUser.uuid in state.config.user)){
        state.config.user[state.session.currentUser.uuid] = { columns: {} };
      }
      state.config.user[state.session.currentUser.uuid]['columns'][data.type] = data.columns;
    },
    setSelectedTenant(state, tenant: any) {
      state.session.selectedTenant = tenant;
      if(!(state.session.currentUser.uuid in state.config.user)){
        state.config.user[state.session.currentUser.uuid] = { columns: {} };
      }
      state.config.user[state.session.currentUser.uuid]['selectedTenant'] = state.session.selectedTenant;
    },
    setCurrentUser(state, user: any) {
      state.session.currentUser = user;
    },
    setServices(state, services: any) {
      state.session.services = services;
    },
    addNotification(state, notification: any){
      state.volatile.notifications.push(notification);
    },
    removeNotification(state, notification: any) {
      state.volatile.notifications.splice(state.volatile.notifications.findIndex((item: any) => item.uuid === notification.uuid), 1);
    },
    updateNotification(state, notification: any) {
      state.volatile.notifications.splice(state.volatile.notifications.findIndex((item: any) => item.uuid === notification.uuid), 1, notification);
      // state.volatile.notifications.push(notification);
    },
    clearNotifications(state) {
      state.volatile.notifications = [];
    },
    logout(state) {
      // Reset state and cookies storage to remove all previous session data
      this.replaceState(getDefaultState());
      // Remove token header from ajax requests
      delete Axios.defaults.headers.common["Authorization"];
    },
  },
  getters: {
    loggedIn: (state) => (time: number) => {
      
      if(state.auth.token) {
        return state.auth.tokenExpiration > time;
      } else {
        return false;
      }
    },
    selectedColumns: (state) => (type: string) => {
      if(state.session.currentUser.uuid in state.config.user && state.config.user[state.session.currentUser.uuid]?.columns[type]){
        return state.config.user[state.session.currentUser.uuid]['columns'][type]
      } else {
        return undefined;
      }
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [
    createPersistedState({
      paths: ['auth'],
      key: 'bascloud-auth-' + process.env.NODE_ENV,
      storage: {
        getItem: (key: string) => Cookies.get(key),
        // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
        setItem: (key: string, value: string) => 
          Cookies.set(key, value, { expires: 1, sameSite: 'strict', secure: true }),
        removeItem: (key: string) => Cookies.remove(key),
      },
      rehydrated(store) {
        // Set token for all future ajax requests
        Axios.defaults.headers.common['Authorization'] = `Bearer ${store.state.auth.token}`;
      },
    }),
    createPersistedState({
      paths: ['session'],
      key: 'bascloud-session-' + process.env.NODE_ENV,
      storage: window.sessionStorage
    }),
    createPersistedState({
      paths: ['config'],
      key: 'bascloud-config-' + process.env.NODE_ENV,
      storage: window.localStorage
    }),
    createPersistedState({
      paths: ['volatile'],
      key: 'bascloud-volatile-' + process.env.NODE_ENV,
      storage: window.sessionStorage
    }),
],
})
