
  import Vue from 'vue'
  import ReportsTable from '@/components/services/report/ReportsTable.vue'
  import ReportCreateDialog from '@/components/services/report/ReportCreateDialog.vue'

  import MeteringService from '@/services/MeteringService';
  import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'ReportsView',

    components: {
      ReportsTable,
      ReportCreateDialog
    },
    data: () => ({
      reports: [] as any,
      devices: [] as any,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      params: {
        pageSize: 1000,
      } as any
    }),
    async created() {
      this.resetParams();
      this.updateReports();
    },
    watch: {
      async currentTenant() {
        this.resetParams();
        this.updateReports();
      },
    },
    methods: {
      onItemCreation(item: any) {
        this.reports.push(item);
      },
      resetParams() {
        this.params = {
          pageSize: 1000
        };
      },
      async onTableReload() {
        this.updateReports();
      },
      onItemUpdate(item: any) {
        this.reports.splice(this.reports.findIndex((prop: any) => prop.uuid === item.uuid), 1);
        this.reports.push(item);
      },
      onItemDelete(item: any) {
        this.reports.splice(this.reports.findIndex((prop: any) => prop.uuid === item.uuid), 1);
      },
      updateReports() {
        if(!this.isAdmin)
          return;

        this.reports = [];
        this.dataLoaded = false;
       
        MeteringService.getAllReportCollectionPages(this.currentTenant, this.params).then((reports: any[]) => {
          this.dataLoaded = true;

          this.reports = reports;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('report.loadingError') + ' ' + extractErrorMessage(err);
          this.dataError = true;      
        });
      }
    },
    computed: {
      hasMetering(): any {
        return this.$root.$store.state.session.services.metering;
      },
      isAdmin(): any {
          return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
              && (this.$root.$store.state.session.permissions.role === 'superadmin' || 
              this.$root.$store.state.session.permissions.role === 'admin');
      }, 
      isRoot(): any {
          return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
              && this.$root.$store.state.session.permissions.role === 'superadmin';
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      }
    }
  })
