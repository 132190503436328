
    import Vue from 'vue'
  
    import IotDeviceMonitoringCard from './IotDeviceMonitoringCard.vue';
    import { autoComplete, extractErrorMessage } from '@/utils/Util';
    import DeviceMonitoringCard from './DeviceMonitoringCard.vue';
    import ReadingService from '@/services/ReadingService';
    import DeviceService from '@/services/DeviceService';
    import GroupMonitoringCard from './GroupMonitoringCard.vue';
    import IoTDeviceService from '@/services/IoTDeviceService';

    export default Vue.extend({
      name: 'IotDeviceMonitoringDashboard',
  
      props: ['value', 'iotdevices', 'devices', 'loading'],
      components: {
        IotDeviceMonitoringCard,
        DeviceMonitoringCard,
        GroupMonitoringCard
      },
      data: (_this: any): any => ({
        loadingProperties: false,
        loadingConnectors: false,
        loadingTenants: false,
        iotdeviceFilter: "",
        timeRanges: [
          "1D",
          "1W",
          "1M",
          "3M"
        ],
        timeRangeSelection: _this.value ?? '1M',
        selectedGroupBy: 'none',
        selectedAlertStates: ['2', '1', '0'],
        connectors: {},
        properties: {},
        tenants: {}
      }),
      created() {
      },
      watch: {
        timeRangeSelection() {
          // send update
          this.$emit('input', this.timeRangeSelection);
        }
      },
      methods: {
        showCard(item: any) {
          return autoComplete(item, this.iotdeviceFilter) && (this.selectedAlertStates.includes(item?.alertActive?.toString() ?? 0) || item.alertActive < 0);
        },
        autoComplete,
        onCardRefresh(iotdevice: any) {
          // send refresh
          this.$emit('reload:item', iotdevice);
        },
        onRefresh() {
          this.$emit('reload:all');
        },
        loadProperty(item: any) {
          if(!('property' in item) && !this.loadingProperties) {
            this.loadCurrentProperties();
          }
        },
        async loadCurrentProperties() {
          if(!this.loadingProperties) {
            console.log('loading properties')
            this.loadingProperties = true;

            let promises: Promise<any>[] = [];
            this.devices.forEach(async (dev: any) => {
              if(!dev.property)
                promises.push(this.loadDeviceProperty(dev));
            });

            this.iotdevices.forEach(async (iotdev: any) => {
              if(!iotdev.property)
                promises.push(this.loadIotDeviceProperty(iotdev));
            });

            await Promise.all(promises);
            console.log('loading properties finished!')
            this.loadingProperties = false;
          }
        }, 
        async loadDeviceProperty(item: any) {
          try {
              const property = await DeviceService.getAssociatedProperty(this.monitoringTenant, item.uuid);
              this.$set(item, 'property', property); // important else DOM is not updated
          } catch(err: any) {
              // console.log(err);
              // console.log(err.response);
              Vue.set(item, 'property', {});
          }
        },   
        async loadIotDeviceProperty(item: any) {
          try {
              let property;
              if(item.propertyId in this.properties) {
                property = this.properties[item.propertyId];
              } else {
                property = await IoTDeviceService.getAssociatedProperty(item.uuid);
                this.properties[item.propertyId] = property;
              }
              this.$set(item, 'property', property); // important else DOM is not updated
          } catch(err: any) {
              // console.log(err);
              // console.log(err.response);
              Vue.set(item, 'property', {});
          }
        },
        loadConnector(item: any) {
          if(!('connector' in item) && !this.loadingConnectors) {
            this.loadCurrentConnectors();
          }
        },
        async loadCurrentConnectors() {
          if(!this.loadingConnectors) {
            this.loadingConnectors = true;

            let promises: Promise<any>[] = [];
              this.devices.forEach(async (dev: any) => {
              if(!dev.property)
                promises.push(this.loadDeviceConnector(dev));
            });

            this.iotdevices.forEach(async (iotdev: any) => {
              if(!iotdev.property)
                promises.push(this.loadIotDeviceConnector(iotdev));
            });
            await Promise.all(promises);
            
            this.loadingConnectors = false;
          }
        }, 
        async loadDeviceConnector(item: any) {
          try {
              const connector = await DeviceService.getAssociatedConnector(this.monitoringTenant, item.uuid);
              Vue.set(item, 'connector', connector); // important else DOM is not updated
          } catch(err: any) {
              // console.log(err);
              // console.log(err.response);
              Vue.set(item, 'connector', {});
          }
        },
        async loadIotDeviceConnector(item: any) {
          try {
              let connector;
              if(item.connectorId in this.connectors) {
                connector = this.connectors[item.connectorId];
              } else {
                connector = await IoTDeviceService.getAssociatedConnector(item.uuid);
                this.connectors[item.connectorId] = connector;
              }
              Vue.set(item, 'connector', connector); // important else DOM is not updated
          } catch(err: any) {
              // console.log(err);
              // console.log(err.response);
              Vue.set(item, 'connector', {});
          }
        },
        loadTenant(item: any) {
          if(!('tenant' in item) && !(item?.tenant) && !this.loadingTenants) {
            this.loadCurrentTenants();
          }
        },
        async loadCurrentTenants() {
          if(!this.loadingTenants) {
            this.loadingTenants = true;

            let promises: Promise<any>[] = [];
            this.iotdevices.forEach(async (iotdev: any) => {
              if(!iotdev.tenant)
                promises.push(this.loadIotDeviceTenant(iotdev));
            });

            await Promise.all(promises);
            this.loadingTenants = false;
          }
        },  
        async loadIotDeviceTenant(item: any) {
          try {
              let tenant;
              if(item.tenantId in this.tenants) {
                tenant = this.tenants[item.tenantId];
              } else {
                tenant = await IoTDeviceService.getAssociatedTenant(item.uuid);
                this.tenants[item.tenantId] = tenant;
              }
              this.$set(item, 'tenant', tenant); // important else DOM is not updated
          } catch(err: any) {
              // console.log(err);
              // console.log(err.response);
              Vue.set(item, 'tenant', {});
          }
        },
        groupBy(list: any[], key: string) {
          return list.reduce((rv, x) => {
              (rv[x[key]] = rv[x[key]] || []).push(x);
              return rv;
          }, {});
        },
      },
      computed: {
        groupedIotDevices(): any[] {
          // return devices grouped by the selected group key

          if(this.selectedGroupBy === 'none')
            return [];

          let groups = [];

          let keys = Array.from(new Set(this.iotdevices.map((value: any) => value[this.selectedGroupBy])));

          for(const key of keys) {
            let devices = this.iotdevices.filter((value: any) => this.showCard(value) && value[this.selectedGroupBy] === key);

            let type;
            let keyEntity;
            if(devices.length > 0) {
              if(this.selectedGroupBy === 'propertyId') {
                type = 'properties';
                keyEntity = devices[0].property;
              } else if(this.selectedGroupBy === 'connectorId') {
                type = 'connectors';
                keyEntity = devices[0].connector;
              } else if(this.selectedGroupBy === 'tenantId') {
                type = 'tenants';
                keyEntity = devices[0].tenant;
              }
            }

            const groupAlert = 
              devices.map((value: any) => value.alertActive).every((s: number) => s===0) ? 
                0 : 
                  devices.map((value: any) => value.alertActive).every((s: number) => s===1) ? 
                    1 : 
                      devices.map((value: any) => value.alertActive).every((s: number) => s===2) ? 
                      2 : 
                        devices.map((value: any) => value.alertActive).some((s: number) => s===2) ? 
                        3 :
                          devices.map((value: any) => value.alertActive).some((s: number) => s===1) ?
                          1 : 0;

            groups.push({
              key: key,
              type: type,
              entity: keyEntity,
              devices: devices.map((value: any) => value.serialnumber),
              availability: {
                last_ping: new Date(Math.max(...devices.map((value: any) => value?.availability?.last_ping ? new Date(value?.availability?.last_ping).getTime() : 0))),
                rate: devices.map((value: any) => value?.availability?.rate ?? 0).reduce((a: number, b: number) => a + b, 0) / devices.length
              },
              // iotdevices[i].alerts.length > 0 ? (iotdevices[i].alerts.some((a: any) => a.active) ? 2 : 1) : 0;
              alertActive: groupAlert 
            })
          }

          console.log(groups)
          return groups;
        },
        groupedDevices(): any[] {
          // return devices grouped by the selected group key

          if(this.selectedGroupBy === 'none')
            return [];

          let groups = [];

          let keys = Array.from(new Set(this.devices.map((value: any) => value[this.selectedGroupBy])));

          for(const key of keys) {
            let devices = this.devices.filter((value: any) => this.showCard(value) && value[this.selectedGroupBy] === key);

            let type;
            let keyEntity;
            if(devices.length > 0) {
              if(this.selectedGroupBy === 'propertyId') {
                type = 'properties';
                keyEntity = devices[0].property;
              } else if(this.selectedGroupBy === 'connectorId') {
                type = 'connectors';
                keyEntity = devices[0].connector;
              } else if(this.selectedGroupBy === 'tenantId') {
                type = 'tenants';
                keyEntity = devices[0].tenant;
              }
            }

            const groupAlert = 
              devices.map((value: any) => value.alertActive).every((s: number) => s===0) ? 
                0 : 
                  devices.map((value: any) => value.alertActive).every((s: number) => s===1) ? 
                    1 : 
                      devices.map((value: any) => value.alertActive).every((s: number) => s===2) ? 
                      2 : 
                        devices.map((value: any) => value.alertActive).some((s: number) => s===2) ? 
                        3 :
                          devices.map((value: any) => value.alertActive).some((s: number) => s===1) ?
                          1 : 0;

            groups.push({
              key: key,
              type: type,
              entity: keyEntity,
              devices: devices.map((value: any) => value.aksId),
              availability: {
                last_ping: new Date(Math.max(...devices.map((value: any) => value?.availability?.last_ping ? new Date(value?.availability?.last_ping).getTime() : 0))),
                rate: devices.map((value: any) => value?.availability?.rate ?? 0).reduce((a: number, b: number) => a + b, 0) / devices.length
              },
              // iotdevices[i].alerts.length > 0 ? (iotdevices[i].alerts.some((a: any) => a.active) ? 2 : 1) : 0;
              alertActive: groupAlert 
            })
          }

          console.log(groups)
          return groups;
        },
        sortedDevices(): any[] {
          return [...this.devices].sort((a: any, b: any) => b.alertActive - a.alertActive);
        },
        sortedIotDevices(): any[] {
          return [...this.iotdevices].sort((a: any, b: any) => b.alertActive - a.alertActive);
        },
        monitoringTenant(): string {
          return 'd97ce06e-4630-4015-89b4-1fbb67bbe328';
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        }
      }
    })
  