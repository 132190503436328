
  import Vue from 'vue'
  import AuthService from '@/services/AuthService';
import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'UserRequestPasswordDialog',

    components: {
    },
    props: ['value', 'email'],
    data: (vm: any): any => ({
        localdialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        valid: true,
        requiredRules: [
            (v: string) => !!v || vm.requiredError,
        ],
        rules: {
            required: (v: string) => !!v || vm.requiredError,
            min: (v: string) => (v && v.length >= 3) || vm.passwordLengthError,
        },
    }),
    created() {
        this.localdialog = this.value;
    },
    methods: {
      validate() {
          if(this.emailForm.validate()) {
              this.requestPasswordChange();
          }
      },
      close() {
        this.localdialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      requestPasswordChange() {
        this.loading = true;

        AuthService.resetPassword(this.email).then(() => {
            this.alertType = "success";
            this.alertText = this.$t('user.emailSent') + ' ' + this.email;
            this.alertVisible = true;
            this.loading = false;

            setTimeout(() => { 
                this.dismiss(); 
            }, 2000);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('user.emailError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      }
    },
    watch: {
      value() {
        this.localdialog = this.value;
      },
        async localdialog(value: boolean) {
            if(value) {
                //s
            }
            this.$emit('input', value);
        }
    },
    computed: {
        emailForm(): any {
            return this.$refs.emailForm;
        },
        passwordLengthError(): any {
            return this.$t('login.minPasswordLength');
        },
        requiredError(): any {
            return this.$t('required');
        }
    }
  })
