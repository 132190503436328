
    import Vue from 'vue'
    import Chart, { Colors } from 'chart.js/auto';
    import 'chartjs-adapter-moment';

    const BORDER_COLORS = [
    'rgb(54, 162, 235)', // blue
    'rgb(255, 99, 132)', // red
    'rgb(255, 159, 64)', // orange
    'rgb(255, 205, 86)', // yellow
    'rgb(75, 192, 192)', // green
    'rgb(153, 102, 255)', // purple
    'rgb(201, 203, 207)' // grey
    ];

    // Border colors with 50% transparency
    const BACKGROUND_COLORS = /* #__PURE__ */ BORDER_COLORS.map(color => color.replace('rgb(', 'rgba(').replace(')', ', 0.5)'));

    const plugin = {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart: Chart, args: any, options: any) => {
            const {ctx} = chart;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = options.color || '#ffffff';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    };

    const plugin2 = {
        id: "increase-legend-spacing", 
        beforeInit(chart: any) {
        // Get reference to the original fit function
        const originalFit = chart.legend.fit;

        // Override the fit function
        chart.legend.fit = function fit() {
            // Call original function and bind scope in order to use `this` correctly inside it
            originalFit.bind(chart.legend)();
            // Change the height as suggested in another answers
            this.height += 10;
        }
        }
    }; 

    export default Vue.extend({
        name: 'ExtendedMultiXChart',

        props: ['axes', 'types', 'labels', 'colors', 'data', 'x', 'y', 'disableAnimation', 'minX', 'minX2', 'minY', 'maxX', 'maxX2', 'maxY', 'options'],
        data: () => ({
            chart: undefined as unknown as Chart
        }),
        watch: {
            '$vuetify.breakpoint.xsOnly'(): any {
                this.createChart();
            },
            '$vuetify.theme.dark'(): any {
                this.createChart();
            },
            datasets(): any {
                this.createChart();
            },
            type(): any {
                this.createChart();
            }
        },
        mounted() {
            this.createChart();
        },
        methods: {
            createChart() {
                this.chart?.destroy();

                this.chart = new Chart(
                    this.chartCanvas,
                    {
                        type: this.types.includes('stacked') ? 'bar' : this.types.length > 0 ? this.types[0] : 'bar',
                        options: {
                            interaction: {
                                axis: 'xy',
                                intersect: false,
                                mode: 'point',
                            },
                            plugins: {
                                title: {
                                    color: this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.7)' : '#666',
                                },
                                legend: {
                                    display: true,
                                    labels: {
                                        color: this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.7)' : '#666'
                                    }
                                },
                                customCanvasBackgroundColor: {
                                    color: this.$vuetify.theme.dark ? '#1E1E1E' : '#ffffff',
                                },
                            },
                            responsive: true,
                            maintainAspectRatio: true,
                            aspectRatio: this.$vuetify.breakpoint.xsOnly ? 0.6 : 2,
                            animation: !this.disableAnimation,
                            scales: {
                                x: {
                                    position: 'bottom',
                                    type: 'time',
                                    ticks: {
                                        color: this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.7)' : '#666',
                                    },
                                    grid: {
                                        color: this.$vuetify.theme.dark ? 'rgba(225,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                                    },
                                    min: this.minX,
                                    max: this.maxX
                                },                           
                                x1: {
                                    display: this.axes.includes('x1'),
                                    position: 'top',
                                    type: 'time',
                                    ticks: {
                                        color: this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.7)' : '#666',
                                    },
                                    grid: {
                                        color: this.$vuetify.theme.dark ? 'rgba(225,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                                    },
                                    min: this.minX2,
                                    max: this.maxX2
                                },                              
                                y: {
                                    position: 'left',
                                    ticks: {
                                        color: this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.7)' : '#666',
                                    },
                                    grid: {
                                        color: this.$vuetify.theme.dark ? 'rgba(225,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                                    },
                                    min: this.minY,
                                    max: this.maxY
                                }, 
                            }
                        },
                        data: {
                            datasets: this.datasets
                        },
                        plugins: [plugin, plugin2]
                    }
                );
            }
        },
        computed: {
            datasets(): any[] {
                let dataset = [];
                for(let [index, label] of this.labels.entries()) {
                    dataset.push({
                        label: label,
                        type: this.types[index] ?? 'bar',
                        xAxisID: this.axes[index],
                        yAxisID: 'y',
                        data: this.data[index].map((value: any) => ({x: value[this.x], y: value[this.y]})),
                        backgroundColor: this.colors && this.colors[index] ? this.colors[index] : BACKGROUND_COLORS[index % BACKGROUND_COLORS.length],
                        borderColor: this.colors && this.colors[index] ? this.colors[index] : BORDER_COLORS[index % BORDER_COLORS.length],
                        order: index
                    });
                }

                return dataset;
            },
            chartCanvas(): any {
                return this.$refs.chartCanvas;
            }, 
        },
    })
