
  import Vue from 'vue'
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import IoTDeviceMappingService from '@/services/IoTDeviceMappingService';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import IotDeviceMappingInfoDialog from '@/components/entities/iotdevicemapping/IotDeviceMappingInfoDialog.vue';
  import DeviceService from '@/services/DeviceService';
  import IoTDeviceService from '@/services/IoTDeviceService';
  import DeviceInfoDialog from '@/components/entities/device/DeviceInfoDialog.vue';

  export default Vue.extend({
    name: 'IotDeviceInfoDialog',

    components: {
        EntityMetaDialog,
        IotDeviceMappingInfoDialog,
        DeviceInfoDialog
    },
    props: ['iotdevice', 'iotdeviceport'],
    data: () => ({
        localdialog: false,
        valid: true,
        passwordToggle: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingTargetDevices: false,
        associatedMappings: [] as any[],
        associatedTenant: {} as any
    }),
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedTenant = {};
        this.associatedMappings = [];
        this.alertVisible = false;
        this.loading = false;
        this.loadingTargetDevices = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async getAssociatedMappings() {
        this.loading = true;

        try {
          const mappings = await IoTDeviceMappingService.getAllCollectionPages(this.iotdevice.uuid, this.iotdeviceport.uuid);
          this.loading = false;

          this.associatedMappings = mappings;
        } catch(err: any) {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('user.tenantError')+ ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
          this.associatedMappings = [];
        }
      },
      aggregationConfig(config: any) {

        let ret = {} as any;

        if('aggregationMode' in config) {
            ret['aggregationMode'] = config['aggregationMode'];
        }
        if('aggregationInterval' in config && 'aggregationFrequency' in config) {
            ret['aggregationTime'] = config['aggregationInterval'] + ' ' + config['aggregationFrequency'];
        }
        if('aggregationFunction' in config) {
            ret['aggregationFunction'] = config['aggregationFunction'];
        }

        return ret;
      },
      async getAssociatedTenant() {
        this.loading = true;

        try {
          const tenant = await IoTDeviceService.getAssociatedTenant(this.iotdevice.uuid);
          this.loading = false;

          this.associatedTenant = tenant;
        } catch(err: any) {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('user.tenantError')+ ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
          this.associatedTenant = {};
        }
      },
      getTargetDeviceLabel(item: any) {
        if(!('targetDevice' in item) && !this.loadingTargetDevices) {
          this.loadTargetDevices();
        }

        return item?.targetDevice?.description;
      },
      async loadTargetDevices() {
        if(!this.loadingTargetDevices && this.associatedMappings.length > 0) {
          this.loadingTargetDevices = true;

          let promises: Promise<any>[] = [];
          this.associatedMappings.forEach(async (dev: any, index: number) => {
            if(!dev.targetDevice)
              promises.push(this.loadTargetDevice(dev, index));
          });
          await Promise.all(promises);
          this.loadingTargetDevices = false;
        }
      }, 
      delay(ms: number) {
            return new Promise(res => setTimeout(res, ms));
        },
      async loadTargetDevice(item: any, index=1) {
        try {
            const device = await DeviceService.getSingle(this.associatedTenant.uuid, item.targetDeviceId);
            this.$set(item, 'targetDevice', device); // important else DOM is not updated
            await this.delay(index*100);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            // Vue.set(item, 'targetDevice', {});
        }
      }, 
      capitalize(value: string) {
        return value.charAt(0).toUpperCase()+value.slice(1)
      },
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                await this.getAssociatedTenant();
                this.getAssociatedMappings();
            } else {
                this.clear();
            }
        }
    },
    computed: {
        currentTenant(): any {
            return this.$root.$store.state.session.selectedTenant.uuid;
        }
    }
  })
