
    import Vue from 'vue'

    import TimeRangeFilter from './TimeRangeFilter.vue';
    import StringFilter from './StringFilter.vue';
    import BooleanFilter from './BooleanFilter.vue';
    import ConnectorFilter from './ConnectorFilter.vue';
    import PropertyFilter from './PropertyFilter.vue';
    import DeviceFilter from './DeviceFilter.vue';
    import NumberFilter from './NumberFilter.vue';
    import TagFilter from './TagFilter.vue';
    import SelectionFilter from './SelectionFilter.vue';
    import TenantFilter from './TenantFilter.vue';
    import UserFilter from './UserFilter.vue';

    // item collections for selectionfilter
    import { SetpointStateTypes } from '../entities/setpoint/SetpointStateTypes';
    import { ReadingFlagTypes, ReadingAggregationFrequencies, ReadingAggregationFunctions } from '../entities/reading/ReadingCollections';
    import { UserPermissionRoles } from '../entities/user/UserCollections';
    import AggregationIntervalFilter from './AggregationIntervalFilter.vue';

    import { components } from './FilterComponents';

    export default Vue.extend({
      name: 'EntityFiltersCard',
  
      components: {
        TimeRangeFilter,
        StringFilter,
        BooleanFilter,
        ConnectorFilter,
        PropertyFilter,
        DeviceFilter,
        NumberFilter,
        TagFilter,
        SelectionFilter,
        TenantFilter
      },
      props: {
        config: {
          type: Array,
          required: true
        },        
        defaults: {
          type: Object,
          default: () => {}
        },        
        value: {
          required: true
        },
        extra_components: {
          type: Object,
          required: false,
          default: () => {}
        }
      },
      data: (_this: any): any => ({
        valid: undefined,
        settings: [],
        toggleSelect: false,
        activeChanges: false,
        selectedComponents: [] as any[],
        localFilters: _this.value,
        default_components: components(_this)
      }),
      created() {
        this.selectedComponents = this.availableComponents.filter((value: any) => value.props.always || value.props.mandatory);
      },
      methods: {
        onAddFilterComponent(item: any) {
          this.selectedComponents.push(item);
          this.activeChanges = true;
        },
        onRemoveFilterComponent(index: number) {
          this.selectedComponents.splice(index, 1);
          this.activeChanges = true;
        },
        reset() {
          this.localFilters = { ...this.defaults };
          this.selectedComponents = this.availableComponents.filter((value: any) => value.props.always || value.props.mandatory);
          this.activeChanges = true;
          this.$emit("reset");
        },
        apply() {
          if(this.filterForm.validate()) {
            this.activeChanges = false;
            this.$emit("input", this.localFilters);
            this.$emit("apply");
          }
        }
      },
      watch: {
        value() {
          this.localFilters = this.value;
          this.$nextTick(() => {
            // little hack to get to not show active changes when filter changed in parent
            this.activeChanges = false;
          });
        },
        localFilters() {
          this.activeChanges = true;
        },
        config() {
          this.selectedComponents = this.availableComponents.filter((value: any) => value.props.always || value.props.mandatory);
          this.localFilters = { ...this.defaults, ...this.localFilters };
        },
        currentTenant() {
          this.reset();
        }
      },
      computed: {
        components(): any[] {
          return {...this.extra_components, ...this.default_components};
        },
        filterForm(): any {
            return this.$refs.filterForm;
        },
        filteredAvailableComponents(): any[] {
          return this.availableComponents.filter((value: any) => !value.props.always && !value.props.mandatory);
        },
        availableComponents(): any[] {
          return this.config.map((value: any, index: number) => ({
            component: this.components[value.attribute].component, 
            props: { 
              ...this.components[value.attribute].props, 
              key: value.attribute,
              label: this.components[value.attribute].label ?? value.attribute, 
              attributes: this.components[value.attribute].attributes, 
              mandatory: value.mandatory??false, 
              always: value.always??false 
            }
          }));
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        },
      }
    })
  