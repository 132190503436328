
  import Vue from 'vue'

  import {parseJwt} from '@/utils/Util';

  export default Vue.extend({
    name: 'UserApiKeyTokenDialog',
    
    props: [],
    data: () => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        token: ""
    }),
    watch: {
        localDialog(value: boolean) {
            if(!value) {
                this.clear();
            }
        }
    },
    methods: {
      applyToken() {
        const decoded = parseJwt(this.token);
        let expiration;
        const now = Date.now();
        if('apiKey' in decoded && !('exp' in decoded)) {
            // key does not expire
            const date = new Date(Date.now())
            date.setFullYear(date.getFullYear()+10);
            expiration = date.getTime();
        } else {
            expiration = decoded.exp * 1000;
        }
        
        if (expiration > now) {
            // Reset current session
            this.$store.commit('logout');
            this.$store.commit("setToken", { token: this.token, expiration: expiration });
            if(this.$root.$store.getters.loggedIn(Date.now())) {
                window.location.reload();
            }
        } else {
            this.alertType = "error";
            this.alertText = this.$t('login.errorExpiredToken') + expiration.toLocaleString();
            this.alertVisible = true;
        }
      },
      close() {
        this.localDialog = false;
      },
      clear() {
        this.token = "";
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      }
    },
  })
