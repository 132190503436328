
  import Vue from 'vue'
import { extractErrorMessage } from '@/utils/Util';
import TagService from '@/services/TagService';
import { TagTypes } from './TagTypes';

  export default Vue.extend({
    name: 'TagCreateDialog',

    components: {
    },
    props: [],
    data: (vm: any): any => ({
        dialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        requiredRule: [
          (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
          (v: any) => Object.keys(v).length > 0 || vm.requiredError
        ],
        tagTypes: TagTypes,
        tag: {
          name: "",
          description: "",
          type: ""
        },
    }),
    methods: {
      validate() {
        if(this.createForm.validate()) {
          this.createTag();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.connector = {
          name: ""
        };
        this.alertVisible = false;
        this.loading = false;
        this.associatedTags = [];
        this.tags =  [];
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async createTag() {
        this.loading = true;
        let name = this.tag.name;
        let description = this.tag.description;
        let type = this.tag.type;

        try {
          const tag = await TagService.create(this.currentTenant, name, description, type);
          this.alertType = "success";
          this.alertText = this.$t('tag.successCreate');
          this.alertVisible = true;

          this.$emit("success", tag);
          setTimeout(() => { 
              this.dismiss(); 
          }, 1500);
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('tag.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
          this.loading = false;
        }
      },
    },
    watch: {
      dialog(value: boolean) {
        if(!value) {
          this.clear();
        }
      }
    },
    computed: {
      createForm(): any {
          return this.$refs.createForm;
      },
      requiredError(): any {
          return this.$t('required');
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
    }
  })
