
  import Vue from 'vue'
  import UserService from '@/services/UserService';
  import MeteringService from '@/services/MeteringService';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';

  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  
  import UserDevicesEditDialog from '@/components/entities/user/UserDevicesEditDialog.vue'
  import UserResendInviteDialog from '@/components/entities/user/UserResendInviteDialog.vue';
  import UserRequestPasswordDialog from '@/components/entities/user/UserRequestPasswordDialog.vue';
  import UserSetPasswordDialog from '@/components/entities/user/UserSetPasswordDialog.vue';
  import UserMeteringDevicesEditDialog from '@/components/entities/user/UserMeteringDevicesEditDialog.vue'

  export default Vue.extend({
    name: 'UserEditDialog',

    props: ['user'],
    components: {
        EntityMetaDialog,
        UserRequestPasswordDialog,
        UserDevicesEditDialog,
        UserResendInviteDialog,
        UserSetPasswordDialog,
        UserMeteringDevicesEditDialog,
        ApiKeyInfoDialog: () => import('@/components/entities/apikey/ApiKeyInfoDialog.vue') as any,
        ApiKeyEditDialog: () => import('@/components/entities/apikey/ApiKeyEditDialog.vue') as any,
        ApiKeyDeleteDialog: () => import('@/components/entities/apikey/ApiKeyDeleteDialog.vue') as any,
        ApiKeyGenerateTokenDialog: () => import('@/components/entities/apikey/ApiKeyGenerateTokenDialog.vue') as any,
        ApiKeyCreateDialog: () => import('@/components/entities/apikey/ApiKeyCreateDialog.vue') as any,
    },
    data: (vm: any): any => ({
        localdialog: false,
        localUser: {} as any,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingTenant: false,
        loadingPermissions: false,
        loadingMetering: false,
        meteringActive: false,
        emailRules: [
            (v: string) => !!v || vm.requiredError,
            (v: string) => /.+@.+\..+/.test(v) || vm.emailValidError
        ],
        userPermissions: {} as any,
        userTenant: {} as any,
        updatedUserTenant: {} as any,
        updatedPermissionsRole: "",
        associatedDevices: [] as any[],
        loadingDevices: false,
        associatedDeviceFilter: "",
        associatedMeteringDevices: [] as any[],
        associatedApiKeys: [] as any[],
        loadingApiKeys: false,
    }),
    async created() {
      this.localUser = Object.assign({}, this.user);
    },
    methods: {
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      close() {
        this.localdialog = false;
      },
      clear() {
        this.userPermissions = {};
        this.meteringActive = false;
        this.userTenant = {};
        this.updatedUserTenant = {};
        this.updatedPermissionsRole = "";
        this.alertVisible = false;
        this.loading = false;
        this.loadingTenant= false;
        this.loadingPermissions= false;
        this.loadingMetering= false;
        this.associatedDeviceFilter = "";
        this.associatedDevices = [];
        this.associatedMeteringDevices = [];
        this.associatedApiKeys = [];
        this.loadingApiKeys = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async save() {
        this.loading = true;

        let tenantId = "";
        let role = "";
        let email = "";
        
        if(this.tenantChanged) {
            tenantId = this.updatedUserTenant.uuid;
            role = this.updatedPermissionsRole;
        } else if(this.permissionRoleChanged) {
            tenantId = this.userTenant.uuid;
            role = this.updatedPermissionsRole;
        }

        if(this.localUser.email.length > 0 && this.localUser.email !== this.user.email) {
            email = this.localUser.email;
        }

        try {
            const user = await UserService.updateUser(this.user.uuid, email, tenantId, role)

            this.localUser = user;

            if(this.tenantChanged && this.meteringActive) {
                // If tenant is updated, it needs to be changed in metering as well
                await MeteringService.updateUser(this.userTenant.uuid, this.user.uuid, tenantId);
            }
            this.alertType = "success";
            this.alertText = this.$t('user.successUpdate');
            this.alertVisible = true;

            this.$emit("update", user);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('user.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        }
      },
      onApiKeyCreation(item: any) {
        this.associatedApiKeys.push(item);
      },
      onApiKeyUpdate(item: any) {
        this.associatedApiKeys.splice(this.associatedApiKeys.findIndex((conn: any) => conn.uuid === item.uuid), 1);
        this.associatedApiKeys.push(item);
      },
      onApiKeyDelete(item: any) {
        this.associatedApiKeys.splice(this.associatedApiKeys.findIndex((conn: any) => conn.uuid === item.uuid), 1);
      },
      getAssociatedDevices() {
        this.loadingDevices = true;
        this.associatedDevices = [];

        UserService.getAllAssociatedDevicePages(this.user.uuid).then((devices: any[]) => {
            this.loadingDevices = false;
            this.associatedDevices = devices;
        }).catch((err) => {
            if(err.response && err.response.status === 404) {
                this.alertType = "warning";
                this.alertText = extractErrorMessage(err);
                this.alertVisible = true;
                this.loadingDevices = false;
            } else {
                console.log(err);
                console.log(err.response);
                this.alertType = "error";
                this.alertText = this.$t('user.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
                this.alertVisible = true; 
                this.loadingDevices = false;
            }
        });
      },
      getAssociatedApiKeys() {
        this.loadingApiKeys = true;
        this.associatedApiKeys = [];

        UserService.getAssociatedApiKeys(this.user.uuid).then((apikeys: any[]) => {
            this.loadingApiKeys = false;
            this.associatedApiKeys = apikeys;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.loadingApiKeys = false;
        });
      },
      loadPermissions() {
          this.loadingPermissions = true;

          UserService.getPermissions(this.user.uuid).then((responseData) => {
                this.loadingPermissions = false;

                this.userPermissions = responseData.permissions;
                this.updatedPermissionsRole = responseData.role;
            }).catch((err) => {
                if(err.response && err.response.status === 404) {
                    this.alertType = "warning";
                    this.alertText = err.response.data.errors[0].source['pointer'];
                    this.alertVisible = true;
                    this.loadingPermissions = false;
                } else {
                    console.log(err);
                    console.log(err.response);
                    this.alertType = "error";
                    this.alertText = this.$t('user.permissionsError') + ' ' + extractErrorMessage(err);
                    this.alertVisible = true; 
                    this.loadingPermissions = false;
                }
                this.userPermissions = {};
            });
      },
      async loadAssociatedTenant() {
        this.loadingTenant = true;

        try {
          const tenant = await UserService.getAssociatedTenant(this.user.uuid)
          this.loadingTenant = false;

          this.userTenant = tenant;
          this.updatedUserTenant = this.userTenant;
        } catch(err: any) {
          if(err.response && err.response.status === 404) {
              this.alertType = "warning";
              this.alertText = err.response.data.errors[0].source['pointer'];
              this.alertVisible = true;
              this.loadingTenant = false;
          } else {
              console.log(err);
              console.log(err.response);
              this.alertType = "error";
              this.alertText = this.$t('user.tenantError') + ' ' + extractErrorMessage(err);
              this.alertVisible = true; 
              this.loadingTenant = false;
          }
          this.userTenant = {};
        }
      },
      autoComplete,
      getDays(date: Date) {
        return Math.floor(( date.valueOf() - new Date().valueOf() ) / 86400000); 
      },
      getColor(date: Date) {
        let diff =  this.getDays(date);
        if (diff <= 0) return 'red'
        else if (diff <= 7) return 'orange'
        else return 'green'
      },
      loadMeteringUser() {
        this.loadingMetering = true;
        
        MeteringService.getUser(this.userTenant.uuid, this.user.uuid).then((user) => {
            this.loadingMetering = false;

            if(Object.keys(user).length > 0) {
                this.meteringActive = true;
            }
        }).catch((err) => {
            if(err.response && err.response.status !== 404) {
                console.log(err);
                console.log(err.response);
            }
            this.loadingMetering = false;
            this.meteringActive = false;
        });
      },
      loadMeteringUserDevices() {
        this.loadingMetering = true;
        this.associatedMeteringDevices = [];
        
        MeteringService.getUserDeviceCollection(this.userTenant.uuid, this.user.uuid).then((devices) => {
            this.loadingMetering = false;

            this.associatedMeteringDevices = devices;
          }).catch((err) => {
              if(err.response && err.response.status !== 404) {
                  console.log(err);
                  console.log(err.response);
              }
              this.loadingMetering = false;
          });
      },
      addMeteringUser() {
        this.loadingMetering = true;
        
        MeteringService.createUser(this.userTenant.uuid, this.user.uuid).then((user) => {
            this.loadingMetering = false;
            console.log(user);
            if(user?.uuid === this.user.uuid) {
                this.meteringActive = true;

                // add all devices of the user to the metering service
                try {
                    this.associatedDevices.forEach(async (element: any) => {
                        await MeteringService.addUserDevice(this.userTenant.uuid, this.user.uuid, element.uuid, element.permission);
                    });
                } catch(err: any) {
                    console.log(err);
                    console.log(err.response);
                }
            }
        }).catch((err) => {
            if(err.response && err.response.status !== 404) {
                console.log(err);
                console.log(err.response);
            }
            this.loadingMetering = false;
            this.meteringActive = false;
        });
      },
      removeMeteringUser() {
        this.loadingMetering = true;

        // upon deleting of a user, the metering service itself also removes all devices associated to the user
        MeteringService.deleteUser(this.userTenant.uuid, this.user.uuid).then(() => {
            this.loadingMetering = false;

            this.meteringActive = false;
        }).catch((err) => {
            if(err.response && err.response.status !== 404) {
                console.log(err);
                console.log(err.response);
            }
            this.loadingMetering = false;
            this.meteringActive = false;
        });
      },
      devicePermissionColors(permission: string) {
          if(permission === 'r') {
              return undefined;
          } else if(permission === 'rw'){
              return 'warning';
          } else {
              return undefined;
          }
      },
      devicePermissionLabels(permission: string) {
          if(permission === 'r') {
              return this.$t('permission.read');
          } else if(permission === 'rw'){
              return this.$t('permission.write');
          } else {
              return permission;
          }
      },
    },
    watch: {
        user() {
            this.localUser = Object.assign({}, this.user);
        },
        async localdialog(value: boolean) {
            if(value) {
                this.getAssociatedDevices();
                this.loadPermissions();
                this.getAssociatedApiKeys();
                await this.loadAssociatedTenant();
                if(Object.keys(this.userTenant).length > 0) {
                  this.loadMeteringUser();
                  this.loadMeteringUserDevices();
                }
            } else {
                this.clear();
            }
        }
    },
    computed: {
        isNewUser(): boolean {
            return this.user && Math.floor((new Date(Date.now()).getTime() - new Date(this.user.createdAt).getTime()) / 86400000) < 30;
        },
        isAdminUser(): boolean {
            return this.user && (this.user.role === 'admin' || this.user.role === 'superadmin');
        },
        filteredAssociatedDevices(): any[] {
            return this.associatedDevices.filter((value: any) => this.autoComplete(value, this.associatedDeviceFilter));
        },
        meteringState(): string {
          if(this.meteringActive) return this.$t('services.active');
          else return this.$t('services.inactive');
        },
        meteringStateColor(): string {
          if(this.meteringActive) return 'green';
          else return 'grey lighten';
        },
        editForm(): any {
            return this.$refs.editForm;
        },
        permissionRoles(): any {
            return UserService.getRoles();
        },
        isAdmin(): any {
            return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
                && (this.$root.$store.state.session.permissions.role === 'superadmin' || 
                this.$root.$store.state.session.permissions.role === 'admin');
        },
        isRoot(): any {
            return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
                && this.$root.$store.state.session.permissions.role === 'superadmin';
        },
        tenantUUID(): any {
            if(Object.keys(this.userTenant).length > 0) {
                return this.userTenant.uuid;
            } else {
                return '';
            }
        },
        tenantPermissionRole(): any {
            if(Object.keys(this.userPermissions).length > 0) {
                return this.userPermissions.role;
            } else {
                return '';
            }
        },
        tenantChanged(): any {
            return Object.keys(this.updatedUserTenant).length > 0 && this.tenantUUID !== this.updatedUserTenant.uuid;
        },
        permissionRoleChanged(): any {
            return Object.keys(this.updatedPermissionsRole).length > 0 &&  this.tenantPermissionRole !== this.updatedPermissionsRole;
        },
        emailValidError(): any {
            return this.$t('login.validEmail');
        },
        requiredError(): any {
            return this.$t('required');
        },
        canUpdateApiKeys(): any {
            if (this.$root.$store.state.session.permissions.resources && 'apikeys' in this.$root.$store.state.session.permissions.resources) {
            return this.$root.$store.state.session.permissions.resources['apikeys'].includes('PATCH');
            } else {
            return false;
            }
        },
        canDeleteApiKeys(): any {
            if (this.$root.$store.state.session.permissions.resources && 'apikeys' in this.$root.$store.state.session.permissions.resources) {
            return this.$root.$store.state.session.permissions.resources['apikeys'].includes('DELETE');
            } else {
            return false;
            }
        },
        canCreateApiKeys(): any {
            if (this.$root.$store.state.session.permissions.resources && 'apikeys' in this.$root.$store.state.session.permissions.resources) {
            return this.$root.$store.state.session.permissions.resources['apikeys'].includes('POST');
            } else {
            return false;
            }
        }, 
    }
  })
