
  import Vue from 'vue'
  import SetpointsTable from '@/components/entities/setpoint/SetpointsTable.vue'
  import InfoCard from '@/components/misc/InfoCard.vue'
  import SetpointCreateDialog from '@/components/entities/setpoint/SetpointCreateDialog.vue'
  import EntityFiltersCard from '@/components/filters/EntityFiltersCard.vue'

  import SetpointService from '@/services/SetpointService';
  import { extractErrorMessage, objectsToCSV } from '@/utils/Util';
import ConnectorService from '@/services/ConnectorService'
import DeviceService from '@/services/DeviceService'
import PropertyService from '@/services/PropertyService'

  export default Vue.extend({
    name: 'SetpointsView',

    components: {
      SetpointsTable,
      InfoCard,
      SetpointCreateDialog,
      EntityFiltersCard
    },
    data: () => ({
      setpointsPage: [] as any,
      nextPageLink: null,
      prevPageLink: null,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      pagination: {
        prevPage: 1,
        page: 1,
        itemsPerPage: 15,
        totalPages: 1,
        itemsLength: 0
      },
      filters: {} as any,
      filtersConfig: [
        { attribute: 'device', always: true },
        { attribute: 'timestamp', always: false },
        { attribute: 'state', always: false },
        { attribute: 'createdAt', always: false }
      ]
    }),
    async created() {
      this.resetPagination();

      if('deviceId' in this.$route.query) {
        try {
          const device = await DeviceService.getSingle(this.currentTenant, this.$route.query.deviceId as string);
          Vue.set(this.filters, 'device', device);
        } catch {}
      }
      if('connectorId' in this.$route.query) {
        try {
          const connector = await ConnectorService.getSingle(this.currentTenant, this.$route.query.connectorId as string);
          Vue.set(this.filters, 'connector', connector);
        } catch {}
      }
      if('propertyId' in this.$route.query) {
        try {
          const property = await PropertyService.getSingle(this.currentTenant, this.$route.query.propertyId as string);
          Vue.set(this.filters, 'property', property);
        } catch {}
      }

      if('device' in this.$route.params) {
        this.filters.device = this.$route.params.device;
      }
      this.updateSetpoints();
    },
    watch: {
      currentTenant() {
        this.filters = {};
        this.resetPagination();
        this.updateSetpoints();
      },
      parameter() {
        if('device' in this.$route.params) {
          this.filters.device = this.$route.params.device;
          this.resetPagination();
          this.updateSetpoints();
        }   
      }
    },
    methods: {
      onTableReload() {
        if(this.pagination.page !== 1)
          this.resetPagination();
        else
          this.updateSetpoints();
      },
      resetPagination() {
        this.pagination = {
          prevPage: 1,
          page: 1,
          itemsPerPage: 15,
          totalPages: 1,
          itemsLength: 0
        };
        this.nextPageLink = null;
        this.prevPageLink = null;
        this.setpointsPage = [];
      },
      onSetpointFiltersApply() {
        this.pagination.page = 1;
        this.updateSetpoints();
      },
      onSetpointItemUdate(item: any) {
        this.setpointsPage.splice(this.setpointsPage.findIndex((prop: any) => prop.uuid === item.uuid), 1);
        this.setpointsPage.push(item);
      },
      onSetpointItemDelete(item: any) {
        // Delete only changes the state so its still in the list
        this.setpointsPage.splice(this.setpointsPage.findIndex((prop: any) => prop.uuid === item.uuid), 1);
        this.setpointsPage.push(item);
        this.pagination.itemsLength--;
      },
      onSetpointCreation(item: any) {
        this.pagination.page = 1;
        this.pageUpdated(1);
      },
      pageUpdated(page: number) {
        this.pagination.prevPage = this.pagination.page;
        this.pagination.page = page;
        this.updateSetpoints();
      },
      pageSizeUpdated(pageSize: number) {
        this.pagination.itemsPerPage = pageSize;
        this.pagination.page = 1;
        this.updateSetpoints();
      },
      updateSetpoints() {
        this.setpointsPage = [];
        this.dataLoaded = false;
        
        let params: any = {
          itemsPerPage: this.pagination.itemsPerPage,
        }

        Object.assign(params, this.filters);

        if(this.pagination.prevPage < this.pagination.page) {
          params['nextPageLink'] = this.nextPageLink;
        } else if (this.pagination.prevPage > this.pagination.page) {
          params['prevPageLink'] = this.prevPageLink;
        }

        SetpointService.getCollectionPage(this.currentTenant, params).then((pageResponse: any) => {
          this.dataLoaded = true;

          this.pagination.itemsLength = pageResponse.data.meta.page.count;
          this.pagination.totalPages = pageResponse.data.meta.page.totalPages;
          this.pagination.page = pageResponse.data.meta.page.page;

          this.nextPageLink = pageResponse.data.links.next;
          this.prevPageLink = pageResponse.data.links.prev;

          pageResponse.data.data.forEach((userObj: any) => {
            this.setpointsPage.push({
                uuid: userObj.id,
                timestamp: userObj.attributes.timestamp,
                value: userObj.attributes.value,
                state: userObj.attributes.state,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
              })
            });
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('setpoint.loadingError') + ' ' + extractErrorMessage(err);
          this.dataError = true;      
        });
      },
      exportSetpoints() {
        this.dataLoaded = false;
        
        let params: any = {
          itemsPerPage: 1000
        }

        Object.assign(params, this.filters);

        SetpointService.getAllCollectionPages(this.currentTenant, params).then((setpoints: any[]) => {
          this.dataLoaded = true;

          const filename = 'bascloud_setpoints.csv';
          const strSetpoints = setpoints.map((read: any) => ({...read, value: read.value.toLocaleString()}));
          const csvContent = objectsToCSV(strSetpoints);
          var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

          if ((navigator as any).msSaveBlob) { // IE 10+
              (navigator as any).msSaveBlob(blob, filename);
          } else {
              var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", filename);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }
          }
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('reading.loadingError') + ' ' + extractErrorMessage(err);
          this.dataError = true;      
        });
      }
    },
    computed: {
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'setpoints' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['setpoints'].includes('POST');
        } else {
          return false;
        }
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      parameter(): any {
        return this.$route.params;
      }
    }
  })
