
  import Vue from 'vue'
  import TenantService from '@/services/TenantService';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'TenantCreateDialog',

    components: {
    },
    props: [],
    data: (vm: any): any => ({
        dialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
            (v: any) => Object.keys(v).length > 0 || vm.requiredError
        ],
        createName: "",
        createUniqueAksId: undefined,
    }),
    async created() {
      //
    },
    methods: {
      validate() {
        if(this.createForm.validate()) {
          this.createTenant();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
        this.createName = "";
      },
      dismiss() {
        this.clear();
        this.close();
      },
      createTenant() {
        this.loading = true;

        TenantService.createTenant(this.createName, this.createUniqueAksId).then((tenant) => {
          this.alertType = "success";
          this.alertText = this.$t('tenant.successCreate') as string;
          this.alertVisible = true;

          this.$emit("success", tenant);
          setTimeout(() => { 
              this.dismiss(); 
          }, 1500);
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('tenant.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
          this.loading = false;
        });
      },
      autoComplete,
    },
    watch: {
      dialog(value: boolean) {
        if(!value) {
          this.clear();
        }
      }
    },
    computed: {
      createForm(): any {
          return this.$refs.createForm;
      },
      requiredError(): any {
          return this.$t('required');
      }
    }
  })
