import DeviceComputationApi from '@/api/DeviceComputationApi';
import store from '../store/index'
import router from '../router/index'

export default {
    async getCount(tenant: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        params['itemsPerPage'] = 1;

        const response = await DeviceComputationApi.getCollection(tenant, params);

        return response?.data?.meta?.page?.count;
    },
    async getAllCollectionPages(tenant: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const devices: any[] = [];
        const pageResponses = await DeviceComputationApi.getAllCollectionPages(tenant, params);
  
        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
            devices.push({
                uuid: userObj.id,
                targetDeviceId: userObj.attributes.targetDeviceId,
                formula: userObj.attributes.formula,
                scope: userObj.attributes.scope,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt
                })
            });
        });

        return devices;
    },
    async getSingle(tenant: string, deviceComputationUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceComputationApi.getSingle(tenant, deviceComputationUUID);
  
        return {
            uuid: response.data.data.id,
            targetDeviceId: response.data.data.attributes.targetDeviceId,
            formula: response.data.data.attributes.description,
            scope: response.data.data.attributes.scope,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt
        };    
    },
    async updateDeviceComputation(tenantUUID: string, deviceComputationUUID: string, targetDeviceId = "", 
     formula = "", scope = undefined): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const response = await DeviceComputationApi.updateDeviceComputation(tenantUUID, deviceComputationUUID, targetDeviceId, formula, scope);

        if(response.data.data.type && response.data.data.type == 'devicecomputations') {
            return {
                uuid: response.data.data.id,
                targetDeviceId: response.data.data.attributes.targetDeviceId,
                formula: response.data.data.attributes.description,
                scope: response.data.data.attributes.scope,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt
            }
        } else {
            throw TypeError("Response not type devicecomputations");
        }
    },
    async getSourceDevices(tenantUUID: string, deviceComputationUUID: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const devices: any[] = [];
        const response = await DeviceComputationApi.getSourceDevices(tenantUUID, deviceComputationUUID);
  
        response.data.data.forEach((userObj: any) => {
            devices.push({
                uuid: userObj.id,
                aksId: (userObj.attributes.aksId || ''),
                localAksId: (userObj.attributes.localAksId || ''),
                description: userObj.attributes.description,
                unit: userObj.attributes.unit,
                type: userObj.attributes.type,
                frequency: userObj.attributes.frequency,
                interval: userObj.attributes.interval,
                aggregation: userObj.attributes.aggregation,
                variable: userObj.attributes.variable,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                deletedAt: userObj.meta?.deletedAt,
            })
        });

        return devices;
    },
    async getTargetDevice(tenantUUID: string, deviceComputationUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceComputationApi.getTargetDevice(tenantUUID, deviceComputationUUID);
  
        return {
                uuid: response.data.data.id,
                aksId: (response.data.data.attributes.aksId || ''),
                localAksId: (response.data.data.attributes.localAksId || ''),
                description: response.data.data.attributes.description,
                unit: response.data.data.attributes.unit,
                type: response.data.data.attributes.type,
                frequency: response.data.data.attributes.frequency,
                interval: response.data.data.attributes.interval,
                aggregation: response.data.data.attributes.aggregation,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                deletedAt: response.data.data.meta?.deletedAt,
            };
    },
    async deleteDeviceComputation(tenantUUID: string, deviceComputationUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await DeviceComputationApi.deleteDeviceComputation(tenantUUID, deviceComputationUUID);
    },
    async createDeviceComputation(tenantUUID: string, targetDeviceId: string, formula: string, 
        scope: any): Promise<any> {
            if (!store.getters.loggedIn(Date.now())) {
                router.push('/login');
            }
        
            const response = await DeviceComputationApi.createDeviceComputation(tenantUUID, targetDeviceId, formula, scope);
    
            if(response.data.data.type && response.data.data.type == 'devicecomputations') {
                return {
                    uuid: response.data.data.id,
                    targetDeviceId: response.data.data.attributes.targetDeviceId,
                    formula: response.data.data.attributes.description,
                    scope: response.data.data.attributes.scope,
                    createdAt: response.data.data.meta.createdAt,
                    updatedAt: response.data.data.meta.updatedAt
                }
            } else {
                throw TypeError("Response not type devicecomputations");
            }
    },
}
  