
  import Vue from 'vue'
  import { RRuleToText } from '@/utils/Util';

  import ReadingCreateDialog from '@/components/entities/reading/ReadingCreateDialog.vue';
  import MeteringService from '@/services/MeteringService';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import TaskInfoDialog from '@/components/services/task/TaskInfoDialog.vue';

  export default Vue.extend({
    name: 'EventInfoDialog',

    components: {
      ReadingCreateDialog,
      EntityMetaDialog,
      TaskInfoDialog,
      // Weird import necessary due to circular dependency
      DeviceInfoDialog: () => import('@/components/entities/device/DeviceInfoDialog.vue') as any
    },
    props: ['value', 'event'],
    data: () => ({
      localdialog: false,
      valid: true,
      alertText: "",
      alertVisible: false,
      alertType: "error",
      loading: false,
      loadingTask: false,
      associatedTask: {} as any,
    }),
    created() {
      this.localdialog = this.value;
    },
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async onReadingCreation(item: any) {
        this.$emit('batchDelete', this.event);
      },
      getDays(date: Date) {
        return Math.floor(( date.valueOf() - new Date().valueOf() ) / 86400000); 
      },
      getStateLabel(state: string) {
        if (state === 'open') return this.$t('event.open')
        else if (state === 'escalated') return this.$t('event.escalated')
        else if (state === 'completed') return this.$t('event.completed')
      },
      getColor(date: Date) {
        let diff =  this.getDays(date);
        if (diff <= 0) return 'red'
        else if (diff <= 7) return 'orange'
        else return 'green'
      },
      getStateColor(state: string) {
        if (state === 'open') return 'orange'
        if (state === 'escalated') return 'red'
        else return 'rgba(76, 175, 80, 0.6)'
      },
      getAssociatedTask() {
        this.loadingTask = true;
        
        MeteringService.getDeviceTask(this.$root.$store.state.session.selectedTenant.uuid, this.event.deviceId).then((task) => {
            this.loadingTask = false;

            this.associatedTask = task;
        }).catch((err) => {
            if(err.response && err.response.status !== 404) {
                console.log(err);
                console.log(err.response);
            }
            this.loadingTask = false;
        });
      },
    },
    watch: {
      value() {
        this.localdialog = this.value;
      },
      localdialog(value: boolean) {
        if(value) {
            this.getAssociatedTask()
        }
        this.$emit('input', value);
      }
    },
    computed: {
      rruleText(): any {
          if(Object.keys(this.associatedTask).length > 0) {
              const text = RRuleToText(this.associatedTask.rrule, this.$root.$store.state.config.language);
              return text.charAt(0).toUpperCase()+text.slice(1);
          } else {
              return "";
          }
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'readings' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['readings'].includes('POST');
        } else {
          return false;
        }
      },
    }
  })
