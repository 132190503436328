
  import Vue from 'vue'
  import IoTDeviceService from '@/services/IoTDeviceService';

  import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'IotDeviceDisableDialog',

    props: ['iotdevice'],
    data: () => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
    }),
    methods: {
      close() {
        this.localDialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      confirm() {
        this.loading = true;
      
        IoTDeviceService.deactivateIoTDevice(this.iotdevice.uuid).then(() => {
            this.alertType = "success";
            this.alertText = this.$t('iotdevice.successDisable') as string;
            this.alertVisible = true;
            this.loading = false;

            this.$emit("disable", this.iotdevice);

            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('iotdevice.errorDisable') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      }
    }
  })
