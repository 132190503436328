
  import Vue from 'vue'
  import LanguageSwitcher from '@/components/misc/LanguageSwitcher.vue'

  export default Vue.extend({
    name: 'SettingsMenu',

    props: [],
    components: {
      LanguageSwitcher,
    },
    data: () => ({
      fav: true,
      menu: false,
      message: false,
      hints: true,
      localDarkMode: false
    }), 
    created() {
      this.localDarkMode = this.$root.$store.state.config.darkMode;
      this.$vuetify.theme.dark = this.$root.$store.state.config.darkMode;
    },   
    watch: {
      localDarkMode() {
        this.$vuetify.theme.dark = this.localDarkMode;
        this.$root.$store.commit("setDarkMode", this.localDarkMode);
      }
    }
  })
