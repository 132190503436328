import store from '../store/index';
import router from '../router/index';
import UserApi from '@/api/UserApi';

export default {
    async getAllCollectionPages(params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const users: any[] = [];
        const pageResponses = await UserApi.getAllCollectionPages(params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                users.push({
                    uuid: userObj.id,
                    email: userObj.attributes.email,
                    role: userObj.attributes.role,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                    lastActiveAt: userObj.meta.lastActiveAt,
                });
            });
        });
        return users;
    },
    async getSelf(): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await UserApi.getSelf();

        return {
            uuid: response.data.data.id,
            email: response.data.data.attributes.email,
            role: response.data.data.attributes.role,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            lastActiveAt: response.data.data.meta.lastActiveAt,
        };
    },
    async getSingle(userUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await UserApi.getSingle(userUUID);

        return {
            uuid: response.data.data.id,
            email: response.data.data.attributes.email,
            role: response.data.data.attributes.role,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            lastActiveAt: response.data.data.meta.lastActiveAt,
        };
    },
    async getPermissions(userUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await UserApi.getPermissions(userUUID);

        return { 
            permissions: response.data.data.attributes,
            role: response.data.data.attributes.role 
        };
    },
    async getAssociatedTenant(userUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await UserApi.getAssociatedTenant(userUUID);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            urlName: response.data.data.attributes.urlName,
            uniqueAksId: response.data.data.attributes.uniqueAksId,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            deletedAt: response.data.data.meta.deletedAt,
        };
    },
    getRoles() : Array<string> {
        return ["user", "admin"];
    },
    async createUser(email: string, tenantUUID="", role="", password=""): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await UserApi.createUser(email, tenantUUID, role, password);

        if(response.data.data.type && response.data.data.type == 'users') {

            return {
                uuid: response.data.data.id,
                email: response.data.data.attributes.email,
                role: response.data.data.attributes.role,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                lastActiveAt: response.data.data.meta.lastActiveAt,
            };
        } else {
            throw TypeError("Response not type users");
        }
    },
    async updateUser(userUUID: string, email = "", tenantUUID = "", role = "") : Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await UserApi.updateUser(userUUID, email, tenantUUID, role);

        return {
            uuid: response.data.data.id,
            email: response.data.data.attributes.email,
            role: response.data.data.attributes.role,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            lastActiveAt: response.data.data.meta.lastActiveAt,
        };
    },
    async deleteUser(userUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await UserApi.deleteUser(userUUID);
    },    
    async setUserPassword(userUUID: string, password: string) : Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await UserApi.setUserPassword(userUUID, password);
    },
    async resendInvite(userUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await UserApi.resendInvite(userUUID);
    },
    async getAssociatedDevices(userUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const devices: any[] = [];
        const response = await UserApi.getAssociatedDevices(userUUID);

        response.data.data.forEach((userObj: any) => {
            devices.push({
                uuid: userObj.id,
                aksId: (userObj.attributes.aksId || ''),
                localAksId: (userObj.attributes.localAksId || ''),
                description: userObj.attributes.description,
                unit: userObj.attributes.unit,
                type: userObj.attributes.type,
                alerting: userObj.attributes.alerting,
                isRelative: userObj.attributes.isRelative,
                frequency: userObj.attributes.frequency,
                interval: userObj.attributes.interval,
                aggregation: userObj.attributes.aggregation,
                permission: userObj.attributes.permission,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                deletedAt: userObj.meta.deletedAt,
            })
        });
        return devices;
    },
    async getAllAssociatedDevicePages(userUUID: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const devices: any[] = [];
        const pageResponses = await UserApi.getAllAssociatedDevicePages(userUUID, params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                devices.push({
                    uuid: userObj.id,
                    aksId: (userObj.attributes.aksId || ''),
                    localAksId: (userObj.attributes.localAksId || ''),
                    description: userObj.attributes.description,
                    unit: userObj.attributes.unit,
                    type: userObj.attributes.type,
                    alerting: userObj.attributes.alerting,
                    isRelative: userObj.attributes.isRelative,
                    frequency: userObj.attributes.frequency,
                    interval: userObj.attributes.interval,
                    aggregation: userObj.attributes.aggregation,
                    permission: userObj.attributes.permission,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                    deletedAt: userObj.meta.deletedAt,
                });
            });
        });
        return devices;
    },
    async getAssociatedApiKeys(userUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const keys: any[] = [];
        const response = await UserApi.getAssociatedApiKeys(userUUID);

        response.data.data.forEach((userObj: any) => {
            keys.push({
                uuid: userObj.id,
                name: userObj.attributes.name,
                expiration: userObj.attributes.expiration,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
            })
        });
        return keys;
    },
    async addUserDevices(userUUID: string, devices: any[]): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await UserApi.addUserDevices(userUUID, devices);
    },
    async updateUserDevice(userUUID: string, deviceUUID: string, permission: string) : Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await UserApi.updateUserDevice(userUUID, deviceUUID, permission);
    },
    async removeUserDevices(userUUID: string, devices: any[]): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await UserApi.removeUserDevices(userUUID, devices);
    }
}
  