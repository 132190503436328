
  import Vue from 'vue'
import { extractErrorMessage } from '@/utils/Util';
import UserService from '@/services/UserService';
import DeviceService from '@/services/DeviceService';

  export default Vue.extend({
    name: 'DeviceAlertCreateDialog',

    components: {
    },
    props: ['device'],
    data: (vm: any): any => ({
        dialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        requiredRule: [
          (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
          (v: any) => Object.keys(v).length > 0 || vm.requiredError
        ],
        requiredSelectList: [
          (v: any) => v.length > 0 || vm.requiredError
        ],
        recipients: [] as string[],
        users: [] as any[],
        selectedTimeUnit: 3600,
        selectedTimeFrequency: 1,
        timeUnits: [
            {name: vm.$t('timeUnit.minutes'), value: 60 },
            {name: vm.$t('timeUnit.hours'), value: 3600 }, 
            {name: vm.$t('timeUnit.days'), value: 86400 },
            {name: vm.$t('timeUnit.weeks'), value: 604800 },
            {name: vm.$t('timeUnit.months'), value: 2629800 },
            {name: vm.$t('timeUnit.years'), value: 31557600 }
        ],
    }),
    methods: {
      validate() {
        if(this.createForm.validate()) {
          this.createAlert();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
        this.selectedTimeUnit = 3600;
        this.selectedTimeFrequency = 1;
        this.users =  [];
        this.recipients =  [];

      },
      dismiss() {
        this.clear();
        this.close();
      },
      async createAlert() {
        this.loading = true;
        let maxAge = this.selectedTimeFrequency * this.selectedTimeUnit;

        console.log(this.recipients, maxAge);

        try {
          const alert = await DeviceService.addAlert(this.currentTenant, this.device.uuid, this.recipients, maxAge);
          this.alertType = "success";
          this.alertText = this.$t('alert.successCreate');
          this.alertVisible = true;

          this.$emit("success", alert);
          setTimeout(() => { 
            this.dismiss(); 
          }, 1500);
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('alert.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
          this.loading = false;
        }
      },
      getUsers() {
        this.users = [];
        this.loading = true;
        let params = {};

        UserService.getAllCollectionPages(params).then((users: any[]) => {
          this.loading = false;

          this.users = users;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.loading = false;    
        });
      },
    },
    watch: {
      dialog(value: boolean) {
        if(!value) {
          this.clear();
        } else {
          this.getUsers();
        }
      }
    },
    computed: {
      createForm(): any {
          return this.$refs.createForm;
      },
      requiredError(): any {
          return this.$t('required');
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
    }
  })
