import axios from 'axios';

export default {
    // device tasks
    getDeviceTask(tenantUUID: string, deviceUUID: string): Promise<any> {
        return axios.get(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/tasks/'+ deviceUUID);
    },
    async getAllTaskCollectionPages(tenantUUID: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let nextPageLink = null;
    
        do {
            let requestURL = process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/tasks?';
            // Paging
            if('pageSize' in params && params.pageSize) {
                requestURL = requestURL + '&pageSize=' + encodeURIComponent(params.pageSize);
            }
            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + nextPageLink;
            }
            const response = await axios.get(requestURL);

            nextPageLink = response.data.links.next;

            requests.push(response);
            currentPage++;
        } while(nextPageLink)
    
        return Promise.all(requests);
    },
    createDeviceTask(tenantUUID: string, deviceUUID: string, rrule: string, note="", forceQRScan=false): Promise<any> {
    
        const body = {
            data: {
                type: "tasks",
                id: deviceUUID,
                attributes: {
                    deviceId: deviceUUID,
                    rrule: rrule,
                    note: note,
                    forceQRScan: forceQRScan
                },
            }
        } as any;

        return axios.post(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/tasks', JSON.stringify(body), { headers: { 'Content-Type': 'application/json'}});
    },
    updateDeviceTask(tenantUUID: string, deviceUUID: string, rrule="", note="", forceQRScan=undefined): Promise<any> {
        const body = {
            data: {
                type: "tasks",
                id: deviceUUID,
                attributes: {
                }
            }
        } as any;

        if(rrule.length > 0) {
            body.data.attributes.rrule = rrule;
        }
        if(note.length > 0) {
            body.data.attributes.note = note;
        }
        if(forceQRScan !== undefined) {
            body.data.attributes.forceQRScan = forceQRScan;
        }

        return axios.patch(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/tasks/' + deviceUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/json'}});
    },
    deleteDeviceTask(tenantUUID: string, deviceUUID: string): Promise<any> {
        return axios.delete(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/tasks/'+ deviceUUID);
    },
    // users
    getUser(tenantUUID: string, userUUID: string): Promise<any> {
        return axios.get(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/users/'+ userUUID);
    },  
    getUserCollection(tenantUUID: string): Promise<any> {
        return axios.get(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/users');
    },
    createUser(tenantUUID: string, userUUID: string): Promise<any> {
    
        const body = {
            data: {
                type: "users",
                id: userUUID,
                attributes: {
                },
            }
        } as any;

        return axios.post(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/users', JSON.stringify(body), { headers: { 'Content-Type': 'application/json'}});    
    },
    updateUser(tenantUUID: string, userUUID: string, tenantId: string): Promise<any> {
        const body = {
            data: {
                type: "users",
                id: userUUID,
                attributes: {
                    tenantId: tenantId,
                }
            }
        } as any;

        return axios.patch(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/users/' + userUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/json'}});    
    },
    deleteUser(tenantUUID: string, userUUID: string): Promise<any> {
        return axios.delete(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/users/'+ userUUID);
    },
    // user devices  
    getUserDeviceCollection(tenantUUID: string, userUUID: string): Promise<any> {
        return axios.get(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/users/'+ userUUID + '/devices');
    },
    addUserDevice(tenantUUID: string, userUUID: string, deviceUUID: string, permission: string): Promise<any> {
    
        const body = {
            data: {
                type: "devices",
                id: deviceUUID,
                attributes: {
                    permission: permission,
                },
            }
        } as any;

        return axios.post(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/users/'+ userUUID + '/devices', JSON.stringify(body), { headers: { 'Content-Type': 'application/json'}});  
    },
    updateUserDevice(tenantUUID: string, userUUID: string, deviceUUID: string, permission: string): Promise<any> {
        const body = {
            data: {
                type: "devices",
                id: deviceUUID,
                attributes: {
                    permission: permission,
                }
            }
        } as any;

        return axios.patch(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/users/'+ userUUID + '/devices/' + deviceUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/json'}});
    },
    removeUserDevice(tenantUUID: string, userUUID: string, deviceUUID: string): Promise<any> {
        return axios.delete(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/users/'+ userUUID + '/devices/' + deviceUUID);
    },
    // events
    getEvent(tenantUUID: string, eventUUID: string): Promise<any> {
        return axios.get(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/events/' + eventUUID);
    },  
    getEventCollection(tenantUUID: string, until: string, userUUID = ""): Promise<any> {
        // TODO paging etc.
        let requestURL = process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/events?&until=' + until;
                
        if(userUUID.length > 0) {
            requestURL = requestURL + '&userId=' + userUUID;
        }

        return axios.get(requestURL);
    },
    async getAllEventCollectionPages(tenantUUID: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let nextPageLink = null;
    
        do {
            let requestURL = process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/events?';

            //  Filters
            if('userId' in params && params.userId && params.userId.length > 0) {
                requestURL = requestURL + '&userId=' + encodeURIComponent(params.userId);
            }
            if('from' in params && params.from && params.from.length > 0) {
                requestURL = requestURL + '&from=' + params.from;
            }
            if('until' in params && params.until && params.until.length > 0) {
                requestURL = requestURL + '&until=' + params.until;
            }
            if('limitPerTask' in params && params.limitPerTask && params.limitPerTask.length > 0) {
                requestURL = requestURL + '&limitPerTask=' + encodeURIComponent(params.limitPerTask);
            }
            
            // Paging
            if('pageSize' in params && params.pageSize) {
                requestURL = requestURL + '&pageSize=' + encodeURIComponent(params.pageSize);
            }
            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + nextPageLink;
            }
            const response = await axios.get(requestURL);

            nextPageLink = response.data.links.next;

            requests.push(response);
            currentPage++;
        } while(nextPageLink)
    
        return Promise.all(requests);
    },
    updateEvent(tenantUUID: string, eventUUID: string, note: string): Promise<any> {
        const body = {
            data: {
                type: "events",
                id: eventUUID,
                attributes: {
                    note: note,
                }
            }
        } as any;

        return axios.patch(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/events/'+ eventUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/json'}});
    },
    doneEvent(tenantUUID: string, eventUUID: string): Promise<any> {
        return axios.post(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/events/' + eventUUID + '/done', JSON.stringify({}), { headers: { 'Content-Type': 'application/json'}});  
    },
    deleteEvent(tenantUUID: string, eventUUID: string): Promise<any> {
        return axios.delete(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/events/' + eventUUID);
    },
    // reports
    getReport(tenantUUID: string, reportUUID: string): Promise<any> {
        return axios.get(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/reports/'+ reportUUID);
    },
    async getAllReportCollectionPages(tenantUUID: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let nextPageLink = null;
    
        do {
            let requestURL = process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/reports?';

            // Paging
            if('pageSize' in params && params.pageSize) {
                requestURL = requestURL + '&pageSize=' + encodeURIComponent(params.pageSize);
            }
            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + nextPageLink;
            }
            const response = await axios.get(requestURL);

            nextPageLink = response.data.links.next;
            
            requests.push(response);
            currentPage++;
        } while(nextPageLink)
    
        return Promise.all(requests);
    },
    createReport(tenantUUID: string, rrule: string, orientByCalendarBounds: boolean, timespanValue: number, timespanUnit: string, config: any, note=""): Promise<any> {
    
        const body = {
            data: {
                type: "reports",
                attributes: {
                    rrule: rrule,
                    orientByCalendarBounds: orientByCalendarBounds,
                    timespanValue: timespanValue,
                    timespanUnit: timespanUnit,
                    config: config,
                    note: note
                },
            }
        } as any;

        return axios.post(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/reports', JSON.stringify(body), { headers: { 'Content-Type': 'application/json'}});
    },
    updateReport(tenantUUID: string, reportUUID: string, rrule="", orientByCalendarBounds=undefined, timespanValue=-1, timespanUnit="", config=undefined, note=""): Promise<any> {
        const body = {
            data: {
                type: "reports",
                id: reportUUID,
                attributes: {
                }
            }
        } as any;

        if(rrule.length > 0) {
            body.data.attributes.rrule = rrule;
        }
        if(config) {
            body.data.attributes.config = config;
        }
        if(orientByCalendarBounds !== undefined) {
            body.data.attributes.orientByCalendarBounds = orientByCalendarBounds;
        }
        if(timespanValue > 0) {
            body.data.attributes.timespanValue = timespanValue;
        }
        if(timespanUnit.length > 0) {
            body.data.attributes.timespanUnit = timespanUnit;
        }
        if(note.length > 0) {
            body.data.attributes.note = note;
        }
        // console.log(body);

        return axios.patch(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/reports/' + reportUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/json'}});
    },
    deleteReport(tenantUUID: string, reportUUID: string): Promise<any> {
        return axios.delete(process.env.VUE_APP_BASCLOUD_METERING_SERVER_URL + '/tenants/' + tenantUUID + '/reports/'+ reportUUID);
    },
}
  