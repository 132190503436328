
  import Vue from 'vue'
  import FilterChipTemplate from './FilterChipTemplate.vue';
  import DeviceService from '@/services/DeviceService';
  import { autoComplete } from '@/utils/Util';
  import ConnectorService from '@/services/ConnectorService';
  import PropertyService from '@/services/PropertyService';

  export default Vue.extend({
    name: 'DeviceFilter',

    components: {
      FilterChipTemplate
    },
    props: ['value', 'label', 'attributes', 'mandatory', 'always', 'multi', 'small'],
    data: (_this: any): any => ({
      rules: [
        (v: any) => (!!v && Object.keys(v).length > 0) || _this.requiredError
      ],
      localDevice: _this.value[_this.attributes['value']],
      localFilters: _this.value as any,
      menu: false,
      loading: false,
      devices: [],
      localConnectorId: undefined,
      localPropertyId: undefined,
      connectorAssociatedDevices: [],
      propertyAssociatedDevices: [],
    }),
    created() {
      this.getDevices();
    },
    methods: {
      onSave() {
        this.localFilters[this.attributes['value']] = this.localDevice;
        this.$emit('input', { ...this.localFilters });
        this.menu = false;
      },
      onReset() {
        delete this.localFilters[this.attributes['value']];
        this.$emit('input', { ...this.localFilters });
        this.menu = false;
      },
      onChipRemove() {
        delete this.localFilters[this.attributes['value']];
        this.$emit('input', { ...this.localFilters });
        this.$emit('delete');
      },      
      autoComplete,
      getDevices() {
        this.devices = [];
        this.loadingDevices = true;

        DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
          this.loadingDevices = false;

          this.devices = devices;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.loadingDevices = false;    
        });
      },
      getConnectorAssociatedDevices() {
        this.connectorAssociatedDevices = [];
        this.loading = true;

        ConnectorService.getAssociatedDevices(this.currentTenant, (this.localFilters.connector as any).uuid).then((devices: any[]) => {
          this.loading = false;
          this.connectorAssociatedDevices = devices;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.loading = false;    
        });
      },
      getPropertyAssociatedDevices() {
        this.propertyAssociatedDevices = [];
        this.loading = true;

        PropertyService.getAssociatedDevices(this.currentTenant, (this.localFilters.property as any).uuid).then((devices: any[]) => {
          this.loading = false;
          this.propertyAssociatedDevices = devices;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.loading = false;    
        });
      },
    },
    watch: {
      value() {
        this.localFilters = { ...this.value };

        // handle filtering of devices when property or connector filters are applied
        if(this.localFilters.connector && this.localFilters.connector.uuid !== this.localConnectorId) {
          this.localConnectorId = this.localFilters.connector.uuid;
          delete this.localFilters[this.attributes['value']];
          this.$emit('input', { ...this.localFilters });
          this.getConnectorAssociatedDevices();
        } else {
          this.connectorAssociatedDevices = [];
        }
        if(this.localFilters.property && this.localFilters.property.uuid !== this.localPropertyId) {
          this.localPropertyId = this.localFilters.property.uuid;
          delete this.localFilters[this.attributes['value']];
          this.$emit('input', { ...this.localFilters });
          this.getPropertyAssociatedDevices();
        } else {
          this.propertyAssociatedDevices = [];
        }
        
        this.localDevice = this.localFilters[this.attributes['value']];
      },
      menu() {
        if(!this.menu) {
          this.localDevice = this.localFilters[this.attributes['value']];
        }
      },
      currentTenant() {
        this.devices = [];
        this.propertyAssociatedDevices = [];
        this.connectorAssociatedDevices = [];
        this.localFilters = { ...this.value }; // TODO
        this.getDevices();
      }
    },
    computed: {
      filteredDevices(): any[] {
        if(!this.localFilters.connector && !this.localFilters.property) {
          return this.devices;
        } else if(this.localFilters.connector && this.localFilters.property) {
          return this.devices.filter((value: any) => this.connectorAssociatedDevices.findIndex((cd: any) => cd.uuid === value.uuid) >= 0 && this.propertyAssociatedDevices.findIndex((pd: any) => pd.uuid === value.uuid) >= 0);
        } else if(this.localFilters.connectory || this.localFilters.property) {
          return this.devices.filter((value: any) => this.connectorAssociatedDevices.findIndex((cd: any) => cd.uuid === value.uuid) >= 0 || this.propertyAssociatedDevices.findIndex((pd: any) => pd.uuid === value.uuid) >= 0);
        }

        return this.devices;
      },
      isValid(): any {
        return this.mandatory ? this.rules.reduce((acc: boolean, rule: any) => acc ? rule(this.localDevice)===true : acc, true) : true;
      },
      requiredError(): any {
        return this.$t('required');
      },
      filterRepr(): any {
        if(this.multi) {
          return this.localFilters[this.attributes['value']]?.map((value: any) => value.aksId).join(', ')
        }
        return this.localFilters[this.attributes['value']]?.aksId ?? '';
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
    }
  })
