
    import Vue from 'vue'

    import ExtendedMultiChart from '@/components/visualization/ExtendedMultiChart.vue';
    import DeviceInfoDialog from '@/components/entities/device/DeviceInfoDialog.vue';
    import ReadingService from '@/services/ReadingService';
    import DeviceService from '@/services/DeviceService';
    import { autoComplete } from '@/utils/Util';
    import TimeRangeFilter from '../filters/TimeRangeFilter.vue';
    import AggregationIntervalFilter from '../filters/AggregationIntervalFilter.vue';
    import SelectionFilter from '../filters/SelectionFilter.vue';
    import { ReadingAggregationFrequencies, ReadingAggregationFunctions } from '../entities/reading/ReadingCollections';
    import SimpleFiltersCard from '@/components/filters/SimpleFiltersCard.vue'
    import TagFilter from '../filters/TagFilter.vue';


    export default Vue.extend({
        name: 'AggregateMultiChartCard',

        components: {
            ExtendedMultiChart,
            DeviceInfoDialog,
            TimeRangeFilter,
            AggregationIntervalFilter,
            SelectionFilter,
            SimpleFiltersCard
        },
        props: ['config'],
        data: (_this: any): any => ({
            devices: [] as any[],
            loadingDevices: false,
            requiredSelect: [
                (v: any) => (!!v && Object.keys(v).length > 0) || _this.requiredError
            ],
            loading: false,
            downloadLoading: false,
            timeFunctions: ReadingAggregationFunctions,
            timeFrequencies: ReadingAggregationFrequencies,
            selection: [],
            selectionData: [] as any[],
            selectedAggregation: { aggregationInterval: 1,  aggregationFrequency: 'daily', from: null, until: null } as any,
            filterConfig: [
                // { attribute: 'reading_timestamp', always: false },
                { attribute: 'devices', always: true },
                { attribute: 'properties', always: true },
                { attribute: 'connectors', always: true },
                { attribute: 'device_tags', always: true },
                { attribute: 'property_tags', always: false },
                { attribute: 'connector_tags', always: false },
            ],
            extra_components: {
                // 'reading_timestamp': {
                //     component: TimeRangeFilter,
                //     attributes: { from: 'from', until: 'until' },
                //     label: _this.$t('entity.reading') + ' ' + _this.$t('attribute.timestamp')
                // },
                'property_tags': {
                    component: TagFilter,
                    attributes: { value: 'property_tags' },
                    props: { multi: true },
                    label: _this.$t('entity.property') + ' ' + _this.$t('entity.tags')
                },
                'device_tags': {
                    component: TagFilter,
                    attributes: { value: 'device_tags' },
                    props: { multi: true },
                    label: _this.$t('entity.device') + ' ' + _this.$t('entity.tags')
                },
                'connector_tags': {
                    component: TagFilter,
                    attributes: { value: 'connector_tags' },
                    props: { multi: true },
                    label: _this.$t('entity.connector') + ' ' + _this.$t('entity.tags')
                },
            }
        }),
        async created() {
            if(this.config) {
                this.selection = this.config.selection;
                this.selectedAggregation = this.config.aggregation;
            }
        },
        watch: {
            config(): any {
                this.selectionData = [];
                this.selection = this.config.selection;
                this.selectedAggregation = this.config.aggregation;
            },
            selectedAggregation(): any {
                this.getData();
                this.configUpdate();
            },
        },
        methods: {
            reset() {
                this.selection = [];
                this.selectionData = [];
                this.selectedAggregation = { aggregationInterval: 1,  aggregationFrequency: 'daily', from: null, until: null };
            },
            delay(ms: number) {
                return new Promise(res => setTimeout(res, ms));
            },
            onRefresh() {
                this.getData();
            },
            configUpdate() {
                this.$emit('update', { selection: this.selection, aggregation: this.selectedAggregation });
            },
            onDeleteAddFilter(index: number) {
                this.selection.splice(index, 1);
                this.selectionData.splice(index, 1);
            },
            onAddFilterGroup() {
                this.selection.push({ filters: [], function: { name: "Last", value: "last" }, selectedChartType: 'bar', selectedChartAxis: 'y', edit: true });
            },
            onSaveFilterGroup(index: number) {
                this.selection[index].edit = false; 
                this.getDeviceData(index);
                this.configUpdate();
            },
            onAddFilter(index: number) {
                this.selection[index].filters.push([]);
            },
            onRemoveFilter(groupIndex: number, index: number) {
                this.selection[groupIndex].filters.splice(index, 1);
            },
            onColorReset(item: any) {
                Vue.set(item, 'selectedColor', undefined);
            },
            autoComplete,
            getDevices() {
                this.devices = [];
                this.loadingDevices = true;

                DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
                    this.loadingDevices = false;

                    this.devices = devices;
                }).catch((err: any) => {
                    console.log(err, err.response);  
                    this.loadingDevices = false;    
                });
            },
            async getDeviceData(index: number) {
                
                this.loading = true;
                let aggregationFrom = this.selectedAggregation.from;
                let aggregationUntil = this.selectedAggregation.until;

                if(!aggregationFrom || !aggregationUntil) {
                    this.loading = false;
                    return;
                }

                let aggregationInterval = this.selectedAggregation.aggregationInterval;
                let aggregationFrequency = this.selectedAggregation.aggregationFrequency;
                let aggregationFunction = this.selection[index].function.value;

                let filters = [] as any[];
                // TODO construct filters
                for(const filter_selection of this.selection[index].filters) {
                    // we have 
                    // { attribute: 'reading_timestamp', always: false },   -> from, until
                    // { attribute: 'devices', always: true },              -> devices
                    // { attribute: 'properties', always: true },           -> properties
                    // { attribute: 'connectors', always: true },           -> connectors
                    // { attribute: 'property_tags', always: false },       -> property_tags
                    // { attribute: 'device_tags', always: true },          -> device_tags
                    // { attribute: 'connector_tags', always: false },      -> connector_tags

                    const filter = {
                        "reading": {},
                        "device": {},
                        "connector": {},
                        "property": {}
                    } as any;

                    if(aggregationFrom) {
                        filter['reading']['from'] = new Date(aggregationFrom).toISOString();
                    }
                    if(aggregationUntil) {
                        filter['reading']['until'] = new Date(aggregationUntil).toISOString();
                    }
                    // if('from' in filter_selection) {
                    //     filter['reading']['from'] = new Date(filter_selection.from).toISOString();
                    // }
                    // if('until' in filter_selection) {
                    //     filter['reading']['until'] = new Date(filter_selection.until).toISOString();
                    // }

                    if('devices' in filter_selection) {
                        filter['device']['ids'] = filter_selection.devices.map((value: any) => value.uuid);
                    }
                    if('device_tags' in filter_selection) {
                        filter['device']['tagIds'] = filter_selection.device_tags.map((value: any) => value.uuid);
                    }

                    if('properties' in filter_selection) {
                        filter['property']['ids'] = filter_selection.properties.map((value: any) => value.uuid);
                    }
                    if('property_tags' in filter_selection) {
                        filter['property']['tagIds'] = filter_selection.property_tags.map((value: any) => value.uuid);
                    }

                    if('connectors' in filter_selection) {
                        filter['connector']['ids'] = filter_selection.connectors.map((value: any) => value.uuid);
                    }

                    filters.push(filter);
                }
       
                try {
                    const data = await ReadingService.getAllGroupAggregationPages(this.currentTenant, filters, aggregationInterval, aggregationFrequency, aggregationFunction, { from: aggregationFrom, until: aggregationUntil });
                    // console.log(data);

                    if(index < this.selectionData.length) {
                        this.selectionData.splice(index, 1, { name: this.selection[index].name, data: data });
                    } else {
                        this.selectionData.push({ name: this.selection[index].name, data: data });
                    }
                } catch(error) {
                    console.log(error);
                }
                this.loading = false;
            },
            async getData() {
                for (let i = 0; i < this.selection.length; i++) {
                    await this.getDeviceData(i);
                }
            },
        },
        computed: {
            chartLabels(): any[] {
                return this.selectionData.map((value: any) => (value.name.length > 70 ? value.name.substring(0, 70)+'...' : value.name));
            },
            chartAxes(): any[] {
                return this.selection.map((value: any) => value.selectedChartAxis);
            },
            chartTypes(): any[] {
                return this.selection.map((value: any) => value.selectedChartType);
            },
            chartColors(): any[] {
                return this.selection.map((value: any) => value.selectedColor?.hexa);
            },
            chartData(): any[] {
                return this.selectionData.map((value: any) => value.data);
            },
            inEdit(): any {
                return this.selection.reduce((acc: boolean, value: any) => acc || value.edit, false);
            },
            requiredError(): any {
                return this.$t('required');
            },
            currentTenant(): any {
                return this.$root.$store.state.session.selectedTenant.uuid;
            },
            timeUnits(): any[] {
                return [
                {name: this.$t('timeUnit.minutes'), frequency: 'MINUTELY' },
                {name: this.$t('timeUnit.hours'), frequency: 'HOURLY'}, 
                {name: this.$t('timeUnit.days'), frequency: 'DAILY'},
                {name: this.$t('timeUnit.weeks'), frequency: 'WEEKLY'},
                {name: this.$t('timeUnit.months'), frequency: 'MONTHLY'},
                {name: this.$t('timeUnit.years'), frequency: 'YEARLY'}
            ]
            },
            timeRanges(): any[] {
                return [
                { value: "1h", label: "1 " + this.$t('timeUnit.hours'), interval: "1", frequency: "MINUTELY" },
                { value: "6h", label: "6 " + this.$t('timeUnit.hours'), interval: "15", frequency: "MINUTELY" },
                { value: "12h", label: "12 " + this.$t('timeUnit.hours'), interval: "15", frequency: "MINUTELY" },
                { value: "1d", label: "1 " + this.$t('timeUnit.day'), interval: "1", frequency: "HOURLY" },
                { value: "1w", label: "1 " + this.$t('timeUnit.week'), interval: "1", frequency: "DAILY" },
                { value: "1m", label: "1 " + this.$t('timeUnit.month'), interval: "1", frequency: "DAILY" },
                { value: "6m", label: "6 " + this.$t('timeUnit.months'), interval: "1", frequency: "WEEKLY" },
                { value: "1y", label: "1 " + this.$t('timeUnit.year'), interval: "1", frequency: "MONTHLY" },
                { value: "3y", label: "3 " + this.$t('timeUnit.years'), interval: "1", frequency: "MONTHLY" },
            ];
            }
        },
    })
