
  import Vue from 'vue'
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import { extractErrorMessage } from '@/utils/Util';
  import TenantInfoDialog from '../tenant/TenantInfoDialog.vue';
  import PropertyInfoDialog from '../property/PropertyInfoDialog.vue';
  import ConnectorInfoDialog from '../connector/ConnectorInfoDialog.vue';
  import IoTDeviceService from '@/services/IoTDeviceService';
  import DeviceInfoDialog from '../device/DeviceInfoDialog.vue';
  import IotDevicePortConfigDialog from './IotDevicePortConfigDialog.vue';
  import AlertsListDialog from '../alert/AlertsListDialog.vue';

  export default Vue.extend({
    name: 'IotDeviceInfoDialog',

    components: {
        EntityMetaDialog,
        TenantInfoDialog,
        PropertyInfoDialog,
        ConnectorInfoDialog,
        DeviceInfoDialog,
        IotDevicePortConfigDialog,
        AlertsListDialog
    },
    props: ['iotdevice'],
    data: () => ({
        localdialog: false,
        valid: true,
        passwordToggle: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        associatedTenant: {} as any,
        associatedProperty: {} as any,
        associatedConnector: {} as any,
        associatedMonitoringDevice: {} as any,
        associatedAlerts: [] as any[]
    }),
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedTenant = {};
        this.associatedProperty = {};
        this.associatedConnector = {};
        this.associatedMonitoringDevice = {};
        this.associatedAlerts = [];
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async getAssociatedTenant() {
        try {
            this.associatedTenant = await IoTDeviceService.getAssociatedTenant(this.iotdevice.uuid);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
        }
      },
      async getAssociatedProperty() {
        try {
            this.associatedProperty = await IoTDeviceService.getAssociatedProperty(this.iotdevice.uuid);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
        }
      },
      async getAssociatedConnector() {
        try {
            this.associatedConnector = await IoTDeviceService.getAssociatedConnector(this.iotdevice.uuid);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
        }
      },
      async getAssociatedMonitoringDevice() {
        try {
            this.associatedMonitoringDevice = await IoTDeviceService.getAssociatedMonitoringDevice(this.iotdevice.uuid);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
        }
      },
      async getAssociatedAlerts() {
        try {
            this.associatedAlerts = await IoTDeviceService.getAssociatedAlerts(this.iotdevice.uuid);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
        }
      },
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                // this.getAssociatedMonitoringDevice();
                this.getAssociatedAlerts();
                this.getAssociatedTenant();
                this.getAssociatedProperty();
                this.getAssociatedConnector();
            } else {
                this.clear();
            }
        }
    },
    computed: {
        hardwareComponents() {
            return this.iotdevice.hardware?.components ?? [];
        },
        activeLabel() {
            if(this.iotdevice.enabled)
                return this.$t('enabled');
            return this.$t('disabled');
        },
        monitoringLabel() {
            if(this.associatedAlerts.length > 0) {
                if(this.associatedAlerts.every((value: any) => value.paused)) {
                    return this.$t('paused');
                } else if(this.associatedAlerts.some((value: any) => value.active)) {
                    return this.$t('on');
                } else {
                    return this.$t('on');
                }
            } else {
                return this.$t('off');
            }
        },
        monitoringIcon() {
            if(this.associatedAlerts.length > 0) {
                if(this.associatedAlerts.every((value: any) => value.paused)) {
                    return 'mdi-bell-sleep-outline';
                } else if(this.associatedAlerts.some((value: any) => value.active)) {
                    return 'mdi-bell-ring-outline';
                } else {
                    return 'mdi-bell';
                }
            } else {
                return 'mdi-bell-off-outline';
            }
        },
        monitoringIconColor() {
            if(this.associatedAlerts.length > 0) {
                if(this.associatedAlerts.every((value: any) => value.paused)) {
                    return 'accent';
                } else if(this.associatedAlerts.some((value: any) => value.active)) {
                    return 'error';
                } else {
                    return 'accent';
                }
            } else {
                return 'accent';
            }
        }
    }
  })
