import axiosLimiter from '@/api/RateLimiter';

export default {
    getCollection(): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/alerts');
    },
    async getAllCollectionPages(params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/alerts?';

            //  Filters
            if('tenantId' in params && params.tenantId && params.tenantId.length > 0) {
                requestURL = requestURL + '&tenantId=' + encodeURIComponent(params.tenantId);
            }
            if('type' in params && params.type && params.type.length > 0) {
                requestURL = requestURL + '&type=' + encodeURIComponent(params.type);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(alertUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/alerts/' + alertUUID);
    },
    createAlert(entityId: string, entityType: string, recipients: string[], maxAge: number, paused?: boolean, tenantId?: string): Promise<any> {
        const body = {
            "data": {
                "type": "alerts",
                "attributes": {
                    "entityId": entityId,
                    "entityType": entityType,
                    "maxAge": maxAge,
                    "recipients": recipients
                }
            }
        } as any;

        if(paused !== undefined) {
            body.data.attributes.paused = paused;
        }

        if(tenantId !== undefined) {
            body.data.attributes.tenantId = tenantId;
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/alerts', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateAlert(alertUUID: string, recipients?: string[], maxAge?: number, paused?: boolean): Promise<any> {
    
        const body = {
            "data": {
                "id": alertUUID,
                "type": "alerts",
                "attributes": {
                }
            }
        } as any;

        if(recipients && recipients.length > 0) {
            body.data.attributes.recipients = recipients;
        }
        if(maxAge) {
            body.data.attributes.maxAge = maxAge;
        }
        if(paused !== undefined) {
            body.data.attributes.paused = paused;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/alerts/' + alertUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    deleteAlert(alertUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/alerts/' + alertUUID);
    },
}
  