import axios from 'axios';

export default {
    async getAllDashboardCollectionPages(tenantUUID: string, userUUID: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let nextPageLink = null;
    
        do {
            let requestURL = process.env.VUE_APP_BASCLOUD_DASHBOARD_SERVER_URL + '/tenants/' + tenantUUID + '/users/' + userUUID + '/dashboards?';
            // Paging
            if('pageSize' in params && params.pageSize) {
                requestURL = requestURL + '&pageSize=' + encodeURIComponent(params.pageSize);
            }
            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_DASHBOARD_SERVER_URL + nextPageLink;
            }
            const response = await axios.get(requestURL);

            nextPageLink = response.data.links.next;

            requests.push(response);
            currentPage++;
        } while(nextPageLink)
    
        return Promise.all(requests);
    },
    createDashboard(tenantUUID: string, userUUID: string, name: string, config: any): Promise<any> {
    
        const body = {
            data: {
                type: "dashboards",
                attributes: {
                    name: name,
                    config: config,
                },
            }
        } as any;

        return axios.post(process.env.VUE_APP_BASCLOUD_DASHBOARD_SERVER_URL + '/tenants/' + tenantUUID + '/users/' + userUUID + '/dashboards', JSON.stringify(body), { headers: { 'Content-Type': 'application/json'}});
    },
    updateDashboard(tenantUUID: string, userUUID: string, dashboardUUID: string, name="", config=undefined): Promise<any> {
        const body = {
            data: {
                type: "dashboards",
                id: dashboardUUID,
                attributes: {
                }
            }
        } as any;

        if(name.length > 0) {
            body.data.attributes.name = name;
        }
        if(config !== undefined) {
            body.data.attributes.config = config;
        }

        return axios.patch(process.env.VUE_APP_BASCLOUD_DASHBOARD_SERVER_URL + '/tenants/' + tenantUUID + '/users/' + userUUID + '/dashboards/' + dashboardUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/json'}});
    },
    deleteDashboard(tenantUUID: string, userUUID: string, dashboardUUID: string): Promise<any> {
        return axios.delete(process.env.VUE_APP_BASCLOUD_DASHBOARD_SERVER_URL + '/tenants/' + tenantUUID + '/users/' + userUUID + '/dashboards/' + dashboardUUID);
    }
}
  