import AuthApi from '@/api/AuthApi';

export default {
    async login(email: string, password: string): Promise<any> {

        const response = await AuthApi.login(email.trim(), password);

        if(response.data.data.type && response.data.data.type == 'accesstoken') {
            return { token: response.data.data.attributes.token, expiration: response.data.data.attributes.expires };
        } else {
            throw TypeError("Response not type accesstoken");
        }
    },
    async resetPassword(email: string): Promise<void> {
        await AuthApi.resetPassword(email);
    },
    async changePassword(userEmail: string, token: string, password: string): Promise<void> {
        await AuthApi.changePassword(userEmail, token, password);
    },
}
  