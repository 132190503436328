
  import Vue from 'vue'
  import Chart from '@/components/visualization/Chart.vue';

  export default Vue.extend({
    name: 'IotDeviceMonitoringGraphDialog',

    components: {
        Chart,
    },
    props: ['device', 'readings'],
    data: (vm: any): any => ({
        localdialog: false,
        selectedChartType: 'scatter',
        chartOptions: {
            barThickness: 1,
            elements: {
                point: {
                    pointStyle: 'cross',
                    radius: 3,
                    backgroundColor: '#3188e5',
                    borderColor: '#3188e5'
                },
                bar: {
                    backgroundColor: '#3188e5',
                    borderColor: '#3188e5'

                }
            },
            plugins: {
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'x',
                    },
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'x'
                    }
                },
            }
        }
    }),
    created() {
        //s 
    },
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
      },
      dismiss() {
        this.clear();
        this.close();
      }
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                //s
            } else {
                //s
            }
        }
    },
    computed: {
    }
  })
