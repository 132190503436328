
  import Vue from 'vue'

  import DeviceService from '@/services/DeviceService';
  import ReadingService from '@/services/ReadingService';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import { ReadingFlagTypes } from './ReadingCollections';

  export default Vue.extend({
    name: 'ReadingEditDialog',
    components: {
        EntityMetaDialog,
    },
    props: ['reading'],
    data: (vm: any): any => ({
        localdialog: false,
        readingFlagTypes: ReadingFlagTypes,
        localReading: {} as any,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingDevice: false,
        numberRule: [
            (v: string) => !!v || vm.requiredError,
            (v: string) => !isNaN(parseFloat(v)) || vm.numberError,
        ],
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        devices: [] as any[],
        associatedDevice: {} as any,
        updatedAssociatedDevice: {} as any,
    }),
    async created() {
        this.localReading = Object.assign({}, this.reading);
    },
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedDevice = {};
        this.updatedAssociatedDevice = {};
        this.devices = [];
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      save() {
        this.alertVisible = false;
        this.loading = true;

        let value = "";
        let flag;
        // let timestamp = "";
        let associatedDeviceUUID = "";

        if(this.localReading.value !== this.reading.value) {
            value = this.localReading.value;
        }
        // if(this.localReading.timestamp !== this.reading.timestamp) {
        //     timestamp = this.localReading.timestamp;
        // }
        if(this.localReading.flag !== this.reading.flag) {
            flag = this.localReading.flag;
        }
        if(this.updatedAssociatedDevice.uuid !== this.associatedDevice.uuid) {
            associatedDeviceUUID = this.updatedAssociatedDevice.uuid;
        }

        ReadingService.updateReading(this.currentTenant, this.reading.uuid, value, associatedDeviceUUID, flag).then((reading) => {

            this.alertType = "success";
            this.alertText = this.$t('reading.successUpdate') as string;
            this.alertVisible = true;

            this.localReading = reading;
            this.associatedDevice = this.updatedAssociatedDevice;
            
            this.$emit("update", reading);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('reading.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getAssociatedDevice() {
        this.loadingDevice = true;

        ReadingService.getAssociatedDevice(this.currentTenant, this.reading.uuid).then((device) => {
            this.loadingDevice = false;

            this.associatedDevice = device;
            this.updatedAssociatedDevice = this.associatedDevice;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('reading.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingDevice = false;
        });
      },
      getDevices() {
        this.devices = [];
        this.loading = true;

        if (!this.$root.$store.getters.loggedIn(Date.now())) {
            this.$router.push('/login');
        } else {

          DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
            this.loading = false;

            this.devices = devices;
          }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;  
            this.loading = false;    
          });
        }
      },
      autoComplete,
    },
    watch: {
        reading() {
            this.localReading = Object.assign({}, this.reading);
        },
        async localdialog(value: boolean) {
            if(value) {
                this.getAssociatedDevice();
                this.getDevices();
            } else {
                this.clear();
            }
        }
    },
    computed: {
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        numberError(): any {
            return this.$t('mustNumber');
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        }
    }
  })
