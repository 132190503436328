
  import Vue from 'vue'
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import TenantService from '@/services/TenantService';
import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'PriceInfoDialog',

    components: {
        EntityMetaDialog,
        // Weird import necessary due to circular dependency
        TenantInfoDialog: () => import('@/components/entities/tenant/TenantInfoDialog.vue') as any
    },
    props: ['price'],
    data: () => ({
        localdialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        associatedTenant: {} as any,
        loadingTenant: false,
    }),
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedTenant ={};
        this.alertVisible = false;
        this.loading = false;
        this.loadingTenant = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async loadAssociatedTenant() {
        this.loadingTenant = true;

        try {
            const tenant = await TenantService.getSingle(this.price.tenantId)
            this.loadingTenant = false;

            this.associatedTenant = tenant;
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('price.errorAssociatedTenant') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingTenant = false;
            this.associatedTenant = {};
        }
      },
      toCurrencyString(price: number) {
          return price ? price.toLocaleString(undefined, { style: 'currency', currency: 'EUR' }) : null;
      },
      discountPrice(discount: number) {
        return this.price.basePrice ? this.price.basePrice * (discount > 1 ? discount : (1-discount)) : null;
      }
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                if(this.price.tenantId) {
                    this.loadAssociatedTenant();
                }
            } else {
                this.clear();
            }
        }
    },
    computed: {
        hasTenant(): boolean {
            return Object.keys(this.associatedTenant).length > 0;
        },
    }
  })
