import axiosLimiter from '@/api/RateLimiter';

const farFutureDateISOString = new Date(9999, 1, 1).toISOString();

export default {
    getCollection(): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants');
    },
    async getAllCollectionPages(params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants?';

            if('name' in params && params.name && params.name.length > 0) {
                requestURL = requestURL + '&name=' + encodeURIComponent(params.name);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }
            if('deleted' in params && params.deleted) {
                requestURL = requestURL + '&deletedUntil=' + encodeURIComponent(farFutureDateISOString);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(tenantUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID);
    },
    createTenant(name: string, uniqueAksId?: boolean): Promise<any> {
               
        const body = {
            data: {
                type: "tenants",
                attributes: {
                    name: name
                }
            }
        } as any;

        if(uniqueAksId !== undefined) {
            body.data.attributes['uniqueAksId'] = uniqueAksId;
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateTenant(tenantUUID: string, name = "", uniqueAksId?: boolean): Promise<any> {
        
        const body = {
            data: {
                type: "tenants",
                id: tenantUUID,
                attributes: {
                }
            }
        } as any;

        if(name.length > 0) {
            body.data.attributes.name = name;
        }

        if(uniqueAksId !== undefined) {
            body.data.attributes['uniqueAksId'] = uniqueAksId;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    deleteTenant(tenantUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID);
    },
    restoreTenant(tenantUUID: string): Promise<any> {

        const body = {
            data: {
                type: "tenants",
                id: tenantUUID
            }
        } as any;

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID,  JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    getAssociatedUsers(tenantUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/users');
    },
    removeUser(tenantUUID: string, userUUID: string): Promise<any> {
        
        const body = {
            data: [
                {
                    type: "users",
                    id: userUUID
                }
            ]
        } as any;

        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID +  '/relationships/users', { headers: {'Content-Type': 'application/vnd.api+json'}, data: JSON.stringify(body)});
    },
    addUser(tenantUUID: string, userUUID: string, role: string): Promise<any> {
        
        const body = {
            data: [
                {
                    type: "users",
                    id: userUUID,
                    role: role
                }
            ]
        } as any;

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID +  '/relationships/users' , JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
}
  