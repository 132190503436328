
import Vue from 'vue'
import { autoComplete, extractErrorMessage } from '@/utils/Util';
import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
import IoTDeviceService from '@/services/IoTDeviceService';
  import IotDeviceDisableDialog from './IotDeviceDisableDialog.vue';
import DeviceInfoDialog from '../device/DeviceInfoDialog.vue';
import PropertyService from '@/services/PropertyService';
import ConnectorService from '@/services/ConnectorService';


export default Vue.extend({
  name: 'IotDeviceCreateDialog',

  props: [],
  components: {
      EntityMetaDialog,
      IotDeviceDisableDialog,
      DeviceInfoDialog,
  },
  data: (vm: any): any => ({
      localdialog: false,
      valid: true,
      passwordToggle: false,
      alertText: "",
      alertVisible: false,
      alertType: "error",
      loading: false,
      loadingTenant: false,
      requiredRule: [
          (v: string) => !!v || vm.requiredError
      ],
      requiredSelect: [
        (v: any) => !!v && Object.keys(v).length > 0 || vm.requiredError
      ],
      iotdevice: {
        serialnumber: "",
        type: "",
        description: "",
        connectivity: "",
        mqttPassword: "",
        hardware: {
          components: [],
          version: ""
        },
        config: {
          rate: 900,
          timeout: 30,
          mbus: {
            baudrate: 2400,
            timeout: 20000
          }
        }
      } as any,
      hardwareComponents: [
        'base',
        'display',
        'mbus',
        'gsm',
        'ir-multiplexer'
      ],
      connectivityMethods: [
        'ETH',
        'WIFI',
        'GSM',
      ],
      iotDeviceTypes: [
        'E32',
        'P42',
        'OTHER',
      ],
      loadingProperties: false,
      loadingConnectors: false,
      connectors: [],
      properties: [],
      associatedTenant: null as any,
      associatedProperty: null as any,
      associatedConnector: null as any,
  }),
  async created() {
    // s
  },
  methods: {
    onConfigUpdate(config: any) {
      this.iotdevice.config = config;
    },
    close() {
      this.localdialog = false;
    },
    clear() {
      this.iotdevice = {
        serialnumber: "",
        type: "",
        description: "",
        connectivity: "",
        mqttPassword: "",
        hardware: {
          components: []
        },
        config: {
          rate: 900,
          timeout: 30,
          mbus: {
            baudrate: 2400,
            timeout: 20000
          }
        }
      };
      this.associatedTenant = null;
      this.associatedProperty = null;
      this.associatedConnector = null;
      this.alertVisible = false;
      this.loading = false;
    },
    dismiss() {
      this.clear();
      this.close();
    },
    validate() {
      if(this.createForm.validate()) {
        this.create();
      }
    },
    create() {
      this.alertVisible = false;
      this.loading = true;

      if(this.iotdevice.type !== 'E32') {
        delete this.iotdevice.config.mbus;
      }

      let associatedTenantUUID;
      let associatedPropertyUUID;
      let associatedConnectorUUID;

      if(this.associatedTenant && Object.keys(this.associatedTenant).length > 0) {
          associatedTenantUUID = this.associatedTenant.uuid;
      }  
      if(this.associatedProperty && Object.keys(this.associatedProperty).length > 0) {
          associatedPropertyUUID = this.associatedProperty.uuid;
      }        
      if(this.associatedConnector && Object.keys(this.associatedConnector).length > 0) {
          associatedConnectorUUID = this.associatedConnector.uuid;
      }

      IoTDeviceService.createIoTDevice(this.iotdevice.serialnumber, this.iotdevice.type, this.iotdevice.description, this.iotdevice.connectivity, this.iotdevice.config, this.iotdevice.hardware, this.iotdevice.mqttPassword, associatedTenantUUID, associatedPropertyUUID, associatedConnectorUUID).then((iotdevice: any) => {
          this.alertType = "success";
          this.alertText = this.$t('iotdevice.successCreate') as string;
          this.alertVisible = true;

          this.$emit("success", iotdevice);
          setTimeout(() => { 
              this.dismiss(); 
          }, 1500);
      }).catch((err) => {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('iotdevice.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
      });
    },
    getProperties() {
      if(!this.associatedTenant)
        return

      this.properties = [];
      this.loadingProperties = true;
      
      PropertyService.getAllCollectionPages(this.associatedTenant.uuid).then((properties: any[]) => {
          this.loadingProperties = false;

          this.properties = properties;
      }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('property.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loadingProperties = false;     
      });
    },
    getConnectors() {
      if(!this.associatedTenant)
        return

      this.connectors = [];
      this.loadingConnectors = true;

      ConnectorService.getAllCollectionPages(this.associatedTenant.uuid).then((connectors: any[]) => {
        this.loadingConnectors = false;

        this.connectors = connectors;
      }).catch((err: any) => {
        console.log(err, err.response);  
        this.alertType = "error";
        this.alertText = this.$t('connector.loadingError') + ' ' + extractErrorMessage(err);
        this.alertVisible = true;  
        this.loadingConnectors = false;    
      });
    }, 
    autoComplete,
  },
  watch: {
      associatedTenant() {
          this.associatedConnector = undefined;
          this.associatedProperty = undefined;
          this.getProperties();
          this.getConnectors();
      },
      iotdevice() {
          this.localIotdevice = JSON.parse(JSON.stringify(this.iotdevice));
      },
      async localdialog(value: boolean) {
          if(value) {
              this.getProperties();
              this.getConnectors();
          } else {
              this.clear(); 
          }
      }
  },
  computed: {
    createForm(): any {
          return this.$refs.createForm;
      },
      requiredError(): any {
          return this.$t('required');
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      activeLabel() {
          if(this.iotdevice.enabled)
              return this.$t('enabled');
          return this.$t('disabled');
      },
      monitoringLabel() {
          if(this.associatedMonitoringAlerts.length > 0)
              return this.$t('on');
          return this.$t('off');
      },
  }
})
