
  import Vue from 'vue'
  import TasksTable from '@/components/services/task/TasksTable.vue'
  import TaskCreateDialog from '@/components/services/task/TaskCreateDialog.vue'

  import MeteringService from '@/services/MeteringService';
  import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'TasksView',

    components: {
      TasksTable,
      TaskCreateDialog
    },
    data: () => ({
      tasks: [] as any,
      devices: [] as any,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      params: {
        pageSize: 1000,
      } as any
    }),
    async created() {
      this.resetParams();
      this.updateTasks();
    },
    watch: {
      async currentTenant() {
        this.resetParams();
        this.updateTasks();
      },
    },
    methods: {
      onTaskCreation(item: any) {
        this.tasks.push(item);
      },
      resetParams() {
        this.params = {
          pageSize: 1000
        };
      },
      async onTableReload() {
        this.updateTasks();
      },
      onTaskUpdate(item: any) {
        this.tasks.splice(this.tasks.findIndex((prop: any) => prop.uuid === item.uuid), 1);
        this.tasks.push(item);
      },
      onTaskDelete(item: any) {
        this.tasks.splice(this.tasks.findIndex((prop: any) => prop.uuid === item.uuid), 1);
      },
      updateTasks() {
        this.tasks = [];
        this.dataLoaded = false;
        
        if (!this.$root.$store.getters.loggedIn(Date.now())) {
            this.$router.push('/login');
        } else {
          MeteringService.getAllTaskCollectionPages(this.currentTenant, this.params).then((tasks: any[]) => {
            // console.log('TASKS: ', tasks.length);
            this.dataLoaded = true;

            this.tasks = tasks;
          }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('task.loadingError') + ' ' + extractErrorMessage(err);
            this.dataError = true;      
          });
          
        }
      }
    },
    computed: {
      isAdmin(): any {
          return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
              && (this.$root.$store.state.session.permissions.role === 'superadmin' || 
              this.$root.$store.state.session.permissions.role === 'admin');
      }, 
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      }
    }
  })
