
  import Vue from 'vue'
import { extractErrorMessage } from '@/utils/Util';
import DeviceService from '@/services/DeviceService';

  export default Vue.extend({
    name: 'DeviceAlertDeleteDialog',
    props: ['device', 'alert'],
    data: () => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
    }),
    methods: {
      close() {
        this.localDialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      confirmDelete() {
        this.loading = true;
      
        DeviceService.removeAlert(this.$root.$store.state.session.selectedTenant.uuid, this.device.uuid, this.alert.uuid).then(() => {
            this.alertType = "success";
            this.alertText = this.$t('alert.successDelete') as string;
            this.alertVisible = true;
            this.loading = false;

            setTimeout(() => { 
                this.$emit("delete", this.alert);
                this.dismiss(); 
            }, 2000);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('alert.errorDelete') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      }
    }
  })
