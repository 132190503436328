
  import Vue from 'vue'
  import { extractErrorMessage, RRuleToText } from '@/utils/Util';
  import DeviceService from '@/services/DeviceService';
  import MeteringService from '@/services/MeteringService';

  import PropertyInfoDialog from '@/components/entities/property/PropertyInfoDialog.vue';
  import ConnectorInfoDialog from '@/components/entities/connector/ConnectorInfoDialog.vue';
  import TaskInfoDialog from '@/components/services/task/TaskInfoDialog.vue';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import QRCard from '@/components/misc/QRCard.vue';
import DeviceTypeService from '@/services/DeviceTypeService';
import PriceService from '@/services/PriceService';
import DeviceComputationService from '@/services/DeviceComputationService';
import AlertsListDialog from '@/components/entities/alert/AlertsListDialog.vue';

  export default Vue.extend({
    name: 'DeviceInfoDialog',

    components: {
      PropertyInfoDialog,
      ConnectorInfoDialog,
      TaskInfoDialog,
      EntityMetaDialog,
      QRCard,
      AlertsListDialog,
      IotDeviceInfoDialog: () => import('@/components/entities/iotdevice/IotDeviceInfoDialog.vue') as any
    },
    props: {
        'device': {
            type: Object,
            required: true
        }, 
        'external': {
            type: Boolean,
            default: false
        },
        'tenant': {
            type: Object,
            required: false
        },
        'tenantId': {
            type: String,
            required: false
        }
    },
    data: (vm: any): any => ({
        localdialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingReadings: false,
        loadingSetpoints: false,
        loadingProperty: false,
        loadingConnector: false,
        loadingTask: false,
        loadingDeviceType: false,
        loadingPrice: false,
        loadingComputation: false,
        associatedTask: {} as any,
        associatedTaskStatus: 200,
        associatedProperty: {} as any,
        associatedConnector: {} as any,
        associatedComputation: null,
        associatedSourceDevices: null,
        associatedIotdevices: [],
        numberOfReadings: null,
        numberOfSetPoints: null,
        deviceTypes: [],
        devicePrice: null,
        numberOfTypeDevices: 1,
        associatedTags: [] as any[],
        readingValidationRules: [
            {name: 'Greater than previous', value: 'greater_previous' },
            {name: 'Greater than zero', value: 'greater_zero' },
            {name: 'Greater than or equal to previous', value: 'greater_equal_previous' },
            {name: 'Greater than or equal to zero', value: 'greater_equal_zero' },
            {name: 'Outlier Detection', value: 'outlier_detection' },
        ],
        readingValidationActions: [
            {name: 'Quarantine', value: 'quarantine' },
            {name: 'Notify', value: 'notify' },
            {name: 'Discard', value: 'discard' },
            {name: 'Do nothing', value: 'nothing' },
        ],
        associatedAlerts: [] as any[],
    }),
    methods: {
      openAssociatedReadings() {
          this.$router.push({ name: 'Readings', params: { device: this.device }, query: { 'forwarding': this.device.uuid }, });
          this.$router.replace({ name: 'Readings', params: { device: this.device }, query: {}, });
      },
      openAssociatedSetpoints() {
          this.$router.push({ name: 'Setpoints', params: { device: this.device }, query: { 'forwarding': this.device.uuid }, });
          this.$router.replace({ name: 'Setpoints', params: { device: this.device }, query: {}, });
      },
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedAlerts = [];
        this.associatedTask = {};
        this.associatedComputation = null;
        this.associatedSourceDevices = null;
        this.numberOfReadings = null;
        this.numberOfSetPoints = null;
        this.alertVisible = false;
        this.loading = false;
        this.deviceTypes = [];
        this.devicePrice = null;
        this.numberOfTypeDevices = 1;
        this.associatedTags = [];
        this.associatedIotdevices = [];
      },
      dismiss() {
        this.clear();
        this.close();
      },
      typeColor(type: string) {
        if(type=== 'SHORT')
            return 'secondary-lighten'
        else if(type=== 'MIDDLE')
            return 'secondary-light'
        else if(type=== 'LONG')
            return 'secondary'
        else if(type=== 'EVENT')
            return 'primary-lighte'
        else if(type=== 'PAYPERUSE')
            return 'primary'
        else if(type==='PASSTHROUGH')
            return 'accent'
      },
      getAssociatedDeviceType() {
        this.loadingDeviceType = true;
        this.deviceTypes = [];

        let params = {} as any;

        params.type = this.device.type;

        DeviceTypeService.getAllCollectionPages(params).then((devicetypes: any[]) => {
            this.loadingDeviceType = false;
            this.deviceTypes = devicetypes;
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('price.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;     
            this.loadingDeviceType = false; 
        });
      },
      getAssociatedTags() {
        this.loading = true;

        DeviceService.getAssociatedTags(this.currentTenant, this.device.uuid).then((tags: any[]) => {
            this.associatedTags = tags;
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('property.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getAssociatedAlerts() {
        this.loading = true;

        DeviceService.getAssociatedAlerts(this.currentTenant, this.device.uuid).then((alerts: any[]) => {
            this.associatedAlerts = alerts;
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('alert.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getNumberOfTypeDevices() {
          this.loadingPrice = true;

          let params = { type: this.device.type, itemsPerPage: 1 };

          DeviceService.getCount(this.currentTenant, params).then((count: number) => {
            this.loadingPrice = false;

            if(count) {
                this.numberOfTypeDevices = count;
            }
          }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;     
            this.loadingPrice = false; 
          });
      },
      getAssociatedPrice() {
        this.loadingPrice = true;

        let params = {} as any;

        params.type = 'devices';
        params.subtype = this.device.type;
        params.tenantId = this.currentTenant;

        PriceService.getAllCollectionPages(params).then((prices: any) => {
            this.loadingPrice = false;

            if(prices.length > 0) {
                this.devicePrice = prices[0];
            }
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('price.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;    
            this.loadingPrice = false;  
        });
      },
      getAssociatedProperty() {
        this.loadingProperty = true;

        DeviceService.getAssociatedProperty(this.currentTenant, this.device.uuid).then((property: any) => {
            this.loadingProperty = false;

            this.associatedProperty = property;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('device.errorAssociatedProperty') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingProperty = false;
        });
      },
      getAssociatedConnector() {
        this.loadingConnector = true;

        DeviceService.getAssociatedConnector(this.currentTenant, this.device.uuid).then((connector: any) => {
            this.loadingConnector = false;

            this.associatedConnector = connector;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
                this.alertText = this.$t('device.emptyAssociatedConnector') as string;
            this.alertVisible = true; 
            this.loadingConnector = false;
        });
      },
      async getAssociatedComputation() {
        this.loadingComputation = true;

        try {
            const computations = await DeviceComputationService.getAllCollectionPages(this.currentTenant, { targetDeviceId: this.device.uuid});
            this.loadingComputation = false;
            if(computations.length > 0) {
                this.associatedComputation = computations[0];
                this.associatedSourceDevices = await DeviceComputationService.getSourceDevices(this.currentTenant, this.associatedComputation.uuid);
            }
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.loadingComputation = false;
        }
      },
      getAssociatedTask() {
        this.loadingTask = true;
        
        MeteringService.getDeviceTask(this.currentTenant, this.device.uuid).then((task) => {
            this.loadingTask = false;

            this.associatedTask = task;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            if(err.response && err.response.status === 404) {
                this.associatedTaskStatus = 404;
            } else if(err.response && err.response.status === 403) {
                this.associatedTaskStatus = 403;
            }
            this.loadingTask = false;
        });
      },
      getAssociatedReadings() {
        this.loadingReadings = true;

        DeviceService.getAssociatedReadingsCount(this.currentTenant, this.device.uuid).then((count: number) => {
            this.loadingReadings = false;

            this.numberOfReadings = count;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('device.errorAssociatedReadings') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingReadings = false;
        });
      },
      getAssociatedSetPoints() {
        this.loadingSetpoints = true;

        DeviceService.getAssociatedSetPointsCount(this.currentTenant, this.device.uuid).then((count: number) => {
            this.loadingSetpoints = false;

            this.numberOfSetPoints = count;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('device.errorAssociatedSetpoints') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getAssociatedIotDevice() {
        this.loadingIotDevice = true;

        DeviceService.getAssociatedIoTDevices(this.currentTenant, this.device.uuid).then((iotdevices: any) => {
            this.loadingIotDevice = false;

            this.associatedIotdevices = iotdevices;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.loadingIotDevice = false;
            this.associatedIotdevices = [];
        });
      },
      findDiscount(index: number) {
        let discountRange;
        if(this.devicePrice && this.devicePrice.discountRange) {
            for(let j=0; j<this.devicePrice.discountRange.length; j++) {
                const dis = this.devicePrice.discountRange[j];
                const lastDis = j>0 ? this.devicePrice.discountRange[j-1] : null;

                if(dis.start!==null && dis.start <= index && ( (dis.end!==null && dis.end >= index) || (dis.end==null && lastDis && lastDis.end!==null && lastDis.end < index) ) ) {
                discountRange = this.devicePrice.discountRange[j];
                }
            }
        }
        return discountRange;
      },
      toCurrencyString(price: number) {
          return price ? price.toLocaleString(undefined, { style: 'currency', currency: 'EUR' }) : null;
      },
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                if(!this.external) {
                    this.getNumberOfTypeDevices();
                    this.getAssociatedPrice();
                }
                this.getAssociatedDeviceType();
                this.getAssociatedTags();
                this.getAssociatedAlerts();

                if(!this.device.deletedAt && !this.external) {
                    this.getAssociatedReadings();
                    this.getAssociatedSetPoints();
                    this.getAssociatedProperty();
                    this.getAssociatedConnector();
                    this.getAssociatedTask();
                    this.getAssociatedComputation();
                    this.getAssociatedIotDevice();
                }
            }
        }
    },
    computed: {
        timeUnitLabel(): any {
            if(this.device.frequency === 'MINUTELY') {
                return this.$t('timeUnit.minutes');
            } else if(this.device.frequency === 'HOURLY') {
                return this.$t('timeUnit.hours');
            } else if(this.device.frequency === 'DAILY') {
                return this.$t('timeUnit.days');
            } else if(this.device.frequency === 'WEEKLY') {
                return this.$t('timeUnit.weeks');
            } else if(this.device.frequency === 'MONTHLY') {
                return this.$t('timeUnit.months');
            } else if(this.device.frequency === 'YEARLY') {
                return this.$t('timeUnit.years');
            }
            return null;
        },
        aggregationLabel(): any {

            if(this.device.aggregation === 'FIRST') {
                return this.$t('aggregation.first');
            } else if(this.device.aggregation === 'LAST') {
                return this.$t('aggregation.last');
            } else if(this.device.aggregation === 'MIN') {
                return this.$t('aggregation.min');
            } else if(this.device.aggregation === 'MAX') {
                return this.$t('aggregation.max');
            } else if(this.device.aggregation === 'AVG') {
                return this.$t('aggregation.avg');
            } else if(this.device.aggregation === 'MEDIAN') {
                return this.$t('aggregation.med');
            }
            return null;
        },
        calculatedDevicePriceRange(): any {
            if(this.devicePrice) {
                // construct range from 0 discount to possible max. discount 
                // (based on number of devices from this type)
                let discountedPrice = this.devicePrice.basePrice;
                const discount = this.findDiscount(this.numberOfTypeDevices);
                if(discount) {
                    discountedPrice = discountedPrice * (1-discount.discount);
                }

                if(this.devicePrice.basePrice === discountedPrice)
                    return this.toCurrencyString(this.devicePrice.basePrice);

                return this.devicePrice.basePrice > discountedPrice ? this.toCurrencyString(discountedPrice) + ' - ' + this.toCurrencyString(this.devicePrice.basePrice) :
                    this.toCurrencyString(this.devicePrice.basePrice) + ' - ' + this.toCurrencyString(discountedPrice);
            }
            return null;
        },
        retentionTime(): any {
          const ret = this.deviceTypes.find((element: any) => element.type === this.device.type);

          return ret ? ret.retention / 86400000 : 0;
        },
        emptyTask(): any {
            return Object.keys(this.associatedTask).length === 0;
        },
        associatedTaskText(): any {
            return this.rruleText || (this.associatedTaskStatus === 403 ? this.$t('task.noPermission') : this.$t('task.noTask'));
        },
        rruleText(): any {
            if(Object.keys(this.associatedTask).length > 0) {
                const text = RRuleToText(this.associatedTask.rrule, this.$root.$store.state.config.language);
                return text.charAt(0).toUpperCase()+text.slice(1);
            } else {
                return "";
            }
        },
        currentTenant(): any {
            if(this.tenant)
                return this.tenant.uuid;

            if(this.tenantId)
                return this.tenantId

            return this.$root.$store.state.session.selectedTenant.uuid;
        },
        alertingLabel(): string {
            return this.device.alerting ? this.$t('enabled') : this.$t('disabled');
        },
        absoluteLabel(): string {
            return this.device.isRelative ? this.$t('relative') : this.$t('absolute');
        }
    }
  })
