
  import MeteringService from '@/services/MeteringService';
import { extractErrorMessage, filterItem } from '@/utils/Util';
import Vue from 'vue'

  export default Vue.extend({
    name: 'UserMeteringDevicesEditDialog',

    props: ['user', 'tenant', "devices", 'value'],
    components: {
    },
    data: (vm: any): any => ({
        localdialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loadingUpdate: false,
        loadingDevices: false,
        search: "",
        numberOfEntities: 0,
        updatedAssociatedDevices: [] as any[],
        showOnlySelected: false,
        allSelected: false,
        partialSelected: false,
        permissionOptions: [
            {value: 'r'},
            {value: 'rw'},
        ]
    }),
    async created() {
        // s
    },
    methods: {
      onSelectAll(props: any) {
          let filteredItems = this.devices;
          if(this.$refs.dataTable.$children[0].filteredItems) {
            filteredItems = this.$refs.dataTable.$children[0].filteredItems;
          }

          this.updatedAssociatedDevices = props.value ? JSON.parse(JSON.stringify(filteredItems)) : [];
      },
      close() {
        this.localdialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
        this.updatedAssociatedDevices = [];
        this.selectedGroups = [];
        this.selectedDevices = [];
        this.numberOfEntities = 0;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async save() {
        this.loadingUpdate = true;
       
        // check which devices changes
        let addedDevices = this.updatedAssociatedDevices.filter((value: any) => this.value.findIndex((dev: any) => dev.uuid === value.uuid) < 0); // new devices
        let oldDevices = this.updatedAssociatedDevices.filter((value: any) => this.value.findIndex((dev: any) => dev.uuid === value.uuid) >= 0);
        let updatedDevices = oldDevices.filter((value: any) => this.value.findIndex((dev: any) => dev.uuid === value.uuid && dev.permission !== value.permission) >= 0);
        let removedDevices = this.value.filter((value: any) => this.updatedAssociatedDevices.findIndex((dev: any) => dev.uuid === value.uuid) < 0);  // removed devices

        try {
          // addedDevices.forEach(async (element: any) => {
          for(const element of addedDevices) {
            await MeteringService.addUserDevice(this.tenant.uuid, this.user.uuid, element.uuid, element.permission);
          }
          // removedDevices.forEach(async (element: any) => {
          for(const element of removedDevices) {
            await MeteringService.removeUserDevice(this.tenant.uuid, this.user.uuid, element.uuid);
          }
          // updatedDevices.forEach(async (element: any) => {
          for(const element of updatedDevices) {
            await MeteringService.updateUserDevice(this.tenant.uuid, this.user.uuid, element.uuid, element.permission);
          }

          this.alertType = "success";
          this.alertText = this.$t('user.successUpdate');
          this.alertVisible = true;

          this.$emit("input", this.updatedAssociatedDevices);
          setTimeout(() => { 
              this.dismiss(); 
          }, 1500);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('user.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingUpdate = false;
        }
      },
      getPermission(item: any): any {
        let tmp = this.updatedAssociatedDevices.find((value: any) => value.uuid === item.uuid);
        return tmp.permission;
      },
      setPermission(item: any, perm: string) {
        let tmp = this.updatedAssociatedDevices.find((value: any) => value.uuid === item.uuid);
        tmp.permission = perm;
      },
      setAllPermission(perm: string) {
        this.updatedAssociatedDevices = this.updatedAssociatedDevices.map((value: any) => {
          value.permission = perm;
          return value;
        })
      },
      getPermissionLabel(perm: string): string {
          if(perm === 'r')
            return this.$t('permission.read');
          else if(perm === 'rw')
            return this.$t('permission.write')
          else 
            return perm;
      },
      getPermissionColor(perm: string): any {
          if(perm === 'r')
            return 'secondary';
          else if(perm === 'rw')
            return 'accent'
          else 
            return undefined;
      },
      isSelected(item: any) {
          return this.updatedAssociatedDevices.findIndex((value: any) => value.uuid === item.uuid) >= 0;
      },
      onPaginationAndFilter(pagination: any) {
        this.numberOfEntities = pagination.itemsLength;
      },
    },
    watch: {
        updatedAssociatedDevices(value: any[]) {
            if(value.length === this.devices.length) {
                this.allSelected = true;
                this.partialSelected = false;
            } else if(value.length > 0) {
                this.allSelected = false;
                this.partialSelected = true;
            } else {
                this.allSelected = false;
                this.partialSelected = false;
            }
        },
        async localdialog(value: boolean) {
            if(value) {
                this.updatedAssociatedDevices = JSON.parse(JSON.stringify(this.value));
            } else {
                this.clear();
            }
        }
    },
    computed: {
      extendedAssociatedMeteringDevices(): any[] {
        let result = [];

        for(const mdev of this.value) {
          let dev = this.devices.find((value: any) => value.uuid === mdev.uuid)
          if(dev) {
            dev['permission'] = mdev.permission;
            result.push(dev);
          } else {
            mdev['description'] = mdev.uuid;
            mdev['aksId'] = mdev.uuid;
            result.push(mdev)
          }
        }
        return result;
      },
      computedTableHeader(): any {
        return [
            { text: this.$t('attribute.aksId'), value: 'aksId'},
            // { text: 'Local AKS ID', value: 'localAksId' },
            { text: this.$t('attribute.description'), value: 'description' },
            { text: this.$t('attribute.unit'), value: 'unit', width:'56px'},
            { text: this.$t('attribute.permission'), value: 'permission', sortable: false, filterable: false },
        ];
      },
      isRoot(): any {
          return this.userPermissions && 'role' in (this.userPermissions as any)
              && (this.userPermissions as any).role === 'superadmin';
      },
    },
  })
