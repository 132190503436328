


export const TagTypes = [
    { name: "Electricity", value: "electricity" },
    { name: "Generated Electricity", value: "electricity_return" },
    { name: "Heating", value: "heat" },
    { name: "Cooling", value: "cool" },
    { name: "Cold Water", value: "cold_water" },
    { name: "Warm Water", value: "warm_water" },
    { name: "Gas", value: "gas" },
    { name: "Air", value: "air" },
    { name: "Waste", value: "waste" },
    { name: "Sensor", value: "sensor" },
    { name: "Alert", value: "alert" },
    { name: "Property", value: "property" },
    { name: "Connector", value: "connector" },
    { name: "General", value: "general" },
];