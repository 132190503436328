
  import Vue from 'vue'
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import { TagTypes } from './TagTypes';

  export default Vue.extend({
    name: 'TagInfoDialog',

    components: {
        EntityMetaDialog,
    },
    props: ['tag'],
    data: () => ({
        localdialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        associatedDeviceFilter: "",
        associatedDevices: [] as any[],
        associatedTags: [] as any[],
        tagTypes: TagTypes,
    }),
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedDevices = [];
        this.alertVisible = false;
        this.associatedTags = [];
        this.loading = false;
        this.associatedDeviceFilter = "";
      },
      dismiss() {
        this.clear();
        this.close();
      },
      autoComplete,
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                //
            } else {
                this.clear();
            }
        }
    },
    computed: {
    }
  })
