import { render, staticRenderFns } from "./DeviceTypesTable.vue?vue&type=template&id=39807c00&scoped=true&"
import script from "./DeviceTypesTable.vue?vue&type=script&lang=ts&"
export * from "./DeviceTypesTable.vue?vue&type=script&lang=ts&"
import style0 from "./DeviceTypesTable.vue?vue&type=style&index=0&id=39807c00&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39807c00",
  null
  
)

export default component.exports