import axiosLimiter from '@/api/RateLimiter';

const farFutureDateISOString = new Date(9999, 1, 1).toISOString();

export default {
    getCollection(tenant: string, params: any = {}): Promise<any> {
        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/devices?';

        //  Filters
        if('aksId' in params && params.aksId && params.aksId.length > 0) {
            requestURL = requestURL + '&aksId=' + encodeURIComponent(params.aksId);
        }
        if('localAksId' in params && params.localAksId && params.localAksId.length > 0) {
            requestURL = requestURL + '&localAksId=' + encodeURIComponent(params.localAksId);
        }
        if('unit' in params && params.unit && params.unit.length > 0) {
            requestURL = requestURL + '&unit=' + encodeURIComponent(params.unit);
        }
        if('type' in params && params.type && params.type.length > 0) {
            requestURL = requestURL + '&type=' + encodeURIComponent(params.type);
        }
        if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
            requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
        }
        if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
            requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
        }
        if('property' in params && params.property && Object.keys(params.property).length > 0) {
            requestURL = requestURL + '&propertyId=' + encodeURIComponent(params.property.uuid);
        }
        if('connector' in params && params.connector && Object.keys(params.connector).length > 0) {
            requestURL = requestURL + '&connectorId=' + encodeURIComponent(params.connector.uuid);
        }
        if('deleted' in params && params.deleted) {
            requestURL = requestURL + '&deletedUntil=' + farFutureDateISOString;
        }

        // Paging
        if('itemsPerPage' in params && params.itemsPerPage) {
            requestURL = requestURL + '&page[size]=' + encodeURIComponent(params.itemsPerPage);
        }
        if('nextPageLink' in params && params.nextPageLink && params.nextPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.nextPageLink;
        }
        if('prevPageLink' in params && params.prevPageLink && params.prevPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.prevPageLink;
        }

        return axiosLimiter.get(requestURL);
    },
    async getAllCollectionPages(tenant: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/devices?';

            //  Filters
            if('aksId' in params && params.aksId && params.aksId.length > 0) {
                requestURL = requestURL + '&aksId=' + encodeURIComponent(params.aksId);
            }
            if('localAksId' in params && params.localAksId && params.localAksId.length > 0) {
                requestURL = requestURL + '&localAksId=' + encodeURIComponent(params.localAksId);
            }
            if('unit' in params && params.unit && params.unit.length > 0) {
                requestURL = requestURL + '&unit=' + encodeURIComponent(params.unit);
            }
            if('type' in params && params.type && params.type.length > 0) {
                requestURL = requestURL + '&type=' + encodeURIComponent(params.type);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }
            if('property' in params && params.property && Object.keys(params.property).length > 0) {
                requestURL = requestURL + '&propertyId=' + encodeURIComponent(params.property.uuid);
            }
            if('connector' in params && params.connector && Object.keys(params.connector).length > 0) {
                requestURL = requestURL + '&connectorId=' + encodeURIComponent(params.connector.uuid);
            }
            if('deleted' in params && params.deleted) {
                requestURL = requestURL + '&deletedUntil=' + farFutureDateISOString;
            }
            if('tag' in params && params.tag && Object.keys(params.tag).length > 0) {
                requestURL = requestURL + '&tagId=' + encodeURIComponent(params.tag.uuid);
            }
            if('tags' in params && params.tags && params.tags.length > 0) {
                requestURL = requestURL + '&tagIds=' + encodeURIComponent(params.tags.map((value: any) => value.uuid).join(','));
            }
            if('alerting' in params && params.alerting != undefined) {
                requestURL = requestURL + '&alerting=' + encodeURIComponent(params.alerting);
            }
            if('isRelative' in params && params.isRelative != undefined) {
                requestURL = requestURL + '&isRelative=' + encodeURIComponent(params.isRelative);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(tenant: string, deviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/devices/' + deviceUUID);
    },
    updateDevice(tenantUUID: string, deviceUUID: string, aksId = "", 
     localAksId = "", description = "", unit = "", type="",
     frequency = "", interval=-1, aggregation="", 
     propertyUUID = "", connectorUUID = "", alerting?: boolean, isRelative?: boolean): Promise<any> {
        
        const body = {
            data: {
                type: "devices",
                id: deviceUUID,
                attributes: {
                },
                relationships: {
                }
            }
        } as any;

        if(propertyUUID.length > 0) {
            body.data.relationships.property = {
                data: {
                    type: "properties",
                    id: propertyUUID
                }
            };
        }
        
        if(connectorUUID.length > 0) {
            body.data.relationships.connector = {
                data: {
                    type: "connectors",
                    id: connectorUUID
                }
            };
        }

        if(aksId.length > 0) {
            body.data.attributes.aksId = aksId;
        }
        if(localAksId.length > 0) {
            body.data.attributes.localAksId = localAksId;
        }
        if(description.length > 0) {
            body.data.attributes.description = description;
        }
        if(unit.length > 0) {
            body.data.attributes.unit = unit;
        }
        if(type.length > 0) {
            body.data.attributes.type = type;
        }
        if(frequency.length > 0) {
            body.data.attributes.frequency = frequency;
        }
        if(interval > 0) {
            body.data.attributes.interval = interval;
        }
        if(aggregation.length > 0) {
            body.data.attributes.aggregation = aggregation;
        }
        if(alerting !== undefined) {
            body.data.attributes.alerting = alerting;
        }
        if(isRelative !== undefined) {
            body.data.attributes.isRelative = isRelative;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/' + deviceUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    getAssociatedReadings(tenantUUID: string, deviceUUID: string, params: any = {}): Promise<any> {

        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/'  + tenantUUID + '/devices/' + deviceUUID + '/readings?';

        //  Filters
        if('from' in params && params.from && params.from.length > 0) {
            requestURL = requestURL + '&from=' + encodeURIComponent(params.from);
        }
        if('until' in params && params.until && params.until.length > 0) {
            requestURL = requestURL + '&until=' + encodeURIComponent(params.until);
        }
        if('timestamp' in params && params.timestamp && params.timestamp.length > 0) {
            requestURL = requestURL + '&timestamp=' + encodeURIComponent(params.timestamp);
        }
        if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
            requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
        }
        if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
            requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
        }
        if('device' in params && params.device && Object.keys(params.device).length > 0) {
            requestURL = requestURL + '&deviceId=' + encodeURIComponent(params.device.uuid);
        }
        if('connector' in params && params.connector && Object.keys(params.connector).length > 0) {
            requestURL = requestURL + '&connectorId=' + encodeURIComponent(params.connector.uuid);
        }
        if('value' in params && params.value) {
            requestURL = requestURL + '&value=' + parseFloat(params.value);
        }

        // Paging
        if('itemsPerPage' in params && params.itemsPerPage) {
            requestURL = requestURL + '&page[size]=' + encodeURIComponent(params.itemsPerPage);
        }
        if('nextPageLink' in params && params.nextPageLink && params.nextPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.nextPageLink;
        }
        if('prevPageLink' in params && params.prevPageLink && params.prevPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.prevPageLink;
        }

        return axiosLimiter.get(requestURL);
    },
    getAssociatedSetPoints(tenantUUID: string, deviceUUID: string, params: any = {}): Promise<any> {

        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/' + deviceUUID + '/setpoints?';

        //  Filters
        if('state' in params && params.state && params.state.length > 0) {
            requestURL = requestURL + '&state=' + encodeURIComponent(params.state);
        } else {
            requestURL = requestURL + '&state=all'; // returns all states in read-only mode (active ones are not set sent)
        }
        if('from' in params && params.from && params.from.length > 0) {
            requestURL = requestURL + '&from=' + encodeURIComponent(params.from);
        }
        if('until' in params && params.until && params.until.length > 0) {
            requestURL = requestURL + '&until=' + encodeURIComponent(params.until);
        } else {
            // ML: Until is set by the backend if not provided, we set if to far future date to get all setpoints
            requestURL = requestURL + '&until=' + farFutureDateISOString;
        }
        if('timestamp' in params && params.timestamp && params.timestamp.length > 0) {
            requestURL = requestURL + '&timestamp=' + encodeURIComponent(params.timestamp);
        }
        if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
            requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
        }
        if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
            requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
        }
        if('device' in params && params.device && Object.keys(params.device).length > 0) {
            requestURL = requestURL + '&deviceId=' + encodeURIComponent(params.device.uuid);
        }

        // Paging ML: needs to be after filters as requestURL is potentially overwritten
        if('itemsPerPage' in params && params.itemsPerPage) {
            requestURL = requestURL + '&page[size]=' + encodeURIComponent(params.itemsPerPage);
        }
        if('nextPageLink' in params && params.nextPageLink && params.nextPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.nextPageLink;
        }
        if('prevPageLink' in params && params.prevPageLink && params.prevPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.prevPageLink;
        }

        return axiosLimiter.get(requestURL);
    },
    deleteDevice(tenantUUID: string, deviceUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/' + deviceUUID);
    },
    restoreDevice(tenantUUID: string, deviceUUID: string): Promise<any> {

        const body = {
            data: {
                type: "devices",
                id: deviceUUID
            }
        } as any;

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/' + deviceUUID,  JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    getAssociatedProperty(tenantUUID: string, deviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/' + deviceUUID + '/property');
    },
    getAssociatedConnector(tenantUUID: string, deviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/' + deviceUUID + '/connector');
    },
    getAssociatedTags(tenantUUID: string, deviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/' + deviceUUID + '/tags');
    },
    addTags(tenantUUID: string, deviceUUID: string, tags: any[]): Promise<any> {
        const body = {
            data: []
        } as any;

        for(const tag of tags) {
            body.data.push({
                type: "tags",
                id: tag.uuid
            });
        }
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/' + deviceUUID + '/tags', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    removeTags(tenantUUID: string, deviceUUID: string, tags: any[]): Promise<any> {
        const body = {
            data: []
        } as any;

        for(const tag of tags) {
            body.data.push({
                type: "tags",
                id: tag.uuid
            });
        }
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID +  '/devices/' + deviceUUID + '/tags', { headers: {'Content-Type': 'application/vnd.api+json'}, data: JSON.stringify(body)});
    },
    createDevice(tenantUUID: string, propertyUUID: string, connectorUUID: string, 
        aksId: string, description: string, unit: string, type: string, 
        frequency: string, interval: number, aggregation: string, localAksId = "", alerting = true, isRelative = false): Promise<any> {
    
        const body = {
            data: {
                type: "devices",
                attributes: {
                    aksId: aksId,
                    description: description,
                    unit: unit,
                    type: type,
                    frequency: frequency,
                    interval: interval,
                    alerting: alerting,
                    isRelative: isRelative,
                    aggregation: aggregation
                },
                relationships: {
                    connector: {
                        data: {
                            type: "connectors",
                            id: connectorUUID
                        }
                    },
                    property: {
                        data: {
                            type: "properties",
                            id: propertyUUID
                        }
                    }
                }
            }
        } as any;

        if(localAksId.length > 0) {
            body.data.attributes.localAksId = localAksId;
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    getAssociatedAlerts(tenantUUID: string, deviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/' + deviceUUID + '/alerts');
    },
    addAlert(tenantUUID: string, deviceUUID: string, recipients: string[], maxAge: number, paused?: boolean): Promise<any> {
        const body = {
            "data": {
                "type": "alerts",
                "attributes": {
                    "maxAge": maxAge,
                    "recipients": recipients
                }
            }
        } as any;

        if(paused !== undefined) {
            body.data.attributes.paused = paused;
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/' + deviceUUID + '/alerts', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateAlert(tenantUUID: string, deviceUUID: string, alertUUID: string, recipients?: string[], maxAge?: number, paused?: boolean): Promise<any> {
        const body = {
            "data": {
                "id": alertUUID,
                "type": "alerts",
                "attributes": {
                }
            }
        } as any;

        if(recipients && recipients.length > 0) {
            body.data.attributes.recipients = recipients;
        }
        if(maxAge) {
            body.data.attributes.maxAge = maxAge;
        }
        if(paused !== undefined) {
            body.data.attributes.paused = paused;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/' + deviceUUID + '/alerts/' + alertUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    removeAlert(tenantUUID: string, deviceUUID: string, alertUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/' + deviceUUID + '/alerts/' + alertUUID);
    },
    getAssociatedIoTDevices(tenantUUID: string, deviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/devices/' + deviceUUID + '/iotdevices');
    },
}
  