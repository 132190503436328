
  import Vue from 'vue'
  import { RRuleToText } from '@/utils/Util';

  import TaskInfoDialog from '@/components/services/task/TaskInfoDialog.vue';
  import TaskEditDialog from '@/components/services/task/TaskEditDialog.vue';
  import TaskDeleteDialog from '@/components/services/task/TaskDeleteDialog.vue';
  import { objectsToCSV, filterItem } from '@/utils/Util';
  import DeviceService from '@/services/DeviceService';

  export default Vue.extend({
    name: 'TasksTable',

    components: {
      TaskInfoDialog,
      TaskEditDialog,
      TaskDeleteDialog,
    },
    props: ['tasks', 'loading'],
    data: (vm: any): any => ({
      tableLoadingText: vm.loadingText,
      search: "",
      showUUIDs: false,
      showAddMenu: false,
      selectedHeaders: [
        // { text: vm.$t('entity.device') + ' ' + vm.$t('attribute.aksId'), value: 'device.aksId', index: 100 },
        { text: vm.$t('attribute.rrule'), value: 'rrule', index: 200 },
        { text: vm.$t('attribute.note'), value: 'note', index: 300 },
        { text: vm.$t('attribute.updatedAt'), value: 'updatedAt', index: 1000 },
        { text: vm.$t('attribute.createdAt'), value: 'createdAt', index: 1100 },
      ],
      currentItems: [],
      loadingDevices: false
    }),
    async created() {
      if(this.tasks.length === 0) {
        this.tableLoadingText = this.$t('task.noData');
      }

      const columns = this.$root.$store.getters.selectedColumns('task');
      if(columns) {
        this.selectedHeaders = columns;
      }
    },
    watch: {
      selectedHeaders() {
        this.$store.commit("setSelectedColumns", { type: 'task', columns: this.selectedHeaders });
      } 
    },
    methods: {
      filterItem,
      onExport() {
          // Data structure
          const data = this.tasks.map((task: any, index: number) => Object({
            uuid: task.uuid,
            deviceId: task.deviceId,
            rrule: task.rrule,
            note: task.note,
            generatedUntil: task.generatedUntil,
            createdAt: task.createdAt,
            updatedAt: task.updatedAt,
          }));

          const filename = 'bascloud_tasks.csv';
          const csvContent = objectsToCSV(data);
          var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

          if ((navigator as any).msSaveBlob) { // IE 10+
              (navigator as any).msSaveBlob(blob, filename);
          } else {
              var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", filename);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }
          }
      },
      onCurrentItemsChange(items: any[]) {
        this.currentItems = items;
      },
      onReload() {
        this.$emit("reload");
      },
      onTaskItemUpdate(item: any) {
        this.$emit("update:task", item);
      },
      onTaskItemDelete(item: any) {
        this.$emit("delete:task", item);
      },
      onTableRowClick(row: any) {
        const button: any = this.$refs["taskInfoButton_" + row.uuid];
        // $el needed for native elements
        button.$el.click();
      },
      rruleText(item: any): any {
          if(Object.keys(item).length > 0) {
              const text = RRuleToText(item.rrule, this.$root.$store.state.config.language);
              return text.charAt(0).toUpperCase()+text.slice(1);
          } else {
              return "";
          }
      },
      getDeviceUnitLabel(item: any) {
        if(!('device' in item) && !this.loadingDevices) {
          this.loadCurrentDevices(item);
        }

        return item?.device?.unit || item.deviceId;
      },
      getDeviceDescriptionLabel(item: any) {
        if(!('device' in item) && !this.loadingDevices) {
          this.loadCurrentDevices(item);
        }

        return item?.device?.description || item.deviceId;
      },
      getDeviceAksLabel(item: any) {
        if(!('device' in item) && !this.loadingDevices) {
          this.loadCurrentDevices(item);
        }

        return item?.device?.aksId || item.deviceId;
      },
      async loadCurrentDevices() {
        if(!this.loadingDevices && this.currentItems.length > 0) {
          this.loadingDevices = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (task: any) => {
            if(!task.device)
              promises.push(this.loadDevice(task));
          });
          await Promise.all(promises);
          this.loadingDevices = false;
        }
      }, 
      async loadDevice(item: any) {
        try {
            const device = await DeviceService.getSingle(this.currentTenant, item.deviceId);
            Vue.set(item, 'device', device); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(item, 'device', {});
        }
      },
    },
    computed: {
      headers() {
        return [
            { text: this.$t('attribute.uuid'), value: 'uuid', index: 0 },
            { text: this.$t('entity.device') + ' ' + this.$t('attribute.aksId'), value: 'device.aksId', index: 100 },
            { text: this.$t('entity.device')+ ' ' + this.$t('attribute.description'), value: 'device.description', index: 101 },
            { text: this.$t('entity.device')+ ' ' + this.$t('attribute.unit'), value: 'device.unit', index: 102 },
            { text: this.$t('attribute.rrule'), value: 'rrule', index: 200 },
            { text: this.$t('attribute.note'), value: 'note', index: 300 },
            { text: this.$t('attribute.updatedAt'), value: 'updatedAt', index: 1000 },
            { text: this.$t('attribute.createdAt'), value: 'createdAt', index: 1100 },
        ];
      },
      loadingText(): any {
        return this.$t('loadingData');
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      computedTableHeader(): any {
        let headers = [...this.selectedHeaders,
          { text: this.$t('attribute.actions'), value: 'actions', sortable: false, filterable: false, index: 9998 },
        ]
        
        if(this.canCreateReading || this.canCreateSetpoint) {
          headers.push(
          { text: '', value: 'readingActions', sortable: false, filterable: false, index: 9999 })
        }
        
        return headers.sort((a: any, b: any) => a.index-b.index);
      },
      isRoot(): any {
          return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
              && this.$root.$store.state.session.permissions.role === 'superadmin';
      },
      isAdmin(): any {
          return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
              && (this.$root.$store.state.session.permissions.role === 'superadmin' || 
              this.$root.$store.state.session.permissions.role === 'admin');
      },
    },
  })
