import axiosLimiter from '@/api/RateLimiter';

const farFutureDateISOString = new Date(9999, 1, 1).toISOString();

export default {
    async getCollectionPage(tenant: string, params: any): Promise<any> {

        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/setpoints?';

        //  Filters
        if('state' in params && params.state && params.state.length > 0) {
            requestURL = requestURL + '&state=' + encodeURIComponent(params.state);
        } else {
            requestURL = requestURL + '&state=all'; // returns all states in read-only mode (active ones are not set sent)
        }
        if('from' in params && params.from && params.from.length > 0) {
            requestURL = requestURL + '&from=' + encodeURIComponent(params.from);
        }
        if('until' in params && params.until && params.until.length > 0) {
            requestURL = requestURL + '&until=' + encodeURIComponent(params.until);
        } else {
            // ML: Until is set by the backend if not provided, we set if to far future date to get all setpoints
            requestURL = requestURL + '&until=' + encodeURIComponent(farFutureDateISOString);
        }
        if('timestamp' in params && params.timestamp && params.timestamp.length > 0) {
            requestURL = requestURL + '&timestamp=' + encodeURIComponent(params.timestamp);
        }
        if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
            requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
        }
        if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
            requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
        }
        if('device' in params && params.device && Object.keys(params.device).length > 0) {
            requestURL = requestURL + '&deviceId=' + encodeURIComponent(params.device.uuid);
        }

        // Paging ML: needs to be after filters as requestURL is potentially overwritten
        if('itemsPerPage' in params && params.itemsPerPage) {
            requestURL = requestURL + '&page[size]=' + encodeURIComponent(params.itemsPerPage);
        }
        if('nextPageLink' in params && params.nextPageLink && params.nextPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.nextPageLink;
        }
        if('prevPageLink' in params && params.prevPageLink && params.prevPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.prevPageLink;
        }

        return axiosLimiter.get(requestURL);
    },
    async getAllCollectionPages(tenant: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/setpoints?';

            //  Filters
            if('state' in params && params.state && params.state.length > 0) {
                requestURL = requestURL + '&state=' + encodeURIComponent(params.state);
            } else {
                requestURL = requestURL + '&state=all'; // returns all states in read-only mode (active ones are not set sent)
            }
            if('from' in params && params.from && params.from.length > 0) {
                requestURL = requestURL + '&from=' + encodeURIComponent(params.from);
            }
            if('until' in params && params.until && params.until.length > 0) {
                requestURL = requestURL + '&until=' + encodeURIComponent(params.until);
            } else {
                // ML: Until is set by the backend if not provided, we set if to far future date to get all setpoints
                requestURL = requestURL + '&until=' + farFutureDateISOString;
            }
            if('timestamp' in params && params.timestamp && params.timestamp.length > 0) {
                requestURL = requestURL + '&timestamp=' + encodeURIComponent(params.timestamp);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }
            if('device' in params && params.device && Object.keys(params.device).length > 0) {
                requestURL = requestURL + '&deviceId=' + encodeURIComponent(params.device.uuid);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(tenant: string, setpointUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/setpoints/' + setpointUUID);
    },
    createSetpoint(tenantUUID: string, value: number, timestamp: string, deviceUUID: string): Promise<any> {
    
        const body = {
            data: {
                type: "setpoints",
                attributes: {
                    value: value,
                    timestamp: timestamp
                },
                relationships: {
                    device: {
                        data: {
                            type: "devices",
                            id: deviceUUID
                        }
                    }
                }
            }
        } as any;

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/setpoints/', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateSetpoint(tenantUUID: string, setpointUUID: string, value = "", 
        deviceUUID = ""): Promise<any> {
        
        const body = {
            data: {
                type: "setpoints",
                id: setpointUUID,
                attributes: {
                }
            }
        } as any;

        if(value.length > 0) {
            body.data.attributes.value = parseFloat(value);
        }
        // Update timestamp makes problems
        // if(timestamp.length > 0) {
        //     body.data.attributes.timestamp = timestamp;
        // }

        if(deviceUUID.length > 0) {
            body.data.relationships= {
                device: {
                    data: {
                        type: "devices",
                        id: deviceUUID
                    }
                }
            };
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/setpoints/' + setpointUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    deleteSetpoint(tenantUUID: string, setpointUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/setpoints/' + setpointUUID);
    },
    getAssociatedDevice(tenant: string, setpointUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/setpoints/' + setpointUUID + '/device');
    },
}
  