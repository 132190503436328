
  import Vue from 'vue'

  export default Vue.extend({
    name: 'GroupCreateDialog',

    components: {
    },
    props: [],
    data: (vm: any): any => ({
        localDialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        name: '',
    }),
    created() {
      //
    },
    methods: {
      validate() {
        if(this.createForm.validate()) {
          this.createGroup();
        }
      },
      close() {
        this.localDialog = false;
      },
      clear() {
        this.name = '';
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      createGroup() {
        this.loading = true;
        this.$emit("success", this.name);
        setTimeout(() => { 
          this.dismiss();
        }, 200);
      },
    },
    watch: {
      localDialog(value: boolean) {
        if(value) {
          // s
        } else {
          this.clear();
        }
      }
    },
    computed: {
      createForm(): any {
          return this.$refs.createForm;
      },
      requiredError(): any {
          return this.$t('required');
      },
    }
  })
