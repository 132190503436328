
  import Vue from 'vue'
  import { RRuleToText } from '@/utils/Util';

  import ReportInfoDialog from '@/components/services/report/ReportInfoDialog.vue';
  import ReportEditDialog from '@/components/services/report/ReportEditDialog.vue';
  import ReportDeleteDialog from '@/components/services/report/ReportDeleteDialog.vue';
  import { objectsToCSV, filterItem } from '@/utils/Util';

  export default Vue.extend({
    name: 'ReportsTable',

    components: {
      ReportInfoDialog,
      ReportDeleteDialog,
      ReportEditDialog
    },
    props: ['reports', 'loading'],
    data: (vm: any): any => ({
      tableLoadingText: vm.loadingText,
      search: "",
      showUUIDs: false,
      showAddMenu: false,
      selectedHeaders: [
        { text: vm.$t('attribute.rrule'), value: 'rrule', index: 200 },
        { text: vm.$t('attribute.note'), value: 'note', index: 300 },
        { text: vm.$t('attribute.updatedAt'), value: 'updatedAt', index: 1000 },
        { text: vm.$t('attribute.createdAt'), value: 'createdAt', index: 1100 },
      ],
      currentItems: [],
      loadingDevices: false
    }),
    async created() {
      if(this.reports.length === 0) {
        this.tableLoadingText = this.$t('report.noData');
      }

      const columns = this.$root.$store.getters.selectedColumns('report');
      if(columns) {
        this.selectedHeaders = columns;
      }
    },
    watch: {
      selectedHeaders() {
        this.$store.commit("setSelectedColumns", { type: 'report', columns: this.selectedHeaders });
      } 
    },
    methods: {
      filterItem,
      onExport() {
          // Data structure
          const data = this.reports.map((task: any, index: number) => Object({
            uuid: task.uuid,
            deviceId: task.deviceId,
            rrule: task.rrule,
            note: task.note,
            generatedUntil: task.generatedUntil,
            createdAt: task.createdAt,
            updatedAt: task.updatedAt,
          }));

          const filename = 'bascloud_reports.csv';
          const csvContent = objectsToCSV(data);
          var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

          if ((navigator as any).msSaveBlob) { // IE 10+
              (navigator as any).msSaveBlob(blob, filename);
          } else {
              var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", filename);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }
          }
      },
      onCurrentItemsChange(items: any[]) {
        this.currentItems = items;
      },
      onReload() {
        this.$emit("reload");
      },
      onItemUpdate(item: any) {
        this.$emit("update:report", item);
      },
      onItemDelete(item: any) {
        this.$emit("delete:report", item);
      },
      onTableRowClick(row: any) {
        const button: any = this.$refs["reportInfoButton_" + row.uuid];
        // $el needed for native elements
        button.$el.click();
      },
      rruleText(item: any): any {
          if(Object.keys(item).length > 0) {
              const text = RRuleToText(item.rrule, this.$root.$store.state.config.language);
              return text.charAt(0).toUpperCase()+text.slice(1);
          } else {
              return "";
          }
      },
    },
    computed: {
      headers() {
        return [
            { text: this.$t('attribute.uuid'), value: 'uuid', index: 0 },
            { text: this.$t('attribute.rrule'), value: 'rrule', index: 200 },
            { text: this.$t('attribute.note'), value: 'note', index: 300 },
            { text: this.$t('attribute.updatedAt'), value: 'updatedAt', index: 1000 },
            { text: this.$t('attribute.createdAt'), value: 'createdAt', index: 1100 },
        ];
      },
      loadingText(): any {
        return this.$t('loadingData');
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      computedTableHeader(): any {
        let headers = [...this.selectedHeaders,
          { text: this.$t('attribute.actions'), value: 'actions', sortable: false, filterable: false, index: 9998 },
        ]
        
        return headers.sort((a: any, b: any) => a.index-b.index);
      },
      isRoot(): any {
          return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
              && this.$root.$store.state.session.permissions.role === 'superadmin';
      },
      isAdmin(): any {
          return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
              && (this.$root.$store.state.session.permissions.role === 'superadmin' || 
              this.$root.$store.state.session.permissions.role === 'admin');
      },
    },
  })
