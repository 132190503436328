
  import Vue from 'vue'

  import TenantInfoDialog from '@/components/entities/tenant/TenantInfoDialog.vue'
  import TenantEditDialog from '@/components/entities/tenant/TenantEditDialog.vue'
  import TenantDeleteDialog from '@/components/entities/tenant/TenantDeleteDialog.vue'
  import TenantRestoreDialog from '@/components/entities/tenant/TenantRestoreDialog.vue'
  import { objectsToCSV, filterItem } from '@/utils/Util';

  export default Vue.extend({
    name: 'TenantsTable',

    components: {
      TenantInfoDialog,
      TenantEditDialog,
      TenantDeleteDialog,
      TenantRestoreDialog
    },
    props: ['tenants', 'loading'],
    data: (vm: any): any => ({
      tableLoadingText: vm.loadingDataText,
      search: "",
      showUUIDs: false,
      numberOfEntities: 0,
      selectedHeaders: [
          { text: vm.$t('attribute.name'), value: 'name', index: 1 },
          { text: vm.$t('attribute.urlName'), value: 'urlName', index: 2 },
          { text: vm.$t('attribute.updatedAt'), value: 'updatedAt', index: 8 },
          { text: vm.$t('attribute.createdAt'), value: 'createdAt', index: 9 },
      ],
    }),
    created() {
      if(this.tenants.length === 0) {
        this.tableLoadingText = this.$t('tenant.noData');
      }

      const columns = this.$root.$store.getters.selectedColumns('tenant');
      if(columns) {
        this.selectedHeaders = columns;
      }
    },
    watch: {
      selectedHeaders() {
        this.$store.commit("setSelectedColumns", { type: 'tenant', columns: this.selectedHeaders });
      } 
    },
    methods: {
      filterItem,
      onExport() {
          let filteredItems = this.tenants;
          if(this.$refs.dataTable.$children[0].filteredItems) {
            filteredItems = this.$refs.dataTable.$children[0].filteredItems;
          }

          // Data structure
          const data = filteredItems.map((ten: any, index: number) => Object({
            uuid: ten.uuid,
            name: ten.name,
            urlName: ten.urlName,
            uniqueAksId: ten.uniqueAksId,
            createdAt: ten.createdAt,
            updatedAt: ten.updatedAt,
            deletedAt: ten.deletedAt,
          }));

          const filename = 'bascloud_tenants.csv';
          const csvContent = objectsToCSV(data);
          var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

          if ((navigator as any).msSaveBlob) { // IE 10+
              (navigator as any).msSaveBlob(blob, filename);
          } else {
              var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", filename);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }
          }
      },
      onReload() {
        this.$emit("reload");
      },
      onTenantItemRestore(item: any) {
        this.$emit("restore", item);
      },
      onTenantItemUpdate(item: any) {
        this.$emit("update", item);
      },
      onTenantItemDelete(item: any) {
        this.$emit("delete", item);
      },
      onPaginationAndFilter(pagination: any) {
        this.numberOfEntities = pagination.itemsLength;
      },
      onTableRowClick(row: any) {
        const button: any = this.$refs["tenantInfoButton_" + row.uuid];
        // $el needed for native elements
        button.$el.click();
      }
    },
    computed: {
      headers() {
        return [
            { text: this.$t('attribute.uuid'), value: 'uuid', index: 0 },
            { text: this.$t('attribute.name'), value: 'name', index: 1 },
            { text: this.$t('attribute.urlName'), value: 'urlName', index: 2 },
            { text: this.$t('tenant.uniqueAksIds'), value: 'uniqueAksId', index: 3 },
            { text: this.$t('attribute.updatedAt'), value: 'updatedAt', index: 8 },
            { text: this.$t('attribute.createdAt'), value: 'createdAt', index: 9 },
        ]
      },
      loadingDataText(): any {
        return this.$t('loadingData');
      },
      computedTableHeader(): any {
        return [...this.selectedHeaders,
          { text: this.$t('attribute.actions'), value: 'actions', sortable: false, filterable: false, index: 100 },
        ].sort((a: any, b: any) => a.index-b.index);
      },
      canUpdate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'tenants' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['tenants'].includes('PATCH');
        } else {
          return false;
        }
      },
      canDelete(): any {
        if (this.$root.$store.state.session.permissions.resources && 'tenants' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['tenants'].includes('DELETE');
        } else {
          return false;
        }
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'tenants' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['tenants'].includes('POST');
        } else {
          return false;
        }
      },
    },
  })
