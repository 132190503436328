

  import Vue from 'vue'
  import mqtt from "mqtt/dist/mqtt";

  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import IoTDeviceService from '@/services/IoTDeviceService';
  import OTAService from '@/services/OTAService';

  export default Vue.extend({
    name: 'IotDeviceCommandDialog',

    props: ['iotdevice'],
    components: {
    },
    data: (vm: any): any => ({
        localdialog: false,
        mqttConsole: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingMQTT: false,
        connectedMQTT: false,
        terminalBuffer: "",
        mqttPassword: "",
        mqttUsername: "",
        mqttBuffer: "",
        mqttTopic: "",
        versions: [],
        components: [
          'app',
          'data'
        ],
        mqttSubscriptions: [
          { value: '/ops/log', name: "Logs"},
          { value: '/data/#', name: "Data"},
          { value: '/ops/#', name: "Operations"},
          { value: '/#', name: "All"},
        ],
        logLevels: [
          { value: 0, name: "Error"},
          { value: 1, name: "Warning"},
          { value: 2, name: "Info"},
          { value: 3, name: "Debug"},
          { value: 4, name: "Print"},
        ],
        logBackends: [
          { value: 0, name: "None"},
          { value: 1, name: "MQTT"},
          { value: 2, name: "File"},
        ],
        selectedVersion: null,
        selectedComponent: null,
        selectedLogLevel: null,
        selectedLogBackend: null,
        customCommand: "",
        client: null
    }),
    async created() {
        this.configString = JSON.stringify(this.value, null, 4);
    },
    methods: {
      async onMQTTDisconnect() {
        this.client.end()
      },
      async onMQTTConnect() {
        this.loadingMQTT = true;
        this.logMQTT("Connecting to MQTT broker...");

        this.client = mqtt.connect({       
          hostname: 'mqtt2.bascloud.net',
          port: 8083,
          protocol: 'wss',
          rejectUnauthorized: true,
          username: this.mqttUsername,
          password: this.mqttPassword
        });

        this.client.on('connect',  () => {
          this.loadingMQTT = false;
          this.connectedMQTT = true;
          this.logMQTT("Successfully connected.");

          this.client.subscribe('bascloud/iotdevice/' + this.iotdevice.serialnumber + this.mqttTopic, (err: any) => {
            if(err) {
              this.logMQTT("Error in subscribing to: bascloud/iotdevice/" + this.iotdevice.serialnumber + this.mqttTopic);
              this.logMQTT(err);
            } else {
              this.logMQTT("Successfully subscribed to: bascloud/iotdevice/" + this.iotdevice.serialnumber + this.mqttTopic);
            }
          })
        });

        this.client.on('message', (topic: string, message: any) => {
          // message is Buffer
          this.logMQTT(topic.replace("bascloud/iotdevice/" + this.iotdevice.serialnumber, "") + ": " + message.toString());
        });

        this.client.on('error', (err: any) => {
          this.loadingMQTT = false;
          console.log(err)
          this.logMQTT("ERR: " + err.toString());
        });

        this.client.on('end', () => {
          this.connectedMQTT = false;
          this.logMQTT("Disconnected");
        });

      },
      async sendPong() {
        this.log('Sending ping request to ' + this.iotdevice.serialnumber);

        try {
          await IoTDeviceService.triggerDevicePing(this.iotdevice.uuid);
          this.log('OK');
        } catch(error: any) {
          this.log('Error: ' + error.message + ' ' + extractErrorMessage(error));
        }
      },
      async onSendCustomCMD() {
        this.log('Sending custom command to ' + this.iotdevice.serialnumber);
        this.log('CMD: ' + this.customCommand);

        try {
          const result = await IoTDeviceService.setCustomCMD(this.iotdevice.uuid, this.customCommand);
          this.log('Result:')
          this.log(result.response);
        } catch(error: any) {
          this.log('Error: ' + error.message + ' ' + extractErrorMessage(error));
        }
      },
      async sendLogging() {
        this.log('Sending Logging command to ' + this.iotdevice.serialnumber);
        if(this.selectedLogLevel)
          this.log('Level: ' + this.selectedLogLevel);
        if(this.selectedLogBackend)
          this.log('Component: ' + this.selectedLogBackend);

        try {
          await IoTDeviceService.setLogging(this.iotdevice.uuid, this.selectedLogLevel, this.selectedLogBackend);
          this.log('Logging successfully set.');
        } catch(error: any) {
          this.log('Error: ' + error.message + ' ' + extractErrorMessage(error));
        }
      },
      async sendOTAUpdate() {
        this.log('Sending OTA update command to ' + this.iotdevice.serialnumber);
        this.log('Version: ' + this.selectedVersion);
        this.log('Component: ' + this.selectedComponent);

        try {
          await IoTDeviceService.triggerOTAUpdate(this.iotdevice.uuid, this.selectedVersion, this.selectedComponent);
          this.log('OTA Update successfully triggered.');
          if(this.iotdevice.connectivity === 'ETH' || this.iotdevice.connectivity === 'WIFI') {
            this.log('OTA Update will take a few minutes...');
          } else if(this.iotdevice.connectivity === 'GSM') {
            this.log('OTA Update over GSM may take up to ~20 minutes...');
          }
          this.log('After execution of OTA update, the update result will be contained in the "state" field of the IoT Device.');
        } catch(error: any) {
          this.log('Error: ' + error.message + ' ' + extractErrorMessage(error));
        }
      },
      async sendGetStatus() {
        this.log('Getting device status for ' + this.iotdevice.serialnumber)
        try {
          const result = await IoTDeviceService.getDeviceStatus(this.iotdevice.uuid);
          this.log('Status:')
          this.log(JSON.stringify(result, null, 4));
        } catch(error: any) {
          this.log('Error: ' + error.message + ' ' + extractErrorMessage(error));
        }
      },
      async sendCollect() {
        this.log('Sending collect command to ' + this.iotdevice.serialnumber);
        try {
          await IoTDeviceService.triggerDeviceCollect(this.iotdevice.uuid);
          this.log('OK');
        } catch(error: any) {
          this.log('Error: ' + error.message + ' ' + extractErrorMessage(error));
        }
      },
      async sendRestart() {
        this.log('Sending restart command to ' + this.iotdevice.serialnumber)
        try {
          await IoTDeviceService.triggerDeviceRestart(this.iotdevice.uuid);
          this.log('OK');
        } catch(error: any) {
          this.log('Error: ' + error.message + ' ' + extractErrorMessage(error));
        }
      },
      logMQTT(message: string) {
          const autoScroll = (this.mqttArea.scrollHeight - this.mqttArea.clientHeight - this.mqttArea.scrollTop) <= 1
          
          this.mqttBuffer += message + "\n";

          Vue.nextTick(() => {
              if(autoScroll) {
                  this.mqttArea.scrollTop = this.mqttArea.scrollHeight
                  this.mqttArea.focus();
              }
          });
      },
      log(message: string) {
          const autoScroll = (this.terminalArea.scrollHeight - this.terminalArea.clientHeight - this.terminalArea.scrollTop) <= 1
          
          this.terminalBuffer += message + "\n";

          Vue.nextTick(() => {
              if(autoScroll) {
                  this.terminalArea.scrollTop = this.terminalArea.scrollHeight
                  this.terminalArea.focus();
              }
          });
      },
      async onCopyMQTTClipboard() {
          try {
              await navigator.clipboard.writeText(this.mqttBuffer);
          } catch(error: any) {
              this.alertText = error.message;
              this.alertVisible = true;
          }
      },
      onMQTTClear() {
          this.mqttBuffer = "";
      },
      onTerminalClear() {
          this.terminalBuffer = "";
      },
      async onCopyTerminalClipboard() {
          try {
              await navigator.clipboard.writeText(this.terminalBuffer);
          } catch(error: any) {
              this.alertText = error.message;
              this.alertVisible = true;
          }
      },
      close() {
        this.localdialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
        this.terminalBuffer = "";
        this.mqttBuffer = "";
        this.mqttUsername = "";
        this.mqttPassword = "";
        this.onMQTTDisconnect();
      },
      dismiss() {
        this.clear();
        this.close();
      },
      getOTAData() {
        this.loading = true;

        OTAService.getAllVersions().then((versions: any[]) => {
          this.loading = false;

          this.versions = versions.filter((value: any) => value.name !== 'snflasher');
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = err.message;
          this.dataError = true;   
          this.loading = false;  
        });
      },
      autoComplete,
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                this.getOTAData();
            } else {
                this.clear(); 
            }
        }
    },
    computed: {
      mqttArea(): HTMLElement {
        return document.getElementById('mqttArea') as HTMLElement;
      },
      terminalArea(): HTMLElement {
        return document.getElementById('terminalArea') as HTMLElement;
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
    }
  })
