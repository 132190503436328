import axiosLimiter from '@/api/RateLimiter';

export default {
    getCollection(): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/apikeys');
    },
    async getAllCollectionPages(params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/apikeys?';

            //  Filters
            if('userId' in params && params.userId && params.userId.length > 0) {
                requestURL = requestURL + '&userId=' + encodeURIComponent(params.userId);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(apikeyUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/apikeys/' + apikeyUUID);
    },
    createApiKey(userId: string, name?: string, expiration?: Date, notify?: boolean): Promise<any> {
        const body = {
            "data": {
                "type": "apikeys",
                "attributes": {
                    "userId": userId
                }
            }
        } as any;

        if(name && name.length > 0) {
            body.data.attributes.name = name;
        }
        if(expiration) {
            body.data.attributes.expiration = expiration.toISOString();
        }
        if(notify) {
            body.data.attributes.notify = true;
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/apikeys', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateApiKey(apikeyUUID: string, name = ""): Promise<any> {
    
        const body = {
            data: {
                type: "apikeys",
                id: apikeyUUID,
                attributes: {
                }
            }
        } as any;

        if(name.length > 0) {
            body.data.attributes.name = name;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/apikeys/' + apikeyUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    deleteApiKey(apikeyUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/apikeys/' + apikeyUUID);
    },
    getAssociatedUser(apikeyUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/apikeys/' + apikeyUUID + '/user');
    },
    generateToken(apikeyUUID: string, notify?: boolean): Promise<any> {      
        let params = "";
        if(notify) {
            params = "&notify"
        }
        
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/apikeys/' + apikeyUUID + '/generateToken?'+params, '', { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
}
  