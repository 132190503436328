import axiosLimiter from '@/api/RateLimiter';

export default {
    getCollection(iotdeviceUUID: string, iotdevicePortUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/'+ iotdeviceUUID + '/ports/' + iotdevicePortUUID + '/mappings');
    },
    async getAllCollectionPages(iotdeviceUUID: string, iotdevicePortUUID: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/'+ iotdeviceUUID + '/ports/' + iotdevicePortUUID + '/mappings?';

            //  Filters

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(iotdeviceUUID: string, iotdevicePortUUID: string, iotdeviceMappingUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/'+ iotdeviceUUID + '/ports/' + iotdevicePortUUID + '/mappings/' + iotdeviceMappingUUID);
    },
    create(iotdeviceUUID: string, iotdevicePortUUID: string, identifiers: string[], targetDeviceId: string, config: any): Promise<any> {
        const body = {
            "data": {		
                "type": "iotdevicemappings",
                "attributes": {
                    "identifiers": identifiers,
                    "targetDeviceId": targetDeviceId,
                    "config": config
                }
            }
        } as any;

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/'+ iotdeviceUUID + '/ports/' + iotdevicePortUUID + '/mappings', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    update(iotdeviceUUID: string, iotdevicePortUUID: string, iotdeviceMappingUUID: string, identifiers?: string[], targetDeviceId?: string, config?: any): Promise<any> {
    
        const body = {
            data: {
                type: "iotdevicemappings",
                id: iotdeviceMappingUUID,
                attributes: {
                }
            }
        } as any;

        if(identifiers !== undefined) {
            body.data.attributes.identifiers = identifiers;
        }
        if(targetDeviceId !== undefined) {
            body.data.attributes.targetDeviceId = targetDeviceId;
        }
        if(config !== undefined) {
            body.data.attributes.config = config;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/'+ iotdeviceUUID + '/ports/' + iotdevicePortUUID + '/mappings/' + iotdeviceMappingUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    delete(iotdeviceUUID: string, iotdevicePortUUID: string, iotdeviceMappingUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/'+ iotdeviceUUID + '/ports/' + iotdevicePortUUID + '/mappings/' + iotdeviceMappingUUID);
    }
}
  