import axiosLimiter from '@/api/RateLimiter';

const farFutureDateISOString = new Date(9999, 1, 1).toISOString();

export default {
    getCollection(params: any = {}): Promise<any> {
        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/devicetypes?';

        //  Filters
        if('type' in params && params.type && params.type.length > 0) {
            requestURL = requestURL + '&type=' + encodeURIComponent(params.type);
        }
        if('tenant' in params && params.tenant && Object.keys(params.tenant).length > 0) {
            requestURL = requestURL + '&tenantId=' + encodeURIComponent(params.tenant.uuid);
        }
        if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
            requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
        }
        if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
            requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
        }
        if('deleted' in params && params.deleted) {
            requestURL = requestURL + '&deletedUntil=' + farFutureDateISOString;
        }

        // Paging
        if('itemsPerPage' in params && params.itemsPerPage) {
            requestURL = requestURL + '&page[size]=' + encodeURIComponent(params.itemsPerPage);
        }
        if('nextPageLink' in params && params.nextPageLink && params.nextPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.nextPageLink;
        }
        if('prevPageLink' in params && params.prevPageLink && params.prevPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.prevPageLink;
        }

        return axiosLimiter.get(requestURL);
    },    
    async getAllCollectionPages(params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/devicetypes?';

            //  Filters
            if('type' in params && params.type && params.type.length > 0) {
                requestURL = requestURL + '&type=' + encodeURIComponent(params.type);
            }
            if('tenant' in params && params.tenant && Object.keys(params.tenant).length > 0) {
                requestURL = requestURL + '&tenantId=' + encodeURIComponent(params.tenant.uuid);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }
            if('deleted' in params && params.deleted) {
                requestURL = requestURL + '&deletedUntil=' + farFutureDateISOString;
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(typeUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/devicetypes/' + typeUUID);
    },
    createDeviceType(type: string, description: string, retention=-1, dataLimit=-1, tenantUUID="", dataLimitFrequency=""): Promise<any> {
        const body = {
            "data": {
                "type": "devicetypes",
                "attributes": {
                    "type": type,
                    "description": description
                }
            }
        } as any;

        if(dataLimit > 0) {
            body.data.attributes.dataLimit = dataLimit;
        }
        if(dataLimitFrequency.length > 0) {
            body.data.attributes.dataLimitFrequency = dataLimitFrequency;
        }
        if(retention > 0) {
            body.data.attributes.retention = retention;
        }
        if(tenantUUID.length > 0) {
            body.data.attributes.tenantId = tenantUUID;
        }
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/devicetypes', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateDeviceType(typeUUID: string, type = "", description = "", retention = -1, dataLimit = -1, tenantUUID = "", dataLimitFrequency="") : Promise<any> {
        
        const body = {
            data: {
                type: "devicetypes",
                id: typeUUID,
                attributes: {
                }
            }
        } as any;

        if(dataLimit && dataLimit > 0) {
            body.data.attributes.dataLimit = dataLimit;
        } else if(!dataLimit) {
            body.data.attributes.dataLimit = null;
        }
        if(dataLimitFrequency.length > 0) {
            body.data.attributes.dataLimitFrequency = dataLimitFrequency;
        }
        if(tenantUUID && tenantUUID.length > 0) {
            body.data.attributes.tenantId = tenantUUID;
        } else if(!tenantUUID) {
            body.data.attributes.tenantId = null;
        }
        if(retention && retention > 0) {
            body.data.attributes.retention = retention;
        } else if(!retention) {
            body.data.attributes.retention = null;
        }
        if(type.length > 0) {
            body.data.attributes.type = type;
        }
        if(description.length > 0) {
            body.data.attributes.description = description;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/devicetypes/' + typeUUID , JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    deleteDeviceType(typeUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/devicetypes/' + typeUUID);
    },
    restoreDeviceType(typeUUID: string): Promise<any> {

        const body = {
            data: {
                type: "devicetypes",
                id: typeUUID
            }
        } as any;

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/devicetypes/' + typeUUID,  JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
}
  