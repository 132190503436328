
  import Vue from 'vue'
  import SetpointService from '@/services/SetpointService';
  import DeviceService from '@/services/DeviceService';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';

  import DateTimePicker from '@/components/misc/DateTimePicker.vue'

  export default Vue.extend({
    name: 'SetpointCreateDialog',

    components: {
      DateTimePicker
    },
    props: ['device'],
    data: (vm: any): any => ({
        dialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingDevice: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        datetimeRules: [
            (v: string) => !!v || vm.requiredError,
            (d: string) => !!d || new Date(d).getTime() > new Date(Date.now()).getTime() || vm.futureError
        ],
        requiredSelect: [
            (v: any) => Object.keys(v).length > 0 || vm.requiredError
        ],
        numberRule: [
            (v: string) => !!v || vm.requiredError,
            (v: string) => !isNaN(parseFloat(v)) || vm.numberError,
        ],
        devices: [] as any[],
        selectedDevice: {} as any,
        setpoint: {
          value: "",
          timestamp: "",
        }
    }),
    methods: {
      validate() {
        if(this.createForm.validate()) {
          this.createSetpoint();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.setpoint = {
          value: "",
          timestamp: "",
        };
        this.selectedDevice = {};
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      createSetpoint() {
        this.loading = true;
        let deviceUUID = this.selectedDevice.uuid;

        SetpointService.createSetpoint(this.currentTenant, parseFloat(this.setpoint.value), this.setpoint.timestamp, deviceUUID).then((setpoint) => {
          this.alertType = "success";
          this.alertText = this.$t('setpoint.successCreate') as string;
          this.alertVisible = true;

          this.$emit("success", setpoint);
          setTimeout(() => { 
              this.dismiss(); 
          }, 1500);
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('setpoint.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
          this.loading = false;
        });
      },
      getDevices() {
        this.loadingDevice = true;

        DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
          this.loadingDevice = false;

          this.devices = devices;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;    
          this.loadingDevice = false;  
        });
      },
      autoComplete,
    },
    watch: {
      dialog(value: boolean) {
        if(value) {
          if(this.device) {
            this.selectedDevice = this.device;
          }
          this.getDevices();
        } else {
          this.clear();
        }
      }
    },
    computed: {
        createForm(): any {
            return this.$refs.createForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        futureError(): any {
            return this.$t('futureDatetime');
        },
        numberError(): any {
            return this.$t('mustNumber');
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        }
    }
  })
