
  import Vue from 'vue'
  import UserService from '@/services/UserService';
  import MeteringService from '@/services/MeteringService';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'UserInfoDialog',

    components: {
        EntityMetaDialog,
        // Weird import necessary due to circular dependency
        TenantInfoDialog: () => import('@/components/entities/tenant/TenantInfoDialog.vue') as any,
        ApiKeyInfoDialog: () => import('@/components/entities/apikey/ApiKeyInfoDialog.vue') as any,
    },
    props: ['user'],
    data: (vm: any): any => ({
        localdialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingTenant: false,
        loadingPermissions: false,
        loadingMetering: false,
        meteringActive: false,
        emailRules: [
            (v: string) => !!v || vm.requiredError,
            (v: string) => /.+@.+\..+/.test(v) || vm.emailValidError
        ],
        userPermissions: {} as any,
        userTenant: {} as any,
        associatedDevices: [] as any[],
        associatedMeteringDevices: [] as any[],
        loadingDevices: false,
        associatedApiKeys: [] as any[],
        loadingApiKeys: false,
        associatedDeviceFilter: "",
        associatedMeteringDevicesFilter: ""
    }),
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.userPermissions = {} as any;
        this.userTenant = {} as any;
        this.alertVisible = false;
        this.loading = false;
        this.associatedDevices = [];
        this.associatedMeteringDevices = [];
        this.associatedDeviceFilter = "";
        this.associatedMeteringDevicesFilter = "";
        this.associatedApiKeys = [];
        this.loadingApiKeys = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      getAssociatedDevices() {
        this.loadingDevices = true;
        this.associatedDevices = [];

        UserService.getAllAssociatedDevicePages(this.user.uuid).then((devices: any[]) => {
            this.loadingDevices = false;
            this.associatedDevices = devices;
        }).catch((err) => {
            if(err.response && err.response.status === 404) {
                this.alertType = "warning";
                this.alertText = extractErrorMessage(err);
                this.alertVisible = true;
                this.loadingDevices = false;
            } else {
                console.log(err);
                console.log(err.response);
                this.alertType = "error";
                this.alertText = this.$t('user.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
                this.alertVisible = true; 
                this.loadingDevices = false;
            }
        });
      },
      getAssociatedApiKeys() {
        this.loadingApiKeys = true;
        this.associatedApiKeys = [];

        UserService.getAssociatedApiKeys(this.user.uuid).then((apikeys: any[]) => {
            this.loadingApiKeys = false;
            this.associatedApiKeys = apikeys;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.loadingApiKeys = false;
        });
      },
      loadPermissions() {
          this.loadingPermissions = true;
          
          UserService.getPermissions(this.user.uuid).then((responseData) => {
                this.loadingPermissions = false;
                this.userPermissions = responseData.permissions;
            }).catch((err) => {
                if(err.response && err.response.status === 404) {
                    this.alertType = "warning";
                    this.alertText = extractErrorMessage(err);
                    this.alertVisible = true;
                    this.loadingPermissions = false;
                } else {
                    console.log(err);
                    console.log(err.response);
                    this.alertType = "error";
                    this.alertText = this.$t('user.permissionsError') + ' ' + extractErrorMessage(err);
                    this.alertVisible = true; 
                    this.loadingPermissions = false;
                }
                this.userPermissions = {};
            });
      },
      async loadAssociatedTenant() {
        this.loadingTenant = true;

        try {
          const tenant = await UserService.getAssociatedTenant(this.user.uuid)
          this.loadingTenant = false;

          this.userTenant = tenant;
        } catch(err: any) {
          if(err.response && err.response.status === 404) {
              this.alertType = "warning";
              this.alertText = extractErrorMessage(err);
              this.alertVisible = true;
              this.loadingTenant = false;
          } else {
              console.log(err);
              console.log(err.response);
              this.alertType = "error";
              this.alertText = this.$t('user.tenantError')+ ' ' + extractErrorMessage(err);
              this.alertVisible = true; 
              this.loadingTenant = false;
          }
          this.userTenant = {};
        }
      },
      loadMeteringUser() {
        this.loadingMetering = true;
        
        MeteringService.getUser(this.userTenant.uuid, this.user.uuid).then((user) => {
            this.loadingMetering = false;

            if(Object.keys(user).length > 0) {
                this.meteringActive = true;
            }
        }).catch((err) => {
            if(err.response && err.response.status !== 404) {
                console.log(err);
                console.log(err.response);
            }
            this.loadingMetering = false;
            this.meteringActive = false;
        });
      },
      loadMeteringUserDevices() {
        this.loadingMetering = true;
        this.associatedMeteringDevices = [];
        
        MeteringService.getUserDeviceCollection(this.userTenant.uuid, this.user.uuid).then((devices) => {
            this.loadingMetering = false;

            this.associatedMeteringDevices = devices;
          }).catch((err) => {
              if(err.response && err.response.status !== 404) {
                  console.log(err);
                  console.log(err.response);
              }
              this.loadingMetering = false;
          });
      },
      devicePermissionColors(permission: string) {
          if(permission === 'r') {
              return 'secondary';
          } else if(permission === 'rw'){
              return 'accent';
          } else {
              return undefined;
          }
      },
      devicePermissionLabels(permission: string) {
          if(permission === 'r') {
              return this.$t('permission.read');
          } else if(permission === 'rw'){
              return this.$t('permission.write');
          } else {
              return permission;
          }
      },
      permissionColors(action: string) {
          if(action === 'GET') {
              return undefined;
          } else if(action === 'POST'){
              return 'success';
          } else if(action === 'PATCH'){
              return 'warning';
          } else if(action === 'DELETE'){
              return 'error';
          } else {
              return undefined;
          }
      },
      permissionLabels(action: string) {
          if(action === 'GET') {
              return this.$t('permission.read');
          } else if(action === 'POST'){
              return this.$t('permission.create');
          } else if(action === 'PATCH'){
              return this.$t('permission.update');
          } else if(action === 'DELETE'){
              return this.$t('permission.delete');
          } else {
              return action;
          }
      },
      autoComplete,
      getDays(date: Date) {
        return Math.floor(( date.valueOf() - new Date().valueOf() ) / 86400000); 
      },
      getColor(date: Date) {
        let diff =  this.getDays(date);
        if (diff <= 0) return 'red'
        else if (diff <= 7) return 'orange'
        else return 'green'
      },
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                this.getAssociatedDevices();
                this.loadPermissions();
                this.getAssociatedApiKeys();
                await this.loadAssociatedTenant();
                if(Object.keys(this.userTenant).length > 0) {
                  this.loadMeteringUser();
                  this.loadMeteringUserDevices();
                }
            }
        }
    },
    computed: {
        extendedAssociatedMeteringDevices(): any[] {
          let result = [];

          for(const mdev of this.associatedMeteringDevices) {
            let dev = this.associatedDevices.find((value: any) => value.uuid === mdev.uuid)
            if(dev) {
              dev['permission'] = mdev.permission;
              result.push(dev);
            } else {
              result.push(mdev)
            }
          }
          return result;
        },
        filteredAssociatedDevices(): any[] {
            return this.associatedDevices.filter((value: any) => this.autoComplete(value, this.associatedDeviceFilter));
        },
        filteredAssociatedMeteringDevices(): any[] {
            return this.extendedAssociatedMeteringDevices.filter((value: any) => this.autoComplete(value, this.associatedMeteringDevicesFilter));
        },
        meteringState(): string {
          if(this.meteringActive) return this.$t('services.active');
          else return this.$t('services.inactive');
        },
        meteringStateColor(): string {
          if(this.meteringActive) return 'green';
          else return 'grey lighten';
        },
        hasTenant(): boolean {
            return Object.keys(this.userTenant).length > 0;
        },
        hasPermissions(): boolean  {
            return Object.keys(this.userPermissions).length > 0;
        },
        requiredError(): any {
            return this.$t('required');
        },
        emailValidError(): any {
            return this.$t('login.validEmail');
        },
        isAdmin(): any {
            return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
                && (this.$root.$store.state.session.permissions.role === 'superadmin' || 
                this.$root.$store.state.session.permissions.role === 'admin');
        },
        isRoot(): any {
            return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
                && this.$root.$store.state.session.permissions.role === 'superadmin';
        }
    }
  })
