
  import Vue from 'vue'

  export default Vue.extend({
    name: 'DatePicker',

    props: ['value', 'label', 'rules', 'dense', 'clearable', 'disabled', 'hint', 'allowedDates'],
    data() {
      return {
        dateMenu: false,
        datePicker: true,
        dates: undefined as string[] | undefined,
        errorMessages: [] as string[],
        error: false,
      }
    },
    created() {
      this.dates = (this.value ? [this.toLocaleDateString(new Date(this.value[0])), this.toLocaleDateString(new Date(this.value[1]))] : undefined);

      // So that the value is emitted atleast once
      this.onDateSave();
    },
    methods: {
      onInputClear() {
        this.dateMenu = false;
        this.datePicker = true;

        this.dates = undefined;
        this.$emit("change", ["", ""]);
        this.$emit('input', ["", ""]);
      },
      onDateSave() {
        this.dateMenu = false;
        this.datePicker = true;

        this.$emit("change", this.utcDateStrings);
        this.$emit('input', this.utcDateStrings);
        // console.log(this.utcDateString);
      },   
      toLocaleDateString(date: Date): string {
        if(date) {
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const day = ('0' + date.getDate()).slice(-2);
          const year = date.getFullYear();
          return year + "-" + month + '-' + day;
        } else {
          return "";
        }
      },
    },
    computed: {
        localeDateString(): string {
          if(this.dateTimes.length > 0) {
            return this.dateTimes.map((value: Date) => value.toLocaleDateString()).join(' - ');
          } else {
            return "";
          }
        },
        utcDateStrings(): string[] | undefined {
          if(this.dateTimes.length > 0) {
            return this.dateTimes.map((value: Date) => value.toISOString());
          } else {
            return undefined;
          }
        },
        dateTimes(): Date[] {
          if(this.dates) {
            return [new Date(this.dates[0]), new Date(this.dates[1])];
          } else {
            return [];
          }
        }        
    },
    watch: {
      value(value: Date[]) {
        this.dates = (this.value ? [this.toLocaleDateString(new Date(value[0])), this.toLocaleDateString(new Date(value[1]))] : undefined);
      },
      dateTime(value: Date) {
        if(this.rules) {
          this.rules.forEach((rule: any) => {
            this.error = false;
            this.errorMessages = [];

            const res = rule(value);
            if(res !== true) {
              this.error = true;
              this.errorMessages.push(res);
            }
          });
        }
      }
    }
  })
