import { render, staticRenderFns } from "./TenantsView.vue?vue&type=template&id=216f7b89&scoped=true&"
import script from "./TenantsView.vue?vue&type=script&lang=ts&"
export * from "./TenantsView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "216f7b89",
  null
  
)

export default component.exports