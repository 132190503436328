
  import DeviceShareService from '@/services/DeviceShareService';
  import Vue from 'vue'
  import { v4 as uuidv4 } from 'uuid';
  import router from '@/router';
import LabelingService from '@/services/LabelingService';

  export default Vue.extend({
    name: 'NotificationsMenu',

    props: [],
    components: {
    },
    data: () => ({
      menu: false,
      refreshInterval: undefined as any,
      labelsInterval: undefined as any,
    }),
    created() {
      if(!this.isRoot) {
        this.updateDeviceShares();
        this.refreshInterval = setInterval(this.updateDeviceShares, 15*60*1000);
      }
      this.labelsInterval = setInterval(this.updateLabels, 3000);

    },
    destroyed() {
      if(this.refreshInterval)
        clearInterval(this.refreshInterval);

      if(this.labelsInterval)
        clearInterval(this.labelsInterval);
    },
    watch: {
      menu(value: boolean) {
        if(!value) {
          setTimeout(() => {
            for(const notification of this.$root.$store.state.volatile.notifications) {
              this.onNotificationRead(notification);
            }
          }, 500);
        }
      }
    },
    methods: {
      onNotificationRemove(item: any) {
        this.$root.$store.commit('removeNotification', item);
        this.menu = false;
      },
      onNotificationRead(item: any) {
        if(item.new) {
          item.new = false;
          this.$root.$store.commit('updateNotification', item);
        }
      },
      onNotificationClear() {
        this.$root.$store.commit('clearNotifications');
        this.menu = false;
      },
      onLinkClick(event: any) {
        router.push(event.link);
        event.new = false;
        this.$root.$store.commit('updateNotification', event);
        this.menu = false;
      },
      async onDownloadClick(item: any) {
        const res = await LabelingService.getDeviceLabel(item.payload.tenantId, item.payload.uuid);

        if ((navigator as any).msSaveBlob) { // IE 10+
            (navigator as any).msSaveBlob(res, 'device-labels.pdf');
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(res);
                link.setAttribute("href", url);
                link.setAttribute("download", 'device-labels.pdf');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
      },
      async updateLabels() {
        // iterate all notifcations with type labels
        // check for each if return is undefined or not 
        for(let i=0; i<this.$root.$store.state.volatile.notifications.length; i++) {
          const notification = this.$root.$store.state.volatile.notifications[i];

          if(notification.type === 'labels') {
            if(!notification.finished) {
              const res = await LabelingService.getDeviceLabel(notification.payload.tenantId, notification.payload.uuid);
              if(res) {
                notification.finished = true;
                notification.new = true;
                this.$root.$store.commit('updateNotification', notification);
              }
            }

            if(notification.payload.expiration < Date.now()) {
              this.$root.$store.commit('removeNotification', notification);
            }
          }

        }
      },
      async updateDeviceShares() {
        this.$root.$store.commit('clearNotifications');

        const now = new Date(Date.now());
        const deviceshares = await DeviceShareService.getAllCollectionPages({ state: 'open' });

        if(deviceshares.length > 0) {

          this.$store.commit("addNotification", {
              uuid: uuidv4(),
              type: 'deviceshares',
              title: this.$t('deviceShare.openSharesTitle'),
              details: deviceshares.length + this.$t('deviceShare.openSharesText'),
              link: '/deviceshares',
              createdAt: now.toISOString(),
              new: true
            }
          );
        }
      }
    },
    computed: {
      isRoot(): any {
          return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
              && this.$root.$store.state.session.permissions.role === 'superadmin';
      },
    }
  })
