
  import Vue from 'vue'

  import ApiKeyInfoDialog from '@/components/entities/apikey/ApiKeyInfoDialog.vue';
  import ApiKeyDeleteDialog from '@/components/entities/apikey/ApiKeyDeleteDialog.vue';
  import ApiKeyGenerateTokenDialog from '@/components/entities/apikey/ApiKeyGenerateTokenDialog.vue';
  import ApiKeyEditDialog from '@/components/entities/apikey/ApiKeyEditDialog.vue';

  import { objectsToCSV, filterItem } from '@/utils/Util';
import ApiKeyService from '@/services/ApiKeyService';

  export default Vue.extend({
    name: 'ApiKeysTable',

    components: {
      ApiKeyDeleteDialog,
      ApiKeyGenerateTokenDialog,
      ApiKeyInfoDialog,
      ApiKeyEditDialog
    },
    props: ['apikeys', 'loading'],
    data: (vm: any): any => ({
      loadingUsers: false,
      currentItems: [],
      tableLoadingText: vm.loadingText,
      search: "",
      showUUIDs: false,
      numberOfEntities: 0,
      selectedHeaders: [
          { text: vm.$t('entity.user') + ' ' + vm.$t('attribute.email'), value: 'user', index: 1 },
          { text: vm.$t('attribute.name'), value: 'name', index: 2 },
          { text: vm.$t('attribute.expiration'), value: 'expiration', index: 3 },
          { text: vm.$t('attribute.updatedAt'), value: 'updatedAt', index: 8 },
          { text: vm.$t('attribute.createdAt'), value: 'createdAt', index: 9 },
      ],
    }),
    created() {
      if(this.apikeys.length === 0) {
        this.tableLoadingText = this.$t('apikey.noData');
      }

      const columns = this.$root.$store.getters.selectedColumns('apikey');
      if(columns) {
        this.selectedHeaders = columns;
      }
    },
    watch: {
      selectedHeaders() {
        this.$store.commit("setSelectedColumns", { type: 'apikey', columns: this.selectedHeaders });
      },
      apikeys() {
        // console.log(this.apikeys);
      }
    },
    methods: {
      onCurrentItemsChange(items: any[]) {
        this.currentItems = items;
      },
      getUserLabel(item: any) {
        if(!('user' in item) && !this.loadingUsers) {
          this.loadUsers();
        }

        return item?.user?.email || '';
      },
      async loadUsers() {
        if(!this.loadingUsers && this.currentItems.length > 0) {
          this.loadingUsers = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (value: any) => {
            if(!value.targetTenant)
              promises.push(this.loadApiKeyUser(value));
          });
          await Promise.all(promises);
          this.loadingUsers = false;
        }
      }, 
      async loadApiKeyUser(item: any) {
        try {
            const user = await ApiKeyService.getAssociatedUser(item.uuid);
            Vue.set(item, 'user', user); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(item, 'user', {});
        }
      },
      filterItem,
      onExport() {
          let filteredItems = this.apikeys;
          if(this.$refs.dataTable.$children[0].filteredItems) {
            filteredItems = this.$refs.dataTable.$children[0].filteredItems;
          }

          // Data structure
          const data = filteredItems.map((conn: any, index: number) => Object({
            uuid: conn.uuid,
            userId: conn.user.uuid,
            userEmail: conn.user.email,
            name: conn.name,
            expiration: conn.expiration,
            createdAt: conn.createdAt,
            updatedAt: conn.updatedAt,
          }));

          const filename = 'bascloud_apikeys.csv';
          const csvContent = objectsToCSV(data);
          var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

          if ((navigator as any).msSaveBlob) { // IE 10+
              (navigator as any).msSaveBlob(blob, filename);
          } else {
              var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", filename);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }
          }
      },
      onReload() {
        this.$emit("reload");
      },
      onItemUpdate(item: any) {
        this.$emit("update", item);
      },
      onItemDelete(item: any) {
        this.$emit("delete", item);
      },
      onPaginationAndFilter(pagination: any) {
        this.numberOfEntities = pagination.itemsLength;
      },
      onTableRowClick(row: any) {
        const button: any = this.$refs["apikeyInfoButton_" + row.uuid];
        // $el needed for native elements
        button.$el.click();
      },
      userField(item: any) {
        return item.user?.email || '';
      },
      getDays(date: Date) {
        return Math.floor(( date.valueOf() - new Date().valueOf() ) / 86400000); 
      },
      getColor(date: Date) {
        let diff =  this.getDays(date);
        if (diff <= 0) return 'red'
        else if (diff <= 7) return 'orange'
        else return 'green'
      },
    },
    computed: {
      headers() {
        return [
          { text: this.$t('attribute.uuid'), value: 'uuid', index: 0 },
          { text: this.$t('entity.user') + ' ' + this.$t('attribute.email'), value: 'user', index: 1 },
          { text: this.$t('attribute.name'), value: 'name', index: 2 },
          { text: this.$t('attribute.expiration'), value: 'expiration', index: 3 },
          { text: this.$t('attribute.updatedAt'), value: 'updatedAt', index: 8 },
          { text: this.$t('attribute.createdAt'), value: 'createdAt', index: 9 },
        ];
      },
      loadingText(): any {
        return this.$t('loadingData');
      },
      computedTableHeader(): any {
        return [...this.selectedHeaders,
          { text: this.$t('attribute.actions'), value: 'actions', sortable: false, filterable: false, index: 100 },
        ].sort((a: any, b: any) => a.index-b.index);
      },
      canUpdate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'apikeys' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['apikeys'].includes('PATCH');
        } else {
          return false;
        }
      },
      canDelete(): any {
        if (this.$root.$store.state.session.permissions.resources && 'apikeys' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['apikeys'].includes('DELETE');
        } else {
          return false;
        }
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'apikeys' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['apikeys'].includes('POST');
        } else {
          return false;
        }
      }, 
    },
  })
