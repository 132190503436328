
  import Vue from 'vue'
  import AuthService from '@/services/AuthService';

  import UserRequestPasswordDialog from '@/components/entities/user/UserRequestPasswordDialog.vue'
  import UserChangePasswordDialog from '@/components/entities/user/UserChangePasswordDialog.vue'
import { extractErrorMessage, parseJwt } from '@/utils/Util';

  export default Vue.extend({
    name: 'Login',

    components: {
      UserRequestPasswordDialog,
      UserChangePasswordDialog
    },
    data: (vm: any): any => ({
        logoPath: require('@/assets/bascloud-logo.png'),
        tab: 0,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        valid: true,
        registerEmail: "",
        registerPassword: "",
        verifyRegisterPassword: "",
        loginPassword: "",
        loginEmail: "",
        resetToken: "",
        showChangePasswordDialog: false,
        emailRules: [
            (v: string) => !!v || vm.requiredError,
            (v: string) => /.+@.+\..+/.test(v) || vm.emailValidError
        ],
        showPassword: false,
        rules: {
            required: (v: string) => !!v || vm.requiredError,
            min: (v: string) => (v && v.length >= 3) || vm.passwordLengthError
        }
    }),
    created() {
        if('token' in this.$route.query && 'email' in this.$route.query) {
          this.loginEmail = this.$route.query.email as string;
          this.resetToken = this.$route.query.token as string;
          this.showChangePasswordDialog = true;
          // Reset current session
          this.$store.commit('logout');
        } else if('llt' in this.$route.query) {
          // Reset current session
          this.$store.commit('logout');
          const token = window.atob(this.$route.query.llt);
          const decoded = parseJwt(token);
          
          let expiration;
          const now = Date.now();
          if('apiKey' in decoded && !('exp' in decoded)) {
              // key does not expire
              const date = new Date(Date.now())
              date.setFullYear(date.getFullYear()+10);
              expiration = date.getTime();
          } else {
              expiration = decoded.exp * 1000;
          }
          this.$store.commit("setToken", { token, expiration });

          if (this.$root.$store.getters.loggedIn(Date.now())) {
            this.$router.replace("/");
          } else {
            const dateString = new Date(expiration).toLocaleString();
            this.alertType = "error";
            this.alertText = this.$t('login.errorExpiredToken') + dateString;
            this.alertVisible = true;
          }
        }
    },
    computed: {
        tabs() {
            return [
            {name: this.$t('button.login'), icon:"mdi-account"},
            // {name: this.$t('button.register'), icon:"mdi-account-outline"}
        ];
        },
        passwordMatch() {
            return () => this.registerPassword === this.verifyRegisterPassword || this.$t('login.passwordMatch');
        },
        loginForm(): any {
            return this.$refs.loginForm;
        },
        registerForm(): any {
            return this.$refs.registerForm;
        },
        passwordLengthError(): any {
            return this.$t('login.minPasswordLength');
        },
        requiredError(): any {
            return this.$t('required');
        },
        emailValidError(): any {
            return this.$t('login.validEmail');
        }
    },
    methods: {
        validate() {
            if(this.tab === 0 && this.loginForm.validate()) {
                this.login();
            } else if(this.tab === 1 && this.registerForm.validate()) {
                this.register();
            }
        },
        reset() {
            if(this.tab === 0) {
                this.loginForm.reset();
            } else if(this.tab === 1) {
                this.registerForm.reset();
            }
        },
        resetValidation() {
            if(this.tab === 0) {
                this.loginForm.resetValidation();
            } else if(this.tab === 1) {
                this.registerForm.resetValidation();
            }
        },
        login() {
            this.loading = true;

            AuthService.login(this.loginEmail.trim(), this.loginPassword).then((data: any) => {
                this.loading = false;

                this.$store.commit("setToken", data);

                if (this.$root.$store.getters.loggedIn(Date.now())) {
                    this.$router.replace("/");
                } else {
                    const dateString = new Date(data.expiration).toLocaleString();
                    this.alertType = "error";
                    this.alertText = this.$t('login.errorExpiredToken') + dateString;
                    this.alertVisible = true;
                }
            }).catch((err) => {
                this.alertType = "error";
                if(err.response && err.response.status === 401) {
                    this.alertText = this.$t('login.errorLogin');
                } else {
                    this.alertText = this.$t('login.errorLogin') + ' ' + extractErrorMessage(err);
                    console.log(err);
                    console.log(err.response);
                }
                this.alertVisible = true; 
                this.loading = false;
            });
        },
        // register() {
        //     this.loading = true;

        //     AuthService.register(this.registerEmail.trim(), this.registerPassword).then((response) => {
        //         this.loading = false;

        //         if(response.data.data.type && response.data.data.type == 'users') {
        //             this.alertType = "success";
        //             this.alertText = this.$t('login.successRegistration') as string;
        //             this.alertVisible = true;

        //             this.loginEmail = this.registerEmail;
        //             this.tab = 0;
        //         }
        //     }).catch((err) => {
        //         console.log(err);
        //         console.log(err.response);
        //         this.alertType = "error";
        //         if(err.response) {
        //             this.alertText = this.$t('login.errorRegistration') + err.response.data.errors[0].title;
        //         } else {
        //             this.alertText = this.$t('login.errorRegistration') as string;
        //         }
        //         this.alertVisible = true;
        //         this.loading = false;
        //     });
        // }
    },
  })
