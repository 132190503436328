
  import Vue from 'vue'

  import UserInfoDialog from '@/components/entities/user/UserInfoDialog.vue'
  import UserEditDialog from '@/components/entities/user/UserEditDialog.vue'
  import UserDeleteDialog from '@/components/entities/user/UserDeleteDialog.vue'
  import { objectsToCSV, filterItem } from '@/utils/Util';

  export default Vue.extend({
    name: 'UsersTable',

    components: {
      UserInfoDialog,
      UserEditDialog,
      UserDeleteDialog
    },
    props: ['users', 'loading'],
    data: (vm: any): any => ({
      tableLoadingText: vm.loadingDataText,
      search: "",
      showUUIDs: false,
      numberOfEntities: 0,
      selectedHeaders: [
          { text: vm.$t('attribute.email'), value: 'email', index: 1 },
          { text: vm.$t('attribute.role'), value: 'role', index: 2 },
          { text: vm.$t('attribute.createdAt'), value: 'createdAt', index: 9 },
          { text: vm.$t('attribute.lastActiveAt'), value: 'lastActiveAt', index: 10 },
      ],
    }),
    async created() {
      if(this.users.length === 0) {
        this.tableLoadingText = this.$t('user.noData') as string;
      }

      const columns = this.$root.$store.getters.selectedColumns('user');
      if(columns) {
        this.selectedHeaders = columns;
      }
    },
    watch: {
      selectedHeaders() {
        this.$store.commit("setSelectedColumns", { type: 'user', columns: this.selectedHeaders });
      } 
    },
    methods: {
      filterItem,    
      userRoleColor(role: string) {
        if(role === 'user') {
          return 'green';
        } else if(role === 'admin') {
          return 'warning'
        } else if(role === 'superadmin') {
          return 'error'
        }
        return 'grey lighten';
      },
      onExport() {
          let filteredItems = this.users;
          if(this.$refs.dataTable.$children[0].filteredItems) {
            filteredItems = this.$refs.dataTable.$children[0].filteredItems;
          }

          // Data structure
          const data = filteredItems.map((user: any, index: number) => Object({
            uuid: user.uuid,
            email: user.email,
            role: user.role,
            lastActiveAt: user.lastActiveAt,
            createdAt: user.createdAt,
            updatedAt: user.updatedAt
          }));

          const filename = 'bascloud_users.csv';
          const csvContent = objectsToCSV(data);
          var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

          if ((navigator as any).msSaveBlob) { // IE 10+
              (navigator as any).msSaveBlob(blob, filename);
          } else {
              var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", filename);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }
          }
      },  
      onReload() {
        this.$emit("reload");
      },
      isCurrentUser(item: any) {
        return item.email===this.$root.$store.state.session.currentUser.email
      },
      onUserItemEdit(item: any) {
        this.$emit("update", item);
      },
      onUserItemDelete(item: any) {
        this.$emit("delete", item);
      },
      onPaginationAndFilter(pagination: any) {
        this.numberOfEntities = pagination.itemsLength;
      },
      onTableRowClick(row: any) {
        const button: any = this.$refs["userInfoButton_" + row.uuid];
        // $el needed for native elements
        button.$el.click();
      },
    },
    computed: {
      headers() {
        return [
          { text: this.$t('attribute.uuid'), value: 'uuid', index: 0 },
          { text: this.$t('attribute.email'), value: 'email', index: 1 },
          { text: this.$t('attribute.role'), value: 'role', index: 2 },
          { text: this.$t('attribute.updatedAt'), value: 'updatedAt', index: 8 },
          { text: this.$t('attribute.createdAt'), value: 'createdAt', index: 9 },
          { text: this.$t('attribute.lastActiveAt'), value: 'lastActiveAt', index: 10 },
        ];
      },
      loadingDataText(): string {
        return this.$t('loadingData');
      },
      computedTableHeader(): any {
        return [...this.selectedHeaders,
          { text: this.$t('attribute.actions'), value: 'actions', sortable: false, filterable: false, index: 100 },
        ].sort((a: any, b: any) => a.index-b.index);
      },
      canUpdate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'users' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['users'].includes('PATCH');
        } else {
          return false;
        }
      },
      canDelete(): any {
        if (this.$root.$store.state.session.permissions.resources && 'users' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['users'].includes('DELETE');
        } else {
          return false;
        }
      },
    },
  })
