
  import Vue from 'vue'
  import { autoComplete, extractErrorMessage } from '@/utils/Util';

  import { validate as validateUUID } from 'uuid';
  import DateTimePicker from '@/components/misc/DateTimePicker.vue'
  import DeviceService from '@/services/DeviceService';
import DeviceShareService from '@/services/DeviceShareService';

  export default Vue.extend({
    name: 'DevieShareCreateDialog',

    components: {
      DateTimePicker
    },
    props: [],
    data: (vm: any): any => ({
        dialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingDevices: false,
        requiredRule: [
          (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
            (v: any) => !!v && Object.keys(v).length > 0 || vm.requiredError
        ],
        requiredAtleastOne: [
            (v: any) => !!v && v.length > 0 || vm.requiredError
        ],
        uuidRule: [
            (v: string) => !!v || vm.requiredError,
            (v: string) => validateUUID(v) || vm.uuidError,
        ],
        devices: [] as any[],
        sourceTenant: {} as any,
        deviceshare: {
          from: null,
          until: null,
          cascadeUpdates: false,
          cascadeDeletes: false,
          targetTenantId: ""
        },
        sourceDevices: [] as any[]
    }),
    methods: {
      validate() {
        if(this.createForm.validate()) {
          this.create();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.sourceDevices = [];
        this.deviceshare = {
          from: null,
          until: null,
          cascadeUpdates: false,
          cascadeDeletes: false,
          targetTenantId: ""
        };
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async create() {
        this.loading = true;

        const sourceTenantId = this.sourceTenant.uuid;
        const targetTenantId = this.deviceshare.targetTenantId;
        const cascadeUpdates = this.deviceshare.cascadeUpdates;
        const cascadeDeletes = this.deviceshare.cascadeDeletes;
        let from;
        let until;

        if(this.deviceshare.from) {
            from = new Date(this.deviceshare.from);
        }
        if(this.deviceshare.until) {
            until = new Date(this.deviceshare.until);
        }
        
        for(const device of this.sourceDevices) {
          try {
            const deviceshare = await DeviceShareService.createDeviceShare(sourceTenantId, device.uuid, targetTenantId, cascadeUpdates, cascadeDeletes, from, until)
            this.alertType = "success";
            this.alertText = this.$t('deviceType.successCreate') as string;
            this.alertVisible = true;

            this.$emit("success", deviceshare);
          } catch(err: any) {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('deviceType.errorCreate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;
          }
        }
        
        setTimeout(() => {
            this.dismiss(); 
        }, 1500);
      },
      autoComplete,      
      getSourceDevices() {
        this.loadingDevices = true;
        this.devices = [];

        DeviceService.getAllCollectionPages(this.sourceTenant.uuid).then((devices: any[]) => {
          this.loadingDevices = false;

          this.devices = devices;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;    
          this.loadingDevices = false;  
        });
      },
    },
    watch: {
      dialog(value: boolean) {
        if(value) {
          if(this.$root.$store.state.session.tenants.length === 1) {
            this.sourceTenant = this.$root.$store.state.session.tenants[0];
          }
        } else {
          this.clear();
        }
      },
      sourceTenant(value: any) {
        if(this.sourceTenant) {
          this.getSourceDevices();
        }
      }
    },
    computed: {
        createForm(): any {
            return this.$refs.createForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        uuidError(): any {
            return this.$t('invalidUUID');
        },
        isRoot(): any {
            return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
                && this.$root.$store.state.session.permissions.role === 'superadmin';
        },
    }
  })
