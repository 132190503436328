
import Vue from 'vue'
import DeviceService from '@/services/DeviceService'
import MeteringService from '@/services/MeteringService'

import { autoComplete, extractErrorMessage } from '@/utils/Util'
import { RRule, rrulestr } from 'rrule'

import DatePicker from '@/components/misc/DatePicker.vue'

const frequencyInputsTable = {
  yearly: {
    rrule: RRule.YEARLY,
    until: true,
    interval: true,
    month: true,
    day_of_month: true,
    day_of_the_week: false,
  },
  monthly: {
    rrule: RRule.MONTHLY,
    until: true,
    interval: true,
    month: false,
    day_of_month: true,
    day_of_the_week: false,
  },
  weekly: {
    rrule: RRule.WEEKLY,
    until: true,
    interval: true,
    month: false,
    day_of_month: false,
    day_of_the_week: true,
  },
  daily: {
    rrule: RRule.DAILY,
    until: true,
    interval: true,
    month: false,
    day_of_month: false,
    day_of_the_week: false,
  },
  once: {
    rrule: null,
    until: false,
    interval: false,
    month: false,
    day_of_month: false,
    day_of_the_week: false,
  },
}

const range = (n: number) => Array.from({ length: n }, (v, k) => k + 1)
const thirty1 = range(31)
const thirty = range(30)
const twenty9 = range(29)

const daysPerMonth = {
  January: thirty1,
  February: twenty9,
  March: thirty1,
  April: thirty,
  May: thirty1,
  June: thirty,
  July: thirty1,
  August: thirty1,
  September: thirty,
  October: thirty1,
  November: thirty,
  December: thirty1,
}

const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export default Vue.extend({
  name: 'TaskEditDialog',

  components: {
    DatePicker,
  },
  props: ['task'],
  data: (vm: any): any => ({
    frequencyInputsTable,
    daysPerMonth,
    weekdays,
    repetitionSelection: '',
    rrule: {
      interval: null,
      start: new Date(Date.now()).toISOString(),
      frequency: null,
      until_num: null,
      month: null,
      daysOfTheMonth: [],
      daysOfTheWeek: [],
      until_date: null,
    },
    dialog: false,
    valid: true,
    alertText: '',
    alertVisible: false,
    alertType: 'error',
    loading: false,
    loadingDevice: false,
    requiredRule: [(v: string) => !!v || vm.requiredError],
    requiredSelect: [
      (v: any) => (!!v && Object.keys(v).length > 0) || vm.requiredError,
    ],
    devices: [] as any[],
    localSelectedDevice: {} as any,
    localTask: {
      note: '',
    } as any,
  }),
  created() {
    this.localTask = Object.assign({}, this.task)
    if (this.task.device) {
      this.localSelectedDevice = this.task.device
    } else {
      this.loadDevice()
    }
  },
  methods: {
    validate() {
      if (this.createForm.validate()) {
        this.updateTask()
      }
    },
    close() {
      this.dialog = false
    },
    clear() {
      this.localTask = {
        note: '',
      }
      this.rrule = {
        interval: null,
        start: new Date(Date.now()).toISOString(),
        frequency: null,
        until_num: null,
        month: null,
        daysOfTheMonth: [],
        daysOfTheWeek: [],
        until_date: null,
      }
      this.localSelectedDevice = {}
      this.alertVisible = false
      this.loading = false
    },
    dismiss() {
      this.clear()
      this.close()
    },
    updateTask() {
      this.loading = true

      let note = this.localTask.note
      const start = new Date(this.rrule.start)

      let forceQRScan
      if (this.task.forceQRScan !== this.localTask.forceQRScan) {
        forceQRScan = this.localTask.forceQRScan
      }

      let _rrule = {
        dtstart: new Date(
          Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())
        ),
      } as any

      if (this.rrule.frequency && this.rrule.frequency.length > 0) {
        if (this.rrule.frequency.toLowerCase() === 'once') {
          // special case, once is not supported by rrule so we create
          // a task with yearly occure but only once
          _rrule.freq = RRule.YEARLY
          _rrule.count = 1
        } else {
          _rrule.freq =
            this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].rrule
        }
      }
      if (
        this.frequencyInputsTable[this.rrule.frequency.toLowerCase()]
          .interval &&
        this.rrule.interval
      ) {
        _rrule.interval = Number(this.rrule.interval)
      }
      if (this.repetitionSelection === 'until_count' && this.rrule.until_num) {
        _rrule.count = Number(this.rrule.until_num)
      }
      if (this.repetitionSelection === 'until_date' && this.rrule.until_date) {
        const until = new Date(this.rrule.until_date)
        _rrule.until = new Date(
          Date.UTC(until.getFullYear(), until.getMonth(), until.getDate())
        )
      }
      if (
        this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].month &&
        this.rrule.month
      ) {
        _rrule.bymonth = Object.keys(daysPerMonth).indexOf(this.rrule.month) + 1
      }
      if (
        this.frequencyInputsTable[this.rrule.frequency.toLowerCase()]
          .day_of_month &&
        this.rrule.daysOfTheMonth.length > 0
      ) {
        _rrule.bymonthday = this.rrule.daysOfTheMonth
      }
      if (
        this.frequencyInputsTable[this.rrule.frequency.toLowerCase()]
          .day_of_the_week &&
        this.rrule.daysOfTheWeek.length > 0
      ) {
        _rrule.byweekday = this.rrule.daysOfTheWeek.map((value: string) =>
          this.weekdays.findIndex((day: string) => day === value)
        )
      }
      // console.log(_rrule);
      const rule = new RRule(_rrule)
      // console.log(rule.toString())

      let rrule = rule.toString()

      let associatedDeviceUUID = this.localSelectedDevice.uuid

      MeteringService.updateDeviceTask(
        this.$root.$store.state.session.selectedTenant.uuid,
        associatedDeviceUUID,
        rrule,
        note,
        forceQRScan
      )
        .then((task) => {
          this.alertType = 'success'
          this.alertText = this.$t('task.successUpdate')
          this.alertVisible = true

          task.device = this.localSelectedDevice
          this.$emit('success', task)

          setTimeout(() => {
            this.dismiss()
          }, 1500)
        })
        .catch((err: any) => {
          console.log(err, err.response)
          this.alertType = 'error'
          this.alertText =
            this.$t('task.errorUpdate') + ' ' + extractErrorMessage(err)
          this.alertVisible = true
          this.loading = false
        })
    },
    getDevices() {
      this.loadingDevice = true

      DeviceService.getAllCollectionPages(this.currentTenant)
        .then((devices: any[]) => {
          this.loadingDevice = false

          this.devices = devices
        })
        .catch((err: any) => {
          console.log(err, err.response)
          this.alertType = 'error'
          this.alertText =
            this.$t('device.loadingError') + ' ' + extractErrorMessage(err)
          this.alertVisible = true
          this.loadingDevice = false
        })
    },
    parseRRule() {
      let _rrule = rrulestr(this.task.rrule)

      this.rrule.start = _rrule.options.dtstart

      if (_rrule.options.freq === RRule.YEARLY) {
        if (_rrule.options.count === 1) {
          // this means its once
          this.rrule.frequency = 'Once'
        } else {
          this.rrule.frequency = 'Yearly'
        }
      } else if (_rrule.options.freq === RRule.MONTHLY) {
        this.rrule.frequency = 'Monthly'
      } else if (_rrule.options.freq === RRule.WEEKLY) {
        this.rrule.frequency = 'Weekly'
      } else if (_rrule.options.freq === RRule.DAILY) {
        this.rrule.frequency = 'Daily'
      } else {
        // unsupported
        // TODO
      }

      if (
        this.frequencyInputsTable[this.rrule.frequency.toLowerCase()]
          .interval &&
        _rrule.options.interval
      ) {
        this.rrule.interval = Number(_rrule.options.interval)
      }
      if (_rrule.options.count && this.rrule.frequency !== 'Once') {
        this.repetitionSelection = 'until_count'
        this.rrule.until_num = Number(_rrule.options.count)
      }
      if (_rrule.options.until) {
        this.repetitionSelection = 'until_date'
        this.rrule.until_date = new Date(_rrule.options.until).toISOString()
      }
      if (!this.rrule.until_num && !this.rrule.until_date) {
        // case its indefinitly
        this.repetitionSelection = 'forever'
      }
      if (
        this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].month &&
        _rrule.options.bymonth
      ) {
        const bymonth = _rrule.options.bymonth
        if (bymonth.length > 0 && typeof bymonth[0] === 'number')
          this.rrule.month = Object.keys(daysPerMonth)[bymonth[0] - 1]
      }
      if (
        this.frequencyInputsTable[this.rrule.frequency.toLowerCase()]
          .day_of_month &&
        _rrule.options.bymonthday
      ) {
        this.rrule.daysOfTheMonth = _rrule.options.bymonthday
      }
      if (
        this.frequencyInputsTable[this.rrule.frequency.toLowerCase()]
          .day_of_the_week &&
        _rrule.options.byweekday
      ) {
        this.rrule.daysOfTheWeek = _rrule.options.byweekday.map(
          (day: number) => this.weekdays[day]
        )
      }
    },
    async loadDevice() {
      try {
        this.loadingDevice = true
        const device = await DeviceService.getSingle(
          this.currentTenant,
          this.task.deviceId
        )
        Vue.set(this.task, 'device', device) // important else DOM is not updated
        this.localSelectedDevice = this.task.device
        this.loadingDevice = false
      } catch (err: any) {
        console.log(err)
        console.log(err.response)
        Vue.set(this.task, 'device', {})
        this.loadingDevice = false
      }
    },
    autoComplete,
  },
  watch: {
    async dialog(value: boolean) {
      if (value) {
        this.localTask = Object.assign({}, this.task)
        if (this.task.device) {
          this.localSelectedDevice = this.task.device
        } else {
          this.loadDevice()
        }
        this.parseRRule()
        this.getDevices()
      } else {
        this.clear()
      }
    },
  },
  computed: {
    intervalHint() {
      const rrule = this.rrule
      return `1 means every ${
        rrule.frequency === 'Daily' ? 'Day' : rrule.frequency.slice(0, -2)
      }, 2 is every other ${
        rrule.frequency === 'Daily' ? 'Days' : rrule.frequency.slice(0, -2)
      }`
    },
    frequencies(): any {
      // TODO add i18n translation
      const keys = Object.keys(frequencyInputsTable)
      return keys.map(
        (value: string) => value.charAt(0).toUpperCase() + value.slice(1)
      )
    },
    months(): any {
      const keys = Object.keys(daysPerMonth)
      return keys.map(
        (value: string) => value.charAt(0).toUpperCase() + value.slice(1)
      )
    },
    createForm(): any {
      return this.$refs.createForm
    },
    requiredError(): any {
      return this.$t('required')
    },
    currentTenant(): any {
      return this.$root.$store.state.session.selectedTenant.uuid
    },
  },
})
