
  import Vue from 'vue'
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import IoTDeviceService from '@/services/IoTDeviceService';
  import DeviceService from '@/services/DeviceService';
  import IotDeviceDisableDialog from './IotDeviceDisableDialog.vue';
  import TenantInfoDialog from '../tenant/TenantInfoDialog.vue';
  import PropertyInfoDialog from '../property/PropertyInfoDialog.vue';
  import ConnectorInfoDialog from '../connector/ConnectorInfoDialog.vue';
  import DeviceInfoDialog from '../device/DeviceInfoDialog.vue';
  import IotDevicePortConfigDialog from './IotDevicePortConfigDialog.vue';
  import IotDeviceReplacementDialog from './IotDeviceReplacementDialog.vue';
  import PropertyService from '@/services/PropertyService';
  import ConnectorService from '@/services/ConnectorService';
  import AlertsListDialog from '../alert/AlertsListDialog.vue';


  export default Vue.extend({
    name: 'IotDeviceEditDialog',

    props: ['iotdevice'],
    components: {
        EntityMetaDialog,
        IotDeviceDisableDialog,
        DeviceInfoDialog,
        IotDevicePortConfigDialog,
        IotDeviceReplacementDialog,
        AlertsListDialog
    },
    data: (vm: any): any => ({
        localdialog: false,
        localIotdevice: {} as any,
        valid: true,
        passwordToggle: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingTenant: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
          (v: any) => !!v && Object.keys(v).length > 0 || vm.requiredError
        ],
        vpnOptions: [
            'on',
            'off'
        ],
        hardwareComponents: [
          'base',
          'display',
          'mbus',
          'gsm',
          'ir-multiplexer'
        ],
        connectivityMethods: [
            'ETH',
            'WIFI',
            'GSM',
        ],
        iotDeviceTypes: [
            'E32',
            'P42',
            'OTHER',
        ],
        loadingProperties: false,
        loadingConnectors: false,
        connectors: [],
        properties: [],
        associatedTenant: null as any,
        associatedProperty: null as any,
        associatedConnector: null as any,
        associatedMonitoringDevice: {} as any,
        associatedAlerts: [],
        updatedAssociatedTenant: null as any,
        updatedAssociatedProperty: null as any,
        updatedAssociatedConnector: null as any,
    }),
    async created() {
        this.localIotdevice = JSON.parse(JSON.stringify(this.iotdevice));
    },
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedTenant = null;
        this.associatedProperty = null;
        this.associatedConnector = null;
        this.updatedAssociatedTenant = null;
        this.updatedAssociatedProperty = null;
        this.updatedAssociatedConnector = null;
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      onItemReplacement(iotdevice: any) {
        this.$emit("update", iotdevice);
        this.dismiss();
      },
      onItemDisable() {
        this.iotdevice.enabled = false;
        this.$emit("update", this.iotdevice);
        this.localIotdevice.enabled = false;
      },
      async onItemEnable() {
        await IoTDeviceService.activateIoTDevice(this.iotdevice.uuid);
        this.iotdevice.enabled = true;
        this.$emit("update", this.iotdevice);
        this.localIotdevice.enabled = true;
      },
      save() {
        this.alertVisible = false;
        this.loading = true;

        let type;
        let description;
        let state;
        let connectivity;
        let mqttPassword;
        let config;
        let hardware;
        let associatedTenantUUID;
        let associatedPropertyUUID;
        let associatedConnectorUUID;

        if(this.localIotdevice.type !== this.iotdevice.type) {
            type = this.localIotdevice.type;
        }
        if(this.localIotdevice.description !== this.iotdevice.description) {
            description = this.localIotdevice.description;
        }
        if(this.localIotdevice.connectivity !== this.iotdevice.connectivity) {
            connectivity = this.localIotdevice.connectivity;
        }
        if(this.localIotdevice.state !== this.iotdevice.state) {
            state = this.localIotdevice.state;
        }
        if(this.localIotdevice.mqttPassword !== this.iotdevice.mqttPassword) {
            mqttPassword = this.localIotdevice.mqttPassword;
        }
        if(JSON.stringify(this.localIotdevice.config) != JSON.stringify(this.iotdevice.config)) {
            config = this.localIotdevice.config;
        }
        if(JSON.stringify(this.localIotdevice.hardware) != JSON.stringify(this.iotdevice.hardware)) {
            hardware = this.localIotdevice.hardware;
        }
        // console.log(this.updatedAssociatedTenant, this.associatedTenant)
        if(this.updatedAssociatedTenant == null && this.associatedTenant != null) {
            associatedTenantUUID = "";
        } else if(this.updatedAssociatedTenant?.uuid !== this.associatedTenant?.uuid) {
            associatedTenantUUID = this.updatedAssociatedTenant?.uuid;
        }

        // console.log(this.updatedAssociatedProperty, this.associatedProperty)
        if(this.updatedAssociatedProperty == null && this.associatedProperty != null) {
            associatedPropertyUUID = "";
        } else if(this.updatedAssociatedProperty?.uuid !== this.associatedProperty?.uuid) {
            associatedPropertyUUID = this.updatedAssociatedProperty?.uuid;
        }        
        
        // console.log(this.updatedAssociatedConnector, this.associatedConnector)
        if(this.updatedAssociatedConnector == null && this.associatedConnector != null) {
            associatedConnectorUUID = "";
        } else if(this.updatedAssociatedConnector?.uuid !== this.associatedConnector?.uuid) {
            associatedConnectorUUID = this.updatedAssociatedConnector?.uuid;
        }

        IoTDeviceService.updateIoTDevice(this.iotdevice.uuid, type, description, connectivity, state, mqttPassword, config, hardware, associatedTenantUUID, associatedPropertyUUID, associatedConnectorUUID).then((iotdevice: any) => {

            this.alertType = "success";
            this.alertText = this.$t('iotdevice.successUpdate') as string;
            this.alertVisible = true;

            this.localIotdevice = iotdevice;

            this.$emit("update", iotdevice);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('iotdevice.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getProperties() {
        if(!this.updatedAssociatedTenant)
          return

        this.properties = [];
        this.loadingProperties = true;
        
        PropertyService.getAllCollectionPages(this.updatedAssociatedTenant.uuid).then((properties: any[]) => {
            this.loadingProperties = false;

            this.properties = properties;
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('property.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingProperties = false;     
        });
      },
      getConnectors() {
        if(!this.updatedAssociatedTenant)
          return

        this.connectors = [];
        this.loadingConnectors = true;

        ConnectorService.getAllCollectionPages(this.updatedAssociatedTenant.uuid).then((connectors: any[]) => {
          this.loadingConnectors = false;

          this.connectors = connectors;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('connector.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;  
          this.loadingConnectors = false;    
        });
      }, 
      async getAssociatedTenant() {
        try {
            this.associatedTenant = await IoTDeviceService.getAssociatedTenant(this.iotdevice.uuid);
            this.updatedAssociatedTenant = this.associatedTenant;
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
        }
      },
      async getAssociatedProperty() {
        try {
            this.associatedProperty = await IoTDeviceService.getAssociatedProperty(this.iotdevice.uuid);
            this.updatedAssociatedProperty = this.associatedProperty;
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
        }
      },
      async getAssociatedConnector() {
        try {
            this.associatedConnector = await IoTDeviceService.getAssociatedConnector(this.iotdevice.uuid);
            this.updatedAssociatedConnector = this.associatedConnector;

        } catch(err: any) {
            console.log(err);
            console.log(err.response);
        }
      },
      async getAssociatedMonitoringDevice() {
        try {
            this.associatedMonitoringDevice = await IoTDeviceService.getAssociatedMonitoringDevice(this.iotdevice.uuid);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
        }
      },
      async getAssociatedAlerts() {
        try {
            this.associatedAlerts = await IoTDeviceService.getAssociatedAlerts(this.iotdevice.uuid);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
        }
      },
      async onAlertDelete(item: any) {
        this.associatedAlerts.splice(this.associatedAlerts.findIndex((value: any) => value.uuid === item.uuid), 1);
      },
      async onAlertCreate(item: any) {
        this.associatedAlerts.push(item);
      },
      async onAlertUpdate(item: any) {
        this.associatedAlerts.splice(this.associatedAlerts.findIndex((value: any) => value.uuid === item.uuid), 1);
        this.associatedAlerts.push(item);
      },
      autoComplete,
    },
    watch: {
        updatedAssociatedTenant() {
            this.updatedAssociatedConnector = undefined;
            this.updatedAssociatedProperty = undefined;
            this.getProperties();
            this.getConnectors();
        },
        iotdevice() {
            this.localIotdevice = JSON.parse(JSON.stringify(this.iotdevice));
            
            if(this.localIotdevice.type === 'E32' && !('mbus' in this.localIotdevice.config)) {
                this.localIotdevice.config.mbus = {}
            }
        },
        async localdialog(value: boolean) {
            if(value) {
                this.getProperties();
                this.getConnectors();
                // this.getAssociatedMonitoringDevice();
                this.getAssociatedAlerts();
                this.getAssociatedTenant();
                this.getAssociatedProperty();
                this.getAssociatedConnector();
            } else {
                this.clear(); 
            }
        }
    },
    computed: {
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        },
        activeLabel() {
            if(this.iotdevice.enabled)
                return this.$t('enabled');
            return this.$t('disabled');
        },
        monitoringLabel() {
            if(this.associatedAlerts.length > 0)
                return this.$t('on');
            return this.$t('off');
        },
    }
  })
