import store from '../store/index'
import router from '../router/index'
import MobileAnalyticsApi from '@/api/MobileAnalyticsApi';

export default {
    async getTenantLogsCollection(tenantUUID: string, params?: any): Promise<any[]> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await MobileAnalyticsApi.getTenantLogsCollection(tenantUUID, params);

        let logs = [] as any[];
        response.data.data.forEach((logObj: any) => {
            logs.push({
                type: logObj.attributes.type,
                userId: logObj.attributes.userId,
                payload: logObj.attributes.payload,
                createdAt: logObj.meta.createdAt,
            });
        });

        return logs;
    },
    async getUserLogsCollection(tenantUUID: string, userUUID: string, params?: any): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await MobileAnalyticsApi.getUserLogsCollection(tenantUUID, userUUID, params);

        let logs = [] as any[];
        response.data.data.forEach((logObj: any) => {
            logs.push({
                type: logObj.attributes.type,
                userId: logObj.attributes.userId,
                payload: logObj.attributes.payload,
                createdAt: logObj.meta.createdAt,
            });
        });

        return logs;
    },
    async getLatestVersion(slug: string): Promise<any> {
        const response = await MobileAnalyticsApi.getLatestVersion(slug);

        return {
            version: response.data.data.attributes.version,
            createdAt: response.data.data.meta.createdAt,
        }
    },
}
  