
  import Vue from 'vue'
  import IoTDevicePortService from '@/services/IoTDevicePortService';
  import IoTDeviceMappingService from '@/services/IoTDeviceMappingService';

  import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'IotDevicePortDeleteDialog',

    props: ['iotdevice', 'iotdeviceport'],
    data: () => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        associatedMappings: [] as any[],
    }),
    methods: {
      close() {
        this.localDialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
        this.associatedMappings = [];
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async confirmDelete() {
        this.loading = true;

        // first delete all mappings, then the port
        for(const mapping of this.associatedMappings) {
            await IoTDeviceMappingService.delete(this.iotdevice.uuid, this.iotdeviceport.uuid, mapping.uuid);
        }
      
        IoTDevicePortService.delete(this.iotdevice.uuid, this.iotdeviceport.uuid).then(() => {
            this.alertType = "success";
            this.alertText = this.$t('iotdeviceport.successDelete') as string;
            this.alertVisible = true;
            this.loading = false;

            this.$emit("delete", this.iotdeviceport);

            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('iotdeviceport.errorDelete') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      async getAssociatedMappings() {
        this.loading = true;

        try {
          const mappings = await IoTDeviceMappingService.getAllCollectionPages(this.iotdevice.uuid, this.iotdeviceport.uuid);
          this.loading = false;

          this.associatedMappings = mappings;
        } catch(err: any) {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('user.tenantError')+ ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
          this.associatedMappings = [];
        }
      },
    },
    watch: {
        async localDialog(value: boolean) {
            if(value) {
                this.getAssociatedMappings();
            } else {
                this.clear();
            }
        }
    },
  })
