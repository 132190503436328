var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_vm._t("activator",null,null,{ on, attrs })]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$t('task.editTitle'))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"createForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.validate.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"row-p6 pt-2"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","label":_vm.$t('attribute.note')},model:{value:(_vm.localTask.note),callback:function ($$v) {_vm.$set(_vm.localTask, "note", $$v)},expression:"localTask.note"}})],1)],1),_c('v-row',{staticClass:"row-p6"},[_c('h3',[_vm._v(_vm._s(_vm.$t('attribute.rrule')))]),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('DatePicker',{attrs:{"dense":"","label":_vm.$t('attribute.start'),"rules":_vm.requiredRule},model:{value:(_vm.rrule.start),callback:function ($$v) {_vm.$set(_vm.rrule, "start", $$v)},expression:"rrule.start"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"dense":"","rules":_vm.requiredSelect,"items":_vm.frequencies,"label":_vm.$t('attribute.frequency')},model:{value:(_vm.rrule.frequency),callback:function ($$v) {_vm.$set(_vm.rrule, "frequency", $$v)},expression:"rrule.frequency"}})],1),(
                _vm.rrule.frequency &&
                _vm.frequencyInputsTable[_vm.rrule.frequency.toLowerCase()].interval
              )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","rules":_vm.requiredRule,"label":_vm.$t('attribute.interval'),"hint":_vm.intervalHint,"persistent-hint":"","step":"1","min":"1","max":"365","type":"number"},model:{value:(_vm.rrule.interval),callback:function ($$v) {_vm.$set(_vm.rrule, "interval", $$v)},expression:"rrule.interval"}})],1):_vm._e(),(
                _vm.rrule.frequency &&
                _vm.frequencyInputsTable[_vm.rrule.frequency.toLowerCase()].month
              )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"dense":"","rules":_vm.requiredSelect,"items":_vm.months,"label":_vm.$t('timeUnit.month')},model:{value:(_vm.rrule.month),callback:function ($$v) {_vm.$set(_vm.rrule, "month", $$v)},expression:"rrule.month"}})],1):_vm._e(),(
                _vm.rrule.frequency &&
                _vm.frequencyInputsTable[_vm.rrule.frequency.toLowerCase()]
                  .day_of_month
              )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"dense":"","rules":_vm.requiredSelect,"multiple":"","small-chips":"","items":_vm.daysPerMonth[_vm.rrule.month || 'January'],"label":_vm.$t('task.daysOfMonth')},model:{value:(_vm.rrule.daysOfTheMonth),callback:function ($$v) {_vm.$set(_vm.rrule, "daysOfTheMonth", $$v)},expression:"rrule.daysOfTheMonth"}})],1):_vm._e(),(
                _vm.rrule.frequency &&
                _vm.frequencyInputsTable[_vm.rrule.frequency.toLowerCase()]
                  .day_of_the_week
              )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"dense":"","rules":_vm.requiredSelect,"multiple":"","chips":"","items":_vm.weekdays,"label":_vm.$t('task.daysOfWeek')},model:{value:(_vm.rrule.daysOfTheWeek),callback:function ($$v) {_vm.$set(_vm.rrule, "daysOfTheWeek", $$v)},expression:"rrule.daysOfTheWeek"}})],1):_vm._e(),(
                _vm.rrule.frequency && _vm.rrule.frequency.toLowerCase() !== 'once'
              )?_c('v-col',{staticClass:"pt-0 mt-n4 pb-0",attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"rules":_vm.requiredSelect,"row":"","dense":""},model:{value:(_vm.repetitionSelection),callback:function ($$v) {_vm.repetitionSelection=$$v},expression:"repetitionSelection"}},[_c('v-radio',{key:"1",attrs:{"label":_vm.$t('task.indefinitely'),"value":"forever"}}),_c('v-radio',{key:"2",attrs:{"label":_vm.$t('task.untilDate'),"value":"until_date"}}),_c('v-radio',{key:"3",attrs:{"label":_vm.$t('task.untilCount'),"value":"until_count"}})],1)],1):_vm._e(),(_vm.repetitionSelection === 'until_count')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","rules":_vm.requiredRule,"label":_vm.$t('attribute.count'),"step":"1","min":"1","type":"number"},model:{value:(_vm.rrule.until_num),callback:function ($$v) {_vm.$set(_vm.rrule, "until_num", $$v)},expression:"rrule.until_num"}})],1):_vm._e(),(_vm.repetitionSelection === 'until_date')?_c('v-col',{attrs:{"cols":"12"}},[_c('DatePicker',{attrs:{"dense":"","label":_vm.$t('attribute.end'),"rules":_vm.requiredRule},model:{value:(_vm.rrule.until_date),callback:function ($$v) {_vm.$set(_vm.rrule, "until_date", $$v)},expression:"rrule.until_date"}})],1):_vm._e()],1),_c('v-row',{staticClass:"row-p6"},[_c('h3',[_vm._v(_vm._s(_vm.$t('entity.device')))]),_c('v-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"dense":"","clearable":"","hide-details":"","loading":_vm.loadingDevice,"disabled":_vm.devices.length === 0,"items":_vm.devices,"filter":_vm.autoComplete,"rules":_vm.requiredSelect,"item-text":"aksId","item-value":"uuid","return-object":"","label":_vm.$t('attribute.aksId')},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.aksId)}}),_c('v-list-item-subtitle',{staticClass:"font-weight-light",domProps:{"innerHTML":_vm._s(data.item.uuid)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s(
                        data.item.description + ' (' + data.item.unit + ')'
                      )}})],1)]}}]),model:{value:(_vm.localSelectedDevice),callback:function ($$v) {_vm.localSelectedDevice=$$v},expression:"localSelectedDevice"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-switch',{staticClass:"pt-0 pb-0",attrs:{"hide-details":"","dense":"","label":_vm.$t('task.forceQRScan')},model:{value:(_vm.localTask.forceQRScan),callback:function ($$v) {_vm.$set(_vm.localTask, "forceQRScan", $$v)},expression:"localTask.forceQRScan"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary","text":""},on:{"click":_vm.dismiss}},[_vm._v(" "+_vm._s(_vm.$t('button.dismiss'))+" ")]),_c('v-btn',{attrs:{"color":"success","text":"","disabled":!_vm.valid || _vm.loading},on:{"click":_vm.validate}},[_vm._v(" "+_vm._s(_vm.$t('button.save'))+" ")])],1)],1),(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('v-alert',{staticStyle:{"margin-bottom":"0px"},attrs:{"dense":"","dismissible":"","type":_vm.alertType},model:{value:(_vm.alertVisible),callback:function ($$v) {_vm.alertVisible=$$v},expression:"alertVisible"}},[_vm._v(" "+_vm._s(_vm.alertText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }