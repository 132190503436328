import axiosLimiter from '@/api/RateLimiter';

export default {
    getCollection(): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users');
    },
    async getAllCollectionPages(params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users?';

            if('email' in params && params.email && params.email.length > 0) {
                requestURL = requestURL + '&email=' + encodeURIComponent(params.email);
            }
            if('role' in params && params.role && params.role.length > 0) {
                requestURL = requestURL + '&role=' + encodeURIComponent(params.role);
            }
            if('tenant' in params && params.tenant && Object.keys(params.tenant).length > 0) {
                requestURL = requestURL + '&tenantId=' + encodeURIComponent(params.tenant.uuid);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSelf(): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/self');
    },
    getSingle(userUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/' + userUUID);
    },
    getPermissions(userUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/' + userUUID + '/permissions');
    },
    getAssociatedTenant(userUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/' + userUUID + '/tenant');
    },
    getRoles() : Array<string> {
        return ["user", "admin"];
    },
    createUser(email: string, tenantUUID="", role="", password=""): Promise<any> {
        const body = {
            data: {
                type: "users",
                attributes: {
                    email: email
                }
            }
        } as any;
        if(password.length > 0) {
            body.data.attributes.password = password;
        }
        if(tenantUUID.length > 0 && role.length > 0) {
            body.data.relationships = {
                tenant: {
                    data: {
                        type: "tenants",
                        id: tenantUUID,
                        role: role
                    }
                }
            };
        }
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateUser(userUUID: string, email = "", tenantUUID = "", role = "") : Promise<any> {
        
        const body = {
            data: {
                type: "users",
                id: userUUID,
                attributes: {
                }
            }
        } as any;

        if(email.length > 0) {
            body.data.attributes.email = email;
        }
        if(tenantUUID.length > 0 && role.length > 0) {
            body.data.relationships = {
                tenant: {
                    data: {
                        type: "tenants",
                        id: tenantUUID,
                        role: role
                    }
                }
            };
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/' + userUUID , JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    deleteUser(userUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/' + userUUID);
    },    
    setUserPassword(userUUID: string, password: string) : Promise<any> {
        
        const body = {
            data: {
                type: "changePassword",
                id: userUUID,
                attributes: {
                    password: password
                }
            }
        } as any;

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/' + userUUID + '/changePassword' , JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    resendInvite(userUUID: string): Promise<any> {
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/' + userUUID + '/resendInvite', "", { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    getAssociatedDevices(userUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/' + userUUID + '/devices');
    },
    async getAllAssociatedDevicePages(userUUID: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/' + userUUID + '/devices?';

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getAssociatedApiKeys(userUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/' + userUUID + '/apikeys');
    },
    addUserDevices(userUUID: string, devices: any[]): Promise<any> {
        const body = {
            data: []
        } as any;

        for(const dev of devices) {
            body.data.push(                
                {
                    type: "devices",
                    id: dev.uuid,
                    attributes: {
                        permission: dev.permission
                    }
                });
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/' + userUUID + '/devices', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateUserDevice(userUUID: string, deviceUUID: string, permission: string) : Promise<any> {
        
        const body = {
            data: {
                type: "devices",
                id: deviceUUID,
                attributes: {
                    permission: permission
                }
            }
        } as any;

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/' + userUUID + '/devices/' + deviceUUID , JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    removeUserDevices(userUUID: string, devices: any[]): Promise<any> {
        const body = {
            data: []
        } as any;

        for(const dev of devices) {
            body.data.push(                
                {
                    type: "devices",
                    id: dev.uuid,
                });
        }

        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/users/' + userUUID + '/devices', { headers: {'Content-Type': 'application/vnd.api+json'}, data: JSON.stringify(body)});
    },
}
  