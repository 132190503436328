
  import Vue from 'vue'
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import DeviceShareService from '@/services/DeviceShareService';
  import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'DeviceShareInfoDialog',

    components: {
        EntityMetaDialog,
        // Weird import necessary due to circular dependency
        TenantInfoDialog: () => import('@/components/entities/tenant/TenantInfoDialog.vue') as any,
        DeviceInfoDialog: () => import('@/components/entities/device/DeviceInfoDialog.vue') as any
    },
    props: ['deviceshare'],
    data: () => ({
        localdialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        sourceTenant: {} as any,
        targetTenant: {} as any,
        sourceDevice: {} as any,
        targetDevice: {} as any,
        loadingTenant: false,
        loadingDevice: false,
    }),
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.sourceTenant ={};
        this.targetTenant={};
        this.sourceDevice={};
        this.targetDevice={};
        this.alertVisible = false;
        this.loading = false;
        this.loadingDevice = false;
        this.loadingTenant = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async loadSourceTenant() {
        this.loadingTenant = true;

        try {
            const tenant = await DeviceShareService.getSourceTenant(this.deviceshare.uuid);
            this.loadingTenant = false;

            this.sourceTenant = tenant;
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('price.errorAssociatedTenant') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingTenant = false;
            this.sourceTenant = {};
        }
      },
      async loadTargetTenant() {
        this.loadingTenant = true;

        if(this.deviceshare.state !== 'approved' && this.isSource) {
            this.alertType = "warning";
            this.alertText = this.$t('deviceShare.notApproved') as string;
            this.alertVisible = true; 
            this.loadingDevice = false;
            this.targetDevice = {};
            return;
        }

        try {
            const tenant = await DeviceShareService.getTargetTenant(this.deviceshare.uuid);
            this.loadingTenant = false;

            this.targetTenant = tenant;
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('price.errorAssociatedTenant') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingTenant = false;
            this.targetTenant = {};
        }
      },
      async loadSourceDevice() {
        this.loadingDevice = true;

        try {
            const device = await DeviceShareService.getSourceDevice(this.deviceshare.uuid);
            this.loadingDevice = false;

            this.sourceDevice = device;
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('price.errorAssociatedTenant') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingDevice = false;
            this.sourceDevice = {};
        }
      },
      async loadTargetDevice() {
        this.loadingDevice = true;

        // if(this.deviceshare.state !== 'approved') {
        //     this.alertType = "warning";
        //     this.alertText = this.$t('deviceShare.notApproved') as string;
        //     this.alertVisible = true; 
        //     this.loadingDevice = false;
        //     this.targetDevice = {};
        //     return;
        // } else 
        if(!this.deviceshare.targetDeviceId) {
            this.alertType = "warning";
            this.alertText = this.$t('deviceShare.emptyTargetDevice') as string;
            this.alertVisible = true; 
            this.loadingDevice = false;
            this.targetDevice = {};
            return;
        }

        try {
            const device = await DeviceShareService.getTargetDevice(this.deviceshare.uuid);
            this.loadingDevice = false;

            this.targetDevice = device;
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('deviceShare.errorTargetDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingDevice = false;
            this.targetDevice = {};
        }
      },
      toCurrencyString(price: number) {
          return price ? price.toLocaleString(undefined, { style: 'currency', currency: 'EUR' }) : null;
      }
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                this.loadSourceTenant();
                this.loadTargetTenant();
                this.loadSourceDevice();

                if(this.isRoot || this.isTarget) {
                    this.loadTargetDevice();
                }
            } else {
                this.clear();
            }
        }
    },
    computed: {
        hasSourceTenant(): boolean {
            return Object.keys(this.sourceTenant).length > 0;
        },
        hasTargetTenant(): boolean {
            return Object.keys(this.targetTenant).length > 0;
        },
        hasTargetDevice(): boolean {
            return Object.keys(this.targetDevice).length > 0;
        },        
        isSource(): boolean {
            return this.deviceshare.sourceTenantId === this.$root.$store.state.session.selectedTenant.uuid;
        },
        isTarget(): boolean {
            return this.deviceshare.targetTenantId === this.$root.$store.state.session.selectedTenant.uuid;
        },
        isRoot(): any {
            return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
                && this.$root.$store.state.session.permissions.role === 'superadmin';
        },
    }
  })
