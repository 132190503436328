
  import Vue from 'vue'

  export default Vue.extend({
    name: 'DatePicker',

    props: ['value', 'label', 'rules', 'dense', 'clearable', 'disabled', 'hint', 'allowedDates'],
    data() {
      return {
        dateMenu: false,
        datePicker: true,
        date: undefined as string | undefined,
        errorMessages: [] as string[],
        error: false,
      }
    },
    created() {
      this.date = (this.value ? this.toLocaleDateString(new Date(this.value)) : undefined);
      // console.log(this.value);
      // console.log(this.date, this.time);
      // So that the value is emitted atleast once
      this.onDateSave();
    },
    methods: {
      onInputClear() {
        this.dateMenu = false;
        this.datePicker = true;

        this.date = undefined;
        this.$emit("change", "");
        this.$emit('input', "");
      },
      onDateSave() {
        this.dateMenu = false;
        this.datePicker = true;

        this.$emit("change", this.utcDateString);
        this.$emit('input', this.utcDateString);
        // console.log(this.utcDateString);
      },   
      toLocaleDateString(date: Date): string {
        if(date) {
          const month = ('0' + (date.getMonth() + 1)).slice(-2);
          const day = ('0' + date.getDate()).slice(-2);
          const year = date.getFullYear();
          return year + "-" + month + '-' + day;
        } else {
          return "";
        }
      },
    },
    computed: {
        localeDateString(): string {
          if(this.dateTime) {
            return this.dateTime.toLocaleDateString();
          } else {
            return "";
          }
        },
        utcDateString(): string {
          if(this.dateTime) {
            return this.dateTime.toISOString();
          } else {
            return "";
          }
        },
        dateTime(): Date | undefined {
          if(this.date) {
            return new Date(this.date);
          } else {
            return undefined;
          }
        }        
    },
    watch: {
      value(value: Date) {
        this.date = (value ? this.toLocaleDateString(new Date(value)) : undefined);
      },
      dateTime(value: Date) {
        if(this.rules) {
          this.rules.forEach((rule: any) => {
            this.error = false;
            this.errorMessages = [];

            const res = rule(value);
            if(res !== true) {
              this.error = true;
              this.errorMessages.push(res);
            }
          });
        }
      }
    }
  })
