import axiosLimiter from '@/api/RateLimiter';

export default {  
    async getAllCollectionPages(tenant: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/tags?';

            if('name' in params && params.name && params.name.length > 0) {
                requestURL = requestURL + '&name=' + encodeURIComponent(params.name);
            }
            if('type' in params && params.type && params.type.length > 0) {
                requestURL = requestURL + '&type=' + encodeURIComponent(params.type);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }
            if('propertyId' in params && params.propertyId && params.propertyId.length > 0) {
                requestURL = requestURL + '&propertyId=' + encodeURIComponent(params.propertyId);
            }
            if('connectorId' in params && params.connectorId && params.connectorId.length > 0) {
                requestURL = requestURL + '&connectorId=' + encodeURIComponent(params.connectorId);
            }
            if('deviceId' in params && params.deviceId && params.deviceId.length > 0) {
                requestURL = requestURL + '&deviceId=' + encodeURIComponent(params.deviceId);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(tenant: string, tagUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/tags/' + tagUUID);
    },
    create(tenantUUID: string, name: string, description: string, type: string): Promise<any> {               
        const body = {
            data: {
                type: "tags",
                attributes: {
                    name: name,
                    type: type
                }
            }
        } as any;

        if(description.length > 0) {
            body.data.attributes.description = description;
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/tags', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    delete(tenantUUID: string, tagUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/tags/' + tagUUID);
    },
    update(tenantUUID: string, tagUUID: string, name = "", description = "", type = ""): Promise<any> {
    
        const body = {
            data: {
                type: "tags",
                id: tagUUID,
                attributes: {
                }
            }
        } as any;

        if(name.length > 0) {
            body.data.attributes.name = name;
        }
        if(description.length > 0) {
            body.data.attributes.description = description;
        }
        if(type.length > 0) {
            body.data.attributes.type = type;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/tags/' + tagUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    getAssociatedDevices(tenantUUID: string, tagUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/tags/' + tagUUID + '/devices');
    },
    getAssociatedProperties(tenantUUID: string, tagUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/tags/' + tagUUID + '/properties');
    },
    getAssociatedConnectors(tenantUUID: string, tagUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/tags/' + tagUUID + '/connectors');
    },
}
  