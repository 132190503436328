
    import Vue from 'vue'
    import Chart from 'chart.js/auto';
    import 'chartjs-adapter-moment';
    import zoomPlugin from 'chartjs-plugin-zoom';
    
    Chart.register(zoomPlugin);

    const plugin = {
        id: 'customCanvasBackgroundColor',
        beforeDraw: (chart: Chart, args: any, options: any) => {
            const {ctx} = chart;
            ctx.save();
            ctx.globalCompositeOperation = 'destination-over';
            ctx.fillStyle = options.color || '#ffffff';
            ctx.fillRect(0, 0, chart.width, chart.height);
            ctx.restore();
        }
    };

    export default Vue.extend({
        name: 'Chart',

        props: ['type', 'label', 'data', 'x', 'y', 'disableAnimation', 'minX', 'minY', 'maxX', 'maxY', 'options'],
        data: () => ({
            chart: undefined as unknown as Chart
        }),
        watch: {
            '$vuetify.theme.dark'(): any {
                this.createChart();
            },
            internalData(): any {
                this.createChart();
            },
            type(): any {
                this.createChart();
            }
        },
        mounted() {
            this.createChart();
        },
        methods: { 
            createChart() {
                this.chart?.destroy();

                this.chart = new Chart(
                    this.chartCanvas,
                    {
                        type: this.type,
                        options: {
                            plugins: {
                                title: {
                                    color: this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.7)' : '#666',
                                },
                                legend: {
                                    display: true,
                                    onClick: () => {},
                                    labels: {
                                        color: this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.7)' : '#666'
                                    }
                                },
                                customCanvasBackgroundColor: {
                                    color: this.$vuetify.theme.dark ? '#1E1E1E' : '#ffffff',
                                },
                            },
                            responsive: true,
                            maintainAspectRatio: true,
                            aspectRatio: 2,
                            animation: !this.disableAnimation,
                            scales: {
                                x: {
                                    type: 'time',
                                    ticks: {
                                        color: this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.7)' : '#666',
                                    },
                                    grid: {
                                        color: this.$vuetify.theme.dark ? 'rgba(225,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                                    },
                                    min: this.minX,
                                    max: this.maxX
                                },                                
                                y: {
                                    ticks: {
                                        color: this.$vuetify.theme.dark ? 'rgba(255, 255, 255, 0.7)' : '#666',
                                    },
                                    grid: {
                                        color: this.$vuetify.theme.dark ? 'rgba(225,255,255,0.1)' : 'rgba(0,0,0,0.1)',
                                    },
                                    min: this.minY,
                                    max: this.maxY
                                },
                            },
                            ...this.options
                        },
                        data: {
                            datasets: [
                                {
                                    label: this.label,
                                    data: this.internalData
                                }
                            ]
                        },
                        plugins: [plugin]
                    }
                );
            }
        },
        computed: {
            internalData(): any[] {
                return this.data.map((value: any) => ({x: value[this.x], y: value[this.y]}))
            },
            chartCanvas(): any {
                return this.$refs.chartCanvas;
            }, 
        },
    })
