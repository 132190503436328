

  import Vue from 'vue'
  import DeviceService from '@/services/DeviceService';
  import PropertyService from '@/services/PropertyService';
  import ConnectorService from '@/services/ConnectorService';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import DeviceTypeService from '@/services/DeviceTypeService';
  import PriceService from '@/services/PriceService';
  import DeviceComputationService from '@/services/DeviceComputationService';

  import { evaluate } from 'mathjs'
import TagService from '@/services/TagService';
import TagCreateDialog from '../tag/TagCreateDialog.vue';

  export default Vue.extend({
    name: 'DeviceCreateDialog',

    components: {
      TagCreateDialog
    },
    props: ['templateDevice', 'tenant'],
    data: (vm: any): any => ({
        dialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingProperties: false,
        loadingConnectors: false,
        loadingDeviceType: false,
        loadingPrice: false,
        loadingDevices: false,
        requiredRule: [
          (v: string) => !!v || vm.requiredError
        ],
        validFormula: [
          (v: string) => !!v || vm.requiredError,
          (v: string) => (!!v && vm.validMathFormula(v)) || vm.invalidFormulaError,
        ],
        requiredSelect: [
          (v: any) => Object.keys(v).length > 0 || vm.requiredError
        ],
        device: {
          aksId: "",
          localAksId: "",
          description: "",
          unit: "",
          type: "MIDDLE",
          frequency: "MINUTELY",
          aggregation: "AVG",
          interval: 15,
          variable: "self"
        },
        devices: [] as any[],
        sourceDevices: [] as any[],
        // calculating device
        isCalculatingDevice: false,
        selectedFormula: null,

        // validation
        hasValidation: false,
        readingValidationRulesSelection: [],
        readingValidationAction: null,

        associatedTags: [] as any[],
        tags: [] as any[],

        properties: [] as any[],
        connectors: [] as any[],
        selectedProperty: {} as any,
        selectedConnector: {} as any,
        deviceTypes: [],
        devicePrices: [],
        numberOfTypeDevices: 1,
        aggregationMethods: [
            {name: vm.aggregationLabel('first'), value: 'FIRST'}, 
            {name: vm.aggregationLabel('last'), value: 'LAST'},
            {name: vm.aggregationLabel('min'), value: 'MIN'}, 
            {name: vm.aggregationLabel('max'), value: 'MAX'}, 
            {name: vm.aggregationLabel('avg'), value: 'AVG'}, 
            {name: vm.aggregationLabel('med'), value: 'MEDIAN'}
        ],
        timeUnits: [
            {name: vm.$t('timeUnit.minutes'), frequency: 'MINUTELY' },
            {name: vm.$t('timeUnit.hours'), frequency: 'HOURLY'}, 
            {name: vm.$t('timeUnit.days'), frequency: 'DAILY'},
            {name: vm.$t('timeUnit.weeks'), frequency: 'WEEKLY'},
            {name: vm.$t('timeUnit.months'), frequency: 'MONTHLY'},
            {name: vm.$t('timeUnit.years'), frequency: 'YEARLY'}
        ],
        readingValidationRules: [
            {name: 'Greater than previous', value: 'greater_previous' },
            {name: 'Greater than zero', value: 'greater_zero' },
            {name: 'Greater than or equal to previous', value: 'greater_equal_previous' },
            {name: 'Greater than or equal to zero', value: 'greater_equal_zero' },
            {name: 'Outlier Detection', value: 'outlier_detection' },
        ],
        readingValidationActions: [
            {name: 'Quarantine', value: 'quarantine' },
            {name: 'Notify', value: 'notify' },
            {name: 'Discard', value: 'discard' },
            {name: 'Do nothing', value: 'nothing' },
        ]
    }),
    mounted() {
      if(this.templateDevice) {
        this.device = this.templateDevice;
      }
    },
    methods: {
      aggregationLabel(label: string) {
        return this.$t('aggregation.'+label);
      },
      async validate() {
        if(this.createForm.validate()) {
          await this.createDevice();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.device = {
          aksId: "",
          localAksId: "",
          description: "",
          unit: "",
          type: "MIDDLE",
          frequency: "MINUTELY",
          aggregation: "AVG",
          interval: 15
        };
        this.selectedProperty = {};
        this.selectedConnector = {};
        this.alertVisible = false;
        this.loading = false;
        this.isCalculatingDevice = false;
        this.selectedFormula = null;
        this.sourceDevices = [];
        this.hasValidation = false;
        this.readingValidationRulesSelection = [];
        this.readingValidationAction = null;
      },
      onDeviceTypeChange() {
        this.getNumberOfTypeDevices();
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async createDevice() {
        this.loading = true;

        let aksId = this.device.aksId;
        let localAksId = ""
        let description = this.device.description;
        let unit = this.device.unit;
        let type = this.device.type;
        let frequency = this.device.frequency;
        let interval = parseInt(this.device.interval);
        let aggregation = this.device.aggregation;

        let associatedPropertyUUID = this.selectedProperty.uuid;
        let associatedConnectorUUID = this.selectedConnector.uuid;

        if("localAksId" in this.device) {
            localAksId = this.device.localAksId;
        }

        let device: any;
        try {
          device = await DeviceService.createDevice(this.currentTenant, associatedPropertyUUID, associatedConnectorUUID,
            aksId, description, unit, type, frequency, interval, aggregation, localAksId);

          if(this.associatedTags.length > 0)
            await DeviceService.addTags(this.currentTenant, device.uuid, this.associatedTags)

          
          this.alertType = "success";
          this.alertText = this.$t('device.successCreate');
          this.alertVisible = true;
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('device.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
          this.loading = false;
        }
        
        // create computation for new device
        if(this.isCalculatingDevice) {
          this.loading = true;

          let scope = this.sourceDevices.reduce((o: any, dev: any) => ({ ...o, [dev.variable]: dev.uuid }), {});
          if(this.selectedFormula.formula.includes('self')) {
            scope['self'] = device.uuid;
          }

          try {
            await DeviceComputationService.createDeviceComputation(this.currentTenant, device.uuid, this.selectedFormula.formula, scope);

            this.alertType = "success";
            this.alertText = this.$t('deviceComputation.successCreate');
            this.alertVisible = true;

            this.$emit("success", device);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
          } catch(err: any) {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('deviceComputation.errorCreate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;
            this.loading = false;
          }
        } else {
          this.$emit("success", device);
          setTimeout(() => { 
              this.dismiss(); 
          }, 1500);
        }
      },
      onRemoveTagClick(tag: any) {
        this.associatedTags.splice(this.associatedTags.findIndex((t: any) => t.uuid === tag.uuid), 1);
      },
      getTags() {
        this.loading = true;

        TagService.getAllCollectionPages(this.$root.$store.state.session.selectedTenant.uuid).then((tags: any[]) => {
          this.loading = false;
          this.tags = tags;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('property.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      validMathFormula(formula: string) {
        try {
          evaluate(formula, this.selectedFormulaScope);
          return true;
        } catch(e: any) {
          return false;
        }
      },
      getDevices() {
        this.loadingDevices = true;

        DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
          this.loadingDevices = false;
          // TODO the process of assigning variable names to devices need to be overthought
          // when new devices are added, or ones deleted in the backend, variable names STILL need to be correct

          this.devices = devices.map((value: any, index: number) => ({ variable: 'd'+index, ...value }));
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;    
          this.loadingDevices = false;  
        });
      },
      getDeviceTypes() {
        this.loadingDeviceType = true;
        this.deviceTypes = [];

        let params = {} as any;

        params.tenantId = this.currentTenant;

        DeviceTypeService.getAllCollectionPages(params).then((devicetypes: any[]) => {
            this.loadingDeviceType = false;

            this.deviceTypes = devicetypes;
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('deviceType.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;     
            this.loadingDeviceType = false; 
        });
      },
      getDevicePrices() {
        this.loadingPrice = true;

        let params = {} as any;

        params.type = 'devices';
        params.tenantId = this.currentTenant;

        PriceService.getAllCollectionPages(params).then((prices: any[]) => {
            this.loadingPrice = false;

            this.devicePrices = prices;
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('price.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;    
            this.loadingPrice = false;  
        });
      },
      getNumberOfTypeDevices() {
          this.numberOfTypeDevices = 1;
          this.loadingPrice = true;

          let params = { type: this.device.type };

          DeviceService.getCount(this.currentTenant, params).then((count: number) => {
            this.loadingPrice = false;

            this.numberOfTypeDevices = count+1; // ML: plus one because this device is not included
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;     
            this.loadingPrice = false; 
          });
      },
      getProperties() {
        this.properties = [];
        this.loadingProperties = true;
        
        PropertyService.getAllCollectionPages(this.currentTenant).then((properties: any[]) => {
            this.loadingProperties = false;

            this.properties = properties;
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('property.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingProperties = false;     
        });
      },
      getConnectors() {
        this.connectors = [];
        this.loadingConnectors = true;

        ConnectorService.getAllCollectionPages(this.currentTenant).then((connectors: any[]) => {
          this.loadingConnectors = false;

          this.connectors = connectors;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('connector.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;  
          this.loadingConnectors = false;    
        });
      },      
      findDiscount(price: any, index: number) {
        let discountRange;
        if(price && price.discountRange) {
            for(let j=0; j<price.discountRange.length; j++) {
                const dis = price.discountRange[j];
                const lastDis = j>0 ? price.discountRange[j-1] : null;

                if(dis.start!==null && dis.start <= index && ( (dis.end!==null && dis.end >= index) || (dis.end==null && lastDis && lastDis.end!==null && lastDis.end < index) ) ) {
                discountRange = price.discountRange[j];
                }
            }
        }
        return discountRange;
      },
      toCurrencyString(price: number) {
          return price ? price.toLocaleString(undefined, { style: 'currency', currency: 'EUR' }) : null;
      },
      autoComplete,
    },
    watch: {
      templateDevice() {
        this.device = this.templateDevice;
      },
      async isCalculatingDevice(value: boolean) {
        if(value) {
          this.getDevices();
        }
      },
      async dialog(value: boolean) {
        if(value) {
          this.getNumberOfTypeDevices();
          this.getDevicePrices();
          this.getDeviceTypes();
          this.getProperties();
          this.getConnectors();
          this.getTags();
        } else {
          this.clear();
        }
      }
    },
    computed: {
        calculationFormulas() {
          return [
            { header: this.$t("computationHeaders.aggregation") },
            { name: this.$t("computations.sum"), formula: "sum("+ this.selectedFormulaVariables.join() + ")", multiple: true, single: false },
            { name: this.$t("computations.average"), formula: "mean("+ this.selectedFormulaVariables.join() + ")", multiple: true, single: false },
            { name: this.$t("computations.diffToAbsolute"), formula: " self + " + this.selectedFormulaVariables[0], multiple: false, single: true },
            { name: this.$t("computations.diffToAbsoluteFactor"), formula: " self + (" + this.selectedFormulaVariables[0] + " * FACTOR)", multiple: false, single: true },
            { header: this.$t("computationHeaders.unitconversion") },
            { name: "W -> kW", formula: this.selectedFormulaVariables[0] +" / 1000.0", multiple: false, single: true },
            { name: "kW -> W", formula: this.selectedFormulaVariables[0] +" * 1000.0", multiple: false, single: true },
            { name: "cm³ -> m³", formula: this.selectedFormulaVariables[0] +" / 1000000.0", multiple: false, single: true },
            { name: "m³ -> cm", formula: this.selectedFormulaVariables[0] +" * 1000000.0", multiple: false, single: true },
            { name: "inch³ -> cm³", formula: this.selectedFormulaVariables[0] +" * 16.387064", multiple: false, single: true },
            { name: "cm³ -> inch³", formula: this.selectedFormulaVariables[0] +" / 16.387064", multiple: false, single: true },
            { name: "feet³ -> m³", formula: this.selectedFormulaVariables[0] +" * 0.02832", multiple: false, single: true },
            { name: "m³ -> feet³", formula: this.selectedFormulaVariables[0] +" / 0.02832", multiple: false, single: true },
            { name: "yard³ -> m³", formula: this.selectedFormulaVariables[0] +" * 0.76456", multiple: false, single: true },
            { name: "m³ -> yard³", formula: this.selectedFormulaVariables[0] +" / 0.76456", multiple: false, single: true },
            { name: "C° -> °F", formula: "(" + this.selectedFormulaVariables[0] +" * 9/5) + 32", multiple: false, single: true },
            { name: "°F -> C°", formula: "(" + this.selectedFormulaVariables[0] +" - 32) * 9/5", multiple: false, single: true },
            { header: this.$t("computationHeaders.custom") },
            { name: this.$t("computationHeaders.custom"), formula: "", multiple: true, single: true, any: true },
          ]; 
        },
        selectedFormulaVariables() {
          return this.sourceDevices.map((value: any) => value.variable);
        },
        selectedFormulaScope() {
          return this.sourceDevices.reduce((o: any, dev: any) => ({ ...o, [dev.variable]: 1, [dev.variable + "_prev"]: 1 }), { "self": 1.0 });
        },
        filteredCalculationFormulas() {
          return this.calculationFormulas.filter((value: any) => this.sourceDevices.length > 1 ? value.multiple || value.header : this.sourceDevices.length == 1 ? value.single || value.header : value.any ||value.header);
        },      
        extraOptions() {
          return this.hasValidation || this.isCalculatingDevice;
        },
        calculatedDevicePriceRange(): any {
            const ret = this.devicePrices.find((element: any) => element.subtype === this.device.type);

            if(this.devicePrices.length > 0 && ret) {
                // construct range from 0 discount to possible max. discount 
                // (based on number of devices from this type)
                let discountedPrice = ret.basePrice;
                const discount = this.findDiscount(ret, this.numberOfTypeDevices);
                if(discount) {
                    discountedPrice = discountedPrice * (1-discount.discount);
                }

                if(ret.basePrice === discountedPrice)
                    return this.toCurrencyString(ret.basePrice);

                return ret.basePrice > discountedPrice ? this.toCurrencyString(discountedPrice) + ' - ' + this.toCurrencyString(ret.basePrice) :
                    this.toCurrencyString(ret.basePrice) + ' - ' + this.toCurrencyString(discountedPrice);
            }
            return null;
        },
        retentionTime(): any {
          const ret = this.deviceTypes.find((element: any) => element.type === this.device.type);

          return ret ? ret.retention / 86400000 : 0;
        },
        createForm(): any {
            return this.$refs.createForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        invalidFormulaError(): any {
          return 'Invalid Formula';
        },
        currentTenant(): any {
          return this.tenant ? this.tenant.uuid : this.$root.$store.state.session.selectedTenant.uuid;
        }
    }
  })
