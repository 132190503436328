
  import Vue from 'vue'
  import BCCSerial from '@/components/functions/bcc/BCCSerial.vue'

  import OTAService from '@/services/OTAService';

  export default Vue.extend({
    name: 'BCCView',

    components: {
      BCCSerial
    },
    created() {
      this.getOTAData();
    },
    data: () => ({
      versions: [] as any,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
    }),
    watch: {
    },
    methods: {
      delay(ms: number) {
        return new Promise(res => setTimeout(res, ms));
      },
      getOTAData() {
        this.dataLoaded = false;

        OTAService.getAllVersions().then((versions: any[]) => {
          this.dataLoaded = true;
          console.log(versions)

          this.versions = versions.filter((value: any) => value.name !== 'snflasher');
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = err.message;
          this.dataError = true;   
          this.dataLoaded = true;  
        });
      }
    },
    computed: {
      currentUser(): any {
        return this.$root.$store.state.session.currentUser;
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
    }
  })
