
  import Vue from 'vue'
  import InfoCard from '@/components/misc/InfoCard.vue'

  import { extractErrorMessage } from '@/utils/Util'
  import TagCreateDialog from '@/components/entities/tag/TagCreateDialog.vue'
  import TagService from '@/services/TagService'

  import TagsTable from '@/components/entities/tag/TagsTable.vue'

  export default Vue.extend({
    name: 'TagsView',

    components: {
      TagsTable,
      TagCreateDialog,
      InfoCard,
    },
    data: () => ({
      tags: [] as any,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      filters: {} as any,
    }),
    created() {
      this.update();
    },
    watch: {
      currentTenant() {
        this.filters = {};
        this.update();
      }
    },
    methods: {
      onTableReload() {
        this.update();
      },
      onFiltersUpdate(filters: any) {
        this.filters = filters;
        this.update();
      },
      onItemUpdate(item: any) {
        this.tags.splice(this.tags.findIndex((conn: any) => conn.uuid === item.uuid), 1);
        this.tags.push(item);
      },
      onItemDelete(item: any) {
        this.tags.splice(this.tags.findIndex((conn: any) => conn.uuid === item.uuid), 1);
      },
      onCreation(connector: any) {
        this.tags.push(connector);
      },
      update() {
        this.tags = [];
        this.dataLoaded = false;

        let params = {};

        Object.assign(params, this.filters);

        TagService.getAllCollectionPages(this.currentTenant, params).then((tags: any[]) => {
          this.dataLoaded = true;

          this.tags = tags;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('tag.loadingError') + ' ' + extractErrorMessage(err);
          this.dataError = true;   
          this.dataLoaded = true;  
        });
      }
    },
    computed: {
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'tags' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['tags'].includes('POST');
        } else {
          return false;
        }
      },      
      lastCreatedAt(): any {
        let occurrences: any = {};
        let sum = 0;

        let pastDate = new Date();
        pastDate.setDate(pastDate.getDate()-7); 

        this.tags.forEach((userObj: any) => {
          let _date = new Date(userObj.createdAt);
          if(_date >= pastDate) {
            let item = _date.getDate() + '-' + _date.getMonth();
            occurrences[item] = (occurrences[item] || 0) + 1;
            sum = sum + 1;
          }
        }, {});

        return sum;
      }
    }
  })
