
  import Vue from 'vue'
  import ConnectorService from '@/services/ConnectorService';
import { extractErrorMessage } from '@/utils/Util';
import TagService from '@/services/TagService';
import TagCreateDialog from '../tag/TagCreateDialog.vue';

  export default Vue.extend({
    name: 'ConnectorCreateDialog',

    components: {
      TagCreateDialog
    },
    props: [],
    data: (vm: any): any => ({
        dialog: false,
        valid: true,
        successfullyCreated: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        associatedTags: [] as any[],
        tags: [] as any[],
        requiredRule: [
          (v: string) => !!v || vm.requiredError
        ],
        connector: {
          name: ""
        },
        token: "",
    }),
    methods: {
      validate() {
        if(this.createForm.validate()) {
          this.createConnector();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.connector = {
          name: ""
        };
        this.alertVisible = false;
        this.loading = false;
        this.associatedTags = [];
        this.tags =  [];
        this.token = "";
        this.successfullyCreated = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async createConnector() {
        this.loading = true;
        let name = this.connector.name;

        try {
          const connector = await ConnectorService.createConnector(this.currentTenant, name);
          
          this.alertType = "success";
          this.alertText = this.$t('connector.successCreate');
          this.alertVisible = true;
          this.successfullyCreated = true;

          this.token = connector.token;
          delete connector.token;

          if(this.associatedTags.length > 0)
            await ConnectorService.addTags(this.currentTenant, connector.uuid, this.associatedTags);
          
          this.$emit("success", connector);
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('connector.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
          this.loading = false;
        }
      },
      onRemoveTagClick(tag: any) {
        this.associatedTags.splice(this.associatedTags.findIndex((t: any) => t.uuid === tag.uuid), 1);
      },
      getTags() {
        this.loading = true;

        TagService.getAllCollectionPages(this.currentTenant).then((tags: any[]) => {
          this.loading = false;
          this.tags = tags;
        }).catch((err) => {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('property.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
        });
      },
    },
    watch: {
      dialog(value: boolean) {
        if(value) {
          this.getTags();
        } else {
          this.clear();
        }
      }
    },
    computed: {
      createForm(): any {
          return this.$refs.createForm;
      },
      requiredError(): any {
          return this.$t('required');
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
    }
  })
