
  import Vue from 'vue'
  import ReadingService from '@/services/ReadingService';
  import DeviceService from '@/services/DeviceService';
  import ImageService from '@/services/ImageService';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';

  import DateTimePicker from '@/components/misc/DateTimePicker.vue'
import MeteringService from '@/services/MeteringService';
import { ReadingFlagTypes } from './ReadingCollections';

  export default Vue.extend({
    name: 'ReadingCreateDialog',

    components: {
      DateTimePicker
    },
    props: ['device', 'timestamp'],
    data: (vm: any): any => ({
        readingFlagTypes: ReadingFlagTypes,
        dialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingDevice: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        numberRule: [
            (v: string) => !!v || vm.requiredError,
            (v: string) => !isNaN(parseFloat(v)) || vm.numberError,
        ],
        requiredSelect: [
            (v: any) => (!!v && Object.keys(v).length > 0) || vm.requiredError
        ],
        imageRules: [
          (v: any) => !v || v.size < 8000000 || vm.imageSizeError,
        ],
        readingValidationRules: [
            {name: 'Greater than previous', value: 'greater_previous' },
            {name: 'Greater than zero', value: 'greater_zero' },
            {name: 'Greater than or equal to previous', value: 'greater_equal_previous' },
            {name: 'Greater than or equal to zero', value: 'greater_equal_zero' },
            {name: 'Outlier Detection', value: 'outlier_detection' },
        ],
        devices: [] as any[],
        selectedDevice: {} as any,
        reading: {
          value: "",
          timestamp: new Date(Date.now()).toISOString(),
          flag: null,
        },
        imageProofFile: null,
        lastReading: {} as any,
        showLastReadingImageProof: false,
        lastReadingImageProofLoading: true,
        lastReadingImageProofData: "" as any,
        associatedTask: {} as any
    }),
    created() {
      if(this.device) {
        this.selectedDevice = this.device;
      }
      if(this.timestamp) {
        this.reading.timestamp = new Date(this.timestamp).toISOString();
      } else {
        this.reading.timestamp = new Date(Date.now()).toISOString();
      }
    },
    methods: {
      validate() {
        if(this.createForm.validate()) {
          this.createReading();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.reading = {
          value: "",
          timestamp: "",
          flag: null,
        };
        this.devices = [];
        this.selectedDevice = {};
        this.lastReading = {};
        this.lastReadingImageProofData = "";
        this.lastReadingImageProofLoading = false;
        this.showLastReadingImageProof = false;
        this.imageProofFile = null;
        this.alertVisible = false;
        this.loading = false;
        this.associatedTask = {};
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async createReading() {
        this.loading = true;
        let deviceUUID = this.selectedDevice.uuid;
        let createdReading = {} as any;

        let flag;
        if(this.reading.flag) {
          flag = this.reading.flag;
        }

        try {
          createdReading = await ReadingService.createReading(this.currentTenant, parseFloat(this.reading.value), this.reading.timestamp, deviceUUID, flag);

          try {
            const date = new Date(createdReading.timestamp);
            date.setUTCDate(date.getUTCDate()+3);

            const events = await MeteringService.getAllEventCollectionPages(this.currentTenant, { until: date.toISOString() }) as any[];
            if(events.length > 0) {
              const deviceEvents = events.filter((value: any) => value.deviceId === deviceUUID);
              const deviceEvent = deviceEvents.reduce((a: any, b: any) => new Date(a.dueDate).getTime() > new Date(b.dueDate).getTime() ? a : b);
              if(deviceEvent) {
                await MeteringService.doneEvent(this.currentTenant, deviceEvent.uuid);
              }
            }
          } catch(err) {
            // for users without metering service activated this throws an error
          }

          this.alertType = "success";
          this.alertText = this.$t('reading.successCreate');
          this.alertVisible = true;
          this.$emit("success", createdReading);

          if(this.imageProofFile) {
            this.loading = true;

            try {
              const uploadURL = await ImageService.getUploadURL(createdReading.uuid, this.imageProofFile);
              const response = await ImageService.uploadImage(uploadURL, this.imageProofFile);

              if(response && response.status === 200) {

                this.alertType = "success";
                this.alertText = this.$t('reading.successImageUpload');
                this.alertVisible = true;
                
                setTimeout(() => {
                  this.dismiss(); 
                }, 1500);
              } else {
                console.log(response);  
                this.alertType = "error";
                this.alertText = this.$t('reading.errorImageUpload');
                this.alertVisible = true;
                this.loading = false;
              }
            } catch(err: any) {
              console.log(err, err.response);  
              this.alertType = "error";
              this.alertText = this.$t('reading.errorImageUpload') + ' ' + extractErrorMessage(err);
              this.alertVisible = true;
              this.loading = false;
            }
          } else {
            setTimeout(() => {
              this.dismiss(); 
            }, 1500);
          }
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('reading.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
          this.loading = false;
        }
      },
      getDevices() {
        this.loadingDevice = true;

        DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
          this.loadingDevice = false;

          this.devices = devices;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;    
          this.loadingDevice = false;  
        });
      },
      getLastReading() {
        this.loading = true;

        ReadingService.getLastReading(this.currentTenant, this.selectedDevice).then((lastReading: any) => {
          this.loading = false;

          if(!lastReading)
            lastReading = {}

          this.lastReading = lastReading;
        }).catch((err: any) => {
          console.log(err, err.response);
          this.loading = false;    
        });
      },
      getAssociatedTask() {
        this.loading = true;
        
        MeteringService.getDeviceTask(this.currentTenant, this.device.uuid).then((task) => {
            this.loading = false;

            this.associatedTask = task;
        }).catch((err) => {
            console.log(err, err.response);
            this.loading = false;
        });
      },
      getImageProof() {
          this.showLastReadingImageProof = true;
          this.lastReadingImageProofLoading = true;

          if(this.lastReading && Object.keys(this.lastReading).length > 0) {
            ImageService.getImage(this.lastReading.uuid).then((imageData) => {
              this.loading = false;
              this.lastReadingImageProofLoading = false;
              this.lastReadingImageProofData = imageData;
            }).catch((err) => {
                console.log(err);
                console.log(err.response);
                this.lastReadingImageProofLoading = false;
                this.lastReadingImageProofData = "";
            });
          }
      },
      autoComplete,
    },
    watch: {
      dialog(value: boolean) {
        if(value) {
          if(this.device) {
            this.selectedDevice = this.device;
          }
          if(this.timestamp) {
            this.reading.timestamp = new Date(this.timestamp).toISOString();
          } else {
            this.reading.timestamp = new Date(Date.now()).toISOString();
          }
          this.getDevices();
        } else {
          this.clear();
        }
      },
      showLastReadingImageProof(value: boolean) {
        if(value && this.lastReadingImageProofData.length === 0) {
          this.getImageProof();
        }
      },
      selectedDevice(value: any) {
        if(value && Object.keys(value).length > 0) {
          this.getLastReading();
        }
      }
    },
    computed: {
      validationRulesValid(): any {
        let valid = true;
        if(this.reading.value.length > 0 && this.device && this.validationRules) {
            for(const rule of this.validationRules) {
              if(rule === 'greater_previous' && 'value' in this.lastReading && this.lastReading?.value !== undefined) {
                valid = parseFloat(this.reading.value) > parseFloat(this.lastReading.value);
              } else if(rule === 'greater_equal_previous' && 'value' in this.lastReading && this.lastReading?.value) {
                valid = parseFloat(this.reading.value) >= parseFloat(this.lastReading.value);
              } else if(rule === 'greater_zero') {
                valid = parseFloat(this.reading.value) > 0;
              } else if(rule === 'greater_equal_zero') {
                valid = parseFloat(this.reading.value) >= 0;
              } else if(rule === 'outlier_detection') {
                valid = true;
              }
              if(!valid)
                return false;
            }
        }
        return valid;
      },
      validationAction(): any {

        if(this.device && this.device?.readingValidationAction) {
          return this.device.readingValidationAction.name;
        } else if(this.selectedDevice && this.selectedDevice?.readingValidationAction) {
          return this.selectedDevice.readingValidationAction.name;
        }
        return 'Do nothing';
      },
      validationRules(): any {

        if(this.device && this.device?.readingValidationRules) {
          return this.device.readingValidationRules;
        } else if(this.selectedDevice && this.selectedDevice?.readingValidationRules) {
          return this.selectedDevice.readingValidationRules;
        }

        return ['greater_equal_previous', 'greater_equal_zero'];
      },
      formattedLastReadingTimestamp(): any {
        if(this.lastReading && 'timestamp' in this.lastReading) {
          return new Date(this.lastReading.timestamp).toLocaleString();
        } else {
          return "";
        }
      },
      createForm(): any {
          return this.$refs.createForm;
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      requiredError(): any {
          return this.$t('required');
      },
      numberError(): any {
          return this.$t('mustNumber');
      },
      imageSizeError(): any {
        return this.$t('maxImageSize')
      }
    }
  })
