
  import Vue from 'vue'
  import ReadingService from '@/services/ReadingService';
  import ImageService from '@/services/ImageService';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'ReadingInfoDialog',

    components: {
        EntityMetaDialog,
        // Weird import necessary due to circular dependency
        DeviceInfoDialog: () => import('@/components/entities/device/DeviceInfoDialog.vue') as any
    },
    props: ['reading'],
    data: () => ({
        localdialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingDevice: false,
        associatedDevice: {} as any,
        imageProofLoading: false,
        imageProofData: "" as any,
        showOverlay: false
    }),
    methods: {
      onClickOutside() {
          this.showOverlay = !this.showOverlay;
      },
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedDevice = {};
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      getAssociatedDevice() {
        this.loadingDevice = true;

        ReadingService.getAssociatedDevice(this.$root.$store.state.session.selectedTenant.uuid, this.reading.uuid).then((device) => {
            this.loadingDevice = false;

            this.associatedDevice = device;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('reading.errorAssociatedDevice') + err;
            this.alertVisible = true; 
            this.loadingDevice = false;
        });
      },
      getImageProof() {
          this.imageProofLoading = true;

          ImageService.getImage(this.reading.uuid).then((imageData) => {
            this.imageProofLoading = false;
            this.imageProofData = imageData;
          }).catch((err) => {
              if(err.response && err.response.status === 404) {
                  // console.log(err);
                  // console.log(err.response);
                  // this.alertType = "warning";
                  // this.alertText = this.$t('reading.emptyImageProof') as string;
                  // this.alertVisible = true;
              } else {
                  console.log(err);
                  console.log(err.response);
                  this.alertType = "error";
                  this.alertText = this.$t('reading.errorImageProof') + ' ' + extractErrorMessage(err);
                  this.alertVisible = true; 
              }
              this.imageProofLoading = false;
              this.imageProofData = "";
          });
      }
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                // console.log(this.reading)
                this.getImageProof();
                this.getAssociatedDevice();
            }
        }
    },
    computed: {
    }
  })
