import MeteringApi from '@/api/MeteringApi';
import store from '../store/index';
import router from '../router/index';

export default {
    // device tasks
    async getDeviceTask(tenantUUID: string, deviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await MeteringApi.getDeviceTask(tenantUUID, deviceUUID);

        return {
                uuid: response.data.data.id,
                deviceId: response.data.data.attributes.deviceId,
                rrule: response.data.data.attributes.rrule,
                note: response.data.data.attributes.note,
                generatedUntil: response.data.data.attributes.generatedUntil,
                forceQRScan: response.data.data.attributes.forceQRScan,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            };
    },
    async getAllTaskCollectionPages(tenantUUID: string, params: any = {}): Promise<any[]> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const tasks: any[] = [];
        const pageResponses = await MeteringApi.getAllTaskCollectionPages(tenantUUID, params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((taskObj: any) => {
                tasks.push({
                    uuid: taskObj.id,
                    deviceId: taskObj.attributes.deviceId,
                    rrule: taskObj.attributes.rrule,
                    note: taskObj.attributes.note,
                    generatedUntil: taskObj.attributes.generatedUntil,
                    forceQRScan: taskObj.attributes.forceQRScan,
                    createdAt: taskObj.meta.createdAt,
                    updatedAt: taskObj.meta.updatedAt,
                });
            });
        });

        return tasks;
    },
    async createDeviceTask(tenantUUID: string, deviceUUID: string, rrule: string, note="", forceQRScan=false): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await MeteringApi.createDeviceTask(tenantUUID, deviceUUID, rrule, note, forceQRScan);

        if(response.data.data.type && response.data.data.type == 'tasks') {

            return {
                uuid: response.data.data.id,
                deviceId: response.data.data.attributes.deviceId,
                rrule: response.data.data.attributes.rrule,
                note: response.data.data.attributes.note,
                forceQRScan: response.data.data.attributes.forceQRScan,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            }
        } else {
            throw TypeError("Response not type tasks");
        }
    },
    async updateDeviceTask(tenantUUID: string, deviceUUID: string, rrule="", note="", forceQRScan=undefined): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await MeteringApi.updateDeviceTask(tenantUUID, deviceUUID, rrule, note, forceQRScan);

        if(response.data.data.type && response.data.data.type == 'tasks') {

            return {
                uuid: response.data.data.id,
                deviceId: response.data.data.attributes.deviceId,
                rrule: response.data.data.attributes.rrule,
                note: response.data.data.attributes.note,
                forceQRScan: response.data.data.attributes.forceQRScan,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            };
        } else {
            throw TypeError("Response not type tasks");
        }
    },
    async deleteDeviceTask(tenantUUID: string, deviceUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await MeteringApi.deleteDeviceTask(tenantUUID, deviceUUID);
    },
    // users
    async getUser(tenantUUID: string, userUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await MeteringApi.getUser(tenantUUID, userUUID);

        return {
            uuid: response.data.data.id,
            tenantId: response.data.data.attributes.teanntId,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },  
    async getUserCollection(tenantUUID: string): Promise<any[]> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const users: any[] = [];
        const response = await MeteringApi.getUserCollection(tenantUUID);

        response.data.data.forEach((userObj: any) => {
            users.push({
                uuid: userObj.id,
                tenantId: userObj.attributes.tenantId,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
            });
        });

        return users;
    },
    async createUser(tenantUUID: string, userUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await MeteringApi.createUser(tenantUUID, userUUID);    

        if(response.data.data.type && response.data.data.type == 'users') {
            return {
                uuid: response.data.data.id,
                tenantId: response.data.data.attributes.teanntId,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            };
        } else {
            throw TypeError("Response not type users");
        }
    },
    async updateUser(tenantUUID: string, userUUID: string, tenantId: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await MeteringApi.updateUser(tenantUUID, userUUID, tenantId); 
        
        if(response.data.data.type && response.data.data.type == 'users') {
            return {
                uuid: response.data.data.id,
                tenantId: response.data.data.attributes.teanntId,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            };
        } else {
            throw TypeError("Response not type users");
        }
    },
    async deleteUser(tenantUUID: string, userUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        await MeteringApi.deleteUser(tenantUUID, userUUID);
    },
    // user devices  
    async getUserDeviceCollection(tenantUUID: string, userUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const userDevices: any[] = [];
        const response = await MeteringApi.getUserDeviceCollection(tenantUUID, userUUID);

        response.data.data.forEach((userObj: any) => {
            userDevices.push({
                uuid: userObj.id,
                permission: userObj.attributes.permission,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt
            });
        });

        return userDevices;
    },
    async addUserDevice(tenantUUID: string, userUUID: string, deviceUUID: string, permission: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
    
        await MeteringApi.addUserDevice(tenantUUID, userUUID, deviceUUID, permission);  
    },
    async updateUserDevice(tenantUUID: string, userUUID: string, deviceUUID: string, permission: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await MeteringApi.updateUserDevice(tenantUUID, userUUID, deviceUUID, permission);
    },
    async removeUserDevice(tenantUUID: string, userUUID: string, deviceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await MeteringApi.removeUserDevice(tenantUUID, userUUID, deviceUUID);
    },
    // events
    async getEvent(tenantUUID: string, eventUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await MeteringApi.getEvent(tenantUUID, eventUUID);

        return { 
            uuid: response.data.data.id,
            taskId: response.data.data.attributes.taskId,
            deviceId: response.data.data.attributes.deviceId,
            dueDate: response.data.data.attributes.dueDate,
            note:  response.data.data.attributes.note,
            state:  response.data.data.attributes.state,
            createdAt:  response.data.data.meta.createdAt,
        };
    },
    async getAllEventCollectionPages(tenantUUID: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const events: any[] = [];
        const pageResponses = await MeteringApi.getAllEventCollectionPages(tenantUUID, params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((eventObj: any) => {
                events.push({ 
                    uuid: eventObj.id,
                    taskId: eventObj.attributes.taskId,
                    deviceId: eventObj.attributes.deviceId,
                    dueDate: eventObj.attributes.dueDate,
                    note:  eventObj.attributes.note,
                    state:  eventObj.attributes.state,
                    createdAt:  eventObj.meta.createdAt,
                });
            });
        });

        return events;
    },
    async updateEvent(tenantUUID: string, eventUUID: string, note: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await MeteringApi.updateEvent(tenantUUID, eventUUID, note);

        return { 
            uuid: response.data.data.id,
            taskId: response.data.data.attributes.taskId,
            deviceId: response.data.data.attributes.deviceId,
            dueDate: response.data.data.attributes.dueDate,
            note:  response.data.data.attributes.note,
            state:  response.data.data.attributes.state,
            createdAt:  response.data.data.meta.createdAt,
        };
    },
    async doneEvent(tenantUUID: string, eventUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await MeteringApi.doneEvent(tenantUUID, eventUUID);
    },
    async deleteEvent(tenantUUID: string, eventUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await MeteringApi.deleteEvent(tenantUUID, eventUUID);
    },
    // reports
    async getReport(tenantUUID: string, reportUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await MeteringApi.getReport(tenantUUID, reportUUID);

        return {
            uuid: response.data.data.id,
            rrule: response.data.data.attributes.rrule,
            orientByCalendarBounds: response.data.data.attributes.orientByCalendarBounds,
            timespanValue: response.data.data.attributes.timespanValue,
            timespanUnit: response.data.data.attributes.timespanUnit,
            note: response.data.data.attributes.note,
            config: response.data.data.attributes.config,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async getAllReportCollectionPages(tenantUUID: string, params: any = {}): Promise<any[]> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const reports: any[] = [];
        const pageResponses = await MeteringApi.getAllReportCollectionPages(tenantUUID, params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((taskObj: any) => {
                reports.push({
                    uuid: taskObj.id,
                    rrule: taskObj.attributes.rrule,
                    orientByCalendarBounds: taskObj.attributes.orientByCalendarBounds,
                    timespanValue: taskObj.attributes.timespanValue,
                    timespanUnit: taskObj.attributes.timespanUnit,
                    note: taskObj.attributes.note,
                    config: taskObj.attributes.config,
                    createdAt: taskObj.meta.createdAt,
                    updatedAt: taskObj.meta.updatedAt,
                });
            });
        });

        return reports;
    },
    async createReport(tenantUUID: string, rrule: string, orientByCalendarBounds: boolean, timespanValue: number, timespanUnit: string, config: any, note=""): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await MeteringApi.createReport(tenantUUID, rrule, orientByCalendarBounds, timespanValue, timespanUnit, config, note);

        if(response.data.data.type && response.data.data.type == 'reports') {

            return {
                uuid: response.data.data.id,
                rrule: response.data.data.attributes.rrule,
                orientByCalendarBounds: response.data.data.attributes.orientByCalendarBounds,
                timespanValue: response.data.data.attributes.timespanValue,
                timespanUnit: response.data.data.attributes.timespanUnit,
                note: response.data.data.attributes.note,
                config: response.data.data.attributes.config,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            }
        } else {
            throw TypeError("Response not type reports");
        }
    },
    async updateReport(tenantUUID: string, reportUUID: string, rrule="", orientByCalendarBounds=undefined, timespanValue=-1, timespanUnit="", config=undefined, note=""): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await MeteringApi.updateReport(tenantUUID, reportUUID, rrule, orientByCalendarBounds, timespanValue, timespanUnit, config, note);

        if(response.data.data.type && response.data.data.type == 'reports') {

            return {
                uuid: response.data.data.id,
                rrule: response.data.data.attributes.rrule,
                orientByCalendarBounds: response.data.data.attributes.orientByCalendarBounds,
                timespanValue: response.data.data.attributes.timespanValue,
                timespanUnit: response.data.data.attributes.timespanUnit,
                note: response.data.data.attributes.note,
                config: response.data.data.attributes.config,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            };
        } else {
            throw TypeError("Response not type reports");
        }
    },
    async deleteReport(tenantUUID: string, reportUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await MeteringApi.deleteReport(tenantUUID, reportUUID);
    },
}
