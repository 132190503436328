
  import Vue from 'vue'
  import UserService from '@/services/UserService';
  import PropertyService from '@/services/PropertyService';
import { extractErrorMessage, filterItem } from '@/utils/Util';

  export default Vue.extend({
    name: 'UserDevicesEditDialog',

    props: ['user', 'tenant', 'value'],
    components: {
    },
    data: (vm: any): any => ({
        localdialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loadingUpdate: false,
        loadingDevices: false,
        search: "",
        numberOfEntities: 0,
        devices: [] as any[],
        properties: [] as any[],
        updatedAssociatedDevices: [] as any[],
        selectedGroups: [],
        showOnlySelected: false,
        allSelected: false,
        partialSelected: false,
        permissionOptions: [
            {value: 'r'},
            {value: 'rw'},
        ]
    }),
    async created() {
        // s
    },
    methods: {
      onSelectAll(props: any) {
          let filteredItems = this.devices;
          if(this.$refs.dataTable.$children[0].filteredItems) {
            filteredItems = this.$refs.dataTable.$children[0].filteredItems;
          }

          this.updatedAssociatedDevices = props.value ? filteredItems : [];
      },
      onGroupSelect(group: any) {
          let prop = this.getProperty(group);
          if(!prop.selectedFull) {
              // select all devices under the property that are not already selected
              this.updatedAssociatedDevices.push(...this.devices.filter((value: any) => value.propertyId === group && this.updatedAssociatedDevices.findIndex((d: any) => d.uuid === value.uuid) < 0));
          } else {
              // remove all devices from this property
              this.updatedAssociatedDevices = this.updatedAssociatedDevices.filter((value: any) => value.propertyId !== group);
          }
      },
      close() {
        this.localdialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loadingUpdate = false;
        this.loadingDevices = false;
        this.updatedAssociatedDevices = [];
        this.devices = [];
        this.properties = [];
        this.selectedGroups = [];
        this.selectedDevices = [];
        this.numberOfEntities = 0;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async save() {
        this.loadingUpdate = true;

        // check which devices changes
        let addedDevices = this.updatedAssociatedDevices.filter((value: any) => this.value.findIndex((dev: any) => dev.uuid === value.uuid) < 0); // new devices
        let oldDevices = this.updatedAssociatedDevices.filter((value: any) => this.value.findIndex((dev: any) => dev.uuid === value.uuid) >= 0);
        let updatedDevices = oldDevices.filter((value: any) => this.value.findIndex((dev: any) => dev.uuid === value.uuid && dev.permission !== value.permission) >= 0);
        let removedDevices = this.value.filter((value: any) => this.updatedAssociatedDevices.findIndex((dev: any) => dev.uuid === value.uuid) < 0);  // removed devices

        // update the bascloud backend
        try {
            await UserService.addUserDevices(this.user.uuid, addedDevices);
            await UserService.removeUserDevices(this.user.uuid, removedDevices);

            for(const dev of updatedDevices) {
                await UserService.updateUserDevice(this.user.uuid, dev.uuid, dev.permission);
            }
    
            this.alertType = "success";
            this.alertText = this.$t('user.successUpdate');
            this.alertVisible = true;

            this.$emit("input", this.updatedAssociatedDevices);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('user.errorUpdate') + err;
            this.alertVisible = true; 
            this.loadingUpdate = false;
        }
      },
      getDevices() {
        this.devices = [];
        this.loadingDevices = true;

        PropertyService.getAllCollectionPages(this.tenant.uuid).then((properties: any[]) => {
          this.properties = properties;

          const promises = [] as Promise<any>[];
          this.properties.forEach((prop: any) => {
            prop['selectedFull'] = false;
            prop['selectedPartial'] = false;

            const prom = PropertyService.getAssociatedDevices(this.tenant.uuid, prop.uuid);
            promises.push(prom);
          });
          return Promise.all(promises);
        }).then((deviceResponses: any[]) => {

          deviceResponses.forEach((devicesList: any[]) => {
            devicesList.forEach((device: any) => {
                device.permission = 'r';
                device.propertyName = this.getProperty(device.propertyId)?.name;
                this.devices.push(device);
            });
          });

         this.updatedAssociatedDevices = this.value.map((value: any) => {
            let tmp = Object.assign({}, value);
            const dev = this.devices.find((d: any) => d.uuid===value.uuid);
            const assocDev = this.value.find((d: any) => d.uuid===value.uuid);

            tmp.permission = assocDev ? assocDev.permission : 'r';
            tmp.propertyId = dev?.propertyId;
            tmp.propertyName = this.getProperty(dev?.propertyId)?.name;

            return tmp;
          });

          this.loadingDevices = false;
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
            this.dataError = true;       
        });
      },
      getProperty(propertyId: any): any {
        // find property for device
        return this.properties.find((prop: any) => prop.uuid === propertyId);
      },
      getPermission(item: any): any {
        let tmp = this.updatedAssociatedDevices.find((value: any) => value.uuid === item.uuid);
        return tmp.permission;
      },
      setPermission(item: any, perm: string) {
        let tmp = this.updatedAssociatedDevices.find((value: any) => value.uuid === item.uuid);
        tmp.permission = perm;
      },
      setAllPermission(perm: string) {
        this.updatedAssociatedDevices = this.updatedAssociatedDevices.map((value: any) => {
          value.permission = perm;
          return value;
        })
      },
      getPermissionLabel(perm: string): string {
          if(perm === 'r')
            return this.$t('permission.read');
          else if(perm === 'rw')
            return this.$t('permission.write')
          else 
            return perm;
      },
      getPermissionColor(perm: string): any {
          if(perm === 'r')
            return 'secondary';
          else if(perm === 'rw')
            return 'accent'
          else 
            return undefined;
      },
      isSelected(item: any) {
          return this.updatedAssociatedDevices.findIndex((value: any) => value.uuid === item.uuid) >= 0;
      },
      groupBy(list: any[], key: string) {
        return list.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
      },
      onPaginationAndFilter(pagination: any) {
        this.numberOfEntities = pagination.itemsLength;
      },
      filterItem
    },
    watch: {
        updatedAssociatedDevices(value: any[]) {
            if(value.length === this.devices.length) {
                this.allSelected = true;
                this.partialSelected = false;
            } else if(value.length > 0) {
                this.allSelected = false;
                this.partialSelected = true;
            } else {
                this.allSelected = false;
                this.partialSelected = false;
            }
            const propertyGroups = this.groupBy(value, 'propertyId');
            this.properties.forEach((prop: any) => {
                if(prop.uuid in propertyGroups) {
                    if(propertyGroups[prop.uuid].length === this.propertyDevices[prop.uuid].length){
                        prop.selectedFull = true;
                        prop.selectedPartial = false;
                    } else {
                        prop.selectedFull = false;
                        prop.selectedPartial = true;  
                    }
                } else {
                    prop.selectedFull = false;
                    prop.selectedPartial = false;
                }
            });
        },
        async localdialog(value: boolean) {
            if(value) {
                this.getDevices();
            } else {
                this.clear();
            }
        }
    },
    computed: {
      propertyDevices(): any {
          return this.groupBy(this.devices, 'propertyId');
      },
      computedTableHeader(): any {
        return [
            { text: this.$t('attribute.aksId'), value: 'aksId'},
            // { text: 'Local AKS ID', value: 'localAksId' },
            { text: this.$t('attribute.description'), value: 'description' },
            { text: this.$t('attribute.unit'), value: 'unit', width:'56px'},
            { text: this.$t('attribute.permission'), value: 'permission', sortable: false, filterable: false },
        ];
      },
        isRoot(): any {
            return this.userPermissions && 'role' in (this.userPermissions as any)
                && (this.userPermissions as any).role === 'superadmin';
        },
    },
  })
