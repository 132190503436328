
  import Vue from 'vue'
  import ApiKeyService from '@/services/ApiKeyService';
  import UserService from '@/services/UserService';
  import DatePicker from '@/components/misc/DatePicker.vue'
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import QRCard from '@/components/misc/QRCard.vue';

  export default Vue.extend({
    name: 'ApiKeyCreateDialog',

    components: {
      DatePicker,
      QRCard
    },
    props: ['user'],
    data: (vm: any): any => ({
        dialog: false,
        valid: true,
        successfullyCreated: false,
        loadingUsers: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        requiredRule: [
          (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
            (v: any) => Object.keys(v).length > 0 || vm.requiredError
        ],
        apikey: {
          name: "",
          expiration: "",
          user: {}
        },
        notifyApiKey: false,
        token: "",
        expirationMS: null,
        users: [] as any[]
    }),
    async created() {
      if(this.user) {
        // console.log(this.user);
        this.apikey.user = this.user;
      }
    },
    methods: {
      validate() {
        if(this.createForm.validate()) {
          this.create();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.apikey = {
          name: "",
          expiration: "",
          user: {}
        };
        this.notifyApiKey = false;
        this.token = "";
        this.expirationMS = null;
        this.successfullyCreated = false;
        this.users = [];
        this.alertVisible = false;
        this.loading = false;
        this.valid = true;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      create() {
        this.loading = true;
        let notify = this.notifyApiKey;
        let name = this.apikey.name;
        let expiration;
        let userId = this.apikey.user.uuid;

        if(this.apikey.expiration) {
          expiration = new Date(this.apikey.expiration);
        }

        ApiKeyService.createApiKey(userId, name, expiration, notify).then((apikey: any) => {
          this.loading = false;
          this.alertType = "success";
          this.alertText = this.$t('apikey.successCreate');
          this.alertVisible = true;
          this.successfullyCreated = true;

          this.token = apikey.token;
          this.expirationMS = new Date(apikey.expiration).getTime();

          apikey.user = this.apikey.user;
          delete apikey.token;

          this.$emit("success", apikey);
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('apikey.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
          this.loading = false;
        });
      },
      async onCopyClipboard() {
        try {
          await navigator.clipboard.writeText(this.token);
          
          this.alertType = "success";
          this.alertText = this.$t('apikey.successCopyClipboard');
          this.alertVisible = true;
        } catch(err: any) {
          this.alertType = "error";
          this.alertText = this.$t('apikey.errorCopyClipboard') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
        }
      },
      autoComplete,
      getUsers() {
        this.users = [];
        this.loadingUsers = true;
        let params = {};

        UserService.getAllCollectionPages(params).then((users: any[]) => {
          this.loadingUsers = false;

          this.users = users;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.loadingUsers = false;    
        });
      },
    },
    watch: {
      dialog(value: boolean) {
        if(value) {
          if(this.user) {
            this.apikey.user = this.user;
          }
          this.getUsers();
        } else {
          this.clear();
        }
      }
    },
    computed: {
      qrCodeData(): string {
        return JSON.stringify({
          expires: this.expirationMS,
          token: this.token
        });
      },
      createForm(): any {
          return this.$refs.createForm;
      },
      requiredError(): any {
          return this.$t('required');
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
    }
  })
