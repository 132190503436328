
  import Vue from 'vue'
  import InfoCard from '@/components/misc/InfoCard.vue'
  import IoTDeviceService from '@/services/IoTDeviceService'
  import EntityFiltersCard from '@/components/filters/EntityFiltersCard.vue'

  import IotDevicesTable from '@/components/entities/iotdevice/IotDevicesTable.vue'
  import IotDeviceCreateDialog from '@/components/entities/iotdevice/IotDeviceCreateDialog.vue'

  import { extractErrorMessage } from '@/utils/Util'

  export default Vue.extend({
    name: 'IoTDeviceView',

    components: {
      InfoCard,
      IotDevicesTable,
      EntityFiltersCard,
      IotDeviceCreateDialog
    },
    data: () => ({
      iotdevices: [] as any,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      filters: {} as any,
      filtersConfig: [
        { attribute: 'serialnumber', always: true },
        // { attribute: 'enabled', always: true },
        { attribute: 'tenant', always: true },
        { attribute: 'type', always: true },
        { attribute: 'property', always: false },
        { attribute: 'connector', always: false },
        { attribute: 'createdAt', always: false }
      ] as any[]
    }),
    created() {
      this.updateData();
    },
    watch: {
    },
    methods: {
      onTableReload() {
        this.updateData();
      },
      onFiltersApply() {
        if(('property' in this.filters || 'connector' in this.filters) && !('tenant' in this.filters)) {
          this.$set(this.filters, 'tenant', this.$root.$store.state.session.selectedTenant); // important else DOM is not updated
        }
        this.updateData();
      },
      onItemUpdate(item: any) {
        this.iotdevices.splice(this.iotdevices.findIndex((conn: any) => conn.uuid === item.uuid), 1);
        this.iotdevices.push(item);
      },
      onItemDelete(item: any) {
        this.iotdevices.splice(this.iotdevices.findIndex((conn: any) => conn.uuid === item.uuid), 1);
      },
      onCreation(item: any) {
        this.iotdevices.push(item);
      },
      async updateData() {
        this.iotdevices = [];
        this.dataLoaded = false;
        this.dataError = false;

        let params = {};

        Object.assign(params, this.filters);

        try {
          let iotdevices = await IoTDeviceService.getAllCollectionPages(params);

          this.dataLoaded = true;
          this.iotdevices = iotdevices;
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = extractErrorMessage(err);
          this.dataError = true;   
          this.dataLoaded = true;  
        }
      }
    },
    computed: {
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'iotdevices' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['iotdevices'].includes('POST');
        } else {
          return false;
        }
      },      
      lastCreatedAt(): any {
        let occurrences: any = {};
        let sum = 0;

        let pastDate = new Date();
        pastDate.setDate(pastDate.getDate()-7); 

        this.iotdevices.forEach((userObj: any) => {
          let _date = new Date(userObj.createdAt);
          if(_date >= pastDate) {
            let item = _date.getDate() + '-' + _date.getMonth();
            occurrences[item] = (occurrences[item] || 0) + 1;
            sum = sum + 1;
          }
        }, {});

        return sum;
      }
    }
  })
