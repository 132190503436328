import axiosLimiter from '@/api/RateLimiter';

export default {  
    async getAllCollectionPages(tenant: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/connectors?';

            if('name' in params && params.name && params.name.length > 0) {
                requestURL = requestURL + '&name=' + encodeURIComponent(params.name);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }
            if('tag' in params && params.tag && Object.keys(params.tag).length > 0) {
                requestURL = requestURL + '&tagId=' + encodeURIComponent(params.tag.uuid);
            }
            if('tags' in params && params.tags && params.tags.length > 0) {
                requestURL = requestURL + '&tagIds=' + encodeURIComponent(params.tags.map((value: any) => value.uuid).join(','));
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(tenant: string, connectorUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenant + '/connectors/' + connectorUUID);
    },
    createConnector(tenantUUID: string, name: string): Promise<any> {               
        const body = {
            data: {
                type: "connectors",
                attributes: {
                    name: name
                }
            }
        } as any;

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/connectors', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    deleteConnector(tenantUUID: string, connectorUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/connectors/' + connectorUUID);
    },
    updateConnector(tenantUUID: string, connectorUUID: string, name = ""): Promise<any> {
    
        const body = {
            data: {
                type: "connectors",
                id: connectorUUID,
                attributes: {
                }
            }
        } as any;

        if(name.length > 0) {
            body.data.attributes.name = name;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/connectors/' + connectorUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    getAssociatedDevices(tenantUUID: string, connectorUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/connectors/' + connectorUUID + '/devices');
    },
    getAssociatedTags(tenantUUID: string, connectorUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/connectors/' + connectorUUID + '/tags');
    },    
    addTags(tenantUUID: string, connectorUUID: string, tags: any[]): Promise<any> {
        const body = {
            data: []
        } as any;

        for(const tag of tags) {
            body.data.push({
                type: "tags",
                id: tag.uuid
            });
        }
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/connectors/' + connectorUUID + '/tags', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    removeTags(tenantUUID: string, connectorUUID: string, tags: any[]): Promise<any> {
        const body = {
            data: []
        } as any;

        for(const tag of tags) {
            body.data.push({
                type: "tags",
                id: tag.uuid
            });
        }
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID +  '/connectors/' + connectorUUID + '/tags', { headers: {'Content-Type': 'application/vnd.api+json'}, data: JSON.stringify(body)});
    },
    generateToken(tenantUUID: string, connectorUUID: string): Promise<any> {               
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/connectors/' + connectorUUID + '/generateToken', '', { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    getAssociatedAlerts(tenantUUID: string, connectorUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/connectors/' + connectorUUID + '/alerts');
    },
    addAlert(tenantUUID: string, connectorUUID: string, recipients: string[], maxAge: number, paused?: boolean): Promise<any> {
        const body = {
            "data": {
                "type": "alerts",
                "attributes": {
                    "maxAge": maxAge,
                    "recipients": recipients
                }
            }
        } as any;

        if(paused !== undefined) {
            body.data.attributes.paused = paused;
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/connectors/' + connectorUUID + '/alerts', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateAlert(tenantUUID: string, connectorUUID: string, alertUUID: string, recipients?: string[], maxAge?: number, paused?: boolean): Promise<any> {
        const body = {
            "data": {
                "id": alertUUID,
                "type": "alerts",
                "attributes": {
                }
            }
        } as any;

        if(recipients && recipients.length > 0) {
            body.data.attributes.recipients = recipients;
        }
        if(maxAge) {
            body.data.attributes.maxAge = maxAge;
        }
        if(paused !== undefined) {
            body.data.attributes.paused = paused;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/connectors/' + connectorUUID + '/alerts/' + alertUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    removeAlert(tenantUUID: string, connectorUUID: string, alertUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/tenants/' + tenantUUID + '/connectors/' + connectorUUID + '/alerts/' + alertUUID);
    },
}
  