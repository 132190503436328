
  import Vue from 'vue'
  import ReadingsTable from '@/components/entities/reading/ReadingsTable.vue'
  import ReadingCreateDialog from '@/components/entities/reading/ReadingCreateDialog.vue'
  import EntityFiltersCard from '@/components/filters/EntityFiltersCard.vue'

  import InfoCard from '@/components/misc/InfoCard.vue'

  import ReadingService from '@/services/ReadingService';
  import { extractErrorMessage, objectsToCSV } from '@/utils/Util';
import ConnectorService from '@/services/ConnectorService'
import DeviceService from '@/services/DeviceService'
import PropertyService from '@/services/PropertyService'

  export default Vue.extend({
    name: 'ReadingsView',

    components: {
      ReadingsTable,
      ReadingCreateDialog,
      EntityFiltersCard,
      InfoCard
    },
    data: () => ({
      readingsPage: [] as any,
      totalReadingCount: null as any,
      nextPageLink: null,
      prevPageLink: null,
      dataLoaded: false,
      loading: false,
      loadingTotalCount: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      pagination: {
        prevPage: 1,
        page: 1,
        itemsPerPage: 15,
        totalPages: 1,
        itemsLength: 0
      },
      aggregationRequest: false,
      filters: {} as any,
      filtersConfig: [
        { attribute: 'device', always: true },
        { attribute: 'timestamp', always: false },
        { attribute: 'value', always: false },
        { attribute: 'property', always: true },
        { attribute: 'connector', always: true },
        { attribute: 'flag', always: false },
        { attribute: 'createdAt', always: false },
        { attribute: 'deleted', always: false }
      ],
      aggregationFiltersConfig: [
        { attribute: 'device', always: true, mandatory: true },
        { attribute: 'interval', always: true, mandatory: true },
        { attribute: 'frequency', always: true, mandatory: true },
        { attribute: 'function', always: true, mandatory: true },
        { attribute: 'timestamp', always: false },
        { attribute: 'createdAt', always: false }
      ],
      aggregationFiltersDefault: {
        aggregationFrequency: 'monthly',
        aggregationInterval: 1,
        aggregationFunction: 'last'
      }
    }),
    async created() {
      this.resetPagination();
      this.getTotalReadings();

      if('deviceId' in this.$route.query) {
        try {
          const device = await DeviceService.getSingle(this.currentTenant, this.$route.query.deviceId as string);
          Vue.set(this.filters, 'device', device);
          this.updateReadings();
        } catch {}
      }
      if('connectorId' in this.$route.query) {
        try {
          const connector = await ConnectorService.getSingle(this.currentTenant, this.$route.query.connectorId as string);
          Vue.set(this.filters, 'connector', connector);
          this.updateReadings();
        } catch {}
      }
      if('propertyId' in this.$route.query) {
        try {
          const property = await PropertyService.getSingle(this.currentTenant, this.$route.query.propertyId as string);
          Vue.set(this.filters, 'property', property);
          this.updateReadings();
        } catch {}
      }

      if('device' in this.$route.params) {
        this.filters.device = this.$route.params.device;
        this.updateReadings();
      }
    },
    watch: {
      currentTenant() {
        this.filters = {};
        this.resetPagination();
        this.totalReadingCount = null;
        this.getTotalReadings();
      },
      parameter() {
        if('device' in this.$route.params) {
          this.filters = {};
          Vue.set(this.filters, 'device', this.$route.params.device);
          this.resetPagination();
          this.updateReadings();
        }   
      }
    },
    methods: {
      onTableReload() {
        if(this.pagination.page !== 1)
          this.resetPagination();
        else
          this.updateReadings();
      },
      resetPagination() {
          this.pagination = {
          prevPage: 1,
          page: 1,
          itemsPerPage: 15,
          totalPages: 1,
          itemsLength: 0
        };
        this.nextPageLink = null;
        this.prevPageLink = null;
        this.readingsPage = [];
      },
      onReadingFiltersApply() {
        this.pagination.page = 1;
        this.updateReadings();
        if(Object.keys(this.$route.query).length > 0)
          this.$router.replace({query: undefined});
      },
      onReadingItemRestore(item: any) {
        this.updateReadings();
      },
      onReadingItemUdate(item: any) {
        this.readingsPage.splice(this.readingsPage.findIndex((prop: any) => prop.uuid === item.uuid), 1);
        this.readingsPage.push(item);
      },
      onReadingItemDelete(item: any) {
        this.readingsPage.splice(this.readingsPage.findIndex((prop: any) => prop.uuid === item.uuid), 1);
        this.pagination.itemsLength--;
      },
      onReadingCreation(item: any) {
        this.pagination.page = 1;
        this.pageUpdated(1);
      },
      pageUpdated(page: number) {
        this.pagination.prevPage = this.pagination.page;
        this.pagination.page = page;
        this.updateReadings();
      },
      pageSizeUpdated(pageSize: number) {
        this.pagination.itemsPerPage = pageSize;
        this.pagination.page = 1;
        this.updateReadings();
      },
      async updateReadings() {
        this.readingsPage = [];
        this.loading = true;
        
        let params: any = {
          itemsPerPage: this.pagination.itemsPerPage
        }

        Object.assign(params, this.filters);

        if(this.pagination.prevPage < this.pagination.page) {
          params['nextPageLink'] = this.nextPageLink;
        } else if (this.pagination.prevPage > this.pagination.page) {
          params['prevPageLink'] = this.prevPageLink;
        }

        try {

          if(this.aggregationRequest) {
            // request reading aggregates
            const pageResponse = await ReadingService.getAggregationPage(this.currentTenant, params.device.uuid, params.aggregationInterval, params.aggregationFrequency, params.aggregationFunction, params);

            this.loading = false;
            
            this.pagination.itemsLength = pageResponse.data.meta.page.count;
            this.pagination.totalPages = pageResponse.data.meta.page.totalPages;
            this.pagination.page = pageResponse.data.meta.page.page;

            this.nextPageLink = pageResponse.data.links.next;
            this.prevPageLink = pageResponse.data.links.prev;

            pageResponse.data.data.forEach((aggObj: any) => {
              this.readingsPage.push({
                  device: params.device,
                  timestamp: aggObj.attributes.bucketStart,
                  value: aggObj.attributes.value !== null ? aggObj.attributes.value : NaN,
                  createdAt: aggObj.meta.createdAt,
                  bucketStart: aggObj.attributes.bucketStart,
                  bucketEnd: aggObj.attributes.bucketEnd,
                })
              });
          } else {
            const pageResponse = await ReadingService.getCollectionPage(this.currentTenant, params);

            this.loading = false;
            
            this.pagination.itemsLength = pageResponse.data.meta.page.count;
            this.pagination.totalPages = pageResponse.data.meta.page.totalPages;
            this.pagination.page = pageResponse.data.meta.page.page;

            this.nextPageLink = pageResponse.data.links.next;
            this.prevPageLink = pageResponse.data.links.prev;

            pageResponse.data.data.forEach((userObj: any) => {
              let obj = {
                uuid: userObj.id,
                timestamp: userObj.attributes.timestamp,
                value: userObj.attributes.value,
                flag: userObj.attributes.flag,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                deletedAt: userObj.meta.deletedAt,
              } as any;

              if(params.device) {
                obj = { device: params.device, ...obj }
              }
              
              this.readingsPage.push(obj)
            });
          }
        } catch (err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('reading.loadingError') + ' ' + extractErrorMessage(err);
          this.dataError = true;  
          this.loading = false;    
        }
      },
      async getTotalReadings() {
        this.loadingTotalCount = true;
        this.totalReadingCount = await ReadingService.getTotalReadingCount(this.currentTenant);
        this.loadingTotalCount = false;
      },
      async exportReadings() {
        this.loading = true;
      
        let params: any = {
          itemsPerPage: 1000
        }

        Object.assign(params, this.filters);

        try {
          let readings = [] as any[];

          if(this.aggregationRequest) {
              // request reading aggregates
              readings = await ReadingService.getAllAggregationPages(this.currentTenant, params.device.uuid, params.aggregationInterval, params.aggregationFrequency, params.aggregationFunction, params);
              this.loading = false;
            } else {
              readings = await ReadingService.getAllCollectionPages(this.currentTenant, params);
              this.loading = false;
            }

            const filename = 'bascloud_readings.csv';
            const strReadings = readings.map((read: any) => ({...read, value: read.value.toLocaleString()}));
            const csvContent = objectsToCSV(strReadings);
            var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

            if ((navigator as any).msSaveBlob) { // IE 10+
                (navigator as any).msSaveBlob(blob, filename);
            } else {
                var link = document.createElement("a");
                if (link.download !== undefined) { // feature detection
                    // Browsers that support HTML5 download attribute
                    var url = URL.createObjectURL(blob);
                    link.setAttribute("href", url);
                    link.setAttribute("download", filename);
                    link.style.visibility = 'hidden';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }
            }
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('reading.loadingError') + ' ' + extractErrorMessage(err);
          this.dataError = true;  
          this.loading = false;    
        }
      }
    },
    computed: {
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'readings' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['readings'].includes('POST');
        } else {
          return false;
        }
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      parameter(): any {
        return this.$route.params;
      }
    }
  })
