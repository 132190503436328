import store from '../store/index'
import router from '../router/index'
import LabelingApi from '@/api/LabelingApi';

export default {
    async getDeviceLabel(tenantUUID: string, labelUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await LabelingApi.getDeviceLabel(tenantUUID, labelUUID);

        if(response.data instanceof Blob || response.data.constructor.name === 'Blob') {
            if(response.data.size > 0)
                return response.data;
        }
    },
    async createDeviceLabel(tenantUUID: string, devices: any[], layout: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await LabelingApi.createDeviceLabel(tenantUUID, devices, layout);

        if(response.data.data.type && response.data.data.type == 'labels') {

            return {
                uuid: response.data.data.id,
                type: response.data.data.attributes.type,
                tenantId: tenantUUID,
                expiration: new Date(response.data.data.attributes.expiration),
                createdAt: response.data.data.meta.createdAt,
            };
        } else {
            throw TypeError("Response not type labels");
        }
    },
}
  