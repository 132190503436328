
  import Vue from 'vue'

  import IoTDeviceService from '@/services/IoTDeviceService';
  import IotDeviceMonitoringDashboard from '@/components/functions/monitoring/IotDeviceMonitoringDashboard.vue';
  import { extractErrorMessage } from '@/utils/Util';
  import DeviceService from '@/services/DeviceService';
  import ReadingService from '@/services/ReadingService';

  export default Vue.extend({
    name: 'IotDeviceMonitoringView',

    components: {
      IotDeviceMonitoringDashboard
    },
    data: () => ({
      loading: false,
      refreshInterval: undefined as any,
      dataError: false,
      alertText: "",
      alertType: "error",
      iotdevices: [] as any[],
      devices: [] as any[],
      timeRangeSelection: '1M'
    }),
    created() {
      this.getData();
    },
    destroyed() {
      if(this.refreshInterval)
        clearInterval(this.refreshInterval);
    },
    activated() {
      this.refreshInterval = setInterval(this.getData, 5*60*1000);
    },
    deactivated() {
      if(this.refreshInterval)
        clearInterval(this.refreshInterval);
    },
    watch: {
      timeRangeSelection() {
        console.log('timerange changed')
        this.getData();
      }
    },
    methods: {
      onReloadAll() {
        this.getData();
      },
      onReloadItem(item: any) {

      },
      delay(ms: number) {
        return new Promise(res => setTimeout(res, ms));
      },
      async getData() {
        await this.getDeviceData();
        this.getDeviceReadingData();
        await this.getIotDeviceData();
      },
      async getIotDeviceData() {
        this.iotdevices = [];
        this.loading = true;

        const date = new Date(Date.now());
        let params: any = {};
        params.until = date.toISOString();

        if(this.timeRangeSelection === '1D') {
          date.setDate(date.getDate()-1);
        } else if(this.timeRangeSelection === '1W') {
          date.setDate(date.getDate()-7);
        } else if(this.timeRangeSelection === '1M') {
          date.setMonth(date.getMonth()-1);
        } else if(this.timeRangeSelection === '3M') {
          date.setMonth(date.getMonth()-3);
        }
        params.from = date.toISOString();

        try {
          let iotdevices = await IoTDeviceService.getAllMonitoringCollectionPages(params);

          for(let i=0;i<iotdevices.length;i++) {
            iotdevices[i].alertActive = iotdevices[i].alerts.length > 0 ? (iotdevices[i].alerts.some((a: any) => a.active) ? 2 : (iotdevices[i].alerts.every((a: any) => a.paused) ? 0 : 1)) : 0;
          }

          this.loading = false;
          this.iotdevices.push(...iotdevices);
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = extractErrorMessage(err);
          this.dataError = true;   
          this.loading = false;  
        }
      },
      async getDeviceData() {
        this.devices = [];
        this.loading = true;

        try {
          let devices = await DeviceService.getAllCollectionPages(this.monitoringTenant, { connector: { uuid: this.oldDeviceConnector } });

          for(let i=0;i<devices.length;i++) {
            devices[i].alerts = [];
            devices[i].alertActive = -1;
            devices[i].readings = [];
            devices[i].loading = true;
          }

          this.loading = false;
          this.devices.push(...devices);
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = extractErrorMessage(err);
          this.dataError = true;   
          this.loading = false;  
        }
      },
      async getDeviceReadingData() {

        try {
          for(let i=0;i<this.devices.length;i++) {
            this.devices[i].loading = true;
            await this.getDeviceAlert(this.devices[i]);
            await this.getReadings(this.devices[i]);
            this.devices[i].loading = false;
          }
          
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = extractErrorMessage(err);
          this.dataError = true;   
        }
      },
      async getDeviceAlert(device: any) {
          if(!device)
            return;
          device.loading = true;

          try {
            const alerts = await DeviceService.getAssociatedAlerts(this.monitoringTenant, device.uuid);
            // console.log('got alerts for '+ device.aksId)

            device.loading = false;
            device.alerts = alerts;
            device.alertActive = alerts.length > 0 ? (device.alerts.some((a: any) => a.active) ? 2 : 1) : 0;
          } catch(err: any) {
            device.loading = false;
            console.log(err, err.response);
          }
      },   
      async getReadings(device: any) {
        if(!device)
            return;
          
        device.readings = [];
        device.loading = true;

        let date = new Date(Date.now());

        let params: any = {};
        params.device = device;
        device.until = new Date(date);
        params.until = date.toISOString();

        if(this.timeRangeSelection === '1D') {
          date.setDate(date.getDate()-1);
        } else if(this.timeRangeSelection === '1W') {
          date.setDate(date.getDate()-7);
        } else if(this.timeRangeSelection === '1M') {
          date.setMonth(date.getMonth()-1);
        } else if(this.timeRangeSelection === '3M') {
          date.setMonth(date.getMonth()-3);
        }
        const creationDate = new Date(device.createdAt);

        if(date < creationDate)
          date = creationDate;

        device.from = new Date(date);
        params.from = date.toISOString();

        try {
          const readings = await ReadingService.getAllCollectionPages(this.monitoringTenant, params);
          // console.log('got readings for '+ device.aksId)

          device.loading = false;
          device.readings = readings;
        } catch(err: any) {
          device.loading = false;
          console.log(err, err.response);
        }
      },
    },
    computed: {
      oldDeviceConnector(): string {
        return 'a6072314-bf23-45b1-bdf2-f342e44f8726'
      },
      monitoringTenant(): string {
        return 'd97ce06e-4630-4015-89b4-1fbb67bbe328';
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      }
    }
  })
