
  import Vue from 'vue'
  import UserService from '@/services/UserService';
  import MeteringService from '@/services/MeteringService';

  import { autoComplete, extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'UserCreateDialog',

    props: ['tenant'],
    data: (vm: any): any => ({
        dialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        createEmail: "",
        createPassword: "",
        verifyCreatePassword: "",
        createUserTenant: {},
        createUserTenantRole: "user",
        createMeteringUser: false,
        emailRules: [
            (v: string) => !!v || vm.requiredError,
            (v: string) => /.+@.+\..+/.test(v) || vm.emailValidError
        ],
        showPassword: false,
        requiredSelect: [
            (v: any) => Object.keys(v).length > 0 || vm.requiredError
        ],
    }),
    async created() {
      if(this.$root.$store.state.session.tenants.length === 1) {
        this.createUserTenant = this.$root.$store.state.session.tenants[0];
      }
      if(this.tenant) {
        this.createUserTenant = this.tenant;
      }
    },
    methods: {
      validate() {
        if(this.createForm.validate()) {
          this.createUser();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.createEmail = "";
        this.createPassword = "";
        this.verifyCreatePassword = "";
        this.alertVisible = false;
        this.loading = false;
        this.createMeteringUser = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async createUser() {
        this.loading = true;

        try {
          let tenantId;
          let tenantRole;
          if(Object.keys(this.createUserTenant).length > 0) {
            tenantId = this.createUserTenant.uuid;
            tenantRole = this.createUserTenantRole;
          }

          const user = await UserService.createUser(this.createEmail, tenantId, tenantRole, this.createPassword);
          
          if(Object.keys(this.createUserTenant).length > 0 && this.createMeteringUser) {
            const meteringUser = await MeteringService.createUser(this.createUserTenant.uuid, user.uuid);
            if(meteringUser?.id === user.uuid) {
              this.meteringActive = true;
            }
          }
          this.alertType = "success";
          this.alertText = this.$t('user.successCreate') as string;
          this.alertVisible = true;

          this.$emit("success", user);
          setTimeout(() => { 
            this.dismiss(); 
          }, 1500);
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('user.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
          this.loading = false;
        }
      },
      autoComplete,
    },
    watch: {
      dialog(value: boolean) {
        if(!value) {
          this.clear();
        }
      }
    },
    computed: {
        meteringState(): string {
          if(this.createMeteringUser) return this.$t('services.active');
          else return this.$t('services.inactive');
        },
        meteringStateColor(): string {
          if(this.createMeteringUser) return 'green';
          else return 'grey lighten';
        },
        permissionRoles(): any {
            return UserService.getRoles();
        },
        createForm(): any {
            return this.$refs.createForm;
        },
        passwordMatch() {
            return () => this.createPassword === this.verifyCreatePassword || this.$t('login.passwordMatch');
        },
        passwordLengthError(): any {
            return this.$t('login.minPasswordLength');
        },
        requiredError(): any {
            return this.$t('required');
        },
        emailValidError(): any {
            return this.$t('login.validEmail');
        }
    }
  })
