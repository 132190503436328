import TimeRangeFilter from './TimeRangeFilter.vue';
import StringFilter from './StringFilter.vue';
import BooleanFilter from './BooleanFilter.vue';
import ConnectorFilter from './ConnectorFilter.vue';
import PropertyFilter from './PropertyFilter.vue';
import DeviceFilter from './DeviceFilter.vue';
import NumberFilter from './NumberFilter.vue';
import TagFilter from './TagFilter.vue';
import SelectionFilter from './SelectionFilter.vue';
import TenantFilter from './TenantFilter.vue';
import UserFilter from './UserFilter.vue';
import { ReadingAggregationFrequencies, ReadingAggregationFunctions, ReadingFlagTypes } from '../entities/reading/ReadingCollections';
import { SetpointStateTypes } from '../entities/setpoint/SetpointStateTypes';
import AggregationIntervalFilter from './AggregationIntervalFilter.vue';
import { UserPermissionRoles } from '../entities/user/UserCollections';


export const components = (context: any) => {
    
    return {
    'timestamp': {
      component: TimeRangeFilter,
      attributes: { from: 'from', until: 'until' },
      label: context.$t('attribute.timestamp')
    },
    'createdAt': {
      component: TimeRangeFilter,
      attributes: { from: 'createdFrom', until: 'createdUntil' },
      label: context.$t('attribute.createdAt')
    },
    'email': {
      component: StringFilter,
      attributes: { value: 'email' },
      label: context.$t('attribute.email')
    },
    'name': {
      component: StringFilter,
      attributes: { value: 'name' },
      label: context.$t('attribute.name')
    },
    'aksId': {
      component: StringFilter,
      attributes: { value: 'aksId' },
      label: context.$t('attribute.aksId')
    },
    'identifier': {
      component: StringFilter,
      attributes: { value: 'identifier' },
      label: context.$t('attribute.identifier')
    },
    'street': {
      component: StringFilter,
      attributes: { value: 'street' },
      label: context.$t('attribute.street')
    },
    'postalCode': {
      component: StringFilter,
      attributes: { value: 'postalCode' },
      label: context.$t('attribute.postalCode')
    },
    'city': {
      component: StringFilter,
      attributes: { value: 'city' },
      label: context.$t('attribute.city')
    },
    'country': {
      component: StringFilter,
      attributes: { value: 'country' },
      label: context.$t('attribute.country')
    },
    'localAksId': {
      component: StringFilter,
      attributes: { value: 'localAksId' },
      label: context.$t('attribute.localAksId')
    },
    'unit': {
      component: StringFilter,
      attributes: { value: 'unit' },
      label: context.$t('attribute.unit')
    },
    'serialnumber': {
      component: StringFilter,
      attributes: { value: 'serialnumber' },
      label: context.$t('attribute.serialnumber')
    },
    'type': {
      component: SelectionFilter,
      attributes: { value: 'type' },
      props: { items: [
        { value: 'E32', name: 'E32' },
        { value: 'P42', name: 'P42' },
        { value: 'OTHER', name: 'OTHER' },
      ]},
      label: context.$t('attribute.type')
    },
    'types': {
      component: SelectionFilter,
      attributes: { value: 'types' },
      props: { multi: true, items: [
        { value: 'E32', name: 'E32' },
        { value: 'P42', name: 'P42' },
        { value: 'OTHER', name: 'OTHER' },
      ]},
      label: context.$t('attribute.type')
    },
    'subtype': {
      component: StringFilter,
      attributes: { value: 'subtype' },
      label: context.$t('attribute.subtype')
    },
    'value': {
      component: NumberFilter,
      attributes: { value: 'value' },
      label: context.$t('attribute.value')
    },
    'flag': {
      component: SelectionFilter,
      attributes: { value: 'flag' },
      props: { items: ReadingFlagTypes, },
      label: context.$t('attribute.flag')
    },
    'flags': {
      component: SelectionFilter,
      attributes: { value: 'flags' },
      props: { items: ReadingFlagTypes, multi: true },
      label: context.$t('attribute.flag')
    },
    'state': {
      component: SelectionFilter,
      attributes: { value: 'state' },
      props: { items: SetpointStateTypes },
      label: context.$t('attribute.state')
    },
    'states': {
      component: SelectionFilter,
      attributes: { value: 'states' },
      props: { items: SetpointStateTypes, multi: true },
      label: context.$t('attribute.state')
    },
    'interval': {
      component: AggregationIntervalFilter,
      attributes: { value: 'aggregationInterval' },
      label: context.$t('attribute.aggregationInterval')
    },
    'frequency': {
      component: SelectionFilter,
      attributes: { value: 'aggregationFrequency' },
      props: { items: ReadingAggregationFrequencies },
      label: context.$t('attribute.aggregationFrequency')
    },
    'function': {
      component: SelectionFilter,
      attributes: { value: 'aggregationFunction' },
      props: { items: ReadingAggregationFunctions },
      label: context.$t('attribute.aggregationFunction')
    },
    'role': {
      component: SelectionFilter,
      attributes: { value: 'role' },
      props: { items: UserPermissionRoles },
      label: context.$t('attribute.role')
    },
    'tag': {
      component: TagFilter,
      attributes: { value: 'tag' },
      label: context.$t('entity.tag')
    },
    'tags': {
      component: TagFilter,
      attributes: { value: 'tags' },
      props: { multi: true },
      label: context.$t('entity.tags')
    },
    'deleted': {
      component: BooleanFilter,
      attributes: { value: 'deleted' },
      label: context.$t('attribute.deleted')
    },
    'isRelative': {
      component: BooleanFilter,
      attributes: { value: 'isRelative' },
      label: context.$t('attribute.isRelative')
    },
    'alerting': {
      component: BooleanFilter,
      attributes: { value: 'alerting' },
      label: context.$t('attribute.alerting')
    },
    'property': {
      component: PropertyFilter,
      attributes: { value: 'property' },
      label: context.$t('entity.property')
    },
    'properties': {
      component: PropertyFilter,
      attributes: { value: 'properties' },
      props: { multi: true },
      label: context.$t('entity.properties')
    },
    'connector': {
      component: ConnectorFilter,
      attributes: { value: 'connector' },
      label: context.$t('entity.connector')
    },
    'connectors': {
      component: ConnectorFilter,
      attributes: { value: 'connectors' },
      props: { multi: true },
      label: context.$t('entity.connectors')
    },
    'device': {
      component: DeviceFilter,
      attributes: { value: 'device' },
      label: context.$t('entity.device')
    },
    'devices': {
      component: DeviceFilter,
      attributes: { value: 'devices' },
      props: { multi: true },
      label: context.$t('entity.device')
    },
    'tenant': {
      component: TenantFilter,
      attributes: { value: 'tenant' },
      label: context.$t('entity.tenant')
    },
    'user': {
      component: UserFilter,
      attributes: { value: 'user' },
      label: context.$t('entity.user')
    },
    'enabled': {
      component: BooleanFilter,
      attributes: { value: 'enabled' },
      label: context.$t('enabled')
    },
  };
}