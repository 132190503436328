
  import Vue from 'vue'

  import DeviceInfoDialog from '@/components/entities/device/DeviceInfoDialog.vue';
import ReadingService from '@/services/ReadingService';

  export default Vue.extend({
    name: 'ReadingCard',

    components: {
      DeviceInfoDialog,
    },
    props: ['item', 'color', 'index'],
    data: () => ({
      loading: false,
      refreshInterval: undefined as any,
      lastReading: undefined as any
    }),
    async created() {
      if(this.index && parseInt(this.index))
        await this.delay(parseInt(this.index)*100);
      this.getLastReading();

      this.refreshInterval = setInterval(this.getLastReading, 15*60*1000);
    },
    destroyed() {
      clearInterval(this.refreshInterval);
    },
    methods: {
      delay(ms: number) {
          return new Promise(res => setTimeout(res, ms));
      },
      numberString(n: number) {
        return n.toLocaleString();
      },
      onDelete() {
        this.$emit('delete', this.item);
      },
      onRefresh() {
        this.getLastReading();
      },
      onShowReadings() {
        this.$router.push({ name: 'Readings', params: { device: this.item.device } });
      },      
      getLastReading() {
        this.loading = true;

        ReadingService.getLastReading(this.currentTenant, this.item.device).then((lastReading: any) => {
          this.loading = false;
          this.lastReading = lastReading;

          if(this.item.offline) {
            let citem = { ...this.item };
            citem.offline = false;
            this.$emit('update:item', citem);
          }
        }).catch((err: any) => {
          this.loading = false;
          console.log(err, err.response);
          if(err.response.status >= 400) {
            // not found or error
            let citem = { ...this.item };
            citem.offline = true;
            this.$emit('update:item', citem);
          }
        });
      },
    },
    computed: {
      itemDate(): string {
        if(this.lastReading) {
          return new Date(this.lastReading.timestamp).toLocaleString();
        } else {
          return 'n.a.'
        }
      },
      backgroundColor(): string | undefined {
        if(this.item.offline)
          return 'red lighten-1';
        else
          return undefined;
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
    },
  })
