
  import Vue from 'vue'
  import ConnectorService from '@/services/ConnectorService';
import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'ConnectorGenerateTokenDialog',
    
    props: ['connector'],
    data: () => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        token: "",
        expiration: "",
    }),
    watch: {
        localDialog(value: boolean) {
            if(!value) {
                this.clear();
            }
        }
    },
    methods: {
      close() {
        this.localDialog = false;
      },
      clear() {
        this.token = "";
        this.expiration = "";
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async onCopyClipboard() {
        try {
          await navigator.clipboard.writeText(this.token);
          
          this.alertType = "success";
          this.alertText = this.$t('apikey.successCopyClipboard') as string;
          this.alertVisible = true;
        } catch(err: any) {
          this.alertType = "error";
          this.alertText = this.$t('apikey.errorCopyClipboard') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
        }
      },
      confirmGenerate() {
        this.loading = true;
      
        ConnectorService.generateToken(this.$root.$store.state.session.selectedTenant.uuid, this.connector.uuid).then((data: any) => {
            this.alertType = "success";
            this.alertText = this.$t('connector.successGenerateToken') as string;
            this.alertVisible = true;
            this.loading = false;

            this.token = data.token;
            this.expiration = data.expires;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('connector.errorGenerateToken') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      }
    },
  })
