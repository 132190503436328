
  import Vue from 'vue'
  import DeviceService from '@/services/DeviceService';
  import MeteringService from '@/services/MeteringService';

  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import { RRule } from 'rrule'

  import DatePicker from '@/components/misc/DatePicker.vue'

  const frequencyInputsTable = {
    yearly: {
      rrule: RRule.YEARLY,
      until: true,
      interval: true,
      month: true,
      day_of_month: true,
      day_of_the_week: false
    },
    monthly: {
      rrule: RRule.MONTHLY,
      until: true,
      interval: true,
      month: false,
      day_of_month: true,
      day_of_the_week: false
    },
    weekly: {
      rrule: RRule.WEEKLY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: true
    },
    daily: {
      rrule: RRule.DAILY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    },
    once: {
      rrule: null,
      until: false,
      interval: false,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    }
  };

  const range = (n: number) => Array.from({ length: n }, (v, k) => k + 1);
  const thirty1 = range(31);
  const thirty = range(30);
  const twenty9 = range(29);

  const daysPerMonth = {
    January: thirty1,
    February: twenty9,
    March: thirty1,
    April: thirty,
    May: thirty1,
    June: thirty,
    July: thirty1,
    August: thirty1,
    September: thirty,
    October: thirty1,
    November: thirty,
    December: thirty1
  };

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  export default Vue.extend({
    name: 'TaskCreateDialog',

    components: {
      DatePicker
    },
    props: ['device', 'tasks'],
    data: (vm: any): any => ({
        frequencyInputsTable,
        daysPerMonth,
        weekdays,
        repetitionSelection: "",
        rrule: {
          interval: 1,
          start: new Date(Date.now()).toISOString(),
          frequency: null,
          until_num: null,
          month: null,
          daysOfTheMonth: [],
          daysOfTheWeek: [],
          until_date: null
        },
        dialog: false,
        valid: true,
        preselectedDevice: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingDevice: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
            (v: any) => (!!v && Object.keys(v).length > 0) || vm.requiredError
        ],
        devices: [] as any[],
        selectedDevices: [] as any[],
        note: "",
        forceQRScan: false,
    }),
    created() {
      if(this.device) {
        this.selectedDevices = [this.device];
        this.devices = [this.device];
        this.preselectedDevice = true;
      }
    },
    methods: {
      validate() {
        if(this.createForm.validate()) {
          this.createTask();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.note = "";
        this.rrule = {
          interval: 1,
          start: new Date(Date.now()).toISOString(),
          frequency: null,
          until_num: null,
          month: null,
          daysOfTheMonth: [],
          daysOfTheWeek: [],
          until_date: null
        };
        this.selectedDevices = [];
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async createTask() {
        this.loading = true;

        let note = this.note;
        let forceQRScan = this.forceQRScan;
        
        let start = new Date(this.rrule.start);

        let _rrule = {
          dtstart: new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate())),
        } as any;

        if(this.rrule.frequency && this.rrule.frequency.length > 0) {
          if(this.rrule.frequency.toLowerCase() === 'once') {
            // special case, once is not supported by rrule so we create 
            // a task with yearly occure but only once
            _rrule.freq = RRule.YEARLY;
            _rrule.count = 1;
          } else {
            _rrule.freq = this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].rrule;
          }
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].interval && this.rrule.interval) {
          _rrule.interval = Number(this.rrule.interval);
        }
        if(this.repetitionSelection === 'until_count' && this.rrule.until_num) {
          _rrule.count = Number(this.rrule.until_num);
        }
        if(this.repetitionSelection === 'until_date' && this.rrule.until_date) {
          _rrule.until = new Date(this.rrule.until_date);
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].month && this.rrule.month) {
          _rrule.bymonth = Object.keys(daysPerMonth).indexOf(this.rrule.month) + 1;
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].day_of_month && this.rrule.daysOfTheMonth.length > 0) {
          _rrule.bymonthday = this.rrule.daysOfTheMonth;
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].day_of_the_week && this.rrule.daysOfTheWeek.length > 0) {
          _rrule.byweekday = this.rrule.daysOfTheWeek.map((value: string) => this.weekdays.findIndex((day: string) => day === value));
        }
        // console.log(_rrule);
        const rule = new RRule(_rrule);
        // console.log(rule.toString())

        let rrule = rule.toString();

        for(const dev of this.selectedDevices) {
          let associatedDeviceUUID = dev.uuid;

          try {
 
            const task = await MeteringService.createDeviceTask(this.$root.$store.state.session.selectedTenant.uuid, associatedDeviceUUID, rrule, note, forceQRScan)
            this.alertType = "success";
            this.alertText = this.$t('task.successCreate');
            this.alertVisible = true;
            task.device = dev;

            this.$emit("success", task);
          } catch(err: any) {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('task.errorCreate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;
          }
        }

        setTimeout(() => { 
            this.dismiss(); 
        }, 1500);
      },
      getDevices() {
        this.loadingDevice = true;

        DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
          this.loadingDevice = false;

          this.devices = devices;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;    
          this.loadingDevice = false;  
        });
      },
      autoComplete,
    },
    watch: {
      async dialog(value: boolean) {
        if(value) {
          if(this.device) {
            this.selectedDevices = [this.device];
            this.devices = [this.device];
            this.preselectedDevice = true;
          } else {
            this.getDevices();
          }
        } else {
          this.clear();
        }
      }
    },
    computed: {
        intervalHint() {
          const rrule = this.rrule;
          return `1 means every ${
            rrule.frequency === "Daily" ? "Day" : rrule.frequency.slice(0, -2)
          }, 2 is every other ${
            rrule.frequency === "Daily" ? "Days" : rrule.frequency.slice(0, -2)
          }`;
        },
        frequencies(): any {
          // TODO add i18n translation
          const keys = Object.keys(frequencyInputsTable);
          return keys.map((value: string) => value.charAt(0).toUpperCase()+value.slice(1))
        },
        months(): any { 
          const keys = Object.keys(daysPerMonth);
          return keys.map((value: string) => value.charAt(0).toUpperCase()+value.slice(1))
        },
        createForm(): any {
            return this.$refs.createForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        }
    }
  })
