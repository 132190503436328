
  import Vue from 'vue'

  import TenantInfoDialog from '@/components/entities/tenant/TenantInfoDialog.vue'

  export default Vue.extend({
    name: 'NavigationDrawer',
    
    props: ['drawer'],
    components: {
      TenantInfoDialog
    },
    model: {
      prop: 'drawer',
      event: 'changed'
    },
    data: () => ({
        localDrawer: null,
        localTenantSelection: {},
        showAdminMenu: false,
        showTenantMenu: true
    }),
    created() {
      if(this.$root.$store.state.session.tenants.length === 0) {
        this.showAdminMenu = true;
        this.showTenantMenu = false;
      }
      this.localTenantSelection = this.currentTenant;

      if(this.showAdminCategory && this.adminItems.findIndex((value: any) => value.path === this.$router.currentRoute.path) >= 0) {
        this.showAdminMenu = true;
        this.showTenantMenu = false;
      }
    },
    methods: {
    },
    watch: {
      showAdminMenu() {
        if(this.showAdminMenu) 
          this.showTenantMenu = false;
      },
      showTenantMenu() {
        if(this.showTenantMenu) 
          this.showAdminMenu = false;
      },
      drawer() {
        this.localDrawer = this.drawer;
      },
      localDrawer() {
        this.$emit('changed', this.localDrawer)
      },
      currentTenant() {
        this.localTenantSelection = this.currentTenant;
      },
      localTenantSelection() {
        this.$root.$store.commit("setSelectedTenant", this.localTenantSelection);
      }
    },
    computed: {
      overviewItems(): any  { 
        return [
          { title: this.$t('navigationDrawer.dashboard'), icon: 'mdi-view-dashboard', path: '/dashboard' },
        ]
      },
      adminItems(): any {
        return [
          { title: this.$t('entity.users'), icon: 'mdi-account-multiple', path: '/users', permission: 'users' },
          { title: this.$t('entity.tenants'), icon: 'mdi-database', path: '/tenants', permission: 'tenants' },
          { title: this.$t('entity.devicetypes'), icon: 'mdi-format-list-bulleted-type', path: '/devicetypes', permission: 'devicetypes' },
          { title: this.$t('entity.prices'), icon: 'mdi-cash-multiple', path: '/prices', permission: 'prices' },
          { title: this.$t('entity.apikeys'), icon: 'mdi-account-key', path: '/apikeys', permission: 'apikeys' },
          { title: this.$t('entity.deviceshares'), icon: 'mdi-share-variant', path: '/deviceshares', permission: 'deviceshares' },
          { title: this.$t('entity.iotdevices'), icon: 'mdi-devices', path: '/iotdevices', permission: 'iotdevices' },
        ]
      },
      entityItems(): any {
        return [
          { title: this.$t('entity.properties'), icon: 'mdi-city-variant-outline', path: '/properties', permission: 'properties' },
          { title: this.$t('entity.connectors'), icon: 'mdi-lan', path: '/connectors', permission: 'connectors' },
          { title: this.$t('entity.devices'), icon: 'mdi-gauge', path: '/devices', permission: 'devices' },
          { title: this.$t('entity.readings'), icon: 'mdi-table', path: '/readings', permission: 'readings' },
          { title: this.$t('entity.setpoints'), icon: 'mdi-timetable', path: '/setpoints', permission: 'setpoints' },
          { title: this.$t('entity.tags'), icon: 'mdi-tag-text-outline', path: '/tags', permission: 'tags' },
        ]
      },
      meteringItems(): any {
        return [
          { title: this.$t('entity.tasks'), icon: 'mdi-clipboard-clock-outline', path: '/tasks' },
          { title: this.$t('entity.events'), icon: 'mdi-calendar', path: '/events' },
        ]
      },
      functionItems(): any {
        return [
          { title: this.$t('navigationDrawer.visualization'), icon: 'mdi-chart-line', path: '/visualization' },
          { title: this.$t('entity.reports'), icon: 'mdi-file-chart-outline', path: '/reports', permission: 'reports' },
        ]
      },
      extraItems(): any {
        return [
          { title: this.$t('navigationDrawer.bccSerial'), icon: 'mdi-chip', path: '/bccserial' },
        ]
      },
      extraMonitoringItems(): any {
        return [
          { title: this.$t('navigationDrawer.iotMonitoring'), icon: 'mdi-monitor', path: '/iotmonitoring' },
          { title: this.$t('navigationDrawer.mobileAppMonitoring'), icon: 'mdi-google-analytics', path: '/mobileanalytics' },
        ]
      },
      adminRoles(): any { 
        return ['superadmin', 'admin']
      },
      userEmail(): string {
        return this.$root.$store.state.session.currentUser.email;
      },
      userRole(): string {
        return this.$t('entity.'+this.$root.$store.state.session.permissions.role) as string;
      },
      isRoot(): any {
          return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
              && this.$root.$store.state.session.permissions.role === 'superadmin';
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant;
      },
      monitoringTenant(): string {
        return 'd97ce06e-4630-4015-89b4-1fbb67bbe328';
      },
      showAdminCategory(): any {
        return this.adminRoles.includes(this.$root.$store.state.session.permissions.role);
      },
      showMeteringCategory(): any {
        return this.adminRoles.includes(this.$root.$store.state.session.permissions.role) 
          || this.$root.$store.state.session.services.metering;
      },
      filteredAdminItems(): any[] {
        if(this.$root.$store.getters.loggedIn(Date.now()) && this.$root.$store.state.session.permissions && this.adminRoles.includes(this.$root.$store.state.session.permissions.role)) {
          return this.adminItems.filter((obj: any) => {
            if (this.$root.$store.state.session.permissions.resources && obj.permission in this.$root.$store.state.session.permissions.resources) {
              return this.$root.$store.state.session.permissions.resources[obj.permission].includes('GET');
            } else {
              return false;
            }
          });
        } else {
          return [];
        }
      },
      filteredEntityItems(): any[] {
        if(this.$root.$store.getters.loggedIn(Date.now()) && this.$root.$store.state.session.permissions) {
          return this.entityItems.filter((obj: any) => {
            if (this.$root.$store.state.session.permissions.resources && obj.permission in this.$root.$store.state.session.permissions.resources) {
              return this.$root.$store.state.session.permissions.resources[obj.permission].includes('GET');
            } else {
              return false;
            }
          });
        } else {
          return [];
        }
      },
      filteredFunctionItems(): any[] {
        if(this.$root.$store.getters.loggedIn(Date.now()) && this.$root.$store.state.session.permissions) {
          return this.functionItems.filter((obj: any) => {
            if(!obj.permission)
              return true;

            if (this.$root.$store.state.session.permissions.resources && obj.permission in this.$root.$store.state.session.permissions.resources) {
              return true;
            } else {
              return false;
            }
          });
        } else {
          return [];
        }
      },
      userIcon(): string {
        if(this.$root.$store.state.session.permissions.role=='superadmin') {
          return 'mdi-account-cowboy-hat';
        } else if (this.$root.$store.state.session.permissions.role=='admin') {
          return 'mdi-account-hard-hat';
        } else {
          return 'mdi-account';
        }
      }
    }
  })
