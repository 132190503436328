
  import Vue from 'vue'
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';

  import IoTDevicePortService from '@/services/IoTDevicePortService';
  import IoTDeviceMappingService from '@/services/IoTDeviceMappingService';
  import DeviceService from '@/services/DeviceService';
  import IoTDeviceService from '@/services/IoTDeviceService';

  import IotDeviceMappingInfoDialog from '@/components/entities/iotdevicemapping/IotDeviceMappingInfoDialog.vue';
  import IotDeviceMappingDeleteDialog from '@/components/entities/iotdevicemapping/IotDeviceMappingDeleteDialog.vue';
  import IotDeviceMappingEditDialog from '@/components/entities/iotdevicemapping/IotDeviceMappingEditDialog.vue';
  import IotDeviceMappingCreateDialog from '@/components/entities/iotdevicemapping/IotDeviceMappingCreateDialog.vue';
  import DeviceInfoDialog from '@/components/entities/device/DeviceInfoDialog.vue';

  import DateTimePicker from '@/components/misc/DateTimePicker.vue'
import MonthDatePicker from '@/components/misc/MonthDatePicker.vue'
import { RRule, rrulestr } from 'rrule'
import DatePicker from '@/components/misc/DatePicker.vue'

const frequencyInputsTable = {
    monthly: {
      rrule: RRule.MONTHLY,
      until: true,
      interval: true,
      month: false,
      day_of_month: true,
      day_of_the_week: false
    },
    weekly: {
      rrule: RRule.WEEKLY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: true
    },
    daily: {
      rrule: RRule.DAILY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    },
    hourly: {
      rrule: RRule.HOURLY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    },
    minutely: {
      rrule: RRule.MINUTELY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    },
    once: {
      rrule: null,
      until: false,
      interval: false,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    }
  };

  const range = (n: number) => Array.from({ length: n }, (v, k) => k + 1);
  const thirty1 = range(31);
  const thirty = range(30);
  const twenty9 = range(29);

  const daysPerMonth = {
    January: thirty1,
    February: twenty9,
    March: thirty1,
    April: thirty,
    May: thirty1,
    June: thirty,
    July: thirty1,
    August: thirty1,
    September: thirty,
    October: thirty1,
    November: thirty,
    December: thirty1
  };

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  export default Vue.extend({
    name: 'IotDeviceEditDialog',

    props: ['iotdevice', 'iotdeviceport'],
    components: {
        EntityMetaDialog,
        IotDeviceMappingInfoDialog,
        IotDeviceMappingDeleteDialog,
        IotDeviceMappingEditDialog,
        IotDeviceMappingCreateDialog,
        DeviceInfoDialog,
        DateTimePicker,
        MonthDatePicker,
        DatePicker
    },
    data: (vm: any): any => ({
        localdialog: false,
        localIotdeviceport: {} as any,
        valid: true,
        passwordToggle: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingTenant: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        irProtocols: [
          "SML",
          "D0"
        ],
        irProtocolPresets: [
          "SML 9600 8N1",
          "D0 9600 8N1",
          "SML 9600 7E1",
          "D0 300 7E1",
        ],
        selectedIRProtocolPreset: "",
        requiredSelect: [
          (v: any) => !!v && Object.keys(v).length > 0 || vm.requiredError
        ],
        associatedMappings: [] as any[],
        associatedTenant: {} as any,
        // rrule
        frequencyInputsTable,
        daysPerMonth,
        weekdays,
        repetitionUntilSelection: "",
        repetitionFromSelection: "",
        rrule: {
          interval: null,
          start: new Date(Date.now()).toISOString(),
          frequency: null,
          until_num: null,
          month: null,
          daysOfTheMonth: [],
          daysOfTheWeek: [],
          until_date: null
        },
        timespanUnits: [
          { text: vm.$t('timeUnit.months'), value: "MONTHS" },  
          { text: vm.$t('timeUnit.weeks'), value: "WEEKS" }, 
          { text: vm.$t('timeUnit.days'), value: "DAYS" }, 
          { text: vm.$t('timeUnit.hours'), value: "HOURS" }, 
          { text: vm.$t('timeUnit.minutes'), value: "MINUTES" }, 
        ],
        apiIntegrationSlugs: [
          'sauter-svc-get-values',
        ]
    }),
    async created() {
        this.localIotdeviceport = JSON.parse(JSON.stringify(this.iotdeviceport));
    },
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedTenant = {};
        this.associatedMappings = [];
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      onIRProtocolChange() {
        if(this.localIotdeviceport.type === 'ir' && this.selectedIRProtocolPreset === 'SML 9600 8N1') {
          this.localIotdeviceport.config = {};
          this.localIotdeviceport.config.protocol = 'SML';
          this.localIotdeviceport.config.baudrate = 9600;
          this.localIotdeviceport.config.parity = '8N1';
        } else if(this.localIotdeviceport.type === 'ir' && this.selectedIRProtocolPreset === 'D0 9600 8N1') {
          this.localIotdeviceport.config = {};
          this.localIotdeviceport.config.protocol = 'D0';
          this.localIotdeviceport.config.baudrate = 9600;
          this.localIotdeviceport.config.parity = '8N1';
          this.localIotdeviceport.config.pullseq = "2f3f210d0a";
          this.localIotdeviceport.config.ackseq = "063030300d0a";
        } else if(this.localIotdeviceport.type === 'ir' && this.selectedIRProtocolPreset === 'SML 9600 7E1') {
          this.localIotdeviceport.config = {};
          this.localIotdeviceport.config.protocol = 'SML';
          this.localIotdeviceport.config.baudrate = 9600;
          this.localIotdeviceport.config.parity = '7E1';
        } else if(this.localIotdeviceport.type === 'ir' && this.selectedIRProtocolPreset === 'D0 300 7E1') {
          this.localIotdeviceport.config = {};
          this.localIotdeviceport.config.protocol = 'D0';
          this.localIotdeviceport.config.baudrate = 300;
          this.localIotdeviceport.config.parity = '7E1';
          this.localIotdeviceport.config.pullseq = "2f3f210d0a";
          this.localIotdeviceport.config.ackseq = "063030300d0a";
        }
      },
      onApiIntegrationSlugChange() {
        if(this.iotdeviceport.type === 'api' && this.iotdeviceport.config.integrationSlug === 'sauter-svc-get-values') {
          this.iotdeviceport.config.parameters = {};
          this.iotdeviceport.config.parameters.interval = 'Hour';
        }
      },
      onMappingCreation(item: any) {
        this.associatedMappings.push(item);
      },
      onMappingUpdate(item: any) {
        this.associatedMappings.splice(this.associatedMappings.findIndex((value: any) => value.uuid === item.uuid), 1);
        this.associatedMappings.push(item);
      },
      onMappingDelete(item: any) {
        this.associatedMappings.splice(this.associatedMappings.findIndex((value: any) => value.uuid === item.uuid), 1);
      },
      save() {
        this.alertVisible = false;
        this.loading = true;

        let portnumber;
        let config;

        if(this.iotdeviceport.type === 'api') {
          const rrule = this.parsedRRule;
          this.localIotdeviceport.config.rrule = rrule.toString();
        }

        if(this.localIotdeviceport.portnumber !== this.iotdeviceport.portnumber) {
            portnumber = parseInt(this.localIotdeviceport.portnumber, 10);
        }
        if(JSON.stringify(this.localIotdeviceport.config) != JSON.stringify(this.iotdeviceport.config)) {
            config = this.localIotdeviceport.config;
        }

        IoTDevicePortService.update(this.iotdevice.uuid, this.iotdeviceport.uuid, portnumber, config).then((iotdeviceport: any) => {

            this.alertType = "success";
            this.alertText = this.$t('iotdeviceport.successUpdate') as string;
            this.alertVisible = true;

            this.localIotdeviceport = iotdeviceport;
            console.log(iotdeviceport);

            this.$emit("update", iotdeviceport);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('iotdevice.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      async getAssociatedMappings() {
        this.loading = true;

        try {
          const mappings = await IoTDeviceMappingService.getAllCollectionPages(this.iotdevice.uuid, this.iotdeviceport.uuid);
          this.loading = false;

          this.associatedMappings = mappings;
        } catch(err: any) {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('user.tenantError')+ ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
          this.associatedMappings = [];
        }
      },
      aggregationConfig(config: any) {

        let ret = {} as any;

        if('aggregationMode' in config) {
            ret['aggregationMode'] = config['aggregationMode'];
        }
        if('aggregationInterval' in config && 'aggregationFrequency' in config) {
            ret['aggregationTime'] = config['aggregationInterval'] + ' ' + config['aggregationFrequency'];
        }
        if('aggregationFunction' in config) {
            ret['aggregationFunction'] = config['aggregationFunction'];
        }

        return ret;
      },
      async getAssociatedTenant() {
        this.loading = true;

        try {
          const tenant = await IoTDeviceService.getAssociatedTenant(this.iotdevice.uuid);
          this.loading = false;

          this.associatedTenant = tenant;
        } catch(err: any) {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('user.tenantError')+ ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
          this.associatedTenant = {};
        }
      },
      getTargetDeviceLabel(item: any) {
        if(!('targetDevice' in item) && !this.loadingTargetDevices) {
          this.loadTargetDevices();
        }

        return item?.targetDevice?.description;
      },
      async loadTargetDevices() {
        if(!this.loadingTargetDevices && this.associatedMappings.length > 0) {
          this.loadingTargetDevices = true;

          let promises: Promise<any>[] = [];
          this.associatedMappings.forEach(async (dev: any, index: number) => {
            if(!dev.targetDevice)
              promises.push(this.loadTargetDevice(dev, index));
          });
          await Promise.all(promises);
          this.loadingTargetDevices = false;
        }
      }, 
      delay(ms: number) {
            return new Promise(res => setTimeout(res, ms));
        },
      async loadTargetDevice(item: any, index=1) {
        try {
            const device = await DeviceService.getSingle(this.associatedTenant.uuid, item.targetDeviceId);
            this.$set(item, 'targetDevice', device); // important else DOM is not updated
            await this.delay(index*100);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            // Vue.set(item, 'targetDevice', {});
        }
      }, 
      capitalize(value: string) {
        return value.charAt(0).toUpperCase()+value.slice(1)
      },
      parseRRule() {
        let _rrule = rrulestr(this.iotdeviceport.config.rrule);

        this.rrule.start = _rrule.options.dtstart;
        this.repetitionFromSelection = 'from_date';

        if(_rrule.options.freq === RRule.YEARLY) {
          if(_rrule.options.count === 1) {
            // this means its once
            this.rrule.frequency = 'Once';
          } else {
            this.rrule.frequency = 'Yearly';
          }
        } else if(_rrule.options.freq === RRule.MONTHLY) {
          this.rrule.frequency = 'Monthly';
        } else if(_rrule.options.freq === RRule.WEEKLY) {
          this.rrule.frequency = 'Weekly';
        } else if(_rrule.options.freq === RRule.DAILY) {
          this.rrule.frequency = 'Daily';
        } else if(_rrule.options.freq === RRule.HOURLY) {
          this.rrule.frequency = 'Hourly';
        } else if(_rrule.options.freq === RRule.MINUTELY) {
          this.rrule.frequency = 'Minutely';
        } else {
          console.error('Invalid RRule freq', _rrule.options.freq);
          // unsupported
          // TODO
        }

        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].interval && _rrule.options.interval) {
          this.rrule.interval = Number(_rrule.options.interval);
        }
        if(_rrule.options.count && this.rrule.frequency !== 'Once') {
          this.repetitionUntilSelection = 'until_count';
          this.rrule.until_num = Number(_rrule.options.count);
        }
        if(_rrule.options.until) {
          this.repetitionUntilSelection = 'until_date';
          this.rrule.until_date = new Date(_rrule.options.until).toISOString();
        }
        if(!this.rrule.until_num && !this.rrule.until_date) {
          // case its indefinitly
          this.repetitionUntilSelection = 'forever';
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].month && _rrule.options.bymonth) {
          this.rrule.month = _rrule.options.bymonth;
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].day_of_month && _rrule.options.bymonthday) {
          this.rrule.daysOfTheMonth = _rrule.options.bymonthday;
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].day_of_the_week && _rrule.options.byweekday) {
          this.rrule.daysOfTheWeek = _rrule.options.byweekday.map((day: number) => this.weekdays[day]);
        }
      },
      autoComplete,
    },
    watch: {
        iotdeviceport() {
            this.localIotdeviceport = JSON.parse(JSON.stringify(this.iotdeviceport));
        },
        async localdialog(value: boolean) {
            if(value) {
                await this.getAssociatedTenant();
                this.getAssociatedMappings();
                if(this.iotdeviceport.type === 'api') {
                  this.parseRRule();
                }
            } else {
                this.clear(); 
            }
        }
    },
    computed: {
        firstExecutionExample() {
          return this.firstExecutionDate ? this.firstExecutionDate.toLocaleString() : '';
        },
        firstExecutionDate() {
          let start = new Date(Date.now());

          if(this.repetitionFromSelection === 'from_date') {
            start = new Date(this.rrule.start);
          }

          return this.parsedRRule ? new Date(this.parsedRRule.all((date: Date, i: number) => i < 2)[start.getTime() <= Date.now() ? 1 : 0]) : undefined;
        },
        parsedRRule() {
          if(!this.rrule.frequency) {
            return undefined;
          }

          let start = new Date(Date.now());

          // start.setUTCDate(start.getUTCDate()+1);

          if(this.repetitionFromSelection === 'from_date' && new Date(this.rrule.start).getTime() > start.getTime()) {
            start = new Date(this.rrule.start);
          }

          let _rrule = {
            dtstart: start,
          } as any;

          if(this.rrule.frequency && this.rrule.frequency.length > 0) {
            if(this.rrule.frequency.toLowerCase() === 'once') {
              // special case, once is not supported by rrule so we create 
              // a task with yearly occure but only once
              _rrule.freq = RRule.YEARLY;
              _rrule.count = 1;
            } else {
              _rrule.freq = this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].rrule;
            }
          }
          if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].interval && this.rrule.interval) {
            _rrule.interval = Number(this.rrule.interval);
          }
          if(this.repetitionUntilSelection === 'until_count' && this.rrule.until_num) {
            _rrule.count = Number(this.rrule.until_num);
          }
          if(this.repetitionUntilSelection === 'until_date' && this.rrule.until_date) {
            const until = new Date(this.rrule.until_date);
            _rrule.until = new Date(Date.UTC(until.getFullYear(), until.getMonth(), until.getDate()));
          }
          if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].month && this.rrule.month) {
            _rrule.bymonth = this.rrule.month;
          }
          if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].day_of_month && this.rrule.daysOfTheMonth.length > 0) {
            _rrule.bymonthday = this.rrule.daysOfTheMonth;
          }
          if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].day_of_the_week && this.rrule.daysOfTheWeek.length > 0) {
            _rrule.byweekday = this.rrule.daysOfTheWeek.map((value: string) => this.weekdays.findIndex((day: string) => day === value));
          }
          // console.log(_rrule);

          return new RRule(_rrule);
        },
        months(): any { 
          const keys = Object.keys(daysPerMonth);
          return keys.map((value: string) => value.charAt(0).toUpperCase()+value.slice(1))
        },
        frequencies(): any {
          // TODO add i18n translation
          const keys = Object.keys(frequencyInputsTable);
          return keys.map((value: string) => value.charAt(0).toUpperCase()+value.slice(1))
        },
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        },
        intervalHint() {
          const rrule = this.rrule;
          return `1 means every ${
            rrule.frequency === "Daily" ? "Day" : rrule.frequency.slice(0, -2)
          }, 2 is every other ${
            rrule.frequency === "Daily" ? "Days" : rrule.frequency.slice(0, -2)
          }`;
        },
    }
  })
