
  import Vue from 'vue'
  import MobileAnalyticsTable from '@/components/functions/analytics/MobileAnalyticsTable.vue'
  import EntityFiltersCard from '@/components/filters/EntityFiltersCard.vue'
  import InfoCard from '@/components/misc/InfoCard.vue'

import { extractErrorMessage } from '@/utils/Util'
import MobileAnalyticsService from '@/services/MobileAnalyticsService'

  export default Vue.extend({
    name: 'MobileAnalyticsView',

    components: {
      InfoCard,
      MobileAnalyticsTable,
      EntityFiltersCard,
    },
    data: () => ({
      logs: [] as any,
      loading: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      filters: {} as any,
      filtersConfig: [
        { attribute: 'user', always: true },
        { attribute: 'timestamp', always: true }
      ],
      versions: {
        'android': undefined,
        'ios': undefined
      } as any
    }),
    created() {
      this.getLogs();
      this.getVersions();
    },
    watch: {
      currentTenantId() {
        this.dataError = false;
        this.filters = {};
        this.getLogs();
      }
    },
    methods: {
      onTableReload() {
        this.getLogs();
        this.getVersions();
      },
      onFiltersApply() {
        this.getLogs();
      },
      getLogs() {
        this.logs = [];
        this.loading = true;
        this.dataError = false;

        let params = {};

        Object.assign(params, this.filters);
        console.log(params);

        if('user' in this.filters && this.filters.user.uuid) {
          MobileAnalyticsService.getUserLogsCollection(this.currentTenantId, this.filters.user.uuid, params).then((logs: any[]) => {
            this.loading = false;
            this.logs = logs;
          }).catch((err: any) => {
            console.log(err, err.response);  
            this.loading = false;
            this.alertType = "error";
            this.alertText = 'Error loading User Mobile Analytics ' + extractErrorMessage(err);
            this.dataError = true;      
          });
        } else {
          MobileAnalyticsService.getTenantLogsCollection(this.currentTenantId, params).then((logs: any[]) => {
            this.loading = false;
            this.logs = logs;
          }).catch((err: any) => {
            console.log(err, err.response);  
            this.loading = false;
            this.alertType = "error";
            this.alertText = 'Error loading Tenant Mobile Analytics ' + extractErrorMessage(err);
            this.dataError = true;      
          });
        }
      },
      getVersions() {
        // this.dataLoaded = false;

        for(const key in this.versions) {
          MobileAnalyticsService.getLatestVersion(key).then((version: any) => {
            this.versions[key] = version.version;
          }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = extractErrorMessage(err);
            this.dataError = true;      
          });
        }
        // this.dataLoaded = true;
      },
    },
    computed: {
      currentTenantId(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
    }
  })
