import axios from 'axios';

export default {
    getAllVersions(): Promise<any> {
        console.log(process.env.VUE_APP_BASCLOUD_OTA_SERVER_URL + '/')
        return axios.get(process.env.VUE_APP_BASCLOUD_OTA_SERVER_URL + '/');
    },
    getVersion(version: string): Promise<any> {
        console.log(process.env.VUE_APP_BASCLOUD_OTA_SERVER_URL + '/' + version + '/')
        return axios.get(process.env.VUE_APP_BASCLOUD_OTA_SERVER_URL + '/' + version + '/');
    },
}
  