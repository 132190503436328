import axios from 'axios';

export default {
    getTenantLogsCollection(tenantUUID: string, params?: any): Promise<any> {
        let requestURL = process.env.VUE_APP_BASCLOUD_MOBILE_LOGS_SERVER_URL + '/analytics/app/' + tenantUUID + '/logs?';

        //  Filters
        if('from' in params && params.from && params.from.length > 0) {
            requestURL = requestURL + '&from=' + encodeURIComponent(params.from);
        }
        if('until' in params && params.until && params.until.length > 0) {
            requestURL = requestURL + '&until=' + encodeURIComponent(params.until);
        }

        return axios.get(requestURL);
    },
    getUserLogsCollection(tenantUUID: string, userUUID: string, params?: any): Promise<any> {

        let requestURL = process.env.VUE_APP_BASCLOUD_MOBILE_LOGS_SERVER_URL + '/analytics/app/' + tenantUUID + '/' + userUUID + '/logs?';

        //  Filters
        if('from' in params && params.from && params.from.length > 0) {
            requestURL = requestURL + '&from=' + encodeURIComponent(params.from);
        }
        if('until' in params && params.until && params.until.length > 0) {
            requestURL = requestURL + '&until=' + encodeURIComponent(params.until);
        }

        return axios.get(requestURL);
    },
    getLatestVersion(slug: string): Promise<any> {

        let requestURL = process.env.VUE_APP_BASCLOUD_MOBILE_LOGS_SERVER_URL + '/analytics/app/version/' + slug.toLowerCase() + '/latest';

        return axios.get(requestURL);
    },
}
  