
  import Vue from 'vue'
  import PropertyService from '@/services/PropertyService';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import AlertsListDialog from '@/components/entities/alert/AlertsListDialog.vue';

  export default Vue.extend({
    name: 'PropertyInfoDialog',

    components: {
        EntityMetaDialog,
        AlertsListDialog,
        // Weird import necessary due to circular dependency
        DeviceInfoDialog: () => import('@/components/entities/device/DeviceInfoDialog.vue') as any
    },
    props: ['property', 'tenant'],
    data: () => ({
        localdialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        associatedDeviceFilter: "",
        associatedDevices: [] as any[],
        associatedTags: [] as any[],
        associatedAlerts: [] as any[]
    }),
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedDevices = [];
        this.associatedTags = [];
        this.alertVisible = false;
        this.loading = false;
        this.associatedDeviceFilter = "";
      },
      dismiss() {
        this.clear();
        this.close();
      },
      getAssociatedDevices() {
        this.loading = true;

        PropertyService.getAssociatedDevices(this.currentTenant, this.property.uuid).then((devices: any[]) => {
            if(devices.length > 0) {
                this.associatedDevices = [];
                this.associatedDevices = devices;
            } else {
                this.alertType = "warning";
                this.alertText = this.$t('property.emptyAssociatedDevice') as string;
                this.alertVisible = true;
            }
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('property.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getAssociatedTags() {
        this.loading = true;

        PropertyService.getAssociatedTags(this.currentTenant, this.property.uuid).then((tags: any[]) => {
            if(tags.length > 0) {
                this.associatedTags = [];
                this.associatedTags = tags;
            }
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('property.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      openAssociatedDevices() {
          this.$router.push({ name: 'Devices', params: { property: this.property } });
      },
      getAssociatedAlerts() {
        this.loading = true;

        PropertyService.getAssociatedAlerts(this.currentTenant, this.property.uuid).then((alerts: any[]) => {
            this.associatedAlerts = alerts;
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('alert.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      autoComplete,
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                this.getAssociatedDevices();
                this.getAssociatedTags();
                this.getAssociatedAlerts();
            } else {
                this.clear();
            }
        }
    },
    computed: {        
        filteredAssociatedDevices(): any[] {
            return this.associatedDevices.filter((value: any) => this.autoComplete(value, this.associatedDeviceFilter));
        },
        currentTenant(): any {
            if(this.tenant)
                return this.tenant.uuid;

            return this.$root.$store.state.session.selectedTenant.uuid;
        }
    }
  })
