
    import Vue from 'vue'
  
    import DeviceInfoDialog from '@/components/entities/device/DeviceInfoDialog.vue';
    import DeviceMonitoringGraphDialog from './DeviceMonitoringGraphDialog.vue';
    import DeviceService from '@/services/DeviceService';
  
    export default Vue.extend({
      name: 'GroupMonitoringCard',
  
      components: {
        DeviceInfoDialog,
        DeviceMonitoringGraphDialog
      },
      props: ['group'],
      data: () => ({
      }),
      async created() {
      },
      async mounted() {
      },
      methods: {
      },
      watch: {
      },
      computed: {
        nameLabel() : string {
          console.log(this.group.type, this.group?.entity?.name, this.group.key)
          if(this.group.type === 'connectors') {
            return this.group?.entity?.name ?? this.group.key ?? '-';
          } else if(this.group.type === 'properties') {
            return this.group?.entity?.name ?? this.group.key ?? '-';
          } else if(this.group.type === 'tenants') {
            return this.group?.entity?.name ?? this.group.key ?? '-';
          } else {
            return this.group.key ?? '-'
          }
        },
        lastTimestamp(): string {
          if(this.group?.availability?.last_ping === undefined)
            return 'n.a.'

          const date = new Date(this.group?.availability?.last_ping);
          return date.toLocaleString();
        },
        onlineRate(): string {
          if(this.group?.availability?.rate === undefined)
            return 'n.a.';

          const perc = this.group?.availability?.rate * 100;

          return perc.toLocaleString(undefined, { maximumFractionDigits: 0 }) + '%';
        },
        backgroundColor(): string | undefined {
          if(this.group.alertActive === 0) {
            return 'grey lighten-2'
          } else if(this.group.alertActive === 2) {
            return 'red lighten-1';
          } else if(this.group.alertActive === 1) {
            return 'green lighten-1';
          } else if(this.group.alertActive === 3) {
            return 'orange lighten-1';
          } else {
            return undefined;
          }
        },
        monitoringTenant(): string {
          return 'd97ce06e-4630-4015-89b4-1fbb67bbe328';
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        }
    },
  })
