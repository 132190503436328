import { render, staticRenderFns } from "./ReadingCreateDialog.vue?vue&type=template&id=00048e83&scoped=true&"
import script from "./ReadingCreateDialog.vue?vue&type=script&lang=ts&"
export * from "./ReadingCreateDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00048e83",
  null
  
)

export default component.exports