import axios from 'axios';

export default {
    getDeviceLabel(tenantUUID: string, labelUUID: string): Promise<any> {
        return axios.get(process.env.VUE_APP_BASCLOUD_LABELING_SERVER_URL + '/tenants/' + tenantUUID + '/devices/labels/'+ labelUUID, { responseType: 'blob' });
    },
    createDeviceLabel(tenantUUID: string, devices: any[], layout: string): Promise<any> {
    
        const body = {
            data: {
                type: "labels",
                layout: layout,
                attributes: {
                    devices: devices.map((value: any) => {
                        return {
                            "type": "devices",
                            "id": value.uuid,
                            "meta": {
                                "createdAt": value.createdAt,
                                "updatedAt": value.updatedAt,
                                "deletedAt": value.deletedAt
                            },
                            "attributes": {
                                "localAksId": value.localAksId,
                                "aksId": value.aksId,
                                "description": value.description,
                                "unit": value.unit,
                                "type": value.type,
                                "frequency": value.frequency,
                                "interval": value.interval,
                                "aggregation": value.aggregation
                            },
                        };    
                    }),
                },
            }
        } as any;

        return axios.post(process.env.VUE_APP_BASCLOUD_LABELING_SERVER_URL + '/tenants/' + tenantUUID + '/devices/labels', JSON.stringify(body), { headers: { 'Content-Type': 'application/json'}});
    },
}
  