
  import Vue from 'vue'
  import DeviceTypesTable from '@/components/entities/devicetype/DeviceTypesTable.vue'
  import DeviceTypeCreateDialog from '@/components/entities/devicetype/DeviceTypeCreateDialog.vue'
  import EntityFiltersCard from '@/components/filters/EntityFiltersCard.vue'

  import InfoCard from '@/components/misc/InfoCard.vue'

  import DeviceTypeService from '@/services/DeviceTypeService';
import { extractErrorMessage } from '@/utils/Util'

  export default Vue.extend({
    name: 'DeviceTypesView',

    components: {
      DeviceTypesTable,
      InfoCard,
      DeviceTypeCreateDialog,
      EntityFiltersCard
    },
    data: () => ({
      devicetypes: [] as any,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      filters: {} as any,
      filtersConfig: [
        { attribute: 'tenant', always: true },
        { attribute: 'type', always: false },
        { attribute: 'createdAt', always: false },
        { attribute: 'deleted', always: false }
      ]
    }),
    async created() {
      this.updateDeviceTypes();
    },
    watch: {
    },
    methods: {
      onTableReload() {
        this.updateDeviceTypes();
      },
      onFiltersApply() {
        this.updateDeviceTypes();
      },
      onItemRestore(item: any) {
        this.updateDeviceTypes();
      },
      onItemUpdate(item: any) {
        this.devicetypes.splice(this.devicetypes.findIndex((prop: any) => prop.uuid === item.uuid), 1);
        this.devicetypes.push(item);
      },
      onItemDelete(item: any) {
        this.devicetypes.splice(this.devicetypes.findIndex((prop: any) => prop.uuid === item.uuid), 1);
      },
      onItemCreation(property: any) {
        this.devicetypes.push(property);
      },
      updateDeviceTypes() {
        this.devicetypes = [];
        this.dataLoaded = false;
        
        let params = {};
        Object.assign(params, this.filters);

        DeviceTypeService.getAllCollectionPages(params).then((devicetypes: any[]) => {
          this.dataLoaded = true;

          this.devicetypes = devicetypes;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('deviceType.loadingError') + ' ' + extractErrorMessage(err);
          this.dataError = true;      
        });
      }
    },
    computed: {
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'devicetypes' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['devicetypes'].includes('POST');
        } else {
          return false;
        }
      },
      lastCreatedAt(): any {
        let occurrences: any = {};
        let sum = 0;

        let pastDate = new Date();
        pastDate.setDate(pastDate.getDate()-7); 

        this.devicetypes.forEach((userObj: any) => {
          let _date = new Date(userObj.createdAt);
          if(_date >= pastDate) {
            let item = _date.getDate() + '-' + _date.getMonth();
            occurrences[item] = (occurrences[item] || 0) + 1;
            sum = sum + 1;
          }
        }, {});

        return sum;
      }
    }
  })
