import ApiKeyApi from '@/api/ApiKeyApi';
import store from '../store/index';
import router from '../router/index';

export default {
    async getAllCollectionPages(params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const prices: any[] = [];
        const pageResponses = await ApiKeyApi.getAllCollectionPages(params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                prices.push({
                    uuid: userObj.id,
                    name: userObj.attributes.name,
                    expiration: userObj.attributes.expiration,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                });
            });
        });

        return prices;
    },
    async getSingle(priceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await ApiKeyApi.getSingle(priceUUID);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            expiration: response.data.data.attributes.expiration,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async createApiKey(userId: string, name?: string, expiration?: Date, notify?: boolean): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await ApiKeyApi.createApiKey(userId, name, expiration, notify);

        if(response.data.data.type && response.data.data.type == 'apikeys') {

            return {
                uuid: response.data.data.id,
                name: response.data.data.attributes.name,
                token: response.data.data.attributes.token,
                expiration: response.data.data.attributes.expiration,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                };
            } else {
                throw TypeError("Response not type apikeys");
            }
    },
    async updateApiKey(apikeyUUID: string, name = ""): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await ApiKeyApi.updateApiKey(apikeyUUID, name);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            expiration: response.data.data.attributes.expiration,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async deleteApiKey(apikeyUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        await ApiKeyApi.deleteApiKey(apikeyUUID);
    },
    async getAssociatedUser(apikeyUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await ApiKeyApi.getAssociatedUser(apikeyUUID);

        return {
            uuid: response.data.data.id,
            email: response.data.data.attributes.email,
            role: response.data.data.attributes.role,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            lastActiveAt: response.data.data.meta.lastActiveAt,
        };
    },
    async generateToken(apikeyUUID: string, notify?: boolean): Promise<any> {  
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const response = await ApiKeyApi.generateToken(apikeyUUID, notify);

        return { token: response.data.data.attributes.token, expiration: response.data.data.attributes.expiration };
    },
}
  