
  import Vue from 'vue'
  import Login from '@/components/Login.vue'
  import LanguageSwitcher from '@/components/misc/LanguageSwitcher.vue'

  export default Vue.extend({
    name: 'LoginView',

    components: {
      Login,
      LanguageSwitcher
    },
    data: () => ({
    }),
    async created() {
      if(this.$root.$store.getters.loggedIn(Date.now()) 
        && !('token' in this.$route.query && 'email' in this.$route.query)) {
          this.$router.push('/dashboard');
      }
    }
  })
