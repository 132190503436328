
  import Vue from 'vue'
  import PropertyService from '@/services/PropertyService';
import { extractErrorMessage } from '@/utils/Util';
import TagService from '@/services/TagService';
import TagCreateDialog from '../tag/TagCreateDialog.vue';

  export default Vue.extend({
    name: 'PropertyCreateDialog',

    components: {
      TagCreateDialog
    },
    props: [],
    data: (vm: any): any => ({
        dialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        requiredRule: [
          (v: string) => !!v || vm.requiredError
        ],
        postalRule: [
          (v: string) => v.length <= 5 || vm.postalCodeError
        ],
        property: {
          name: "",
          aksId: "",
          identifier: "",
          street: "",
          postalCode: "",
          city: "",
          country: "",
          createdAt: "",
          updatedAt: "",
        },
        associatedTags: [] as any[],
        tags: [] as any[]
    }),
    methods: {
      validate() {
        if(this.createForm.validate()) {
          this.createProperty();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.property = {
                    name: "",
                    aksId: "",
                    identifier: "",
                    street: "",
                    postalCode: "",
                    city: "",
                    country: "",
                    createdAt: "",
                    updatedAt: "",
                  };
          this.alertVisible = false;
          this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async createProperty() {
        this.loading = true;

        let name = this.property.name;
        let aksId = "";
        let identifier = "";
        let street = "";
        let postalCode = "";
        let city = "";
        let country = "";

        if("aksId" in this.property) {
            aksId = this.property.aksId;
        }
        if("identifier" in this.property) {
            identifier = this.property.identifier;
        }
        if("street" in this.property) {
            street = this.property.street;
        }
        if("postalCode" in this.property) {
            postalCode = this.property.postalCode;
        }
        if("city" in this.property) {
            city = this.property.city;
        }
        if("country" in this.property) {
            country = this.property.country;
        }

        try {
          const property = await PropertyService.createProperty(this.$root.$store.state.session.selectedTenant.uuid, name, aksId, identifier, street, postalCode, city, country);
          this.alertType = "success";
          this.alertText = this.$t('property.successCreate') as string;
          this.alertVisible = true;

          if(this.associatedTags.length > 0)
            await PropertyService.addTags(this.$root.$store.state.session.selectedTenant.uuid, property.uuid, this.associatedTags)
          
          this.$emit("success", property);
          setTimeout(() => { 
              this.dismiss(); 
          }, 1500);
        } catch(err: any) {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('property.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
          this.loading = false;
        }
      },
      onRemoveTagClick(tag: any) {
        this.associatedTags.splice(this.associatedTags.findIndex((t: any) => t.uuid === tag.uuid), 1);
      },
      getTags() {
        this.loading = true;

        TagService.getAllCollectionPages(this.$root.$store.state.session.selectedTenant.uuid).then((tags: any[]) => {
          this.tags = tags;
          this.loading = false;
        }).catch((err) => {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('property.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
        });
      },
    },
    watch: {
      dialog(value: boolean) {
        if(value) {
          this.getTags();
        } else {
          this.clear();
        }
      }
    },
    computed: {
        createForm(): any {
            return this.$refs.createForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        postalCodeError(): any {
          return this.$t('postalCodeLength');
        }
    }
  })
