
  import Vue from 'vue'

  import DeviceInfoDialog from '@/components/entities/device/DeviceInfoDialog.vue';
  import DeviceEditDialog from '@/components/entities/device/DeviceEditDialog.vue';
  import DeviceDeleteDialog from '@/components/entities/device/DeviceDeleteDialog.vue';
  import ReadingCreateDialog from '@/components/entities/reading/ReadingCreateDialog.vue';
  import SetpointCreateDialog from '@/components/entities/setpoint/SetpointCreateDialog.vue';
  import DeviceRestoreDialog from '@/components/entities/device/DeviceRestoreDialog.vue';
  import DevicePrintLabelsDialog from '@/components/entities/device/DevicePrintLabelsDialog.vue';

  import { objectsToCSV, filterItem, RRuleToText } from '@/utils/Util';
  import DeviceService from '@/services/DeviceService';
import MeteringService from '@/services/MeteringService';

  export default Vue.extend({
    name: 'DevicesTable',

    components: {
      DeviceInfoDialog,
      DeviceDeleteDialog,
      DeviceEditDialog,
      ReadingCreateDialog,
      SetpointCreateDialog,
      DeviceRestoreDialog,
      DevicePrintLabelsDialog
    },
    props: ['devices', 'loading'],
    data: (vm: any): any => ({
      tableLoadingText: vm.loadingText,
      search: "",
      showUUIDs: false,
      showAddMenu: false,
      numberOfEntities: 0,
      selectedHeaders: [
          { text: vm.$t('attribute.aksId'), value: 'aksId', index: 200 },
          { text: vm.$t('attribute.description'), value: 'description', index: 400 },
          { text: vm.$t('attribute.unit'), value: 'unit', width:'96px', index: 500 },
          { text: vm.$t('attribute.type'), value: 'type', index: 600 },
          { text: vm.$t('attribute.updatedAt'), value: 'updatedAt', index: 1000 },
          { text: vm.$t('attribute.createdAt'), value: 'createdAt', index: 1100 },
      ],
      currentItems: [] as any[],
      loadingProperties: false,
      loadingConnectors: false,
      loadingTasks: false,
    }),
    async created() {
      if(this.devices.length === 0) {
        this.tableLoadingText = this.$t('device.noData');
      }

      const columns = this.$root.$store.getters.selectedColumns('device');
      if(columns) {
        this.selectedHeaders = columns;
      }
    },
    watch: {
      selectedHeaders() {
        this.$store.commit("setSelectedColumns", { type: 'device', columns: this.selectedHeaders });
      }
    },
    methods: {
      filterItem,
      onPrintLabels() {
        //
      },
      onExport() {
          let filteredItems = this.devices;
          if(this.$refs.dataTable.$children[0].filteredItems) {
            filteredItems = this.$refs.dataTable.$children[0].filteredItems;
          }

          // Data structure
          const data = filteredItems.map((dev: any, index: number) => Object({
            uuid: dev.uuid,
            aksId: dev.aksId,
            localAksId: dev.localAksId,
            description: dev.description,
            unit: dev.unit,
            type: dev.type,
            frequency: dev.frequency,
            interval: dev.interval,
            aggregation: dev.aggregation,
            createdAt: dev.createdAt,
            updatedAt: dev.updatedAt,
            deletedAt: dev.deletedAt,
          }));

          const filename = 'bascloud_devices.csv';
          const csvContent = objectsToCSV(data);
          var blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

          if ((navigator as any).msSaveBlob) { // IE 10+
              (navigator as any).msSaveBlob(blob, filename);
          } else {
              var link = document.createElement("a");
              if (link.download !== undefined) { // feature detection
                  // Browsers that support HTML5 download attribute
                  var url = URL.createObjectURL(blob);
                  link.setAttribute("href", url);
                  link.setAttribute("download", filename);
                  link.style.visibility = 'hidden';
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
              }
          }
      },
      onCurrentItemsChange(items: any[]) {
        this.currentItems = items;
      },
      onReload() {
        this.$emit("reload");
      },
      onSetpointCreation(item: any) {
        // s
      },
      onReadingCreation(item: any) {
        // s
      },
      onDeviceItemRestore(item: any) {
        this.$emit("restore", item);
      },
      onDeviceItemUpdate(item: any) {
        this.$emit("update", item);
      },
      onDeviceItemDelete(item: any) {
        this.$emit("delete", item);
      },
      onTableRowClick(row: any) {
        const button: any = this.$refs["deviceInfoButton_" + row.uuid];
        // $el needed for native elements
        button.$el.click();
      },
      onPaginationAndFilter(pagination: any) {
        this.numberOfEntities = pagination.itemsLength;
      },
      onAddReadingClick(item: any) {
        console.log(item);
      },
      getPropertyNameLabel(item: any) {
        if(!('property' in item) && !this.loadingProperties) {
          this.loadCurrentProperties();
        }

        return item?.property?.name;
      },
      async loadCurrentProperties() {
        if(!this.loadingProperties && this.currentItems.length > 0) {
          this.loadingProperties = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (dev: any) => {
            if(!dev.property)
              promises.push(this.loadProperty(dev));
          });
          await Promise.all(promises);
          this.loadingProperties = false;
        }
      }, 
      async loadProperty(item: any) {
        try {
            const property = await DeviceService.getAssociatedProperty(this.currentTenant, item.uuid);
            this.$set(item, 'property', property); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(item, 'property', {});
        }
      },      
      getConnectorNameLabel(item: any) {
        if(!('connector' in item) && !this.loadingConnectors) {
          this.loadCurrentConnectors(item);
        }

        return item?.connector?.name;
      },
      async loadCurrentConnectors() {
        if(!this.loadingConnectors && this.currentItems.length > 0) {
          this.loadingConnectors = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (dev: any) => {
            if(!dev.connector)
              promises.push(this.loadConnector(dev));
          });
          await Promise.all(promises);
          
          this.loadingConnectors = false;
        }
      }, 
      async loadConnector(item: any) {
        try {
            const connector = await DeviceService.getAssociatedConnector(this.currentTenant, item.uuid);
            Vue.set(item, 'connector', connector); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(item, 'connector', {});
        }
      },
      getTaskLabel(item: any) {
        if(!('task' in item) && !this.loadingTasks) {
          this.loadDeviceTasks();
        }

        if(item?.task?.rrule) {
          const text = RRuleToText(item?.task.rrule, this.$root.$store.state.config.language);
          return text.charAt(0).toUpperCase()+text.slice(1);
        } else {
          return '-';
        }
      },
      async loadDeviceTasks() {
        if(!this.loadingTasks && this.currentItems.length > 0) {
          this.loadingTasks = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (dev: any) => {
            if(!dev.task)
              promises.push(this.loadTask(dev));
          });
          await Promise.all(promises);
          this.loadingTasks = false;
        }
      }, 
      async loadTask(item: any) {
        try {
            const task = await MeteringService.getDeviceTask(this.currentTenant, item.uuid);
            this.$set(item, 'task', task); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(item, 'task', {});
        }
      }, 
    },
    computed: {
      filteredItems() {
        return this.$refs?.dataTable?.$children[0]?.filteredItems || this.devices;
      },
      headers() {
        return [
          { text: this.$t('attribute.uuid'), value: 'uuid', index: 0 },
          { text: this.$t('entity.property') + ' ' + this.$t('attribute.name'), value: 'property.name', index: 100 },
          { text: this.$t('entity.connector') + ' ' + this.$t('attribute.name'), value: 'connector.name', index: 101 },
          { text: this.$t('attribute.aksId'), value: 'aksId', index: 200 },
          { text: this.$t('attribute.localAksId'), value: 'localAksId', index: 300 },
          { text: this.$t('attribute.description'), value: 'description', index: 400 },
          { text: this.$t('attribute.unit'), value: 'unit', width:'96px', index: 500 },
          { text: this.$t('attribute.type'), value: 'type', index: 600 },
          { text: this.$t('attribute.frequency'), value: 'frequency', index: 700 },
          { text: this.$t('attribute.interval'), value: 'interval', index: 800 },
          { text: this.$t('attribute.aggregationMethod'), value: 'aggregation', index: 900 },
          { text: this.$t('entity.task') + ' ' + this.$t('attribute.description'), value: 'task.text', index: 901 },
          { text: this.$t('attribute.updatedAt'), value: 'updatedAt', index: 1000 },
          { text: this.$t('attribute.createdAt'), value: 'createdAt', index: 1100 },
        ];
      },
      loadingText(): any {
        return this.$t('loadingData');
      },
      computedTableHeader(): any {
        let headers = [...this.selectedHeaders,
          { text: this.$t('attribute.actions'), value: 'actions', sortable: false, filterable: false, index: 9998 },
        ]
        
        if(this.canCreateReading || this.canCreateSetpoint) {
          headers.push(
          { text: '', value: 'readingActions', sortable: false, filterable: false, index: 9999 })
        }
        
        return headers.sort((a: any, b: any) => a.index-b.index);
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      canUpdate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'devices' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['devices'].includes('PATCH');
        } else {
          return false;
        }
      },
      canCreateReading(): any {
        if (this.$root.$store.state.session.permissions.resources && 'readings' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['readings'].includes('POST');
        } else {
          return false;
        }
      },
      canCreateSetpoint(): any {
        if (this.$root.$store.state.session.permissions.resources && 'setpoints' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['setpoints'].includes('POST');
        } else {
          return false;
        }
      },
      canDelete(): any {
        if (this.$root.$store.state.session.permissions.resources && 'devices' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['devices'].includes('DELETE');
        } else {
          return false;
        }
      },
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'devices' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['devices'].includes('POST');
        } else {
          return false;
        }
      },
    },
  })
