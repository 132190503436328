
  import Vue from 'vue'

  import IotDeviceInfoDialog from '@/components/entities/iotdevice/IotDeviceInfoDialog.vue';
  import IotDeviceMonitoringGraphDialog from './IotDeviceMonitoringGraphDialog.vue';
  import DeviceService from '@/services/DeviceService';
  import PropertyService from '@/services/PropertyService';
  import ConnectorService from '@/services/ConnectorService';

  export default Vue.extend({
    name: 'IotDeviceMonitoringCard',

    components: {
      IotDeviceInfoDialog,
      IotDeviceMonitoringGraphDialog
    },
    props: ['iotdevice', 'timeRangeSelection'],
    data: () => ({
      loadingProperties: false,
      loadingConnectors: false,
    }),
    async created() {

    },
    methods: {
      delay(ms: number) {
          return new Promise(res => setTimeout(res, ms));
      },
      onRefresh() {
        this.$emit('onRefresh');
      },
      onShowReadings() {
        this.$router.push({ name: 'Readings', params: { device: this.iotdevice?.availability?.deviceId } });
      },
      getTenantNameLabel() {
        if(!('tenant' in this.iotdevice)) {
          this.$emit('onLoadTenant', this.iotdevice);
        }
        return this.iotdevice?.tenant?.name ?? '-';
      },
      getPropertyNameLabel() {
        if(!('property' in this.iotdevice)) {
          this.$emit('onLoadProperty', this.iotdevice);
        }
        return this.iotdevice?.property?.name ?? '-';
      },      
      getConnectorNameLabel() {
        if(!('connector' in this.iotdevice)) {
          this.$emit('onLoadConnector', this.iotdevice);
        }
        return this.iotdevice?.connector?.name ?? '-';
      },
    },
    watch: {
    },
    computed: {
      lastTimestamp(): string {
        if(this.iotdevice?.availability?.last_ping === undefined)
          return 'n.a.'

        const date = new Date(this.iotdevice?.availability?.last_ping);
        return date.toLocaleString();
      },
      onlineRate(): string {
        if(this.iotdevice?.availability?.rate === undefined)
          return 'n.a.';

        const perc = this.iotdevice?.availability?.rate * 100;

        return perc.toLocaleString(undefined, { maximumFractionDigits: 0 }) + '%';
      },
      backgroundColor(): string | undefined {
        if(this.iotdevice.alertActive === 0) {
          return 'grey lighten-2'
        } else if(this.iotdevice.alertActive === 2) {
          return 'red lighten-1';
        } else if(this.iotdevice.alertActive === 1) {
          return 'green lighten-1';
        } else {
          return undefined;
        }
      },
      monitoringTenant(): string {
        return 'd97ce06e-4630-4015-89b4-1fbb67bbe328';
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      }
    },
  })
