
  import Vue from 'vue'

  export default Vue.extend({
    name: "LanguageSwitcher",
    props: [],
    data: (vm: any): any => ({
        languages: [
            {
            id: 'en',
            name: 'english',
            flagClass: 'fi fi-gb'
            },
            {
            id: 'de',
            name: 'german',
            flagClass: 'fi fi-de'
            }
        ],
        currentLanguage: 'en'
    }),
    created() {
        this.currentLanguage = this.$root.$store.state.config.language;
        this.$i18n.locale = this.$root.$store.state.config.language;
    },
    computed: {
        currentLanguageIcon() {
            return this.languages.filter((x: any) => x.id === this.currentLanguage)[0].flagClass;
        }
    },
    methods: {
        translatedLanguageTitle(title: string) {
            return this.$t('locales.'+title);
        }
    },
    watch: {
        currentLanguage() {
            this.$i18n.locale = this.currentLanguage;
            this.$root.$store.commit("setLanguage", this.currentLanguage);
        }
    }
  })
