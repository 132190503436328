
  import Vue from 'vue'
import { extractErrorMessage } from '@/utils/Util';
import DeviceShareService from '@/services/DeviceShareService';

  export default Vue.extend({
    name: 'DeviceShareSyncDialog',

    props: ['deviceshare'],
    data: () => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
    }),
    methods: {
      close() {
        this.localDialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      confirmSync() {
        this.loading = true;
      
        DeviceShareService.syncDeviceShare(this.deviceshare.uuid).then(() => {
            this.alertType = "success";
            this.alertText = this.$t('deviceShare.successSync') as string;
            this.alertVisible = true;
            this.loading = false;

            setTimeout(() => {
                this.dismiss(); 
            }, 2000);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('deviceShare.errorSync') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      }
    },
    computed: {
        isSource(): boolean {
            return this.deviceshare.sourceTenantId === this.$root.$store.state.session.selectedTenant.uuid;
        },
        isTarget(): boolean {
            return this.deviceshare.targetTenantId === this.$root.$store.state.session.selectedTenant.uuid;
        },
        isRoot(): any {
            return this.$root.$store.state.session.permissions && 'role' in this.$root.$store.state.session.permissions
                && this.$root.$store.state.session.permissions.role === 'superadmin';
        },
    }
  })
