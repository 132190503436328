
    import Vue from 'vue'

    // import { EspLoader } from 'esptool.ts'
    import { ESPLoader, Transport } from 'esptool-js'

    import FlashEraseConfirmDialog from './FlashEraseConfirmDialog.vue';
    import OTAService from '@/services/OTAService';
    import axios from 'axios';


    type Partition = {
        name: string;
        data: Uint8Array;
        address: number;
    };

    export default Vue.extend({
      name: 'BCCConnect',
  
      props: ['dataLoading', 'versions'],
      components: {
        FlashEraseConfirmDialog
      },
      data: (vm: any): any => ({
        requiredSelect: [
          (v: any) => !!v && Object.keys(v).length > 0 || vm.requiredError
        ],
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        loading: false,
        unsupported: false,
        alertVisible: false,
        alertText: "",
        alertType: "error",
        connected: false,
        monitoring: false,
        firmwareBaudrate: 115200,
        flashBaudrate: 460800,
        flashSNBaudrate: 9600,
        baudrates: [
            9600, 115200, 460800, 921600
        ],
        flashing: false,
        flashProgress: 0,
        flashSN: false,
        eraseFlash: false,
        serialnumber: "",
        terminalBuffer: "",
        usbProductId: "",
        usbVendorId: "",
        deviceChipName: "",
        deviceMACAddress: "",
        serialPort: undefined as SerialPort | undefined,
        serialReader: undefined as ReadableStreamDefaultReader | undefined,
        serialWriter: undefined as WritableStreamDefaultWriter | undefined,
        espLoader: undefined as any,
        transport: undefined as any,
        snFlashVersion: "snflasher",
        selectedVersion: undefined,
        firmwareData: {}
      }),
      created() {
        if (!("serial" in navigator)) {
            this.alertVisible = true;
            this.alertText = "This browser does not support Web Serial API.";
            this.unsupported = true;
        }
      },
      mounted() {
        //s
      },
      watch: {
        versions() {
            if(this.versions && !this.selectedVersion){
                this.selectedVersion = this.versions[this.versions.length -1];
            }
        }
      },
      methods: {
        clean() {
            this.onTerminalClear();
        },
        writeLine(data: any) {
            this.log(data);
        },
        write(data: any) {
            this.log(data);
        },
        debug(message: string) {
            const autoScroll = (this.terminalArea.scrollHeight - this.terminalArea.clientHeight - this.terminalArea.scrollTop) <= 1
            
            this.terminalBuffer += "DEBUG: " + message + "\n";

            Vue.nextTick(() => {
                if(autoScroll) {
                    this.terminalArea.scrollTop = this.terminalArea.scrollHeight
                    this.terminalArea.focus();
                }
            });
        },
        error(message: string) {
            const autoScroll = (this.terminalArea.scrollHeight - this.terminalArea.clientHeight - this.terminalArea.scrollTop) <= 1
            
            this.terminalBuffer += "ERROR: " + message + "\n";

            Vue.nextTick(() => {
                if(autoScroll) {
                    this.terminalArea.scrollTop = this.terminalArea.scrollHeight
                    this.terminalArea.focus();
                }
            });
        },
        log(message: string) {
            const autoScroll = (this.terminalArea.scrollHeight - this.terminalArea.clientHeight - this.terminalArea.scrollTop) <= 1
            
            this.terminalBuffer += message + "\n";

            Vue.nextTick(() => {
                if(autoScroll) {
                    this.terminalArea.scrollTop = this.terminalArea.scrollHeight
                    this.terminalArea.focus();
                }
            });
        },
        onTerminalClear() {
            this.terminalBuffer = "";
        },
        async onCopyTerminalClipboard() {
            try {
                await navigator.clipboard.writeText(this.terminalBuffer);
            } catch(error: any) {
                this.alertText = error.message;
                this.alertVisible = true;
            }
        },
        delay(ms: number) {
            return new Promise(res => setTimeout(res, ms));
        },
        bufferToString(buffer: ArrayBuffer) {
            const bytes = new Uint8Array(buffer)
            return bytes.reduce((string, byte) => (string + String.fromCharCode(byte)), "")
        },
        async downloadFirmwareFiles(version: string) {
            this.firmwareData[version] = {};
            this.log("Downloading Firmware files: " + version);

            const versionFiles = await OTAService.getVersion(version);
            console.log(versionFiles)

            for(const versionFile of versionFiles.files) {
                try {
                    console.log(versionFile.url)
                    this.log(versionFile.url)
                    const response = await axios.get(versionFile.url, {responseType: 'arraybuffer'});
                    console.log(response);
                    this.firmwareData[version][versionFile.name] = this.bufferToString(response.data);
                    this.log("\tFinished.")
                } catch (err) {
                    console.log(err);
                    this.log("\tError downloading firmware file.");
                    this.log(err);
                    throw err;
                }
            }
            this.log("Finished downloading Firmware files.");
        },
        async flashEraseDevice() {
            if (!this.eraseFlash) {
                return;
            }
            this.log("Starting erase process...");

            try {
                this.log("Connecting...");

                this.transport = new Transport(this.serialPort);
                this.espLoader = new ESPLoader(this.transport, this.flashBaudrate, this, this.firmwareBaudrate);
                await this.espLoader.main_fn();

                // waiting some time to settle, otherwise timeout occurs
                await this.delay(1500);
                
                this.log("Erasing device flash...");

                await this.espLoader.erase_flash();

                this.log("Successfully erased device flash.");
            } catch(error: any) {
                console.log(error);
                this.error(error.message);
            } finally {
                await this.transport.disconnect();
                await this.transport.waitForUnlock(1500);

                this.transport = null;
                this.espLoader = null;
            }
        },
        async flashSerialNumber() {
            this.log("Flashing Serialnumber... S/N: " + this.serialnumber);

            const partitions: Partition[] = [
                {
                    name: "bootloader.bin",
                    data: this.firmwareData[this.snFlashVersion]["bootloader.bin"],
                    address: 0x1000,
                },
                {
                    name: "partitions.bin",
                    data: this.firmwareData[this.snFlashVersion]["partitions.bin"],
                    address: 0x8000,
                },
                {
                    name: "boot_app0.bin",
                    data: this.firmwareData[this.snFlashVersion]["boot_app0.bin"],
                    address: 0xe000,
                },
                {
                    name: "firmware.bin",
                    data: this.firmwareData[this.snFlashVersion]["firmware.bin"],
                    address: 0x10000,
                }
            ];

            try {
                this.log("Connecting...");

                this.transport = new Transport(this.serialPort);
                this.espLoader = new ESPLoader(this.transport, this.flashBaudrate, this, this.firmwareBaudrate);
                await this.espLoader.main_fn();

                // waiting some time to settle, otherwise timeout occurs
                await this.delay(1500);

                this.log("Flashing device partitions...");

                await this.espLoader.write_flash(
                    partitions,
                    'keep',
                    undefined,
                    undefined,
                    false,
                    true,
                    (fileIndex: number, written: number, total: number) => {
                        this.flashProgress = (written / total) * 100;
                    },
                    undefined,
                );

                this.log("Successfully written device partitions.");
                this.log("Flashing succeeded.");
            } catch(error: any) {
                console.log(error);
                this.error(error.message);
            } finally {
                await this.transport.setDTR(false);
                await new Promise((resolve) => setTimeout(resolve, 100));
                await this.transport.setDTR(true);

                await this.transport.disconnect();
                await this.transport.waitForUnlock(1500);

                this.transport = null;
                this.espLoader = null;
            }

            try {
                this.log("Writing serialnumber...");
                // write serialnumber through serial input
                await this.serialPort.open({ baudRate: this.flashSNBaudrate });
                
                this.flashSerialMonitor();
                await this.delay(500);

                this.serialWriter = this.serialPort.writable.getWriter();
                await this.serialWriter.write(new TextEncoder().encode(this.serialnumber+'\n'));
                await this.delay(1000);

                this.serialReader.cancel();
                this.log("Successfully written serialnumber.");
            } catch(error: any) {
                console.log(error);
                this.error(error.message);
            } finally {
                if(this.serialPort.writable.locked)
                    this.serialWriter.releaseLock();

                if(this.serialPort.readable.locked)
                    this.serialReader.releaseLock();

                await this.serialPort.close();
            }
        },
        async flashSerialMonitor() {
            
            this.serialReader = this.serialPort.readable.getReader();
            try {
                while (true) {
                    const { value, done } = await this.serialReader.read();
                    if (done) {
                        break;
                    }
                    this.log(new TextDecoder().decode(value));
                }
            } catch (error) {
                console.log(error);
            } finally {
                //s
            }
        },
        async flashFirmware() {
            this.log("Flashing Firmware...\nVersion: " + this.selectedVersion.name);

            const partitions: Partition[] = [
                {
                    name: "bootloader.bin",
                    data: this.firmwareData[this.selectedVersion.name]["bootloader.bin"],
                    address: 0x1000,
                },
                {
                    name: "partitions.bin",
                    data: this.firmwareData[this.selectedVersion.name]["partitions.bin"],
                    address: 0x8000,
                },
                {
                    name: "boot_app0.bin",
                    data: this.firmwareData[this.selectedVersion.name]["boot_app0.bin"],
                    address: 0xe000,
                },
                {
                    name: "firmware.bin",
                    data: this.firmwareData[this.selectedVersion.name]["firmware.bin"],
                    address: 0x10000,
                },
                {
                    name: "spiffs.bin",
                    data: this.firmwareData[this.selectedVersion.name]["spiffs.bin"],
                    address: 0x3D0000,
                }
            ];

            try {
                this.log("Connecting..."); 

                this.transport = new Transport(this.serialPort);
                this.espLoader = new ESPLoader(this.transport, this.flashBaudrate, this, this.firmwareBaudrate);
                await this.espLoader.main_fn();

                // waiting some time to settle, otherwise timeout occurs
                await this.delay(1500);

                await this.espLoader.write_flash(
                    partitions,
                    'keep',
                    undefined,
                    undefined,
                    false,
                    true,
                    (fileIndex: number, written: number, total: number) => {
                        this.flashProgress = (written / total) * 100;
                    },
                    undefined,
                );

                this.log("Successfully written device partitions.");
                this.log("Flashing succeeded.");
                this.log("Resetting the BCC...");
            } catch(error: any) {
                console.log(error);
                this.error(error.message);
            } finally {

                await this.transport.setDTR(false);
                await new Promise((resolve) => setTimeout(resolve, 100));
                await this.transport.setDTR(true);

                await this.transport.disconnect();
                await this.transport.waitForUnlock(1500);

                this.transport = null;
                this.espLoader = null;

                this.onMonitorClick();
            }
        },
        async onFlashClick() {
            this.onTerminalClear();

            this.log("Starting Flash Process.\nVersion: " + this.selectedVersion.name);

            this.flashing = true;
            this.loading = true;

            try {
                if(this.eraseFlash) {
                    await this.flashEraseDevice();
                }

                if(this.flashSN) {
                    await this.downloadFirmwareFiles(this.snFlashVersion);
                    await this.flashSerialNumber();
                }

                await this.downloadFirmwareFiles(this.selectedVersion.name);
                await this.flashFirmware();
            } catch(error: any) {
                console.log(error);
            } finally {
                this.flashing = false;
                this.loading = false;
            }
        },
        async onConnectClick() {
            this.alertVisible = false;

            try {
                this.serialPort = await (navigator as any).serial.requestPort({ filters: [{ usbVendorId: 6790 }, { usbVendorId: 1027 }] }).catch((error: any) => {});
                if(!this.serialPort)
                    return;

                const usbInfo = await this.serialPort.getInfo();
                this.usbProductId = usbInfo?.usbProductId;
                this.usbVendorId = usbInfo?.usbVendorId;

                this.loading = true;

                this.transport = new Transport(this.serialPort);
                this.espLoader = new ESPLoader(this.transport, this.firmwareBaudrate, this, this.firmwareBaudrate);
                const chip = await this.espLoader.main_fn();

                this.deviceChipName = chip;

                this.loading = false;
                await this.transport.disconnect();
                await this.transport.waitForUnlock(1500);

                this.transport = null;
                this.espLoader = null;

                this.connected = true;
            } catch(error: any) {
                console.log(error);
                this.alertVisible = true;
                this.alertText = error.message;
                this.loading = false;
            }
        },
        async onDisconnectClick() {

            if(this.monitoring)
                this.onMonitorClick();

            if(this.transport)
                this.transport.disconnect().catch((error: any) => {console.log(error);});

            if(this.serialPort)
                this.serialPort.close().catch((error: any) => {console.log(error);});

            this.transport = null;
            this.espLoader = null;

            this.onTerminalClear();

            this.deviceChipName = "";
            this.usbProductId = "";
            this.usbVendorId = "";
            this.connected = false;
            this.monitoring = false;
        },
        onMonitorClick() {
            this.monitoring = !this.monitoring;
            if(this.monitoring) {
                this.onTerminalClear();
                this.startMonitoring();
            } else {
                this.stopMonitoring();
            }
        },
        async startMonitoring() {

            if(!this.serialPort.readable)
                await this.serialPort.open({ baudRate: this.firmwareBaudrate });

            this.serialReader = this.serialPort.readable.getReader();
            try {
                while (this.monitoring) {
                    const { value, done } = await this.serialReader.read();
                    if (done) {
                        break;
                    }
                    this.log(new TextDecoder().decode(value));
                }
            } catch (error) {
                console.log(error);
            } finally {
                if(this.serialPort.readable.locked)
                    this.serialReader.releaseLock();

                await this.serialPort.close();
                console.log('closed serial port after monitor')
            }
        },
        async stopMonitoring() {
            this.serialReader.cancel();
        }
      },
      computed:{
        terminalArea(): HTMLElement {
            return document.getElementById('terminalArea') as HTMLElement;
        },
        requiredError(): any {
            return this.$t('required');
        },
      }
    })
  