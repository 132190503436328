
  import Vue from 'vue'
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
    import ApiKeyService from '@/services/ApiKeyService';

  export default Vue.extend({
    name: 'ApiKeyEditDialog',

    props: ['apikey'],
    components: {
        EntityMetaDialog,
    },
    data: (vm: any): any => ({
        localdialog: false,
        localApikey: {} as any,
        showAddDeviceField: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
    }),
    async created() {
        this.localApikey = Object.assign({}, this.apikey);
    },
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      save() {
        this.alertVisible = false;
        this.loading = true;

        let name = "";

        if(this.localApikey.name !== this.apikey.name) {
            name = this.localApikey.name;
        }

        ApiKeyService.updateApiKey(this.apikey.uuid, name).then((apikey: any) => {

            this.alertType = "success";
            this.alertText = this.$t('apikey.successUpdate') as string;
            this.alertVisible = true;

            apikey.user = this.apikey.user;

            this.localConnector = apikey;

            this.$emit("update", apikey);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('apikey.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      autoComplete,
    },
    watch: {
        apikey() {
            this.localApikey = Object.assign({}, this.apikey);
        },
        async localdialog(value: boolean) {
            if(!value) {
                this.clear();
            }
        }
    },
    computed: {
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        }
    }
  })
