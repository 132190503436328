
import Vue from 'vue'
import AlertCreateDialog from '@/components/entities/alert/AlertCreateDialog.vue';
import AlertDeleteDialog from '@/components/entities/alert/AlertDeleteDialog.vue';
import AlertEditDialog from '@/components/entities/alert/AlertEditDialog.vue';

import AlertService from '@/services/AlertService';

  export default Vue.extend({
    name: 'AlertsListDialog',

    components: {
        AlertCreateDialog,
        AlertDeleteDialog,
        AlertEditDialog
    },
    props: {
        entity: Object,
        entityType: String,
        alerts: {
            type: Array,
            default() {
                return [] as any[];
            }
        },
        edit: Boolean
    },
    data: () => ({
    }),
    methods: {
        async onGlobalEnable() {
            // this.$emit("enable");
            for(let i=0; i<this.alerts.length;i++) {
                const item = this.alerts[i];
                console.log(item.uuid)
                const alert = await AlertService.updateAlert(item.uuid, undefined, undefined, false);
                this.alerts[i] = alert;
                Vue.set(this.alerts, i, alert)
            }
        },
        async onGlobalDisable() {
            // this.$emit("disable");
            for(let i=0; i<this.alerts.length;i++) {
                const item = this.alerts[i];
                console.log(item.uuid)
                const alert = await AlertService.updateAlert(item.uuid, undefined, undefined, true);
                Vue.set(this.alerts, i, alert)
            }
        },
        async onAlertEnable(item: any) {
            const alert = await AlertService.updateAlert(item.uuid, undefined, undefined, false);
            this.$emit("update:alert", alert);
        },
        async onAlertDisable(item: any) {
            const alert = await AlertService.updateAlert(item.uuid, undefined, undefined, true);
            this.$emit("update:alert", alert);
        },
        onAlertCreate(item: any) {
            this.$emit("create:alert", item);
        },
        onAlertDelete(item: any) {
            this.$emit("delete:alert", item);
        },
        onAlertUpdate(item: any) {
            this.$emit("update:alert", item);
        }
    },
    computed: {
        sortedAlerts(): any[] {
            return this.alerts.sort((a: any, b: any) => a.maxAge-b.maxAge);
        },
        allPaused(): boolean {
            return this.alerts.every((value: any) => value.paused);
        },
        monitoringLabel(): any {
            if(this.alerts.length > 0) {
                if(this.alerts.every((value: any) => value.paused)) {
                    return this.$t('paused');
                } else if(this.alerts.some((value: any) => value.active)) {
                    return this.$t('on');
                } else {
                    return this.$t('on');
                }
            } else {
                return this.$t('off');
            }
        },
        monitoringLabelColor(): string | undefined {
            if(this.alerts.length > 0) {
                if(this.alerts.every((value: any) => value.paused)) {
                    return 'disabled';
                } else if(this.alerts.some((value: any) => value.active)) {
                    return 'success';
                } else {
                    return 'success';
                }
            } else {
                return 'warning';
            }
        },
        monitoringIcon(): string {
            if(this.alerts.length > 0) {
                if(this.alerts.every((value: any) => value.paused)) {
                    return 'mdi-bell-sleep-outline';
                } else if(this.alerts.some((value: any) => value.active)) {
                    return 'mdi-bell-ring';
                } else {
                    return 'mdi-bell';
                }
            } else {
                return 'mdi-bell-off-outline';
            }
        },
        monitoringIconColor(): string {
            if(this.alerts.length > 0) {
                if(this.alerts.every((value: any) => value.paused)) {
                    return 'disabled';
                } else if(this.alerts.some((value: any) => value.active)) {
                    return 'error';
                } else {
                    return 'success';
                }
            } else {
                return 'accent';
            }
        }
    }
  })
