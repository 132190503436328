

export const ReadingFlagTypes = [
    { name: "Reset", value: "reset" },
    { name: "Swap", value: "swap" },
    { name: "Bad", value: "bad" },
    { name: "Updated", value: "updated" },
];

export const ReadingAggregationFrequencies = [
    { name: "Minutely", value: "minutely" },
    { name: "Hourly", value: "hourly" },
    { name: "Daily", value: "daily" },
    { name: "Weekly", value: "weekly" },
    { name: "Monthly", value: "monthly" },
    { name: "Yearly", value: "yearly" },
];

export const ReadingAggregationFunctions = [
    { name: "Last", value: "last" },
    { name: "First", value: "first" },
    { name: "Min", value: "min" },
    { name: "Max", value: "max" },
    { name: "Avg", value: "avg" },
    { name: "Median", value: "median" },
    { name: "Sum", value: "sum" },
    { name: "Diff", value: "diff" },
    { name: "Consumption", value: "consumption" },
];
