import axiosLimiter from '@/api/RateLimiter';

export default {
    getCollection(params: any = {}): Promise<any> {
        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/deviceshares?';

        //  Filters
        if('state' in params && params.state && params.state.length > 0) {
            requestURL = requestURL + '&state=' + encodeURIComponent(params.state);
        }
        if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
            requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
        }
        if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
            requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
        }

        // Paging
        if('itemsPerPage' in params && params.itemsPerPage) {
            requestURL = requestURL + '&page[size]=' + encodeURIComponent(params.itemsPerPage);
        }
        if('nextPageLink' in params && params.nextPageLink && params.nextPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.nextPageLink;
        }
        if('prevPageLink' in params && params.prevPageLink && params.prevPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.prevPageLink;
        }

        return axiosLimiter.get(requestURL);
    },    
    async getAllCollectionPages(params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/deviceshares?';

            //  Filters
            if('state' in params && params.state && params.state.length > 0) {
                requestURL = requestURL + '&state=' + encodeURIComponent(params.state);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(shareUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/deviceshares/' + shareUUID);
    },
    createDeviceShare(sourceTenantId: string, sourceDeviceId: string, targetTenantId: string, cascadeUpdates=false, cascadeDeletes=false, from?: Date, until?: Date): Promise<any> {
        const body = {
            "data": {
                "type": "deviceshares",
                "attributes": {
                    "sourceTenantId": sourceTenantId,
                    "sourceDeviceId": sourceDeviceId,
                    "targetTenantId": targetTenantId
                }
            }
        } as any;

        if(cascadeUpdates) {
            body.data.attributes.cascadeUpdates = cascadeUpdates;
        }
        if(cascadeDeletes) {
            body.data.attributes.cascadeDeletes = cascadeDeletes;
        }
        if(from) {
            body.data.attributes.from = from.toISOString();
        }
        if(until) {
            body.data.attributes.until = until.toISOString();
        }
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/deviceshares', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateDeviceShare(shareUUID: string, state?: string, sourceDeviceId?: string, targetDeviceId?: string, cascadeUpdates?: boolean, cascadeDeletes?: boolean, from?: Date, until?: Date) : Promise<any> {
        
        const body = {
            data: {
                type: "deviceshares",
                id: shareUUID,
                attributes: {
                }
            }
        } as any;

        if(state && state.length > 0) {
            body.data.attributes.state = state;
        }
        if(sourceDeviceId && sourceDeviceId.length > 0) {
            body.data.attributes.sourceDeviceId = sourceDeviceId;
        } 
        if(targetDeviceId && targetDeviceId.length > 0) {
            body.data.attributes.targetDeviceId = targetDeviceId;
        } 
        if(cascadeUpdates === false || cascadeUpdates === true) {
            body.data.attributes.cascadeUpdates = cascadeUpdates;
        }         
        if(cascadeDeletes === false || cascadeDeletes === true) {
            body.data.attributes.cascadeDeletes = cascadeDeletes;
        } 
        if(from) {
            body.data.attributes.from = from.toISOString();
        }
        if(until) {
            body.data.attributes.until = until.toISOString();
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/deviceshares/' + shareUUID , JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    deleteDeviceShare(shareUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/deviceshares/' + shareUUID);
    },
    syncDeviceShare(shareUUID: string): Promise<any> {
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/deviceshares/' + shareUUID + '/sync', '', { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    getSourceTenant(shareUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/deviceshares/' + shareUUID + '/sourcetenant');
    },
    getTargetTenant(shareUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/deviceshares/' + shareUUID + '/targettenant');
    },
    getSourceDevice(shareUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/deviceshares/' + shareUUID + '/sourcedevice');
    },
    getTargetDevice(shareUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/deviceshares/' + shareUUID + '/targetdevice');
    },
}
  