import AlertApi from '@/api/AlertApi';
import store from '../store/index';
import router from '../router/index';

const timeUnits = [
    {name: 'minutes', value: 60 },
    {name: 'hours', value: 3600 }, 
    {name: 'days', value: 86400 },
    {name: 'weeks', value: 604800 },
    {name: 'months', value: 2629800 },
    {name: 'years', value: 31557600 }
];

export default {
    async getAllCollectionPages(params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const alerts: any[] = [];
        const pageResponses = await AlertApi.getAllCollectionPages(params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                let timeUnit;
                let frequency;
                for(const unit of [...timeUnits].reverse()) {
                    if(userObj.attributes.maxAge % unit.value === 0) {
                        timeUnit = unit.name;
                        frequency =  userObj.attributes.maxAge / unit.value;
                        break;
                    }
                }
                alerts.push({
                    uuid: userObj.id,
                    recipients: userObj.attributes.recipients,
                    maxAge: userObj.attributes.maxAge,
                    paused: userObj.attributes.paused,
                    active: userObj.attributes.active,
                    entityType: userObj.attributes.entityType,
                    entityId: userObj.attributes.entityId,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                    timeUnit: timeUnit,
                    frequency: frequency
                });
            });
        });

        return alerts;
    },
    async getSingle(priceUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await AlertApi.getSingle(priceUUID);
        let timeUnit;
        let frequency;
        for(const unit of [...timeUnits].reverse()) {
            if(response.data.data.attributes.maxAge % unit.value === 0) {
                timeUnit = unit.name;
                frequency =  response.data.data.attributes.maxAge / unit.value;
                break;
            }
        }
        return {
            uuid: response.data.data.id,
            recipients: response.data.data.attributes.recipients,
            maxAge: response.data.data.attributes.maxAge,
            paused: response.data.data.attributes.paused,
            active: response.data.data.attributes.active,
            entityType: response.data.data.attributes.entityType,
            entityId: response.data.data.attributes.entityId,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            timeUnit: timeUnit,
            frequency: frequency
        };
    },
    async createAlert(entityId: string, entityType: string, recipients: string[], maxAge: number, paused?: boolean, tenantId?: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await AlertApi.createAlert(entityId, entityType, recipients, maxAge, paused, tenantId);

        if(response.data.data.type && response.data.data.type == 'alerts') {
            let timeUnit;
            let frequency;
            for(const unit of [...timeUnits].reverse()) {
                if(response.data.data.attributes.maxAge % unit.value === 0) {
                    timeUnit = unit.name;
                    frequency =  response.data.data.attributes.maxAge / unit.value;
                    break;
                }
            }
            return {
                uuid: response.data.data.id,
                recipients: response.data.data.attributes.recipients,
                maxAge: response.data.data.attributes.maxAge,
                paused: response.data.data.attributes.paused,
                active: response.data.data.attributes.active,
                entityType: response.data.data.attributes.entityType,
                entityId: response.data.data.attributes.entityId,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                timeUnit: timeUnit,
                frequency: frequency
                };
            } else {
                throw TypeError("Response not type alerts");
            }
    },
    async updateAlert(alertUUID: string, recipients?: string[], maxAge?: number, paused?: boolean): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await AlertApi.updateAlert(alertUUID, recipients, maxAge, paused);
        let timeUnit;
        let frequency;
        for(const unit of [...timeUnits].reverse()) {
            if(response.data.data.attributes.maxAge % unit.value === 0) {
                timeUnit = unit.name;
                frequency =  response.data.data.attributes.maxAge / unit.value;
                break;
            }
        }
        return {
            uuid: response.data.data.id,
            recipients: response.data.data.attributes.recipients,
            maxAge: response.data.data.attributes.maxAge,
            paused: response.data.data.attributes.paused,
            active: response.data.data.attributes.active,
            entityType: response.data.data.attributes.entityType,
            entityId: response.data.data.attributes.entityId,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            timeUnit: timeUnit,
            frequency: frequency
        };
    },
    async deleteAlert(alertUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        await AlertApi.deleteAlert(alertUUID);
    },
}
  