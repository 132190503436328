
  import Vue from 'vue'
  import UsersTable from '@/components/entities/user/UsersTable.vue'
  import UserCreateDialog from '@/components/entities/user/UserCreateDialog.vue'
  import EntityFiltersCard from '@/components/filters/EntityFiltersCard.vue'

  import InfoCard from '@/components/misc/InfoCard.vue'

  import UserService from '@/services/UserService';
import { extractErrorMessage } from '@/utils/Util'

  export default Vue.extend({
    name: 'UsersView',

    components: {
      UsersTable,
      InfoCard,
      EntityFiltersCard,
      UserCreateDialog
    },
    data: () => ({
      users: [] as any,
      dataLoaded: false,
      dataError: false,
      alertText: "",
      alertType: "error",
      filters: {} as any,
      filtersConfig: [
        { attribute: 'email', always: true },
        { attribute: 'tenant', always: false },
        { attribute: 'role', always: false },
        { attribute: 'createdAt', always: false }
      ]
    }),
    created() {
      this.updateUsers();
    },
    methods: {
      onTableReload() {
        this.updateUsers();
      },
      onUserFiltersApply() {
        this.updateUsers();
      },
      onUserCreation(user: any) {
        this.users.push(user);
      },
      onUserItemEdit(item: any) {
        // ML: Directly editing the item in the users list does not refresh the table
        this.users.splice(this.users.findIndex((user: any) => user.uuid === item.uuid), 1);
        this.users.push(item);
      },
      onUserItemDelete(item: any) {
        // On delete of a user item, remove it from the table list
        this.users.splice(this.users.findIndex((user: any) => user.uuid === item.uuid), 1);
      },
      updateUsers() {
        this.users = [];
        this.dataLoaded = false;

        let params = {};

        Object.assign(params, this.filters);

        UserService.getAllCollectionPages(params).then((users: any[]) => {
          this.dataLoaded = true;

          this.users = users;
        }).catch((err: any) => {
          console.log(err, err.response);  
          this.alertType = "error";
          this.alertText = this.$t('user.loadingError') + ' ' + extractErrorMessage(err);
          this.dataError = true;      
        });
      },
    },
    computed: {
      canCreate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'users' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['users'].includes('POST');
        } else {
          return false;
        }
      },
      lastCreatedAt(): any {
        let occurrences: any = {};
        let sum = 0;

        let pastDate = new Date();
        pastDate.setDate(pastDate.getDate()-7); 

        this.users.forEach((userObj: any) => {
          let _date = new Date(userObj.createdAt);
          if(_date >= pastDate) {
            let item = _date.getDate() + '-' + _date.getMonth();
            occurrences[item] = (occurrences[item] || 0) + 1;
            sum = sum + 1;
          }
        }, {});

        return sum;
      }
    }
  })
