
  import Vue from 'vue'
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import PriceService from '@/services/PriceService';

  export default Vue.extend({
    name: 'PriceCreateDialog',

    components: {
    },
    props: [],
    data: (vm: any): any => ({
        dialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        requiredRule: [
          (v: string) => !!v || vm.requiredError
        ],
        price: {
          type: "",
          subtype: "",
          basePrice: null,
          perUsePrice: null,
          discountRange: [],
        },
        createForAllTenants: false,
        showAddDiscountField: false,
        tmpDiscount: {
          start: null,
          end: null,
          discount: null,
        },
        associatedTenant: null,
    }),
    methods: {
      onAddDiscountClick() {
          if(!this.editForm.validate()) {
            return;
          }
          if(!this.price.discountRange) {
            this.price['discountRange'] = [];
          }
          let tmp = { 
            start: parseInt(this.tmpDiscount.start),
            end: this.tmpDiscount.end ? parseInt(this.tmpDiscount.end) : null,
            discount: parseFloat(this.tmpDiscount.discount),
          };
          this.price.discountRange.push(tmp);
          this.price.discountRange.sort((a: any, b: any) => {
              return a.start - b.start;
          });
      },
      onShowDiscountAdd() {
          this.showAddDiscountField = !this.showAddDiscountField;

          this.tmpDiscount = {
            start: null,
            end: null,
            discount: null,
          };
      },
      onDiscountRemoveClick(index: number, item: any) {
          this.price?.discountRange.splice(index, 1);
      },
      validate() {
        if(this.createForm.validate()) {
          this.create();
        }
      },
      close() {
        this.dialog = false;
      },
      clear() {
        this.price = {
          type: "",
          subtype: "",
          basePrice: null,
          perUsePrice: null,
          discountRange: [],
        };
        this.tmpDiscount = {
          start: null,
          end: null,
          discount: null,
        };
        this.createForAllTenants = false;
        this.showAddDiscountField = false;
        this.associatedTenant = null;
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async create() {
        this.loading = true;

        let type = this.price.type;
        let subtype = "";
        let basePrice = parseFloat(this.price.basePrice);
        let perUsePrice = -1;
        let tenantIds = [undefined];
        let discountRange = undefined;

        if(this.price.subtype) {
            subtype = this.price.subtype;
        }
        if(this.price.perUsePrice) {
            perUsePrice = parseFloat(this.price.perUsePrice);
        }
        if(this.price.discountRange && this.price.discountRange.length > 0) {
            discountRange = this.price.discountRange;
        }
        if(this.associatedTenant) {
            tenantIds = [this.associatedTenant.uuid];
        } else if(this.createForAllTenants) {
            tenantIds = tenantIds.concat(this.$root.$store.state.session.tenants.map((value: any) => value.uuid));
            console.log(tenantIds)
        }

        for(const tenantId of tenantIds) {
          try {
              const price = await PriceService.createPrice(type, subtype, basePrice, perUsePrice, discountRange, tenantId);

              this.alertType = "success";
              this.alertText = this.$t('price.successCreate') as string;
              this.alertVisible = true;
              
              this.$emit("success", price);
          } catch(err: any) {
              console.log(err);
              console.log(err.response);
              this.alertType = "error";
              this.alertText = this.$t('price.errorCreate') + ' ' + extractErrorMessage(err);
              this.alertVisible = true; 
              this.loading = false;
          }
        }
        setTimeout(() => { 
            this.dismiss(); 
        }, 1500);
      },
      toCurrencyString(price: number) {
          return price ? price.toLocaleString(undefined, { style: 'currency', currency: 'EUR' }) : null;
      },
      discountPrice(discount: number) {
        return this.price.basePrice ? this.price.basePrice * (discount > 1 ? discount : (1-discount)) : null;
      },
      autoComplete,
    },
    watch: {
      dialog(value: boolean) {
        if(!value) {
          this.clear();
        }
      }
    },
    computed: {
        createForm(): any {
            return this.$refs.createForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        whitespaceError(): any {
            return this.$t('whitespace');
        },
    }
  })
