import '@mdi/font/css/materialdesignicons.min.css'
import 'flag-icons/css/flag-icons.min.css'

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'


Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
      dark: false,
      options: {
        customProperties: true,
      },
      themes: {
        light: {
          primary: '#005686',
          secondary: '#3188e5',
          accent: colors.orange,
          error: colors.red.accent3,
          background: colors.grey.lighten2
        },
        dark: {
          primary: '#363636',
          secondary: '#3188e5',
          accent: colors.orange.darken3,
          error: colors.red.darken3,
          background: colors.grey.darken3
        }
      },
    },
});
