
  import Vue from 'vue'

  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import TenantService from '@/services/TenantService';
  import DeviceTypeService from '@/services/DeviceTypeService';

  export default Vue.extend({
    name: 'DeviceTypeEditDialog',

    props: ['devicetype'],
    components: {
        EntityMetaDialog,
    },
    data: (vm: any): any => ({
        localdialog: false,
        localDevicetype: {} as any,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingTenant: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
            (v: any) => !!v || vm.requiredError
        ],
        associatedTenant: {} as any,
        retentionDays: null,
        updatedAssociatedTenant: {} as any,
        timeUnits: [
            {name: vm.$t('timeUnit.always'), frequency: 'ALWAYS' },
            {name: vm.$t('timeUnit.hours'), frequency: 'HOURLY'}, 
            {name: vm.$t('timeUnit.days'), frequency: 'DAILY'},
            {name: vm.$t('timeUnit.weeks'), frequency: 'WEEKLY'},
            {name: vm.$t('timeUnit.months'), frequency: 'MONTHLY'},
            {name: vm.$t('timeUnit.years'), frequency: 'YEARLY'}
        ],
    }),
    async created() {
        this.localDevicetype = Object.assign({}, this.devicetype);
        if(this.localDevicetype.retention)
            this.retentionDays = this.localDevicetype.retention / 86400000;
    },
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedTenant = {};
        this.updatedAssociatedTenant = {};
        this.alertVisible = false;
        this.loading = false;
        this.loadingTenant = false;
        this.retentionDays = null;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      save() {
        this.alertVisible = false;
        this.loading = true;

        let type = "";
        let description = "";
        let dataLimit = -1 as any;
        let dataLimitFrequency = "";
        let retention = -1 as any;
        let tenantId = "" as any;
        let retentionMS = this.retentionDays ? parseInt(this.retentionDays) * 86400000 : null;

        if(this.localDevicetype.type !== this.devicetype.type) {
            type = this.localDevicetype.type;
        }
        if(this.localDevicetype.description !== this.devicetype.description) {
            description = this.localDevicetype.description;
        }
        if(this.localDevicetype.dataLimit && this.localDevicetype.dataLimit !== this.devicetype.dataLimit) {
            dataLimit = parseInt(this.localDevicetype.dataLimit);
        } else if(!this.localDevicetype.dataLimit) {
            dataLimit = null;
        }
        if(dataLimit && this.localDevicetype.dataLimitFrequency !== this.devicetype.dataLimitFrequency) {
            dataLimitFrequency = this.localDevicetype.dataLimitFrequency;
        }
        if(retentionMS && retentionMS !== this.devicetype.retention) {
            retention = retentionMS;
        } else if(!retentionMS) {
            retention = null;
        }

        if(this.updatedAssociatedTenant && ((!this.associatedTenant && this.updatedAssociatedTenant) || this.updatedAssociatedTenant.uuid !== this.associatedTenant.uuid)) {
            tenantId = this.updatedAssociatedTenant.uuid;
        } else if(this.associatedTenant && !this.updatedAssociatedTenant) {
            tenantId = null;
        }

        DeviceTypeService.updateDeviceType(this.devicetype.uuid, type, description, retention, dataLimit, tenantId, dataLimitFrequency).then((devicetype: any) => {

            this.alertType = "success";
            this.alertText = this.$t('deviceType.successUpdate') as string;
            this.alertVisible = true;

            this.localDevicetype = devicetype;
            this.$emit("update", devicetype);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('deviceType.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      async loadAssociatedTenant() {
        this.loadingTenant = true;

        try {
            const tenant = await TenantService.getSingle(this.devicetype.tenantId)
            this.loadingTenant = false;

            this.associatedTenant = tenant;

            this.updatedAssociatedTenant = Object.assign({}, this.associatedTenant);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('deviceType.errorAssociatedTenant') + err;
            this.alertVisible = true; 
            this.loadingTenant = false;
            this.associatedTenant = {};
        }
      },
      autoComplete,
    },
    watch: {
        devicetype() {
            this.localDevicetype = Object.assign({}, this.devicetype);
            if(this.localDevicetype.retention)
                this.retentionDays = this.localDevicetype.retention / 86400000;
        },
        async localdialog(value: boolean) {
            if(value) {
                if(this.devicetype.tenantId) {
                    this.loadAssociatedTenant();
                }
            } else {
                this.clear();
            }
        }
    },
    computed: {
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
    }
  })
