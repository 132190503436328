
  import Vue from 'vue'
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
    import { extractErrorMessage } from '@/utils/Util';
    import ApiKeyService from '@/services/ApiKeyService';

  export default Vue.extend({
    name: 'ApiKeyInfoDialog',

    components: {
        EntityMetaDialog,
        // Weird import necessary due to circular dependency
        UserInfoDialog: () => import('@/components/entities/user/UserInfoDialog.vue') as any
    },
    props: ['apikey'],
    data: () => ({
        localdialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        associatedUser: {},
    }),
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedUser = {};
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      getAssociatedUser() {
        this.loading = true;

        ApiKeyService.getAssociatedUser(this.apikey.uuid).then((user: any) => {
            this.loading = false;
            this.associatedUser = user;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('connector.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                this.getAssociatedUser();
            } else {
                this.clear();
            }
        }
    },
    computed: {
    }
  })
