import axiosLimiter from '@/api/RateLimiter';

export default {
    getCollection(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/'+ iotdeviceUUID + '/ports');
    },
    async getAllCollectionPages(iotdeviceUUID: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/'+ iotdeviceUUID + '/ports?';

            //  Filters
            if('type' in params && params.type && params.type.length > 0) {
                requestURL = requestURL + '&type=' + encodeURIComponent(params.type);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(iotdeviceUUID: string, iotdevicePortUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/'+ iotdeviceUUID + '/ports/' + iotdevicePortUUID);
    },
    create(iotdeviceUUID: string, type: string, portnumber: number, config: any): Promise<any> {
        const body = {
            "data": {
                "type": "iotdeviceports",
                "attributes": {
                    "portnumber": portnumber,
                    "type": type,
                    "config": config
                }
            }
        } as any;

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/'+ iotdeviceUUID + '/ports', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    update(iotdeviceUUID: string, iotdevicePortUUID: string, portnumber?: number, config?: any): Promise<any> {
    
        const body = {
            data: {
                type: "iotdeviceports",
                id: iotdevicePortUUID,
                attributes: {
                }
            }
        } as any;

        if(portnumber !== undefined) {
            body.data.attributes.portnumber = portnumber;
        }
        if(config) {
            body.data.attributes.config = config;
        }
        // console.log(body);
        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/'+ iotdeviceUUID + '/ports/' + iotdevicePortUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    delete(iotdeviceUUID: string, iotdevicePortUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/'+ iotdeviceUUID + '/ports/' + iotdevicePortUUID);
    }
}
  