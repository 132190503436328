
  import Vue from 'vue'

  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import TenantService from '@/services/TenantService';
  import PriceService from '@/services/PriceService';

  export default Vue.extend({
    name: 'PriceEditDialog',

    props: ['price'],
    components: {
        EntityMetaDialog,
    },
    data: (vm: any): any => ({
        localdialog: false,
        localPrice: {} as any,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingTenant: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        showAddDiscountField: false,
        tmpDiscount: {
          start: null,
          end: null,
          discount: null,
        },
        associatedTenant: null,
        updatedAssociatedTenant: null
    }),
    methods: {
      onAddDiscountClick() {
          if(!this.editForm.validate()) {
            return;
          }
          if(!this.localPrice.discountRange) {
            this.localPrice['discountRange'] = [];
          }
          let tmp = { 
            start: parseInt(this.tmpDiscount.start),
            end: this.tmpDiscount.end ? parseInt(this.tmpDiscount.end) : null,
            discount: parseFloat(this.tmpDiscount.discount),
          };
          this.localPrice.discountRange.push(tmp);
          this.localPrice.discountRange.sort((a: any, b: any) => {
              return a.start - b.start;
          });
      },
      onShowDiscountAdd() {
          this.showAddDiscountField = !this.showAddDiscountField;

          this.tmpDiscount = {
            start: null,
            end: null,
            discount: null,
          };
      },
      onDiscountRemoveClick(index: number, item: any) {
          this.localPrice?.discountRange.splice(index, 1);
      },
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedTenant = null;
        this.updatedAssociatedTenant = null;
        this.alertVisible = false;
        this.loading = false;
        this.loadingTenant = false;
        this.retentionDays = 180;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      async save() {

        this.alertVisible = false;
        this.loading = true;

        let type = this.localPrice.type;
        let subtype = "";
        let basePrice = parseFloat(this.localPrice.basePrice);
        let perUsePrice = -1;
        let tenantId = "";
        let discountRange = undefined;

        if(this.localPrice.subtype) {
            subtype = this.localPrice.subtype;
        }
        if(this.localPrice.perUsePrice) {
            perUsePrice = parseFloat(this.localPrice.perUsePrice);
        }
        if(this.localPrice.discountRange) {
            discountRange = this.localPrice.discountRange;
        }
        if(this.updatedAssociatedTenant) {
            tenantId = this.updatedAssociatedTenant.uuid;
        }

        try {
            await PriceService.deletePrice(this.price.uuid);

            const newPrice = await PriceService.createPrice(type, subtype, basePrice, perUsePrice, discountRange, tenantId);

            this.alertType = "success";
            this.alertText = this.$t('price.successCreate') as string;
            this.alertVisible = true;
            
            this.$emit("delete", this.price);
            this.$emit("update", newPrice);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('price.errorCreate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        }
      },
      async loadAssociatedTenant() {
        this.loadingTenant = true;

        try {
            const tenant = await TenantService.getSingle(this.price.tenantId)
            this.loadingTenant = false;

            this.associatedTenant = tenant;

            this.updatedAssociatedTenant = Object.assign({}, this.associatedTenant);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('price.errorAssociatedTenant') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingTenant = false;
            this.associatedTenant = {};
        }
      },
      toCurrencyString(price: number) {
          return price ? price.toLocaleString(undefined, { style: 'currency', currency: 'EUR' }) : null;
      },
      discountPrice(discount: number) {
        return this.localPrice.basePrice ? this.localPrice.basePrice * (discount >= 1 ? discount : (1-discount)) : null;
      },
      autoComplete,
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                if(this.price && this.price.tenantId) {
                    this.loadAssociatedTenant();
                }
                this.localPrice = JSON.parse(JSON.stringify(this.price));
            } else {
                this.clear();
            }
        }
    },
    computed: {
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
    }
  })
