
  import Vue from 'vue'
  import ApiKeyService from '@/services/ApiKeyService';

  import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'ApiKeyDeleteDialog',

    props: ['apikey'],
    data: () => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
    }),
    methods: {
      close() {
        this.localDialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      confirmDelete() {
        this.loading = true;
      
        ApiKeyService.deleteApiKey(this.apikey.uuid).then(() => {
            this.alertType = "success";
            this.alertText = this.$t('apikey.successDelete') as string;
            this.alertVisible = true;
            this.loading = false;

            setTimeout(() => { 
                this.$emit("delete", this.apikey);
                this.dismiss(); 
            }, 2000);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('apikey.errorDelete') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      }
    }
  })
