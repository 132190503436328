import ReadingApi from '@/api/ReadingApi';
import store from '../store/index';
import router from '../router/index';

export default {
    async getLastReading(tenantUUID: string, device: any): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const params = {
            device: device,
            itemsPerPage: 1,
        }

        const pageResponse = await ReadingApi.getCollectionPage(tenantUUID, params);

        if(pageResponse.data.data.length > 0) {
            const readObj = pageResponse.data.data[0];

            return {
                uuid: readObj.id,
                timestamp: readObj.attributes.timestamp,
                value: readObj.attributes.value,
                flag: readObj.attributes.flag,
                createdAt: readObj.meta.createdAt,
                updatedAt: readObj.meta.updatedAt,
                deletedAt: readObj.meta.deletedAt,
            };
        } else {
            return undefined;
        }
    },
    async getTotalReadingCount(tenantUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const params = {
            itemsPerPage: 1,
        }

        const pageResponse = await ReadingApi.getCollectionPage(tenantUUID, params);

        return pageResponse.data.meta.page.count;
    },
    async getCollectionPage(tenantUUID: string, params: any): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const pageResponse = await ReadingApi.getCollectionPage(tenantUUID, params);

        return pageResponse;
    },
    async getAllCollectionPages(tenantUUID: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const readings: any[] = [];
        const pageResponses = await ReadingApi.getAllCollectionPages(tenantUUID, params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                readings.push({
                    uuid: userObj.id,
                    timestamp: userObj.attributes.timestamp,
                    value: userObj.attributes.value,
                    flag: userObj.attributes.flag,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                    deletedAt: userObj.meta.deletedAt,
                });
            });
        });
        
        return readings;
    },
    async getSingle(tenantUUID: string, readingUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const pageResponse = await ReadingApi.getSingle(tenantUUID, readingUUID);

        return {
            uuid: pageResponse.data.data.id,
            timestamp: pageResponse.data.data.attributes.timestamp,
            value: pageResponse.data.data.attributes.value,
            flag: pageResponse.data.data.attributes.flag,
            createdAt: pageResponse.data.data.meta.createdAt,
            updatedAt: pageResponse.data.data.meta.updatedAt,
            deletedAt: pageResponse.data.data.meta.deletedAt,
        };
    },
    async createReading(tenantUUID: string, value: number, timestamp: string, deviceUUID: string, flag?: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await ReadingApi.createReading(tenantUUID, value, timestamp, deviceUUID, flag);

        if(response.data.data.type && response.data.data.type == 'readings') {

            return {
                uuid: response.data.data.id,
                timestamp: response.data.data.attributes.timestamp,
                value: response.data.data.attributes.value,
                flag: response.data.data.attributes.flag,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                deletedAt: response.data.data.meta.deletedAt,
            }
        } else {
            throw TypeError("Response not type readings");
        }
    },
    async updateReading(tenantUUID: string, readingUUID: string, value = "", deviceUUID = "", flag=""): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await ReadingApi.updateReading(tenantUUID, readingUUID, value, deviceUUID, flag);

        return {
            uuid: response.data.data.id,
            timestamp: response.data.data.attributes.timestamp,
            value: response.data.data.attributes.value,
            flag: response.data.data.attributes.flag,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            deletedAt: response.data.data.meta.deletedAt,
        }
    },
    async deleteReading(tenantUUID: string, readingUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await ReadingApi.deleteReading(tenantUUID, readingUUID);
    },
    async restoreReading(tenantUUID: string, readingUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await ReadingApi.restoreReading(tenantUUID, readingUUID);
    },
    async getAssociatedDevice(tenantUUID: string, readingUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await ReadingApi.getAssociatedDevice(tenantUUID, readingUUID);

        return {
            uuid: response.data.data.id,
            aksId: (response.data.data.attributes.aksId || ''),
            localAksId: (response.data.data.attributes.localAksId || ''),
            description: response.data.data.attributes.description,
            unit: response.data.data.attributes.unit,
            type: response.data.data.attributes.type,
            alerting: response.data.data.attributes.alerting,
            isRelative: response.data.data.attributes.isRelative,
            frequency: response.data.data.attributes.frequency,
            interval: response.data.data.attributes.interval,
            aggregation: response.data.data.attributes.aggregation,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            deletedAt: response.data.data.meta.deletedAt,
        };
    },
    async getAllAggregationPages(tenantUUID: string, deviceUUID: string, interval: string, frequency: string, aggFunction: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const aggregates: any[] = [];
        const pageResponses = await ReadingApi.getAllAggregationPages(tenantUUID, deviceUUID, interval, frequency, aggFunction, params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((aggObj: any) => {
                aggregates.push({
                    deviceId: deviceUUID,
                    timestamp: aggObj.attributes.bucketStart,
                    value: aggObj.attributes.value !== null ? aggObj.attributes.value : NaN,
                    createdAt: aggObj.meta.createdAt,
                    bucketStart: aggObj.attributes.bucketStart,
                    bucketEnd: aggObj.attributes.bucketEnd,
                });
            });
        });
        return aggregates;
    },
    async getAllGroupAggregationPages(tenantUUID: string, filters: any[], interval: number, frequency: string, aggFunction: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const aggregates: any[] = [];
        const pageResponses = await ReadingApi.getAllGroupAggregationPages(tenantUUID, filters, interval, frequency, aggFunction, params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((aggObj: any) => {
                aggregates.push({
                    timestamp: aggObj.attributes.bucketStart,
                    value: aggObj.attributes.value !== null ? aggObj.attributes.value : NaN,
                    createdAt: aggObj.meta.createdAt,
                    bucketStart: aggObj.attributes.bucketStart,
                    bucketEnd: aggObj.attributes.bucketEnd,
                });
            });
        });
        return aggregates;
    },
    async getAggregationPage(tenantUUID: string, deviceUUID: string, interval: string, frequency: string, aggFunction: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const pageResponse = await ReadingApi.getAggregationPage(tenantUUID, deviceUUID, interval, frequency, aggFunction, params);

        return pageResponse;
    },
}
  