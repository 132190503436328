import { render, staticRenderFns } from "./SetpointCreateDialog.vue?vue&type=template&id=28112834&scoped=true&"
import script from "./SetpointCreateDialog.vue?vue&type=script&lang=ts&"
export * from "./SetpointCreateDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28112834",
  null
  
)

export default component.exports