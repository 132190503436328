import DeviceTypeApi from '@/api/DeviceTypeApi';
import store from '../store/index'
import router from '../router/index'

export default {
    async getCount(params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        params['itemsPerPage'] = 1;

        const response = await DeviceTypeApi.getCollection(params);

        return response?.data?.meta?.page?.count;
    },    
    async getAllCollectionPages(params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const deviceTypes: any[] = [];
        const pageResponses = await DeviceTypeApi.getAllCollectionPages(params);

        pageResponses.forEach((response: any) => {
                response.data.data.forEach((userObj: any) => {
                    deviceTypes.push({
                        uuid: userObj.id,
                        type: userObj.attributes.type,
                        description: userObj.attributes.description,
                        dataLimit: parseInt(userObj.attributes.dataLimit),
                        dataLimitFrequency: userObj.attributes.dataLimitFrequency,
                        retention: parseInt(userObj.attributes.retention),
                        tenantId: userObj.attributes.tenantId,
                        createdAt: userObj.meta.createdAt,
                        updatedAt: userObj.meta.updatedAt,
                        deletedAt: userObj.meta.deletedAt,
                });
            });
        });

        return deviceTypes;
    },
    async getSingle(typeUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceTypeApi.getSingle(typeUUID);
  
        return {
            uuid: response.data.data.id,
            type: response.data.data.attributes.type,
            description: response.data.data.attributes.description,
            dataLimit: parseInt(response.data.data.attributes.dataLimit),
            dataLimitFrequency: response.data.data.attributes.dataLimitFrequency,
            retention: parseInt(response.data.data.attributes.retention),
            tenantId: response.data.data.attributes.tenantId,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            deletedAt:  response.data.data.meta.deletedAt,
        };
    },
    async createDeviceType(type: string, description: string, retention=-1, dataLimit=-1, tenantUUID="", dataLimitFrequency=""): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await DeviceTypeApi.createDeviceType(type, description, retention, dataLimit, tenantUUID, dataLimitFrequency);

        if(response.data.data.type && response.data.data.type == 'devicetypes') {

            return {
                uuid: response.data.data.id,
                type: response.data.data.attributes.type,
                description: response.data.data.attributes.description,
                dataLimit: response.data.data.attributes.dataLimit,
                dataLimitFrequency: response.data.data.attributes.dataLimitFrequency,
                retention: parseInt(response.data.data.attributes.retention),
                tenantId: response.data.data.attributes.tenantId,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                deletedAt:  response.data.data.meta.deletedAt,
            };
        } else {
            throw TypeError("Response not type devicestypes");
        }
    },
    async updateDeviceType(typeUUID: string, type = "", description = "", retention = -1, dataLimit = -1, tenantUUID = "", dataLimitFrequency="") : Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const response = await DeviceTypeApi.updateDeviceType(typeUUID, type, description, retention, dataLimit, tenantUUID, dataLimitFrequency);

        if(response.data.data.type && response.data.data.type == 'devicetypes') {

            return {
                uuid: response.data.data.id,
                type: response.data.data.attributes.type,
                description: response.data.data.attributes.description,
                dataLimit: response.data.data.attributes.dataLimit,
                dataLimitFrequency: response.data.data.attributes.dataLimitFrequency,
                retention: parseInt(response.data.data.attributes.retention),
                tenantId: response.data.data.attributes.tenantId,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                deletedAt:  response.data.data.meta.deletedAt,
            };
        } else {
            throw TypeError("Response not type devices");
        }
    },
    async deleteDeviceType(typeUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await DeviceTypeApi.deleteDeviceType(typeUUID);
    },
    async restoreDeviceType(typeUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await DeviceTypeApi.restoreDeviceType(typeUUID);
    },
}
  