import TagApi from '@/api/TagApi';
import store from '../store/index'
import router from '../router/index'

export default { 
    async getAllCollectionPages(tenant: string, params: any = {}): Promise<any[]> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const tags: any[] = [];
        const pageResponses = await TagApi.getAllCollectionPages(tenant, params);
  
        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                tags.push({
                    uuid: userObj.id,
                    name: userObj.attributes.name,
                    description: userObj.attributes.description,
                    type: userObj.attributes.type,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                })
            });
        });

        return tags;
    },
    async getSingle(tenant: string, tagUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await TagApi.getSingle(tenant, tagUUID);
  
        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
            lastActiveAt: response.data.data.meta.lastActiveAt,
        };
    },
    async create(tenantUUID: string, name: string, description: string, type: string): Promise<any> { 
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await TagApi.create(tenantUUID, name, description, type);

        if(response.data.data.type && response.data.data.type == 'tags') {
            return {
                uuid: response.data.data.id,
                name: response.data.data.attributes.name,
                description: response.data.data.attributes.description,
                type: response.data.data.attributes.type,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            }
        } else {
            throw TypeError("Response not type tags");
        }
    },
    async delete(tenantUUID: string, tagUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await TagApi.delete(tenantUUID, tagUUID);
    },
    async update(tenantUUID: string, tagUUID: string, name?: string, description?: string, type?: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await TagApi.update(tenantUUID, tagUUID, name, description, type);

        if(response.data.data.type && response.data.data.type == 'tags') {
            return {
                uuid: response.data.data.id,
                name: response.data.data.attributes.name,
                description: response.data.data.attributes.description,
                type: response.data.data.attributes.type,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                lastActiveAt: response.data.data.meta.lastActiveAt,
            }
        } else {
            throw TypeError("Response not type tags");
        }
    },
    async getAssociatedDevices(tenantUUID: string, tagUUID: string): Promise<any[]> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const associatedDevices: any[] = [];
        const response = await TagApi.getAssociatedDevices(tenantUUID, tagUUID);

        response.data.data.forEach((userObj: any) => {
            associatedDevices.push({
                uuid: userObj.id,
                aksId: (userObj.attributes.aksId || ''),
                localAksId: (userObj.attributes.localAksId || ''),
                description: userObj.attributes.description,
                unit: userObj.attributes.unit,
                type: userObj.attributes.type,
                alerting: userObj.attributes.alerting,
                isRelative: userObj.attributes.isRelative,
                frequency: userObj.attributes.frequency,
                interval: userObj.attributes.interval,
                aggregation: userObj.attributes.aggregation,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                deletedAt: userObj.meta.deletedAt,
            })
        });

        return associatedDevices;
    },
    async getAssociatedProperties(tenantUUID: string, tagUUID: string): Promise<any[]> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const associatedProperties: any[] = [];
        const response = await TagApi.getAssociatedProperties(tenantUUID, tagUUID);

        response.data.data.forEach((userObj: any) => {
            associatedProperties.push({
                uuid: userObj.id,
                name: userObj.attributes.name,
                aksId: (userObj.attributes.aksId || ''),
                identifier: userObj.attributes.identifier,
                street: userObj.attributes.street,
                postalCode: userObj.attributes.postalCode,
                city: userObj.attributes.city,
                country: userObj.attributes.country,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
            })
        });

        return associatedProperties;
    },
    async getAssociatedConnectors(tenantUUID: string, tagUUID: string): Promise<any[]> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const associatedConnectors: any[] = [];
        const response = await TagApi.getAssociatedConnectors(tenantUUID, tagUUID);

        response.data.data.forEach((userObj: any) => {
            associatedConnectors.push({
                uuid: userObj.id,
                name: userObj.attributes.name,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                lastActiveAt: userObj.meta.lastActiveAt,
            })
        });

        return associatedConnectors;
    },
}
  