
import Vue from 'vue'
import { autoComplete, extractErrorMessage } from '@/utils/Util';
import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
import IoTDevicePortService from '@/services/IoTDevicePortService';
import DeviceService from '@/services/DeviceService';
import IoTDeviceService from '@/services/IoTDeviceService';

import IotDeviceMappingInfoDialog from '@/components/entities/iotdevicemapping/IotDeviceMappingInfoDialog.vue';
import IotDeviceMappingDeleteDialog from '@/components/entities/iotdevicemapping/IotDeviceMappingDeleteDialog.vue';
import IotDeviceMappingEditDialog from '@/components/entities/iotdevicemapping/IotDeviceMappingEditDialog.vue';
import IotDeviceMappingCreateDialog from '@/components/entities/iotdevicemapping/IotDeviceMappingCreateDialog.vue';
import DeviceInfoDialog from '@/components/entities/device/DeviceInfoDialog.vue';
import DateTimePicker from '@/components/misc/DateTimePicker.vue'
import MonthDatePicker from '@/components/misc/MonthDatePicker.vue'
import { RRule, rrulestr } from 'rrule'
import DatePicker from '@/components/misc/DatePicker.vue'

const frequencyInputsTable = {
    monthly: {
      rrule: RRule.MONTHLY,
      until: true,
      interval: true,
      month: false,
      day_of_month: true,
      day_of_the_week: false
    },
    weekly: {
      rrule: RRule.WEEKLY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: true
    },
    daily: {
      rrule: RRule.DAILY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    },
    hourly: {
      rrule: RRule.HOURLY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    },
    minutely: {
      rrule: RRule.MINUTELY,
      until: true,
      interval: true,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    },
    once: {
      rrule: null,
      until: false,
      interval: false,
      month: false,
      day_of_month: false,
      day_of_the_week: false
    }
  };

  const range = (n: number) => Array.from({ length: n }, (v, k) => k + 1);
  const thirty1 = range(31);
  const thirty = range(30);
  const twenty9 = range(29);

  const daysPerMonth = {
    January: thirty1,
    February: twenty9,
    March: thirty1,
    April: thirty,
    May: thirty1,
    June: thirty,
    July: thirty1,
    August: thirty1,
    September: thirty,
    October: thirty1,
    November: thirty,
    December: thirty1
  };

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

export default Vue.extend({
  name: 'IotDevicePortCreateDialog',

  props: ['iotdevice', 'type', 'portnumber'],
  components: {
      EntityMetaDialog,
      IotDeviceMappingInfoDialog,
      IotDeviceMappingDeleteDialog,
      IotDeviceMappingEditDialog,
      IotDeviceMappingCreateDialog,
      DeviceInfoDialog,
      DateTimePicker,
      MonthDatePicker,
      DatePicker
  },
  data: (vm: any): any => ({
      localdialog: false,
      valid: true,
      passwordToggle: false,
      alertText: "",
      alertVisible: false,
      alertType: "error",
      loading: false,
      loadingTenant: false,
      requiredRule: [
          (v: string) => !!v || vm.requiredError
      ],
      requiredSelect: [
        (v: any) => !!v && Object.keys(v).length > 0 || vm.requiredError
      ],
      irProtocols: [
        "SML",
        "D0"
      ],
      irProtocolPresets: [
        "SML 9600 8N1",
        "D0 9600 8N1",
        "SML 9600 7E1",
        "D0 300 7E1",
      ],
      selectedIRProtocolPreset: "SML 9600 8N1",
      iotdeviceport: {
        portnumber: vm.portnumber || 1,
        type: vm.type || "",
        config: {
        }
      } as any,
      createdIotdeviceport: null,
      loadingTargetDevices: false,
      associatedMappings: [],
      associatedTenant: {} as any,
      // rrule
      note: "",
      timespanValue: 1,
      timespanUnit: "MONTHS",
      orientByCalendarBounds: true,
      frequencyInputsTable,
      daysPerMonth,
      weekdays,
      repetitionFromSelection: "",
      repetitionUntilSelection: "",
      rrule: {
        interval: 1,
        start: new Date(Date.now()).toISOString(),
        frequency: null,
        until_num: null,
        month: null,
        daysOfTheMonth: [],
        daysOfTheWeek: [],
        until_date: null
      },
      timespanUnits: [
        { text: vm.$t('timeUnit.months'), value: "MONTHS" },  
        { text: vm.$t('timeUnit.weeks'), value: "WEEKS" }, 
        { text: vm.$t('timeUnit.days'), value: "DAYS" }, 
        { text: vm.$t('timeUnit.hours'), value: "HOURS" }, 
        { text: vm.$t('timeUnit.minutes'), value: "MINUTES" }, 
      ],
      apiIntegrationSlugs: [
        'sauter-svc-get-values',
      ]
  }),
  async created() {
    if(this.type === 'ir') {
      this.iotdeviceport.config.protocol = 'SML';
      this.iotdeviceport.config.baudrate = 9600;
      this.iotdeviceport.config.parity = '8N1';
    } else if(this.type === 'pulse') {
      this.iotdeviceport.config.debounce = 500;
      this.iotdeviceport.config.min = 100;
    } else if(this.type === 'mbus') {
      //
    }
  },
  methods: {
    onIRProtocolChange() {
      if(this.iotdeviceport.type === 'ir' && this.selectedIRProtocolPreset === 'SML 9600 8N1') {
        this.iotdeviceport.config = {};
        this.iotdeviceport.config.protocol = 'SML';
        this.iotdeviceport.config.baudrate = 9600;
        this.iotdeviceport.config.parity = '8N1';
      } else if(this.iotdeviceport.type === 'ir' && this.selectedIRProtocolPreset === 'D0 9600 8N1') {
        this.iotdeviceport.config = {};
        this.iotdeviceport.config.protocol = 'D0';
        this.iotdeviceport.config.baudrate = 9600;
        this.iotdeviceport.config.parity = '8N1';
        this.iotdeviceport.config.pullseq = "2f3f210d0a";
        this.iotdeviceport.config.ackseq = "063030300d0a";
      } else if(this.iotdeviceport.type === 'ir' && this.selectedIRProtocolPreset === 'SML 9600 7E1') {
        this.iotdeviceport.config = {};
        this.iotdeviceport.config.protocol = 'SML';
        this.iotdeviceport.config.baudrate = 9600;
        this.iotdeviceport.config.parity = '7E1';
      } else if(this.iotdeviceport.type === 'ir' && this.selectedIRProtocolPreset === 'D0 300 7E1') {
        this.iotdeviceport.config = {};
        this.iotdeviceport.config.protocol = 'D0';
        this.iotdeviceport.config.baudrate = 300;
        this.iotdeviceport.config.parity = '7E1';
        this.iotdeviceport.config.pullseq = "2f3f210d0a";
        this.iotdeviceport.config.ackseq = "063030300d0a";
      }
    },
    onApiIntegrationSlugChange() {
      if(this.iotdeviceport.type === 'api' && this.iotdeviceport.config.integrationSlug === 'sauter-svc-get-values') {
        this.iotdeviceport.config.parameters = {};
        this.iotdeviceport.config.parameters.interval = 'Hour';
      }
    },
    onMappingCreation(item: any) {
      this.associatedMappings.push(item);
    },
    onMappingUpdate(item: any) {
      this.associatedMappings.splice(this.associatedMappings.findIndex((value: any) => value.uuid === item.uuid), 1);
      this.associatedMappings.push(item);
    },
    onMappingDelete(item: any) {
      this.associatedMappings.splice(this.associatedMappings.findIndex((value: any) => value.uuid === item.uuid), 1);
    },
    close() {
      this.localdialog = false;
    },
    clear() {
      this.iotdeviceport = {
        portnumber: this.portnumber || 1,
        type: this.type || "",
        config: {
        }
      };
      this.createdIotdeviceport = null;
      this.loadingTargetDevices = false;
      this.associatedMappings = [];
      this.associatedTenant = {};
      
      if(this.type === 'ir') {
        this.selectedIRProtocolPreset = 'SML 9600 8N1';
        this.iotdeviceport.config.protocol = 'SML';
        this.iotdeviceport.config.baudrate = 9600;
        this.iotdeviceport.config.parity = '8N1';
      } else if(this.type === 'pulse') {
        this.iotdeviceport.config.debounce = 500;
        this.iotdeviceport.config.min = 100;
      } else if(this.type === 'mbus') {
        //
      }

      this.alertVisible = false;
      this.loading = false;

      // rrule
      this.note =  "";
      this.timespanValue = 1;
      this.timespanUnit = "MONTHS";
      this.orientByCalendarBounds = true;
      
      this.repetitionFromSelection = "";
      this.repetitionUntilSelection = "";
      this.rrule = {
        interval: null,
        start: new Date(Date.now()).toISOString(),
        frequency: null,
        until_num: null,
        month: null,
        daysOfTheMonth: [],
        daysOfTheWeek: [],
        until_date: null
      };
    },
    dismiss() {
      this.clear();
      this.close();
    },
    validate() {
      if(this.createForm.validate()) {
        this.create();
      }
    },
    create() {
      this.alertVisible = false;
      this.loading = true;

      if(this.iotdeviceport.type.toLowerCase() === 'api') {
        this.iotdeviceport.config.timespanValue = this.timespanValue;
        this.iotdeviceport.config.timespanUnit = this.timespanUnit;

        const rule = this.parsedRRule;
        this.iotdeviceport.config.rrule = rule.toString();
      }

      IoTDevicePortService.create(this.iotdevice.uuid, this.iotdeviceport.type, parseInt(this.iotdeviceport.portnumber), this.iotdeviceport.config).then((iotdeviceport: any) => {
          this.alertType = "success";
          this.alertText = this.$t('iotdeviceport.successCreate') as string;
          this.alertVisible = true;
          this.loading = false;
          this.createdIotdeviceport = iotdeviceport;

          this.$emit("success", iotdeviceport);
      }).catch((err) => {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('iotdeviceport.errorCreate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
      });
    },
    getTargetDeviceLabel(item: any) {
      if(!('targetDevice' in item) && !this.loadingTargetDevices) {
        this.loadTargetDevices();
      }

      return item?.targetDevice?.description;
    },
    async loadTargetDevices() {
      if(!this.loadingTargetDevices && this.associatedMappings.length > 0) {
        this.loadingTargetDevices = true;

        let promises: Promise<any>[] = [];
        this.associatedMappings.forEach(async (dev: any, index: number) => {
          if(!dev.targetDevice)
            promises.push(this.loadTargetDevice(dev, index));
        });
        await Promise.all(promises);
        this.loadingTargetDevices = false;
      }
    }, 
    delay(ms: number) {
        return new Promise(res => setTimeout(res, ms));
    },
    async getAssociatedTenant() {
      this.loading = true;

      try {
        const tenant = await IoTDeviceService.getAssociatedTenant(this.iotdevice.uuid);
        this.loading = false;

        this.associatedTenant = tenant;
      } catch(err: any) {
        console.log(err);
        console.log(err.response);
        this.alertType = "error";
        this.alertText = this.$t('user.tenantError')+ ' ' + extractErrorMessage(err);
        this.alertVisible = true; 
        this.loading = false;
        this.associatedTenant = {};
      }
    },
    async loadTargetDevice(item: any, index=1) {
      try {
          const device = await DeviceService.getSingle(this.associatedTenant.uuid, item.targetDeviceId);
          this.$set(item, 'targetDevice', device); // important else DOM is not updated
          await this.delay(index*100);
      } catch(err: any) {
          console.log(err);
          console.log(err.response);
          Vue.set(item, 'targetDevice', undefined);
      }
    },
    autoComplete,
  },
  watch: {
    async localdialog(value: boolean) {
        if(value) {
            this.iotdeviceport.portnumber = (this.portnumber || 1);
            await this.getAssociatedTenant();
        } else {
            this.clear(); 
        }
    }
  },
  computed: {
      firstExecutionExample() {
        return this.firstExecutionDate ? this.firstExecutionDate.toLocaleString() : '';
      },
      firstExecutionDate() {
        let start = new Date(Date.now());

        if(this.repetitionFromSelection === 'from_date') {
          start = new Date(this.rrule.start);
        }

        return this.parsedRRule ? new Date(this.parsedRRule.all((date: Date, i: number) => i < 2)[start.getTime() <= Date.now() ? 1 : 0]) : undefined;
      },
      parsedRRule() {
        if(!this.rrule.frequency) {
          return undefined;
        }

        let start = new Date(Date.now());

        // start.setUTCDate(start.getUTCDate()+1);

        if(this.repetitionFromSelection === 'from_date' && new Date(this.rrule.start).getTime() > start.getTime()) {
            start = new Date(this.rrule.start);
          }

        let _rrule = {
          dtstart: start,
        } as any;

        if(this.rrule.frequency && this.rrule.frequency.length > 0) {
          if(this.rrule.frequency.toLowerCase() === 'once') {
            // special case, once is not supported by rrule so we create 
            // a task with yearly occure but only once
            _rrule.freq = RRule.YEARLY;
            _rrule.count = 1;
          } else {
            _rrule.freq = this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].rrule;
          }
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].interval && this.rrule.interval) {
          _rrule.interval = Number(this.rrule.interval);
        }
        if(this.repetitionUntilSelection === 'until_count' && this.rrule.until_num) {
          _rrule.count = Number(this.rrule.until_num);
        }
        if(this.repetitionUntilSelection === 'until_date' && this.rrule.until_date) {
          const until = new Date(this.rrule.until_date);
          _rrule.until = new Date(Date.UTC(until.getFullYear(), until.getMonth(), until.getDate()));
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].month && this.rrule.month) {
          _rrule.bymonth = this.rrule.month;
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].day_of_month && this.rrule.daysOfTheMonth.length > 0) {
          _rrule.bymonthday = this.rrule.daysOfTheMonth;
        }
        if(this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].day_of_the_week && this.rrule.daysOfTheWeek.length > 0) {
          _rrule.byweekday = this.rrule.daysOfTheWeek.map((value: string) => this.weekdays.findIndex((day: string) => day === value));
        }
        // console.log(_rrule);

        return new RRule(_rrule);
      },
      months(): any { 
        const keys = Object.keys(daysPerMonth);
        return keys.map((value: string) => value.charAt(0).toUpperCase()+value.slice(1))
      },
      frequencies(): any {
        // TODO add i18n translation
        const keys = Object.keys(frequencyInputsTable);
        return keys.map((value: string) => value.charAt(0).toUpperCase()+value.slice(1))
      },
      createForm(): any {
          return this.$refs.createForm;
      },
      requiredError(): any {
          return this.$t('required');
      },
      currentTenant(): any {
        return this.$root.$store.state.session.selectedTenant.uuid;
      },
      intervalHint() {
        const rrule = this.rrule;
        return `1 means every ${
          rrule.frequency === "Daily" ? "Day" : rrule.frequency.slice(0, -2)
        }, 2 is every other ${
          rrule.frequency === "Daily" ? "Days" : rrule.frequency.slice(0, -2)
        }`;
      },
  }
})
