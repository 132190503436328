
  import Vue from 'vue'

  import SetpointInfoDialog from '@/components/entities/setpoint/SetpointInfoDialog.vue';
  import SetpointEditDialog from '@/components/entities/setpoint/SetpointEditDialog.vue';
  import SetpointDeleteDialog from '@/components/entities/setpoint/SetpointDeleteDialog.vue';
  import SetpointService from '@/services/SetpointService';

  export default Vue.extend({
    name: 'SetpointsTable',

    components: {
      SetpointInfoDialog,
      SetpointDeleteDialog,
      SetpointEditDialog
    },
    props: ['pagination', 'setpointsPage', 'loading'],
    data: (vm: any): any => ({
      tableLoadingText: vm.loadingDataText,
      showUUIDs: false,
      sortDesc: true,
      sortBy: "timestamp",
      selectedHeaders: [
        { text: vm.$t('attribute.timestamp'), value: 'timestamp', filterable: false, index: 200 },
        { text: vm.$t('attribute.value'), value: 'value', sortable: false, filterable: false, index: 300 },
        { text: vm.$t('attribute.state'), value: 'state', sortable: false, index: 400 },
        { text: vm.$t('attribute.updatedAt'), value: 'updatedAt', index: 1000 },
        { text: vm.$t('attribute.createdAt'), value: 'createdAt', index: 1100 },
      ],      
      currentItems: [],
      loadingDevices: false
    }),
    async created() {
      if(this.setpointsPage.length === 0) {
        this.tableLoadingText = this.$t('setpoint.noData');
      }

      const columns = this.$root.$store.getters.selectedColumns('setpoint');
      if(columns) {
        this.selectedHeaders = columns;
      }
    },
    watch: {
      selectedHeaders() {
        this.$store.commit("setSelectedColumns", { type: 'setpoint', columns: this.selectedHeaders });
      } 
    },
    methods: {
      onExport() {
        this.$emit("export");
      },  
      onReload() {
        this.$emit("reload");
      },
      onCurrentItemsChange(items: any[]) {
        this.currentItems = items;
      },
      onSort() {
        // Reset the timestamp sorting if changed, as sorted is done in backend
        setTimeout(() => { this.sortDesc = true; }, 1); // ML: needed for some reason to react
      },
      onSetpointItemUpdate(item: any) {
        this.$emit("update", item);
      },
      onSetpointItemDelete(item: any) {
        this.$emit("delete", item);
      },
      onTableRowClick(row: any) {
        const button: any = this.$refs["setpointInfoButton_" + row.uuid];
        // $el needed for native elements
        button.$el.click();
      },
      getColor(state: string) {
        if (state === 'deprecated') return 'rgba(244, 64, 52, 0.6)'
        else if (state === 'sent') return 'rgba(255, 152, 0, 0.6)'
        else return 'rgba(76, 175, 80, 0.6)'
      },
      getDeviceUnitLabel(item: any) {
        if(!('device' in item) && !this.loadingDevices) {
          this.loadCurrentDevices(item);
        }

        return item?.device?.unit || item.deviceId;
      },
      getDeviceDescriptionLabel(item: any) {
        if(!('device' in item) && !this.loadingDevices) {
          this.loadCurrentDevices(item);
        }

        return item?.device?.description;
      },
      getDeviceAksLabel(item: any) {
        if(!('device' in item) && !this.loadingDevices) {
          this.loadCurrentDevices(item);
        }

        return item?.device?.aksId;
      },
      async loadCurrentDevices() {
        if(!this.loadingDevices && this.currentItems.length > 0) {
          this.loadingDevices = true;

          let promises: Promise<any>[] = [];
          this.currentItems.forEach(async (task: any) => {
            if(!task.device)
              promises.push(this.loadDevice(task));
          });
          await Promise.all(promises);
          this.loadingDevices = false;
        }
      }, 
      async loadDevice(item: any) {
        try {
            const device = await SetpointService.getAssociatedDevice(this.currentTenant, item.uuid);
            Vue.set(item, 'device', device); // important else DOM is not updated
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            Vue.set(item, 'device', {});
        }
      },
    },
    computed: {
      headers() {
        return [
            { text: this.$t('attribute.uuid'), value: 'uuid', index: 0 },
            { text: this.$t('entity.device') + ' ' + this.$t('attribute.aksId'), value: 'device.aksId', index: 100 },
            { text: this.$t('entity.device')+ ' ' + this.$t('attribute.description'), value: 'device.description', index: 101 },
            { text: this.$t('entity.device')+ ' ' + this.$t('attribute.unit'), value: 'device.unit', index: 102 },
            { text: this.$t('attribute.timestamp'), value: 'timestamp', filterable: false, index: 200 },
            { text: this.$t('attribute.value'), value: 'value', sortable: false, filterable: false, index: 300 },
            { text: this.$t('attribute.state'), value: 'state', sortable: false, index: 400 },
            { text: this.$t('attribute.updatedAt'), value: 'updatedAt', index: 1000 },
            { text: this.$t('attribute.createdAt'), value: 'createdAt', index: 1100 },
        ];
      },
      exportTooltipColor(): any {
        return this.exportEnabled ? "" : "error";
      },
      exportEnabled(): boolean {
        return this.pagination.itemsLength < 5000;
      },
      tableFooterProps(): any {
        return {
          'items-per-page-options': [5, 10, 15, 30],
          'items-per-page-text': this.$t('itemsPerPageText'),
        };
      },
      loadingDataText(): any {
        return this.$t('loadingData');
      },
      computedTableHeader() {
        return [...this.selectedHeaders,
          { text: this.$t('attribute.actions'), value: 'actions', sortable: false, filterable: false, index: 100 },
        ].sort((a: any, b: any) => a.index-b.index);
      },
      canUpdate(): any {
        if (this.$root.$store.state.session.permissions.resources && 'setpoints' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['setpoints'].includes('PATCH');
        } else {
          return false;
        }
      },
      canDelete(): any {
        if (this.$root.$store.state.session.permissions.resources && 'setpoints' in this.$root.$store.state.session.permissions.resources) {
          return this.$root.$store.state.session.permissions.resources['setpoints'].includes('DELETE');
        } else {
          return false;
        }
      },
    }
  })
