import PropertyApi from '@/api/PropertyApi';
import store from '../store/index';
import router from '../router/index';

const timeUnits = [
    {name: 'minutes', value: 60 },
    {name: 'hours', value: 3600 }, 
    {name: 'days', value: 86400 },
    {name: 'weeks', value: 604800 },
    {name: 'months', value: 2629800 },
    {name: 'years', value: 31557600 }
];

export default {
    async getAllCollectionPages(tenantUUID: string, params: any = {}): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const properties: any[] =[];
        const pageResponses = await PropertyApi.getAllCollectionPages(tenantUUID, params);

        pageResponses.forEach((response: any) => {
            response.data.data.forEach((userObj: any) => {
                properties.push({
                    uuid: userObj.id,
                    name: userObj.attributes.name,
                    aksId: (userObj.attributes.aksId || ''),
                    identifier: userObj.attributes.identifier,
                    street: userObj.attributes.street,
                    postalCode: userObj.attributes.postalCode,
                    city: userObj.attributes.city,
                    country: userObj.attributes.country,
                    createdAt: userObj.meta.createdAt,
                    updatedAt: userObj.meta.updatedAt,
                })
            });
        });

        return properties;
    },
    async createProperty(tenantUUID: string, name: string, aksId = "", identifier = "", street = "", postalCode = "", city = "", country = ""): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
    
        const response = await PropertyApi.createProperty(tenantUUID, name, aksId, identifier, street, postalCode, city, country);

        if(response.data.data.type && response.data.data.type == 'properties') {
           
            return {
                uuid: response.data.data.id,
                name: response.data.data.attributes.name,
                aksId: (response.data.data.attributes.aksId || ''),
                identifier: response.data.data.attributes.identifier,
                street: response.data.data.attributes.street,
                postalCode: response.data.data.attributes.postalCode,
                city: response.data.data.attributes.city,
                country: response.data.data.attributes.country,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
            };
        } else {
            throw TypeError("Response not type properties");
        }
    },
    async updateProperty(tenantUUID: string, propertyUUID: string, name = undefined, aksId = undefined, identifier = undefined, street = undefined, postalCode = undefined, city = undefined, country = undefined): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        const response = await PropertyApi.updateProperty(tenantUUID, propertyUUID, name, aksId, identifier, street, postalCode, city, country);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            aksId: (response.data.data.attributes.aksId || ''),
            identifier: response.data.data.attributes.identifier,
            street: response.data.data.attributes.street,
            postalCode: response.data.data.attributes.postalCode,
            city: response.data.data.attributes.city,
            country: response.data.data.attributes.country,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async getSingle(tenantUUID: string, propertyUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        const response = await PropertyApi.getSingle(tenantUUID, propertyUUID);

        return {
            uuid: response.data.data.id,
            name: response.data.data.attributes.name,
            aksId: (response.data.data.attributes.aksId || ''),
            identifier: response.data.data.attributes.identifier,
            street: response.data.data.attributes.street,
            postalCode: response.data.data.attributes.postalCode,
            city: response.data.data.attributes.city,
            country: response.data.data.attributes.country,
            createdAt: response.data.data.meta.createdAt,
            updatedAt: response.data.data.meta.updatedAt,
        };
    },
    async getAssociatedDevices(tenantUUID: string, propertyUUID: string): Promise<any[]> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const devices: any[] = [];
        const response = await PropertyApi.getAssociatedDevices(tenantUUID, propertyUUID);

        response.data.data.forEach((userObj: any) => {
            devices.push({
                uuid: userObj.id,
                aksId: (userObj.attributes.aksId || ''),
                localAksId: (userObj.attributes.localAksId || ''),
                description: userObj.attributes.description,
                unit: userObj.attributes.unit,
                type: userObj.attributes.type,
                alerting: userObj.attributes.alerting,
                isRelative: userObj.attributes.isRelative,
                frequency: userObj.attributes.frequency,
                interval: userObj.attributes.interval,
                aggregation: userObj.attributes.aggregation,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                deletedAt: userObj.meta.deletedAt,
                propertyId: propertyUUID
            });
        });
        return devices;
    },
    async getAssociatedTags(tenantUUID: string, propertyUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const tags: any[] = [];
        const response = await PropertyApi.getAssociatedTags(tenantUUID, propertyUUID);

        response.data.data.forEach((userObj: any) => {
            tags.push({
                uuid: userObj.id,
                name: userObj.attributes.name,
                description: userObj.attributes.description,
                type: userObj.attributes.type,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                propertyId: propertyUUID
            });
        });
        return tags;
    },
    async addTags(tenantUUID: string, propertyUUID: string, tags: any[]): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        return await PropertyApi.addTags(tenantUUID, propertyUUID, tags);
    },
    async removeTags(tenantUUID: string, propertyUUID: string, tags: any[]): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        await PropertyApi.removeTags(tenantUUID, propertyUUID, tags);
    },
    async deleteProperty(tenantUUID: string, propertyUUID: string): Promise<void> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }
        
        await PropertyApi.deleteProperty(tenantUUID, propertyUUID);
    },
    async getAssociatedAlerts(tenantUUID: string, propertyUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const alerts: any[] = [];
        const response = await PropertyApi.getAssociatedAlerts(tenantUUID, propertyUUID);

        response.data.data.forEach((userObj: any) => {
            let timeUnit;
            let frequency;
            for(const unit of [...timeUnits].reverse()) {
                if(userObj.attributes.maxAge % unit.value === 0) {
                    timeUnit = unit.name;
                    frequency =  userObj.attributes.maxAge / unit.value;
                    break;
                }
            }
            alerts.push({
                uuid: userObj.id,
                recipients: userObj.attributes.recipients,
                maxAge: userObj.attributes.maxAge,
                paused: userObj.attributes.paused,
                active: userObj.attributes.active,
                createdAt: userObj.meta.createdAt,
                updatedAt: userObj.meta.updatedAt,
                timeUnit: timeUnit,
                frequency: frequency
            });
        });
        return alerts;
    },
    async addAlert(tenantUUID: string, propertyUUID: string, recipients: string[], maxAge: number): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await PropertyApi.addAlert(tenantUUID, propertyUUID, recipients, maxAge);

        if(response.data.data.type && response.data.data.type == 'alerts') {
            let timeUnit;
            let frequency;
            for(const unit of [...timeUnits].reverse()) {
                if(response.data.data.attributes.maxAge % unit.value === 0) {
                    timeUnit = unit.name;
                    frequency =  response.data.data.attributes.maxAge / unit.value;
                    break;
                }
            }
            return {
                uuid: response.data.data.id,
                recipients: response.data.data.attributes.recipients,
                maxAge: response.data.data.attributes.maxAge,
                paused: response.data.data.attributes.paused,
                active: response.data.data.attributes.active,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                timeUnit: timeUnit,
                frequency: frequency
            }
        } else {
            throw TypeError("Response not type alerts");
        }
    },
    async updateAlert(tenantUUID: string, propertyUUID: string, alertUUID: string, recipients?: string[], maxAge?: number): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        const response = await PropertyApi.updateAlert(tenantUUID, propertyUUID, alertUUID, recipients, maxAge);

        if(response.data.data.type && response.data.data.type == 'alerts') {
            let timeUnit;
            let frequency;
            for(const unit of [...timeUnits].reverse()) {
                if(response.data.data.attributes.maxAge % unit.value === 0) {
                    timeUnit = unit.name;
                    frequency =  response.data.data.attributes.maxAge / unit.value;
                    break;
                }
            }
            return {
                uuid: response.data.data.id,
                recipients: response.data.data.attributes.recipients,
                maxAge: response.data.data.attributes.maxAge,
                paused: response.data.data.attributes.paused,
                active: response.data.data.attributes.active,
                createdAt: response.data.data.meta.createdAt,
                updatedAt: response.data.data.meta.updatedAt,
                timeUnit: timeUnit,
                frequency: frequency
            }
        } else {
            throw TypeError("Response not type alerts");
        }
    },
    async removeAlert(tenantUUID: string, propertyUUID: string, alertUUID: string): Promise<any> {
        if (!store.getters.loggedIn(Date.now())) {
            router.push('/login');
        }

        await PropertyApi.removeAlert(tenantUUID, propertyUUID, alertUUID);
    },
}
  