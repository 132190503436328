
  import Vue from 'vue'
  import ApiKeyService from '@/services/ApiKeyService';
  import { extractErrorMessage } from '@/utils/Util';
  import QRCard from '@/components/misc/QRCard.vue';

  export default Vue.extend({
    name: 'ApiKeyGenerateTokenDialog',
    
    components: {
      QRCard
    },
    props: ['apikey'],
    data: (vm: any): any => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        notifyApiKey: false,
        token: "",
        expiration: "",
        expirationMS: null
    }),
    watch: {
        localDialog(value: boolean) {
            if(!value) {
                this.clear();
            }
        }
    },
    methods: {
      close() {
        this.localDialog = false;
      },
      clear() {
        this.expirationMS = null;
        this.token = "";
        this.notifyApiKey = false;
        this.expiration = "";
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      confirmGenerate() {
        this.loading = true;
        let notify = this.notifyApiKey;

        ApiKeyService.generateToken(this.apikey.uuid, notify).then((data: any) => {
            this.alertType = "success";
            this.alertText = this.$t('apikey.successGenerateToken') as string;
            this.alertVisible = true;
            this.loading = false;

            this.token = data.token;
            this.expiration = data.expiration;
            this.expirationMS = new Date(data.expiration).getTime();
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('apikey.errorGenerateToken') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      async onCopyClipboard() {
        try {
          await navigator.clipboard.writeText(this.token);

          this.alertType = "success";
          this.alertText = this.$t('apikey.successCopyClipboard') as string;
          this.alertVisible = true;
        } catch(err: any) {
          this.alertType = "error";
          this.alertText = this.$t('apikey.errorCopyClipboard') + ' ' + extractErrorMessage(err);
          this.alertVisible = true;
        }
      },
    },
    computed: {
        qrCodeData(): string {
            return JSON.stringify({
            expires: this.expirationMS,
            token: this.token
            });
        },
        }
  })
