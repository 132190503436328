
  import Vue from 'vue'
  import IoTDeviceService from '@/services/IoTDeviceService';

  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import IoTDevicePortService from '@/services/IoTDevicePortService';
  import IoTDeviceMappingService from '@/services/IoTDeviceMappingService';

  export default Vue.extend({
    name: 'IotDeviceReplacementDialog',

    props: ['iotdevice'],
    data: (vm: any): any => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        requiredSelect: [
          (v: any) => Object.keys(v).length > 0 || vm.requiredError
        ],
        iotdevices: [] as any[],
        sourceIotDevice: {} as any,
    }),
    methods: {
      autoComplete,
      close() {
        this.localDialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
        this.iotdevices = [];
      },
      dismiss() {
        this.clear();
        this.close();
      },
      getIotDevices() {
        this.iotdevices = [];
        this.loading = true;

        IoTDeviceService.getAllCollectionPages().then((iotdevices: any[]) => {
            this.loading = false;
            this.iotdevices = iotdevices;
        }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('iotdevice.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;    
            this.loading = false;  
        });
      },
      async confirm() {
        this.loading = true;

        let associatedTenant, associatedProperty, associatedConnector;
        let ports;
        let updatedIotdevice;
        try {
          // get the configuration of the selected iotdevice
          // get the associated tenant, property, connector
          associatedTenant = await IoTDeviceService.getAssociatedTenant(this.sourceIotDevice.uuid).catch(()=>{});
          associatedProperty = await IoTDeviceService.getAssociatedProperty(this.sourceIotDevice.uuid).catch(()=>{});
          associatedConnector = await IoTDeviceService.getAssociatedConnector(this.sourceIotDevice.uuid).catch(()=>{});
          console.log(associatedTenant, associatedProperty, associatedConnector)

          // get all ports
          ports = await IoTDevicePortService.getAllCollectionPages(this.sourceIotDevice.uuid);

          // per port, get all mappings
          for(let i=0; i<ports.length; i++) {
            const mappings = await IoTDeviceMappingService.getAllCollectionPages(this.sourceIotDevice.uuid, ports[i].uuid);
            ports[i].mappings = mappings;
          }
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('iotdevice.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
            return;
        }

        try {
          // update the iotdevice itself
          // set the associated tenant, property, connector
          updatedIotdevice = await IoTDeviceService.updateIoTDevice(
            this.iotdevice.uuid, 
            this.sourceIotDevice.type, 
            this.sourceIotDevice.description, 
            this.sourceIotDevice.connectivity, 
            undefined, 
            undefined, 
            this.sourceIotDevice.config, 
            undefined, 
            associatedTenant?.uuid, 
            associatedProperty?.uuid, 
            associatedConnector?.uuid);

          // first delete all ports/mappings of the replacement device?
          // create all ports
          for(const port of ports) {
            const newPort = await IoTDevicePortService.create(this.iotdevice.uuid, port.type, port.portnumber, port.config);
            // per port create all mappings
            for(const mapping of port.mappings) {
              await IoTDeviceMappingService.create(this.iotdevice.uuid, newPort.uuid, mapping.identifiers, mapping.targetDeviceId, mapping.config);
            }
          }

          this.$emit("update", updatedIotdevice);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        } catch(err: any) {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('iotdevice.errorUpdate') + ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
          return;
        }
      }
    },
    watch: {
      localDialog(value: boolean) {
        if(value) {
          this.getIotDevices();
        } else {
          this.clear();
        }
      }
    }
  })
