
  import Vue from 'vue'
  import AggregateMultiChartCard from './dashboard/AggregateMultiChartCard.vue'

  export default Vue.extend({
    name: 'Visualization',

    props: ['config'],
    components: {
      AggregateMultiChartCard
    },
    data: () => ({
    }),
    created() {
      // e
    },
    methods: {
      onUpdate(config: any) {
        this.$emit('update', config);
      }
    }
  })
