
  import Vue from 'vue'
  import { QRCodeSVG } from '@cheprasov/qrcode';

  export default Vue.extend({
    name: 'QRCodeCard',

    props: ['uuid'],
    data: () => ({
      svgData: '',
    }),
    async created() {
      const qrSVG = new QRCodeSVG(this.uuid);
      this.svgData = await qrSVG.toDataUrl();
    },
  })
