import axios from 'axios';

export default {
    login(email: string, password: string): Promise<any> {
        const body = {
            data: {
                type: "credentials",
                attributes: {
                    email: email,
                    password: password
                }
            }
        };
        return axios.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/login', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    // register(email: string, password: string): Promise<any> {
    //     const body = {
    //         "data": {
    //             "type": "users",
    //             "attributes": {
    //                 "email": email,
    //                 "password": password
    //             }
    //         }
    //     };
    //     return axios.post(process.env.VUE_APP_BASCLOUD_BACKEND_SERVER_URL + '/signup', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    // },
    resetPassword(email: string): Promise<any> {
        const body = {
            "data": {
                "type": "resetPassword",
                "attributes": {
                    "email": email
                }
            }
        };
        return axios.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/resetPassword', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    changePassword(userEmail: string, token: string, password: string): Promise<any> {
        const body = {
            "data": {
                "type": "changePassword",
                "attributes": {
                    "token": token,
                    "email": userEmail,
                    "password": password
                }
            }
        };
        return axios.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/changePassword', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
}
  