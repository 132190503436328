
  import Vue from 'vue'
  import IoTDevicePortService from '@/services/IoTDevicePortService';
  import IotDevicePortInfoDialog from '../iotdeviceport/IotDevicePortInfoDialog.vue';
  import IotDevicePortEditDialog from '../iotdeviceport/IotDevicePortEditDialog.vue';
  import IotDevicePortDeleteDialog from '../iotdeviceport/IotDevicePortDeleteDialog.vue';
  import IotDevicePortCreateDialog from '../iotdeviceport/IotDevicePortCreateDialog.vue';

  import { autoComplete, extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'IotDevicePortConfigDialog',

    props: {
        readonly: { default: true },
        iotdevice: {}
    },
    components: {
        IotDevicePortInfoDialog,
        IotDevicePortEditDialog,
        IotDevicePortDeleteDialog,
        IotDevicePortCreateDialog
    },
    data: (vm: any): any => ({
        localdialog: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        ports: [] as any[]
    }),
    async created() {
        //s
    },
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      onPortCreation(item: any) {
        this.ports.push(item);
      },
      onPortUpdate(item: any) {
        this.ports.splice(this.ports.findIndex((value: any) => value.uuid === item.uuid), 1);
        this.ports.push(item);
      },
      onPortDelete(item: any) {
        this.ports.splice(this.ports.findIndex((value: any) => value.uuid === item.uuid), 1);
      },
      async getAssociatedPorts() {
        this.loading = true;

        try {
          const ports = await IoTDevicePortService.getAllCollectionPages(this.iotdevice.uuid)
          this.loading = false;

          this.ports = ports;
        } catch(err: any) {
          console.log(err);
          console.log(err.response);
          this.alertType = "error";
          this.alertText = this.$t('user.tenantError')+ ' ' + extractErrorMessage(err);
          this.alertVisible = true; 
          this.loading = false;
          this.ports = [];
        }
      },
      autoComplete,
    },
    watch: {
        async localdialog(value: boolean) {
            if(value) {
                this.getAssociatedPorts();
            } else {
                this.clear(); 
            }
        }
    },
    computed: {
        mbusPorts(): any[] {
          return this.ports.filter((value: any) => value.type === 'mbus');
        },
        nextMbusPortnumber(): number {
            if(this.mbusPorts.length === 0)
                return 1;
            return Math.max(...this.mbusPorts.map((o: any) => o.portnumber)) + 1;
        },
        pulsePorts(): any[] {
          return this.ports.filter((value: any) => value.type === 'pulse');
        },
        nextPulsePortnumber(): number {
            if(this.pulsePorts.length === 0)
                return 1;
            return Math.max(...this.pulsePorts.map((o: any) => o.portnumber)) + 1;
        },
        irPorts(): any[] {
          return this.ports.filter((value: any) => value.type === 'ir');
        },
        nextIrPortnumber(): number {
            if(this.irPorts.length === 0)
                return 1;
            return (Math.max(...this.irPorts.map((o: any) => o.portnumber)) + 1);
        },
        mqttPorts(): any[] {
          return this.ports.filter((value: any) => value.type === 'mqtt');
        },
        nextMQTTPortnumber(): number {
            if(this.mqttPorts.length === 0)
                return 1;
            return Math.max(...this.mqttPorts.map((o: any) => o.portnumber)) + 1;
        },
        apiPorts(): any[] {
          return this.ports.filter((value: any) => value.type === 'api');
        },
        nextApiPortnumber(): number {
            if(this.apiPorts.length === 0)
                return 1;
            return Math.max(...this.apiPorts.map((o: any) => o.portnumber)) + 1;
        },
        requiredError(): any {
            return this.$t('required');
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        },
    }
  })
