
    import Vue from 'vue'
  
    import DeviceInfoDialog from '@/components/entities/device/DeviceInfoDialog.vue';
    import DeviceMonitoringGraphDialog from './DeviceMonitoringGraphDialog.vue';
    import DeviceService from '@/services/DeviceService';
  
    export default Vue.extend({
      name: 'DeviceMonitoringCard',
  
      components: {
        DeviceInfoDialog,
        DeviceMonitoringGraphDialog
      },
      props: ['device'],
      data: () => ({
      }),
      async created() {
  
      },
      methods: {
        delay(ms: number) {
            return new Promise(res => setTimeout(res, ms));
        },
        onRefresh() {
          this.$emit('onRefresh', this.device);
        },
        onShowReadings() {
          this.$router.push({ name: 'Readings', params: { device: this.device } });
        }
      },
      watch: {
      },
      computed: {
        getPropertyNameLabel() {
          if(!('property' in this.device)) {
            this.$emit('onLoadProperty', this.device);
          }
          return this.device?.property?.name ?? '-';
        },      
        getConnectorNameLabel() {
          if(!('connector' in this.device)) {
            this.$emit('onLoadConnector', this.device);
          }
          return this.device?.connector?.name ?? '-';
        },
        lastTimestamp(): string {
          if(!(this.device?.readings) || this.device.readings.length === 0)
            return 'n.a.'
  
          const date = new Date(this.device.readings[0].timestamp);
          return date.toLocaleString();
        },
        onlineRate(): string {
          if(!(this.device?.readings) || this.device.readings.length === 0)
            return 'n.a.';
  
          // count the number of ping readings in the timeframe, divide through number of 15min slots in that timeframe
          const diff = Math.abs(this.device.until - this.device.from);
          const intervals = Math.floor(diff/ (15*60*1000));
          const rate = (this.device.readings.length / intervals) * 100;
  
          return rate.toLocaleString(undefined, { maximumFractionDigits: 0 }) + '%';
        },
        backgroundColor(): string | undefined {
          if(this.device?.alertActive === 0) {
            return 'grey lighten-2'
          } else if(this.device?.alertActive === 2) {
            return 'red lighten-1';
          } else if(this.device?.alertActive === 1) {
            return 'green lighten-1';
          } else {
            return undefined;
          }
        },
        monitoringTenant(): string {
          return 'd97ce06e-4630-4015-89b4-1fbb67bbe328';
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        }
      },
    })
  