import axiosLimiter from '@/api/RateLimiter';

export default {
    getCollection(): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices');
    },
    async getAllCollectionPages(params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices?';

            //  Filters
            if('serialnumber' in params && params.serialnumber && params.serialnumber.length > 0) {
                requestURL = requestURL + '&serialnumber=' + encodeURIComponent(params.serialnumber);
            }
            if('type' in params && params.type && params.type.length > 0) {
                requestURL = requestURL + '&type=' + encodeURIComponent(params.type);
            }
            if('active' in params) {
                requestURL = requestURL + '&active=' + encodeURIComponent(params.active);
            }
            if('tenant' in params && params.tenant && params.tenant.uuid.length > 0) {
                requestURL = requestURL + '&tenantId=' + encodeURIComponent(params.tenant.uuid);
            }
            if('property' in params && params.property && params.property.uuid.length > 0) {
                requestURL = requestURL + '&propertyId=' + encodeURIComponent(params.property.uuid);
            }
            if('connector' in params && params.connector && params.connector.uuid.length > 0) {
                requestURL = requestURL + '&connectorId=' + encodeURIComponent(params.connector.uuid);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    getSingle(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID);
    },
    createIoTDevice(serialnumber: string, type: string, description: string, connectivity: string, config: any, hardware: any, mqttPassword?: string, tenantUUID?: string, propertyUUID?: string, connectorUUID?: string): Promise<any> {
        const body = {
            "data": {
                "type": "iotdevices",
                "attributes": {
                    "serialnumber": serialnumber,
                    "type": type,
                    "description": description,
                    "connectivity": connectivity,
                    "config": config,
                    "hardware": hardware
                },
                "relationships": {
                }
            }
        } as any;

        if(mqttPassword) {
            body.data.attributes.mqttPassword = mqttPassword;
        }

        if(tenantUUID) {
            body.data.relationships.tenant = {
                data: {
                    type: "tenants",
                    id: tenantUUID
                }
            }
        }
        if(propertyUUID) {
            body.data.relationships.property = {
                data: {
                    type: "properties",
                    id: propertyUUID
                }
            }
        }
        if(connectorUUID) {
            body.data.relationships.connector = {
                data: {
                    type: "connectors",
                    id: connectorUUID
                }
            }
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateIoTDevice(iotdeviceUUID: string, type?: string, description?: string, connectivity?: string, state?: string, mqttPassword?: string, config?: any, hardware?: any, tenantUUID?: string, propertyUUID?: string, connectorUUID?: string): Promise<any> {
    
        const body = {
            data: {
                type: "iotdevices",
                id: iotdeviceUUID,
                attributes: {
                },
                relationships: {
                }
            }
        } as any;

        if(type) {
            body.data.attributes.type = type;
        }
        if(description !== undefined) {
            body.data.attributes.description = description;
        }
        if(connectivity) {
            body.data.attributes.connectivity = connectivity;
        }
        if(state !== undefined) {
            body.data.attributes.state = state;
        }
        if(mqttPassword) {
            body.data.attributes.mqttPassword = mqttPassword;
        }
        if(config) {
            body.data.attributes.config = config;
        }
        if(hardware) {
            body.data.attributes.hardware = hardware;
        }

        if(tenantUUID) {
            body.data.relationships.tenant = {
                data: {
                    type: "tenants",
                    id: tenantUUID
                }
            }
        } else if (tenantUUID === "") {
            body.data.relationships.tenant = {
                data: {
                    type: "tenants",
                    id: null
                }
            }
        }

        if(propertyUUID) {
            body.data.relationships.property = {
                data: {
                    type: "properties",
                    id: propertyUUID
                }
            }
        } else if (propertyUUID === "") {
            body.data.relationships.property = {
                data: {
                    type: "properties",
                    id: null
                }
            }
        }

        if(connectorUUID) {
            body.data.relationships.connector = {
                data: {
                    type: "connectors",
                    id: connectorUUID
                }
            }
        } else if (connectorUUID === "") {
            body.data.relationships.connector = {
                data: {
                    type: "connectors",
                    id: null
                }
            }
        }

        console.log(body)

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    deleteIoTDevice(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID);
    },
    activateIoTDevice(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/activate');
    },
    deactivateIoTDevice(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/deactivate');
    },
    activateIoTDeviceMonitoring(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/activateMonitoring');
    },
    deactivateIoTDeviceMonitoring(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/deactivateMonitoring');
    },
    getAssociatedMonitoringDevice(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/device');
    },
    getAssociatedTenant(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/tenant');
    },
    getAssociatedConnector(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/connector');
    },
    getAssociatedProperty(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/property');
    },
    getDeviceStatus(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/control/status');
    },
    triggerDeviceRestart(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/control/restart');
    },
    triggerDeviceCollect(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/control/collect');
    },
    triggerDevicePing(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/control/ping');
    },
    triggerOTAUpdate(iotdeviceUUID: string, version: string, component: string): Promise<any> {
        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/control/update?';

        requestURL = requestURL + '&version=' + encodeURIComponent(version);
        requestURL = requestURL + '&component=' + encodeURIComponent(component);

        return axiosLimiter.get(requestURL);
    },    
    setLogging(iotdeviceUUID: string, level?: string, backend?: string): Promise<any> {
        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/control/logging?';

        if(level !== undefined)
            requestURL = requestURL + '&level=' + encodeURIComponent(level);

        if(backend !== undefined)
            requestURL = requestURL + '&backend=' + encodeURIComponent(backend);

        return axiosLimiter.get(requestURL);
    },
    setCustomCMD(iotdeviceUUID: string, cmd: string): Promise<any> {
        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/control/cmd?';

        requestURL = requestURL + '&cmd=' + encodeURIComponent(cmd);
        return axiosLimiter.get(requestURL);
    },
    async getAllMonitoringCollectionPages(params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/monitoring?from=' + params.from + '&until=' + params.until;

            //  Filters
            if('serialnumber' in params && params.serialnumber && params.serialnumber.length > 0) {
                requestURL = requestURL + '&serialnumber=' + encodeURIComponent(params.serialnumber);
            }
            if('type' in params && params.type && params.type.length > 0) {
                requestURL = requestURL + '&type=' + encodeURIComponent(params.type);
            }
            if('active' in params) {
                requestURL = requestURL + '&active=' + encodeURIComponent(params.active);
            }
            if('tenant' in params && params.tenant && params.tenant.uuid.length > 0) {
                requestURL = requestURL + '&tenantId=' + encodeURIComponent(params.tenant.uuid);
            }
            if('property' in params && params.property && params.property.uuid.length > 0) {
                requestURL = requestURL + '&propertyId=' + encodeURIComponent(params.property.uuid);
            }
            if('connector' in params && params.connector && params.connector.uuid.length > 0) {
                requestURL = requestURL + '&connectorId=' + encodeURIComponent(params.connector.uuid);
            }
            if('createdFrom' in params && params.createdFrom && params.createdFrom.length > 0) {
                requestURL = requestURL + '&createdFrom=' + encodeURIComponent(params.createdFrom);
            }
            if('createdUntil' in params && params.createdUntil && params.createdUntil.length > 0) {
                requestURL = requestURL + '&createdUntil=' + encodeURIComponent(params.createdUntil);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    async getAllMonitoringReadingPages(iotdeviceUUID: string, params: any = {}): Promise<any> {

        const requests = [];
        let currentPage = 1;
        let totalPages = null;
        let nextPageLink = null;
    
        while (totalPages === null || currentPage < totalPages+1) {
            let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/monitoring?';

            //  Filters
            if('from' in params && params.from && params.from.length > 0) {
                requestURL = requestURL + '&from=' + encodeURIComponent(params.from);
            }
            if('until' in params && params.until && params.until.length > 0) {
                requestURL = requestURL + '&until=' + encodeURIComponent(params.until);
            }

            if(nextPageLink) {
                requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + nextPageLink;
            }
            const response: Promise<any> = axiosLimiter.get(requestURL);

            if (totalPages === null || currentPage < totalPages+1) {
                const results: any = await response;
                totalPages = parseInt(results.data.meta.page.totalPages);
                nextPageLink = results.data.links.next;
            }
            requests.push(response);
            currentPage++;
        }
    
        return Promise.all(requests);
    },
    async getMonitoringReadingPage(iotdeviceUUID: string, params: any): Promise<any> {

        let requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/monitoring?';

        //  Filters
        if('from' in params && params.from && params.from.length > 0) {
            requestURL = requestURL + '&from=' + encodeURIComponent(params.from);
        }
        if('until' in params && params.until && params.until.length > 0) {
            requestURL = requestURL + '&until=' + encodeURIComponent(params.until);
        }
        // Paging
        if('itemsPerPage' in params && params.itemsPerPage) {
            requestURL = requestURL + '&page[size]=' + encodeURIComponent(params.itemsPerPage);
        }
        if('nextPageLink' in params && params.nextPageLink && params.nextPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.nextPageLink;
        }
        if('prevPageLink' in params && params.prevPageLink && params.prevPageLink.length > 0) {
            requestURL = process.env.VUE_APP_BASCLOUD_SERVER_URL + params.prevPageLink;
        }

        return axiosLimiter.get(requestURL);
    },
    getAssociatedAlerts(iotdeviceUUID: string): Promise<any> {
        return axiosLimiter.get(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/alerts');
    },
    addAlert(iotdeviceUUID: string, recipients: string[], maxAge: number, paused?: boolean): Promise<any> {
        const body = {
            "data": {
                "type": "alerts",
                "attributes": {
                    "maxAge": maxAge,
                    "recipients": recipients
                }
            }
        } as any;

        if(paused !== undefined) {
            body.data.attributes.paused = paused;
        }

        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/alerts', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    updateAlert(iotdeviceUUID: string, alertUUID: string, recipients?: string[], maxAge?: number, paused?: boolean): Promise<any> {
        const body = {
            "data": {
                "id": alertUUID,
                "type": "alerts",
                "attributes": {
                }
            }
        } as any;

        if(recipients && recipients.length > 0) {
            body.data.attributes.recipients = recipients;
        }
        if(maxAge) {
            body.data.attributes.maxAge = maxAge;
        }
        if(paused !== undefined) {
            body.data.attributes.paused = paused;
        }

        return axiosLimiter.patch(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/alerts/' + alertUUID, JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    removeAlert(iotdeviceUUID: string, alertUUID: string): Promise<any> {
        return axiosLimiter.delete(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/iotdevices/' + iotdeviceUUID + '/alerts/' + alertUUID);
    },
}
  