
  import Vue from 'vue'
  import LabelingService from '@/services/LabelingService';
  import { extractErrorMessage } from '@/utils/Util';
  import { v4 as uuidv4 } from 'uuid';

  export default Vue.extend({
    name: 'DevicePrintLabelsDialog',

    props: ['devices'],
    data: (vm: any): any => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        requiredRule: [
          (v: string) => !!v || vm.requiredError
        ],
        requiredSelect: [
          (v: any) => Object.keys(v).length > 0 || vm.requiredError
        ],
        alertType: "error",
        loading: false,
        selectedLayout: '30334',
        layouts: ['30334']
    }),
    methods: {
      close() {
        this.localDialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      async confirmGenerate() {
        this.loading = true;
      
        try {
            const label = await LabelingService.createDeviceLabel(this.$root.$store.state.session.selectedTenant.uuid, this.devices, this.selectedLayout);

            this.alertType = "success";
            this.alertText = this.$t('device.successLabelCreate') as string;
            this.alertVisible = true;

            // add entry to notifications menu
            this.$store.commit("addNotification", {
                uuid: uuidv4(),
                type: 'labels',
                title: this.$t('entity.devices') + ' ' + this.$t('entity.labels'),
                details: this.$t('device.generateLabelNotificationText') + ' ' + this.devices.length + ' ' + this.$t('entity.devices'),
                finished: false,
                createdAt: label.createdAt,
                new: true,
                payload: label
                }
            );
            // notifications menu adds timer that checks until document is finished, when finished shows download button
            
            this.$emit("generated", label);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('device.errorLabelCreate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        }
      }
    },
    computed: {
        requiredError(): any {
            return this.$t('required');
        },
    }
  })
