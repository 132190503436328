
  import Vue from 'vue'

  export default Vue.extend({
    name: 'MonthDatePicker',

    props: ['value', 'label', 'rules', 'dense', 'clearable'],
    data() {
      return {
        dateMenu: false,
        datePicker: true,
        date: undefined as string | undefined,
        errorMessages: [] as string[],
        error: false,
      }
    },
    created() {
      this.date = (this.value ? this.value : undefined);
      
      // console.log(this.date, this.pickerType);
      // So that the value is emitted atleast once
      this.onDateSave();
    },
    methods: {
      onInputClear() {
        this.dateMenu = false;
        this.datePicker = true;

        this.date = undefined;
        this.$emit("change", undefined);
        this.$emit('input', undefined);
      },
      onDateSave() {
        this.dateMenu = false;
        this.datePicker = true;

        this.$emit("change", this.date);
        this.$emit('input', this.date);
      },
    },
    computed: {       
    },
    watch: {
      dateTime(value: Date) {
        this.rules.forEach((rule: any) => {
          this.error = false;
          this.errorMessages = [];

          const res = rule(value);
          if(res !== true) {
            this.error = true;
            this.errorMessages.push(res);
          }
        });
      }
    }
  })
