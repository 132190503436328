import axiosLimiter from '@/api/RateLimiter';

export default {
    createDeviceReport(recipients: string[], columns: string[], filters: any, options={}, cc=[], bcc=[]): Promise<any> {
        const body = {
            "data": {
                "type": "device-report",
                "attributes": {
                    "filters": filters,
                    "columns": columns,
                    "recipients": recipients
                }
            }
        } as any;

        if(Object.keys.length > 0) {
            body.data.attributes.options = options;
        }
        if(cc.length > 0) {
            body.data.attributes.cc = cc;
        }
        if(bcc.length > 0) {
            body.data.attributes.bcc = bcc;
        }
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/reports', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },    
    createReadingReport(recipients: string[], columns: string[], filters: any, options={}, cc=[], bcc=[]): Promise<any> {
        const body = {
            "data": {
                "type": "reading-report",
                "attributes": {
                    "filters": filters,
                    "columns": columns,
                    "recipients": recipients
                }
            }
        } as any;

        if(Object.keys.length > 0) {
            body.data.attributes.options = options;
        }
        if(cc.length > 0) {
            body.data.attributes.cc = cc;
        }
        if(bcc.length > 0) {
            body.data.attributes.bcc = bcc;
        }
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/reports', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },    
    createBillingReport(recipients: string[], columns: string[], filters: any, options={}, cc=[], bcc=[]): Promise<any> {
        const body = {
            "data": {
                "type": "billing-report",
                "attributes": {
                    "filters": filters,
                    "columns": columns,
                    "recipients": recipients
                }
            }
        } as any;

        if(options) {
            body.data.attributes.options = options;
        }
        if(cc.length > 0) {
            body.data.attributes.cc = cc;
        }
        if(bcc.length > 0) {
            body.data.attributes.bcc = bcc;
        }
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/reports', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },
    createMeteringReport(recipients: string[], columns: string[], filters: any, options={}, cc=[], bcc=[]): Promise<any> {
        const body = {
            "data": {
                "type": "metering-report",
                "attributes": {
                    "filters": filters,
                    "columns": columns,
                    "recipients": recipients
                }
            }
        } as any;

        if(Object.keys.length > 0) {
            body.data.attributes.options = options;
        }
        if(cc.length > 0) {
            body.data.attributes.cc = cc;
        }
        if(bcc.length > 0) {
            body.data.attributes.bcc = bcc;
        }
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/reports', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },   
    createIotDeviceReport(recipients: string[], columns: string[], filters: any, options={}, cc=[], bcc=[]): Promise<any> {
        const body = {
            "data": {
                "type": "iotdevice-report",
                "attributes": {
                    "filters": filters,
                    "columns": columns,
                    "recipients": recipients
                }
            }
        } as any;

        if(Object.keys.length > 0) {
            body.data.attributes.options = options;
        }
        if(cc.length > 0) {
            body.data.attributes.cc = cc;
        }
        if(bcc.length > 0) {
            body.data.attributes.bcc = bcc;
        }
        return axiosLimiter.post(process.env.VUE_APP_BASCLOUD_SERVER_URL + '/reports', JSON.stringify(body), { headers: { 'Content-Type': 'application/vnd.api+json'}});
    },   
}
  