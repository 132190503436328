
  import Vue from 'vue'
  import DeviceService from '@/services/DeviceService';

  export default Vue.extend({
    name: 'EntityMetaDialog',
    props: ['entity'],
    data: () => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error"
    }),
    methods: {
      close() {
        this.localDialog = false;
      },
      clear() {
        this.alertVisible = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
    }
  })
