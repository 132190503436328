
  import Vue from 'vue'

  export default Vue.extend({
    name: 'InfoCard',

    props: ['title', 'value', 'color', 'background-color', 'loading'],
    data: () => ({
    }),
  })
