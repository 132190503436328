
  import Vue from 'vue'
  import DeviceService from '@/services/DeviceService';
import { extractErrorMessage } from '@/utils/Util';

  export default Vue.extend({
    name: 'DeviceRestoreDialog',

    props: ['device'],
    data: () => ({
        localDialog: false,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
    }),
    methods: {
      close() {
        this.localDialog = false;
      },
      clear() {
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      confirmRestore() {
        this.loading = true;
      
        DeviceService.restoreDevice(this.$root.$store.state.session.selectedTenant.uuid, this.device.uuid).then(() => {
            this.alertType = "success";
            this.alertText = this.$t('device.successRestore') as string;
            this.alertVisible = true;
            this.loading = false;

            setTimeout(() => { 
                this.$emit("restore", this.device);
                this.dismiss(); 
            }, 2000);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('device.errorRestore') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      }
    }
  })
