
import Vue from 'vue'

import { RRule, rrulestr } from 'rrule'

import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue'
import DeviceService from '@/services/DeviceService'

const frequencyInputsTable = {
  yearly: {
    rrule: RRule.YEARLY,
    until: true,
    interval: true,
    month: true,
    day_of_month: true,
    day_of_the_week: false,
  },
  monthly: {
    rrule: RRule.MONTHLY,
    until: true,
    interval: true,
    month: false,
    day_of_month: true,
    day_of_the_week: false,
  },
  weekly: {
    rrule: RRule.WEEKLY,
    until: true,
    interval: true,
    month: false,
    day_of_month: false,
    day_of_the_week: true,
  },
  daily: {
    rrule: RRule.DAILY,
    until: true,
    interval: true,
    month: false,
    day_of_month: false,
    day_of_the_week: false,
  },
  once: {
    rrule: null,
    until: false,
    interval: false,
    month: false,
    day_of_month: false,
    day_of_the_week: false,
  },
}

const range = (n: number) => Array.from({ length: n }, (v, k) => k + 1)
const thirty1 = range(31)
const thirty = range(30)
const twenty9 = range(29)

const daysPerMonth = {
  January: thirty1,
  February: twenty9,
  March: thirty1,
  April: thirty,
  May: thirty1,
  June: thirty,
  July: thirty1,
  August: thirty1,
  September: thirty,
  October: thirty1,
  November: thirty,
  December: thirty1,
}

const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]

export default Vue.extend({
  name: 'TaskInfoDialog',

  components: {
    EntityMetaDialog,
    // Weird import necessary due to circular dependency
    DeviceInfoDialog: () =>
      import('@/components/entities/device/DeviceInfoDialog.vue') as any,
  },
  props: ['task'],
  data: (vm: any): any => ({
    frequencyInputsTable,
    daysPerMonth,
    weekdays,
    repetitionSelection: '',
    rrule: {
      interval: null,
      start: '',
      frequency: null,
      until_num: null,
      month: null,
      daysOfTheMonth: [],
      daysOfTheWeek: [],
      until_date: null,
    },
    dialog: false,
    alertText: '',
    alertVisible: false,
    alertType: 'error',
    loading: false,
    loadingDevice: false,
  }),
  created() {
    //
  },
  methods: {
    close() {
      this.dialog = false
    },
    clear() {
      this.rrule = {
        interval: null,
        start: '',
        frequency: null,
        until_num: null,
        month: null,
        daysOfTheMonth: [],
        daysOfTheWeek: [],
        until_date: null,
      }
      this.alertVisible = false
      this.loading = false
      this.loadingDevice = false
    },
    dismiss() {
      this.clear()
      this.close()
    },
    parseRRule() {
      let _rrule = rrulestr(this.task.rrule)

      this.rrule.start = _rrule.options.dtstart

      if (_rrule.options.freq === RRule.YEARLY) {
        if (_rrule.options.count === 1) {
          // this means its once
          this.rrule.frequency = 'Once'
        } else {
          this.rrule.frequency = 'Yearly'
        }
      } else if (_rrule.options.freq === RRule.MONTHLY) {
        this.rrule.frequency = 'Monthly'
      } else if (_rrule.options.freq === RRule.WEEKLY) {
        this.rrule.frequency = 'Weekly'
      } else if (_rrule.options.freq === RRule.DAILY) {
        this.rrule.frequency = 'Daily'
      } else {
        // unsupported
        this.rrule.frequency = 'Once'
      }

      if (
        this.frequencyInputsTable[this.rrule.frequency.toLowerCase()]
          .interval &&
        _rrule.options.interval
      ) {
        this.rrule.interval = Number(_rrule.options.interval)
      }
      if (_rrule.options.count && this.rrule.frequency !== 'Once') {
        this.repetitionSelection = 'until_count'
        this.rrule.until_num = Number(_rrule.options.count)
      }
      if (_rrule.options.until) {
        this.repetitionSelection = 'until_date'
        this.rrule.until_date = new Date(_rrule.options.until).toISOString()
      }
      if (!this.rrule.until_num && !this.rrule.until_date) {
        // case its indefinitly
        this.repetitionSelection = 'forever'
      }
      if (
        this.frequencyInputsTable[this.rrule.frequency.toLowerCase()].month &&
        _rrule.options.bymonth
      ) {
        const bymonth = _rrule.options.bymonth
        if (bymonth.length > 0 && typeof bymonth[0] === 'number')
          this.rrule.month = Object.keys(daysPerMonth)[bymonth[0] - 1]
      }
      if (
        this.frequencyInputsTable[this.rrule.frequency.toLowerCase()]
          .day_of_month &&
        _rrule.options.bymonthday
      ) {
        this.rrule.daysOfTheMonth = _rrule.options.bymonthday
      }
      if (
        this.frequencyInputsTable[this.rrule.frequency.toLowerCase()]
          .day_of_the_week &&
        _rrule.options.byweekday
      ) {
        this.rrule.daysOfTheWeek = _rrule.options.byweekday.map(
          (day: number) => this.weekdays[day]
        )
      }
    },
    async loadDevice() {
      try {
        this.loadingDevice = true
        const device = await DeviceService.getSingle(
          this.currentTenant,
          this.task.deviceId
        )
        Vue.set(this.task, 'device', device) // important else DOM is not updated
        this.loadingDevice = false
      } catch (err: any) {
        console.log(err)
        console.log(err.response)
        Vue.set(this.task, 'device', {})
        this.loadingDevice = false
      }
    },
  },
  watch: {
    async dialog(value: boolean) {
      if (value) {
        // console.log(this.task)
        if (!this.task.device || Object.keys(this.task.device).length === 0) {
          this.loadDevice()
        }
        this.parseRRule()
      } else {
        this.clear()
      }
    },
  },
  computed: {
    intervalHint() {
      const rrule = this.rrule
      return `1 means every ${
        rrule.frequency === 'Daily' ? 'Day' : rrule.frequency.slice(0, -2)
      }, 2 is every other ${
        rrule.frequency === 'Daily' ? 'Days' : rrule.frequency.slice(0, -2)
      }`
    },
    currentTenant(): any {
      return this.$root.$store.state.session.selectedTenant.uuid
    },
    frequencies(): any {
      // TODO add i18n translation
      const keys = Object.keys(frequencyInputsTable)
      return keys.map(
        (value: string) => value.charAt(0).toUpperCase() + value.slice(1)
      )
    },
    months(): any {
      const keys = Object.keys(daysPerMonth)
      return keys.map(
        (value: string) => value.charAt(0).toUpperCase() + value.slice(1)
      )
    },
    requiredError(): any {
      return this.$t('required')
    },
  },
})
