
  import Vue from 'vue'

  import DeviceService from '@/services/DeviceService';
  import SetpointService from '@/services/SetpointService';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';

  export default Vue.extend({
    name: 'SetpointEditDialog',
    props: ['setpoint'],
    components: {
        EntityMetaDialog,
    },
    data: (vm: any): any => ({
        localdialog: false,
        localSetpoint: {} as any,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingDevice: false,
        setpointStates: ["active", "sent", "deprecated"],
        numberRule: [
            (v: string) => !!v || vm.requiredError,
            (v: string) => !isNaN(parseFloat(v)) || vm.numberError,
        ],
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        devices: [] as any[],
        associatedDevice: {} as any,
        updatedAssociatedDevice: {} as any,
    }),
    async created() {
        this.localSetpoint = Object.assign({}, this.setpoint);
    },
    methods: {
      close() {
        this.localdialog = false;
      },
      clear() {
        this.devices = [];
        this.associatedDevice = {};
        this.updatedAssociatedDevice = {};
        this.alertVisible = false;
        this.loading = false;
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      save() {
        this.alertVisible = false;
        this.loading = true;

        let value = "";
        // let timestamp = "";
        // let state = "";
        let associatedDeviceUUID = "";

        if(this.localSetpoint.value !== this.setpoint.value) {
            value = this.localSetpoint.value;
        }
        // if(this.localSetpoint.timestamp !== this.setpoint.timestamp) {
        //     timestamp = this.localSetpoint.timestamp;
        // }
        // if(this.localSetpoint.state !== this.setpoint.state) {
        //     state = this.localSetpoint.state;
        // }
        if(this.updatedAssociatedDevice !== this.associatedDevice) {
            associatedDeviceUUID = this.updatedAssociatedDevice;
        }

        SetpointService.updateSetpoint(this.$root.$store.state.session.selectedTenant.uuid, this.setpoint.uuid, value, associatedDeviceUUID).then((setpoint) => {

            this.alertType = "success";
            this.alertText = this.$t('setpoint.successUpdate') as string;
            this.alertVisible = true;

            this.localSetpoint = setpoint;
            this.associatedDevice = this.updatedAssociatedDevice;
            
            this.$emit("update", setpoint);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('setpoint.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getAssociatedDevice() {
        this.loadingDevice = true;

        SetpointService.getAssociatedDevice(this.currentTenant, this.setpoint.uuid).then((device) => {
            this.loadingDevice = false;

            this.associatedDevice = device;
            this.updatedAssociatedDevice = this.associatedDevice;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('setpoint.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loadingDevice = false;
        });
      },
      getDevices() {
        this.devices = [];
        this.loading = true;

        if (!this.$root.$store.getters.loggedIn(Date.now())) {
            this.$router.push('/login');
        } else {

          DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
            this.loading = false;

            this.devices = devices;
          }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;  
            this.loading = false;    
          });
        }
      },
      getColor(state: string) {
        if (state === 'deprecated') return 'rgba(244, 64, 52, 0.6)'
        else if (state === 'sent') return 'rgba(255, 152, 0, 0.6)'
        else return 'rgba(76, 175, 80, 0.6)'
      },
      autoComplete,
    },
    watch: {
        setpoint() {
            this.localSetpoint = Object.assign({}, this.setpoint);
        },
        localdialog(value: boolean) {
            if(value) {
                this.getAssociatedDevice();
                this.getDevices();
            } else {
                this.clear();
            }
        }
    },
    computed: {
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        numberError(): any {
            return this.$t('mustNumber');
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        }
    }
  })
