
  import Vue from 'vue'
  import ConnectorService from '@/services/ConnectorService';
  import DeviceService from '@/services/DeviceService';
  import { autoComplete, extractErrorMessage } from '@/utils/Util';
  import EntityMetaDialog from '@/components/misc/EntityMetaDialog.vue';
  import TagCreateDialog from '../tag/TagCreateDialog.vue';
import TagService from '@/services/TagService';
import AlertsListDialog from '../alert/AlertsListDialog.vue';

  export default Vue.extend({
    name: 'ConnectorEditDialog',
    props: ['connector'],
    components: {
        EntityMetaDialog,
        TagCreateDialog,
        AlertsListDialog,
        DeviceInfoDialog: () => import('@/components/entities/device/DeviceInfoDialog.vue') as any
    },
    data: (vm: any): any => ({
        localdialog: false,
        localConnector: {} as any,
        showAddDeviceField: false,
        valid: true,
        alertText: "",
        alertVisible: false,
        alertType: "error",
        loading: false,
        loadingDevices: false,
        requiredRule: [
            (v: string) => !!v || vm.requiredError
        ],
        associatedDevices: [] as any[],
        devices: [] as any[],
        selectedDevice: null,
        associatedDeviceFilter: "",
        associatedTags: [] as any[],
        updatedAssociatedTags: [] as any[],
        tags: [] as any[],
        associatedAlerts: [] as any[],

    }),
    async created() {
        this.localConnector = Object.assign({}, this.connector);
    },
    methods: {
      onDeviceRemoveClick(item: any) {
        // TODO currently its not possible to remove a device from a property without assigning it to another
      },
      onRemoveTagClick(tag: any) {
        this.updatedAssociatedTags.splice(this.updatedAssociatedTags.findIndex((t: any) => t.uuid === tag.uuid), 1);
      },
      onAddDeviceClick() {
          if(this.selectedDevice) {
            this.loading = true;

            DeviceService.updateDevice(this.currentTenant, 
             (this.selectedDevice as any).uuid, "", "", "", "", "", this.connector.uuid).then((device: any) => {
                this.alertType = "success";
                this.alertText = this.$t('connector.successAddDevice') + (this.selectedDevice as any).name;
                this.alertVisible = true;
                this.loading = false;
            }).catch((err) => {
                console.log(err);
                console.log(err.response);
                this.alertType = "error";
                this.alertText = this.$t('connector.errorAddDevice') + ' ' + extractErrorMessage(err);
                this.alertVisible = true; 
                this.loading = false;
            });
          }
      },
      onShowAddDevicesClick() {
        if(this.devices.length === 0) {
          this.loadingDevices = true;

          DeviceService.getAllCollectionPages(this.currentTenant).then((devices: any[]) => {
            this.loadingDevices = false;

            this.devices = devices;
          }).catch((err: any) => {
            console.log(err, err.response);  
            this.alertType = "error";
            this.alertText = this.$t('device.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true;    
            this.loadingDevices = false;  
          });
        }
        this.showAddDeviceField = ! this.showAddDeviceField
      },
      close() {
        this.localdialog = false;
      },
      clear() {
        this.associatedDevices = [];
        this.devices = [];
        this.associatedTags = [];
        this.tags = [];
        this.updatedAssociatedTags = [];
        this.associatedAlerts = [];
        this.selectedDevice = null;
        this.alertVisible = false;
        this.loading = false;
        this.loadingDevices = false;
        this.associatedDeviceFilter = "";
      },
      dismiss() {
        this.clear();
        this.close();
      },
      validate() {
        if(this.editForm.validate()) {
          this.save();
        }
      },
      async save() {
        this.alertVisible = false;
        this.loading = true;

        let name = "";

        if(this.localConnector.name !== this.connector.name) {
            name = this.localConnector.name;
        }

        let addedTags = this.updatedAssociatedTags.filter((value: any) => this.associatedTags.findIndex((tag: any) => tag.uuid === value.uuid) < 0); // new tags
        let removedTags = this.associatedTags.filter((value: any) => this.updatedAssociatedTags.findIndex((tag: any) => tag.uuid === value.uuid) < 0);  // removed tags

        try {
            if(addedTags.length > 0)
                await ConnectorService.addTags(this.currentTenant, this.connector.uuid, addedTags);
            if(removedTags.length > 0)
                await ConnectorService.removeTags(this.currentTenant, this.connector.uuid, removedTags);

            const connector = await ConnectorService.updateConnector(this.currentTenant, this.connector.uuid, name); 

            this.alertType = "success";
            this.alertText = this.$t('connector.successUpdate') as string;
            this.alertVisible = true;

            this.localConnector = connector;
            
            this.$emit("update", connector);
            setTimeout(() => { 
                this.dismiss(); 
            }, 1500);
        } catch(err: any) {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('connector.errorUpdate') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        }
      },
      getAssociatedDevices() {
        this.loading = true;

        ConnectorService.getAssociatedDevices(this.currentTenant, this.connector.uuid).then((devices: any[]) => {
            if(devices.length > 0) {
                this.associatedDevices = devices;
            } else {
                this.alertType = "warning";
                this.alertText = this.$t('connector.emptyAssociatedDevice') as string;
                this.alertVisible = true;
            }
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('connector.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getAssociatedAlerts() {
        this.loading = true;

        ConnectorService.getAssociatedAlerts(this.$root.$store.state.session.selectedTenant.uuid, this.connector.uuid).then((alerts: any[]) => {
            this.associatedAlerts = alerts;
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('alert.loadingError') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      openAssociatedDevices() {
          this.$router.push({ name: 'Devices', params: { connector: this.connector } });
      },
      getAssociatedTags() {
        this.loading = true;

        ConnectorService.getAssociatedTags(this.$root.$store.state.session.selectedTenant.uuid, this.connector.uuid).then((tags: any[]) => {
            if(tags.length > 0) {
                this.associatedTags = tags;
                this.updatedAssociatedTags = [...tags];
            }
            this.loading = false;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('property.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      getTags() {
        this.loading = true;

        TagService.getAllCollectionPages(this.$root.$store.state.session.selectedTenant.uuid).then((tags: any[]) => {
            this.loading = false;
            this.tags = tags;
        }).catch((err) => {
            console.log(err);
            console.log(err.response);
            this.alertType = "error";
            this.alertText = this.$t('property.errorAssociatedDevice') + ' ' + extractErrorMessage(err);
            this.alertVisible = true; 
            this.loading = false;
        });
      },
      async onAlertDelete(item: any) {
        this.associatedAlerts.splice(this.associatedAlerts.findIndex((value: any) => value.uuid === item.uuid), 1);
      },
      async onAlertCreate(item: any) {
        this.associatedAlerts.push(item);
      },
      async onAlertUpdate(item: any) {
        this.associatedAlerts.splice(this.associatedAlerts.findIndex((value: any) => value.uuid === item.uuid), 1);
        this.associatedAlerts.push(item);
      },
      autoComplete,
    },
    watch: {
        connector() {
            this.localConnector = Object.assign({}, this.connector);
        },
        async localdialog(value: boolean) {
            if(value) {
                this.getAssociatedDevices();
                this.getAssociatedTags();
                this.getTags();
                this.getAssociatedAlerts();
            } else {
                this.clear();
            }
        }
    },
    computed: {
        filteredAssociatedDevices(): any[] {
            return this.associatedDevices.filter((value: any) => this.autoComplete(value, this.associatedDeviceFilter));
        },
        editForm(): any {
            return this.$refs.editForm;
        },
        requiredError(): any {
            return this.$t('required');
        },
        currentTenant(): any {
          return this.$root.$store.state.session.selectedTenant.uuid;
        }
    }
  })
